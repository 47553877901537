import "../src/common/flexboxgrid.css";
import "./App.scss";
import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { useInitializeExperiments } from "./components/Experiments/experimentHelpers";
import LoadingRoomContainer from "./components/LoadingRoom/LoadingRoomContainer";
import { GcpPaths } from "./constants";
import { CloudLinkType } from "./Type";
import FeatureFlags from "./utils/FeatureFlags";
import { sendFeatureFlagErrorLogs } from "./utils/useFeatureFlagEnabled";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { ErrorBoundaryWrapper } from "./components/Error/ErrorBoundary";

performance.mark("Render.App");

const OciLandingPage = lazy(() => import("./pages/oci/OciLandingPage"));
const SignUpPage = lazy(() => import("./pages/signup/SignUpPage"));
const ActivationPage = lazy(() => import("./pages/activation/ActivationPage"));
const HomePage = lazy(() => import("./pages/home/HomePage"));
const AzureHome = lazy(() => import("./azure/AzureHome"));
const AwsHome = lazy(() => import("./aws/AwsHome"));
const GcpWelcomeFlow = lazy(() => import("./pages/gcp/WelcomeFlow/GcpWelcomeFlow"));
const GcpSignInFlow = lazy(() => import("./pages/gcp/SignInFlow/GcpSignInFlow"));
const GcpSignUpFlow = lazy(() => import("./pages/gcp/SignUpFlow/GcpSignUpFlow"));
const Unlink = lazy(() => import("./Unlink"));
const O4ALandingPage = lazy(() => import("./pages/o4a/O4ALandingPage"));

function App() {
  const [enableGcp, enableAws, enableLocalization, enableUnlinking] = React.useMemo(
    () => [
      FeatureFlags.isEnabled("enable-gcp", err => sendFeatureFlagErrorLogs("enable-gcp", err)),
      FeatureFlags.isEnabled("enable-aws", err => sendFeatureFlagErrorLogs("enable-aws", err)),
      FeatureFlags.isEnabled("enable-localization", err =>
        sendFeatureFlagErrorLogs("enable-localization", err),
      ),
      FeatureFlags.isEnabled("enable-unlinking", err =>
        sendFeatureFlagErrorLogs("enable-unlinking", err),
      ),
    ],
    [],
  );

  useInitializeExperiments();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Suspense fallback={<LoadingRoomContainer show={true} showHeader={true} delay={1000} />}>
            <Routes>
              {enableGcp && (
                <>
                  {
                    <Route
                      path={GcpPaths.ROOT_PATH}
                      element={
                        <ErrorBoundaryWrapper>
                          <GcpWelcomeFlow />
                        </ErrorBoundaryWrapper>
                      }
                    >
                      <Route
                        path={GcpPaths.ROOT_PATH}
                        element={
                          <ErrorBoundaryWrapper>
                            <GcpSignInFlow />
                          </ErrorBoundaryWrapper>
                        }
                      >
                        <Route index element={<Navigate to={GcpPaths.SIGNUP_PATH} />} />
                        <Route
                          path={GcpPaths.SIGNUP_PATH}
                          element={
                            <ErrorBoundaryWrapper>
                              <GcpSignUpFlow />
                            </ErrorBoundaryWrapper>
                          }
                        />
                        <Route
                          path={GcpPaths.WILDCARD_PATH}
                          element={<Navigate to={GcpPaths.ROOT_PATH} />}
                        />
                      </Route>
                    </Route>
                  }
                </>
              )}
              {enableAws && (
                <>
                  {enableUnlinking && (
                    <Route
                      path="/aws/unlink"
                      element={<Unlink cloudType={CloudLinkType.AWS} />}
                    ></Route>
                  )}
                  <Route path="/aws" element={<AwsHome enableLocalization={enableLocalization} />}>
                    <Route index element={<Navigate to="/aws/linking" replace />} />
                    <Route path="/aws/linking" element={<OciLandingPage />}></Route>
                    <Route path="/aws/signup" element={<SignUpPage />}></Route>
                    <Route path="/aws/activate" element={<ActivationPage />} />
                    <Route path="/aws/*" element={<Navigate to="/aws" />} />
                  </Route>
                </>
              )}

              {enableUnlinking && (
                <Route
                  path="/azure/unlink"
                  element={<Unlink cloudType={CloudLinkType.AZURE} />}
                ></Route>
              )}
              <Route path="/azure" element={<AzureHome enableLocalization={enableLocalization} />}>
                <Route index element={<O4ALandingPage />} />
                {["/azure/linking", "/azure/signup", "/azure/activate"].map(path => (
                  <Route key={path} path={path} element={<O4ALandingPage />} />
                ))}
                <Route path="/azure/activate/automated" element={<ActivationPage />} />
                <Route path="/azure/activate/guided" element={<ActivationPage />} />
                <Route path="/azure/linking/automated" element={<OciLandingPage />} />
                <Route path="/azure/linking/guided" element={<OciLandingPage />} />
                <Route path="/azure/signup/automated" element={<SignUpPage />} />
                <Route path="/azure/signup/guided" element={<SignUpPage />} />
                <Route path="/azure/*" element={<Navigate to="/azure" />} />
              </Route>

              <Route
                path="*"
                element={!enableGcp && !enableAws ? <Navigate to="/azure" /> : <HomePage />}
              />
            </Routes>
          </Suspense>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
