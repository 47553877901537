class QueryParams {
  /**
   * Url parameter name for code that is passed back from GCP
   */
  public static readonly Code = "code";

  /**
   * Url parameter name for state that is passed to/back from GCP
   */
  public static readonly State = "state";
}

export default QueryParams;
