import * as kernel from "./kernel";

class BrowserNetworkServices implements kernel.INetworkServices {
  deliverHttp(request: Request): Promise<Response> {
    return fetch(request);
  }
}

export class BrowserIOKernel implements kernel.IIOKernel {
  readonly networkServices: kernel.INetworkServices;
  readonly fileServices: kernel.IFileServices;
  constructor() {
    this.networkServices = new BrowserNetworkServices();
    this.fileServices = null;
  }
}
