import { SecurityToken } from "../fluorine/SecurityToken";
import jwtDecode from "jwt-decode";

const TIME_BEFORE_EXPIRATION = 10 * 60; // 10 minutes in seconds
const CLOCK_OFFSET_IN_SECONDS = 60; // 1 minute in seconds

export const isTokenAboutToExpire = (tokenString: string): boolean => {
  const securityToken = jwtDecode(tokenString) as SecurityToken;
  const now = Date.now() / 1000;
  return now < securityToken.exp && now + TIME_BEFORE_EXPIRATION >= securityToken.exp;
};

export const isTokenDead = (tokenString: string): boolean => {
  const securityToken = jwtDecode(tokenString) as SecurityToken;
  const now = Date.now() / 1000;
  return now >= securityToken.exp - CLOCK_OFFSET_IN_SECONDS;
};

// If the token is being used after reusedTokenExpiration sec of initialization, return true
export const isTokenReused = (tokenString: string, reusedTokenExpiration: number): boolean => {
  const securityToken = jwtDecode(tokenString) as SecurityToken;
  const now = Date.now() / 1000;
  return now >= securityToken.iat + reusedTokenExpiration;
};

// Takes a date string and returns a timestamp in seconds
export const dateStringToTimestamp = (dateString: string): number => {
  return new Date(dateString).getTime() / 1000;
};
