import "./AzureLoadingRoom.css";
import HeaderContainer from "../Headers/HeaderContainer";

performance.mark("Render.Loading");

type AzureLoadingRoomProps = {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  showHeader?: boolean;
  hideSpinner?: boolean;
};

const AzureLoadingRoom = ({ showHeader, hideSpinner, title, subtitle }: AzureLoadingRoomProps) => {
  return (
    <>
      {showHeader && <HeaderContainer includeLogo={false} />}
      <div className="o4aLoadingPageContainer" data-testid="loading-room">
        <div className="o4aLoadingPageLoadingGraphic"></div>
        {!hideSpinner && <div className="spinner" />}
        <div className="loadingRoomTitle" data-testid="loading-room-title">
          {title}
        </div>
        <div className="loadingRoomSubtitle" data-testid="loading-room-subtitle">
          {subtitle}
        </div>
      </div>
    </>
  );
};

export default AzureLoadingRoom;
