/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */

import jwtDecode from "jwt-decode";
import QueryParams from "./constants/queryParams";
import StorageKeys from "./constants/storageKeys";
import UrlConstants from "./constants/urls";
import { TokenState } from "./enums/tokenState";
import { AccountInfo } from "./interfaces/accountInfo";
import { FetchApiSignature, Options } from "./interfaces/fetchApiSignature";
import { FetchTokenProps } from "./interfaces/fetchTokenProps";
import { GcpAuthConfig } from "./interfaces/gcpAuthConfig";
import { GetAccessTokenResponse } from "./interfaces/getAccessTokenResponse";
import { IdentityToken } from "./interfaces/identityToken";
import { UserInfo } from "./interfaces/userInfo";
import { UserTokens } from "./interfaces/userTokens";
import { getWindow } from "./utils/browserHelpers";
import { generatePKCEChallenge } from "./utils/crypto";
import ErrorUtils from "./utils/errorUtils";
import {
  clearDb,
  createDbInstance,
  getGcpIndexedDBData,
  initGcpIndexedDBData,
} from "./utils/indexedDb";
import {
  getItemFromLocalStore,
  removeItemFromLocalStore,
  setItemToLocalStore,
} from "./utils/storageUtils";
import {
  fetchTokenFromMccp,
  getIdentityTokenValue,
  isTokenAboutToExpire,
  isTokenDead,
} from "./utils/tokenUtils";
import { constructState } from "./utils/urlStateUtils";
import {
  getAuthUrl,
  getRevokeTokenUrl,
  getRoutingPath,
  restoreApplicationState,
} from "./utils/urlUtils";

class GcpAuth {
  private gcpConfig: GcpAuthConfig;
  private isHandleRedirectInProcess: boolean;

  private constructor(config: GcpAuthConfig) {
    this.gcpConfig = config;
    this.isHandleRedirectInProcess = false;
  }

  private static singleton: GcpAuth;

  public static async init(config: GcpAuthConfig): Promise<GcpAuth> {
    createDbInstance();
    GcpAuth.singleton = new GcpAuth(config);

    await GcpAuth.singleton.handleRedirect();
    return GcpAuth.singleton;
  }

  public static getInstance(): GcpAuth {
    if (!GcpAuth.singleton) {
      throw ErrorUtils.authNotInitializedError();
    }
    return GcpAuth.singleton;
  }

  public async authenticate(force: boolean = false) {
    try {
      if (!(this.getTokenState() === TokenState.VALID) || force) {
        const state: string = await this.createStateParamValue();
        const challenge = await this.generateCodeChallenge();
        const url = getAuthUrl(UrlConstants.GcpAuthURLTemplate, this.gcpConfig, challenge, state);
        getWindow().location.replace(url);
      }
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
  }

  public async logOut() {
    await this.revokeToken();
    removeItemFromLocalStore(StorageKeys.UserTokensKey);
    await clearDb();
    getWindow().location.href = this.gcpConfig.signoutUrl
      ? this.gcpConfig.signoutUrl
      : this.gcpConfig.redirectUri;
  }

  public async signRequest(request: Request, options?: Options): Promise<Request> {
    const intRequest = new Request(request);
    const userTokens = await this.getUserTokens();
    const bearer = `Bearer ${userTokens.access_token}`;
    intRequest.headers.append("Authorization", bearer);
    if (options) {
      options.requestOverride(intRequest, { userTokens });
    }
    return intRequest;
  }

  private async createStateParamValue() {
    let state: string = "";
    try {
      let gcpIndexedDBData = await getGcpIndexedDBData();
      // Create new state key only if one does not exist in IndexDb
      if (!gcpIndexedDBData || !gcpIndexedDBData.stateSignKey) {
        gcpIndexedDBData = await initGcpIndexedDBData();
      }
      const routingPath = getRoutingPath();
      state = await constructState(routingPath);
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
    return state;
  }

  private async generateCodeChallenge() {
    const challenge = await generatePKCEChallenge(128);
    this.saveVerifier(challenge.codeVerifier);
    return challenge.codeChallenge;
  }

  private saveVerifier(codeVerifier: string) {
    setItemToLocalStore(StorageKeys.CodeVerifierKey, codeVerifier);
  }
  private getVerifier() {
    return getItemFromLocalStore(StorageKeys.CodeVerifierKey);
  }

  public async getUserTokens(forceRefresh: boolean = false) {
    let userTokens: UserTokens = getItemFromLocalStore(StorageKeys.UserTokensKey);
    if (userTokens) {
      const isInValid =
        isTokenDead(userTokens.expiry_date) ||
        isTokenAboutToExpire(userTokens.expiry_date) ||
        forceRefresh;
      if (isInValid) {
        userTokens = await this.getRefreshedUserTokens(); // eslint-disable-line camelcase
      }
      return userTokens;
    }
    throw ErrorUtils.userNotLoggedInError();
  }

  private getTokenState(): TokenState {
    let tokenState = TokenState.EMPTY;
    const tokens = getItemFromLocalStore(StorageKeys.UserTokensKey);
    if (tokens) {
      tokenState = !isTokenDead((tokens as UserTokens).expiry_date)
        ? TokenState.VALID
        : TokenState.INVALID;
    }
    return tokenState;
  }

  public async getAccessToken() {
    const tokens = await this.getUserTokens();
    if (tokens) {
      return tokens.access_token;
    }
    throw ErrorUtils.userNotLoggedInError();
  }

  public async getIdentityToken() {
    const tokens = await this.getUserTokens();
    if (tokens) {
      return tokens.id_token;
    }
    throw ErrorUtils.userNotLoggedInError;
  }

  public async getIdentityTokenDetails(): Promise<IdentityToken | undefined> {
    const tokens = await this.getUserTokens();
    if (tokens) {
      return tokens.decoded_id_token;
    }
  }

  public async getAccount(): Promise<AccountInfo | undefined> {
    let accountInfo: AccountInfo | undefined;
    const identityDetails = await this.getIdentityTokenDetails();
    if (identityDetails) {
      accountInfo = {
        email: identityDetails.email,
        name: identityDetails.name,
        idTokenClaims: identityDetails,
      };
    }
    return accountInfo;
  }

  public async getUserInfo(): Promise<UserInfo | undefined> {
    let userInfo: UserInfo | undefined;
    const identityDetails = await this.getIdentityTokenDetails();
    if (identityDetails) {
      userInfo = {
        email: identityDetails.email,
        username: identityDetails.name,
      };
    }
    return userInfo;
  }

  private async getRefreshedUserTokens() {
    const tokens = await this.refreshUserTokens();
    return this.setRefreshedUserTokensToStorage(tokens);
  }

  private setRefreshedUserTokensToStorage(newTokens: GetAccessTokenResponse) {
    const tokens = getItemFromLocalStore(StorageKeys.UserTokensKey);
    if (tokens) {
      tokens.access_token = newTokens.accessToken;
      tokens.expires_in = newTokens.expiresIn;
      setItemToLocalStore(StorageKeys.UserTokensKey, tokens);
    }
    return tokens;
  }

  private async handleRedirect() {
    if (this.isHandleRedirectInProcess) {
      return;
    }
    try {
      this.isHandleRedirectInProcess = true;
      const params = new URLSearchParams(getWindow().location.search);
      if (params && params.has(QueryParams.Code)) {
        const code = params.get(QueryParams.Code);
        if (code) {
          const response = await this.fetchToken(code);
          if (!response.error) {
            const userTokens: UserTokens = {
              access_token: response.accessToken,
              refresh_token: response.refreshToken,
              expires_in: response.expiresIn,
              expiry_date: new Date().getTime() + response.expiresIn * 1000,
              id_token: response.idToken,
              decoded_id_token: response.idToken && jwtDecode(response.idToken),
            };
            setItemToLocalStore(StorageKeys.UserTokensKey, userTokens);
          }
          await restoreApplicationState(params, this.gcpConfig);
        }
      }
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      throw error;
    } finally {
      this.isHandleRedirectInProcess = false;
    }
  }

  public getFetchApi(options?: Options): FetchApiSignature {
    return async (input: string | Request, init?: RequestInit) => {
      const request = await this.signRequest(new Request(input, init), options);
      return fetch(request);
    };
  }

  /**
   * @returns true if authenticated, otherwise returns an error
   */
  public async isSessionActive(): Promise<boolean> {
    const tokens = getItemFromLocalStore(StorageKeys.UserTokensKey);
    return tokens && !isTokenDead((tokens as UserTokens).expiry_date);
  }

  /**
   * Get the token issued at - iat from the security token
   * @returns token issued at as a timestamp in seconds
   */
  public async getIssuedAt(): Promise<number> {
    return getIdentityTokenValue("iat");
  }

  /**
   * Get the token expiration - exp from the security token
   * @returns token expiration as a timestamp in seconds
   */
  public async getExpiration(): Promise<number> {
    const tokens = getItemFromLocalStore(StorageKeys.UserTokensKey);
    if (tokens) {
      return (tokens as UserTokens).expiry_date;
    }
    throw ErrorUtils.userNotLoggedInError();
  }

  /**
   * Get the inactivity limit in seconds
   * @returns limit in seconds
   */
  public async getInactivityLimit(): Promise<number> {
    const tokens = getItemFromLocalStore(StorageKeys.UserTokensKey);
    return tokens ? tokens.expires_in : -1;
  }

  /**
   * Get the session expiration - exp from the access token
   * @returns session expiration as a timestamp in seconds
   */
  public async getSessionExpiration(): Promise<number> {
    const tokens = getItemFromLocalStore(StorageKeys.UserTokensKey);
    if (tokens) {
      return (tokens as UserTokens).expiry_date;
    }
    throw ErrorUtils.userNotLoggedInError();
  }

  public async tryRefreshToken(force?: boolean) {
    await this.getUserTokens(force);
  }

  private async fetchToken(authCode: string) {
    let response = null;
    const verifier = this.getVerifier();
    const payload = {
      clientId: this.gcpConfig.clientId,
      compartmentId: this.gcpConfig.compartmentId,
      action: "GET_TOKEN",
      code: authCode,
      codeVerifier: verifier,
      redirectUri: this.gcpConfig.redirectUri,
    };

    try {
      response = await fetchTokenFromMccp({
        ociFetchApi: this.gcpConfig.ociFetchApi,
        payload: JSON.stringify(payload),
      } as unknown as FetchTokenProps);
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      throw error;
    }
    return response?.json();
  }

  private async refreshUserTokens() {
    let response = null;
    const tokens = getItemFromLocalStore(StorageKeys.UserTokensKey);

    if (tokens) {
      const payload = {
        clientId: this.gcpConfig.clientId,
        compartmentId: this.gcpConfig.compartmentId,
        action: "REFRESH_TOKEN",
        refreshToken: tokens.refresh_token,
      };

      try {
        response = await fetchTokenFromMccp({
          ociFetchApi: this.gcpConfig.ociFetchApi,
          payload: JSON.stringify(payload),
        } as unknown as FetchTokenProps);
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
        throw error;
      }
    } else {
      throw ErrorUtils.userNotLoggedInError();
    }
    return response?.json();
  }

  private async revokeToken() {
    const tokens = getItemFromLocalStore(StorageKeys.UserTokensKey);
    if (tokens) {
      try {
        const response = await fetch(
          getRevokeTokenUrl(UrlConstants.GcpOAuth2RevokeTokenURLTemplate, tokens.access_token),
          {
            method: "POST",
          },
        );
        if (!response.ok) {
          throw new Error(await response.json());
        }
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
        throw error;
      }
    } else {
      throw ErrorUtils.userNotLoggedInError();
    }
  }
}

export default GcpAuth;
