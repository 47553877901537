export function truncate(fullText: string, length: number): string {
  if (!fullText && fullText !== "") return undefined;

  if (fullText.length <= length) {
    return fullText;
  }

  return `...${fullText.slice(fullText.length - length, fullText.length)}`;
}

/**
 * Removes html tags from keeping the string value only.
 * removeTags("<span class=\"my-class\">Important Text</span>") === "Important Text"
 *
 * @param str
 * @returns stripped out string
 */
export function removeTags(str: string): string {
  return str.replace(/(<([^>]+)>)/gi, "");
}

export function allIndexOf(searchString: string, searchFor: string): number[] {
  let i = -1;
  const indexes: number[] = [];
  while ((i = searchString.indexOf(searchFor, i === -1 ? 0 : i + searchFor.length)) !== -1) {
    indexes.push(i);
  }
  return indexes;
}

export function toCamelCase(str: string): string {
  return str.replace(/([-_]\w)/g, g => g[1].toUpperCase());
}
