import React from "react";
import { SUPPORT_URL } from "../../constants";
import { RenderMetricsProps, withRenderMetrics } from "../../CourierService/RenderMetrics";
import * as Messages from "../../codegen/Messages";
import "./OciFooter.css";

type OciFooterProps = {
  isTrusteAvailable: boolean;
};

export const OciFooter = withRenderMetrics(
  ({ renderMetrics, isTrusteAvailable }: OciFooterProps & RenderMetricsProps) => {
    const year = new Date().getFullYear();

    React.useEffect(() => {
      renderMetrics && renderMetrics.rendered("Footer");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <footer data-testid="footer">
        <div className="navigation">
          <a
            href="http://www.oracle.com/us/legal/terms/index.html"
            target="_blank"
            rel="noreferrer"
          >
            {Messages.ociLandingPage.termsOfUse()}
          </a>
          <a
            href="http://www.oracle.com/us/legal/privacy/overview/index.html"
            target="_blank"
            rel="noreferrer"
          >
            {Messages.ociLandingPage.privacy()}
          </a>
          <a href={SUPPORT_URL} target="_blank" rel="noopener noreferrer">
            {Messages.footer.support()}
          </a>

          {isTrusteAvailable ? (
            // eslint-disable-next-line
            <a
              data-testid="cookiesPref"
              id="teconsent"
              className="cookie-prefs truste_caIcon_display"
              aria-label="Open Cookie Preferences Modal"
              role="complementary"
            />
          ) : (
            <span className="link-item">{Messages.footer.cookiePreferenceUnavailable()}</span>
          )}
        </div>
        <p className="copyright">
          {`Copyright © 2016-${year}, Oracle and/or its affiliates. All rights reserved.`}
        </p>
      </footer>
    );
  },
);
