/**
 * Footer is a required component of the Console UI. For reasons on why that is, refer to
 * https://confluence.oci.oraclecorp.com/display/~coyuen/Console+Footer
 */

import "./GcpFooter.scss";
import * as React from "react";
import { Typography } from "@mui/material";
import * as Messages from "../../codegen/Messages";
import { TermsOfUseLinks } from "../../constants";
import { RenderMetricsProps, withRenderMetrics } from "../../CourierService/RenderMetrics";

const footerLinkClass = "footer-link-dark";

interface FooterProps {
  isTrusteAvailable: boolean;
}

const Copyright = withRenderMetrics(({ renderMetrics }: RenderMetricsProps) => {
  React.useEffect(() => {
    renderMetrics && renderMetrics.rendered("Copyright");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const date = new Date();
  const year = date.getFullYear().toString();
  return (
    <span className="copyright-text">
      <Typography variant="caption">{Messages.gcpFooter.copyright(year)}</Typography>
    </span>
  );
});

const PrivacyLink = withRenderMetrics(({ renderMetrics }: RenderMetricsProps) => {
  React.useEffect(() => {
    renderMetrics && renderMetrics.rendered("PrivacyLink");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <span className={footerLinkClass}>
      <a
        className="privacy-link"
        href={TermsOfUseLinks.privacyLink}
        target="_blank"
        rel="noreferrer"
      >
        <Typography variant="caption">{Messages.gcpFooter.privacy()}</Typography>
      </a>
    </span>
  );
});

const TermsLink = withRenderMetrics(({ renderMetrics }: RenderMetricsProps) => {
  React.useEffect(() => {
    renderMetrics && renderMetrics.rendered("TermsLink");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <span className={footerLinkClass}>
      <a
        className="terms-link"
        href={TermsOfUseLinks.termsOfUseLink}
        target="_blank"
        rel="noreferrer"
      >
        <Typography variant="caption">{Messages.gcpFooter.terms()}</Typography>
      </a>
    </span>
  );
});

const Cookies = withRenderMetrics(
  ({ renderMetrics, isTrusteAvailable }: RenderMetricsProps & FooterProps) => {
    React.useEffect(() => {
      renderMetrics && renderMetrics.rendered("Cookies");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        {isTrusteAvailable ? (
          <span className={footerLinkClass} id="teconsent" />
        ) : (
          <span className="error-msg">
            <Typography variant="caption">
              {Messages.gcpFooter.cookiePreferencesUnavailable()}
            </Typography>
          </span>
        )}
      </>
    );
  },
);

const GcpFooter = withRenderMetrics(
  ({ renderMetrics, isTrusteAvailable }: RenderMetricsProps & FooterProps) => {
    React.useEffect(() => {
      renderMetrics && renderMetrics.rendered("GcpFooter");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <div className="footer-space" />
        <div className="footer-wrapper">
          <footer className="footer-container" data-test-id="home-footer">
            <div className="footer-component">
              <Copyright />
            </div>
            <div className="footer-component">
              <PrivacyLink />
            </div>
            <div className="footer-component">
              <TermsLink />
            </div>
            <Cookies isTrusteAvailable={isTrusteAvailable} />
          </footer>
        </div>
      </>
    );
  },
);

export default GcpFooter;
