import { isCanaryRun } from "../bootstrapUtils";
import { Environment, signup_environment } from "../config/apiConfig";
import { CloudLinkType, EventType } from "../Type";
import { getCloudType } from "../utils/CommonUtil";
import { getEventNameByFlow } from "../utils/EnvironmentConfig";
import { APP_NAME, PROD_INFINITY_URL, TESTING_INFINITY_URL } from "./InfinityConstants";

const infinityInitTagId = "InfinityStartScript";
const noScriptURL = "Infinity analytics is enabled with no configured script URL";

type EventReport = {
  eventName: string;
  eventType: number;
  options?: { ociTenancy?: string; azTenantId?: string };
};

const mutationName = "pageLoadMutation"; // Set name so you can remove it later if desired

declare global {
  interface Window {
    chrome: any;
    ORA: any;
  }
}

let messageQueue: EventReport[] = [];
let processingQueue = false;
let initialized = false;

/**
 * Initializes the infinity tag
 */
export const initializeInfinity = (): void => {
  // inserts the <src> tag into the browser and configures it
  initializeTag();
};

/**
 * Track an event with infinity analytics
 * @param eventName the origin of the tracking event
 * @param eventType type of event - click, selection etc.
 */
export const postAnalytics = (
  eventName: string,
  eventType: number = EventType.PageViewEvent,
  options?: {
    ociTenancy?: string;
    azTenantId?: string;
    awsAccountId?: string;
    awsAccountName?: string;
    error?: any;
  },
): void => {
  if (!window.ORA || !window.ORA.click) {
    if (!initialized && !document.getElementById(infinityInitTagId)) {
      initializeTag();
    }
    messageQueue.push({ eventName, eventType, options });
    return;
  }

  if (messageQueue.length > 0 && !processingQueue) {
    flushQueue();
  }
  collect(eventName, eventType, options);
};

const flushQueue = (): void => {
  processingQueue = true;
  messageQueue.forEach(message => {
    collect(message.eventName, message.eventType, message.options);
  });
  messageQueue = [];
  processingQueue = false;
};

const collect = (
  eventName: string,
  eventType: number,
  options?: { ociTenancy?: string; azTenantId?: string },
): void => {
  try {
    if (window.ORA && window.ORA.click) {
      window.ORA.click({
        data: {
          ...baseData(),
          "wt.dl": eventType,
          "wt.oaeve": getEventNameByFlow(eventName),
          "wt.azTenantId": options?.azTenantId,
          "wt.ociTenancy": options?.ociTenancy,
        },
      });
    }
  } catch (error) {
    console.log("Analytics error", error);
  }
};

const initializeTag = (): void => {
  const scriptUrl = getAnalyticsURL();
  if (!scriptUrl || typeof scriptUrl !== "string") {
    console.log(noScriptURL);
    return;
  }
  if (!document.getElementById(infinityInitTagId)) {
    const scriptTag = document.createElement("script");
    scriptTag.async = true;
    scriptTag.type = "text/javascript";
    scriptTag.src = scriptUrl;
    scriptTag.id = infinityInitTagId;
    document.head.appendChild(scriptTag);
    initialized = true;
  }
  configurator();
};

// Adds mutations: Add some parameters to default events
const configurator = (): void => {
  // Declare Your Parameters to persist
  const mutationsAppDataObject = baseData();

  // Set to true to persist or false to remove existing mutation
  const persistVars = true;

  // Set Mutations
  window.ORA = window.ORA || { productReady: [] };
  window.ORA.productReady.push([
    "analytics",
    (function (persistVars, mutationName, mutationsAppDataObject) {
      return () => {
        const applyMutations = (
          persistVars: boolean,
          mutationName: string,
          mutationsAppDataObject: any,
        ): any => {
          // If not persisting...
          if (!persistVars) {
            // Clear 'pageLoadMutation' mutation
            window.ORA.analytics.rmMutation(mutationName); // Remove mutatation
          } else {
            const pageLoadMutation = (msg: any): void => {
              // eslint-disable-next-line guard-for-in, no-restricted-syntax
              for (const keyName in mutationsAppDataObject) {
                msg.payload[keyName] = mutationsAppDataObject[keyName];
              }
            };
            window.ORA.analytics.addMutation("pageLoadMutation", pageLoadMutation);
          }
        };
        return applyMutations(persistVars, mutationName, mutationsAppDataObject);
      };
    })(persistVars, mutationName, mutationsAppDataObject),
  ]);
};

const baseData = (): any => {
  const newBaseData = {
    "wt.app": APP_NAME,
  };
  return newBaseData;
};

const getAnalyticsURL = (): string => {
  if (isCanaryRun()) return TESTING_INFINITY_URL;
  return signup_environment === Environment.PROD_ORACLE ? PROD_INFINITY_URL : TESTING_INFINITY_URL;
};

export const postCloudSpecificAnalytics = (
  name: string,
  cloudType?: CloudLinkType,
  options?: {
    ociTenancy?: string;
    azTenantId?: string;
    awsAccountId?: string;
    awsAccountName?: string;
    gcpOrganizationId?: string;
    error?: any;
  },
  eventType?: EventType,
) => {
  const eventName = `${(cloudType || getCloudType()).toLowerCase()} ${name}`;
  postAnalytics(eventName, eventType || EventType.PageViewEvent, options);
};
