class UrlPlaceholders {
  public static readonly ClientIdPlaceholder = "<client_id>";

  public static readonly ScopePlaceholder = "<scope>";

  public static readonly RedirectUriPlaceholder = "<redirect_uri>";

  public static readonly LogoutUriPlaceholder = "<logout_uri>";

  public static readonly CodeChallengePlaceholder = "<code_challenge>";

  public static readonly StatePlaceholder = "<state>";

  public static readonly TokenPlaceholder = "<token>";
}

export default UrlPlaceholders;
