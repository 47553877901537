import { isTokenReused } from "../../utils/tokenUtils";

/**
 * Url parameter name for security token defined by SOUP
 */
export const SECURITY_TOKEN_PARAMETER_NAME = "security_token";

/**
 * Url parameter name for id token when SOUP calls back console
 */
export const ID_TOKEN_PARAMETER_NAME = "id_token";

/**
 * Url parameter name for single logout
 */
export const SLO_PARAMETER_NAME = "slo";

/**
 * returns url params from hash fragment (used from SOUP redirect)
 */
export function getCurrentUrlParameters(): URLSearchParams {
  // Get Url hash without the leading #, or empty string if hash doesn't exist.
  const hashString = window.location.hash.length > 0 ? window.location.hash.substring(1) : "";
  return new URLSearchParams(hashString);
}

/**
 * checks if we just logged in from SOUP
 */
export function checkSoupLanding(currentUrlParameters: URLSearchParams, reusedTokenExpiration?: number): boolean {
  const securityToken = currentUrlParameters.get(SECURITY_TOKEN_PARAMETER_NAME);
  const identityToken = currentUrlParameters.get(ID_TOKEN_PARAMETER_NAME);
  if (!securityToken || !identityToken) {
    return false;
  }
  if (reusedTokenExpiration) {
    return !isTokenReused(securityToken, reusedTokenExpiration);
  }
  return true;
}

export function checkForSLO(currentUrlParameters: URLSearchParams): boolean {
  const SLO = currentUrlParameters.get(SLO_PARAMETER_NAME);
  if (SLO) {
    history.replaceState({}, "", `${window.location.pathname}`);
    return true;
  }
  return false;
}
