import { configureStore } from "@reduxjs/toolkit";
import { gcpStateReducer } from "../models/gcpState";
import { Action as ReduxAction, combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

// https://blog.logrocket.com/persist-state-redux-persist-redux-toolkit-react/#:~:text=With%20the%20Redux%20Persist%20library,state%20will%20still%20be%20preserved.

export interface State {
  [index: string]: any;
}

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  gcpState: gcpStateReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);

export interface Action extends ReduxAction {
  type: string;
}
