import { IProvidesAuthorizationContext } from "../client/authorization";
import { BrowserIOKernel } from "../io/BrowserIOKernel";
import { BMCClient } from "../BMCClient";
import { fetchFluorineCrypto, IKeyPairEnvelope, Jwk } from "../crypto";
import { HgIndexedDBData } from "../HgIndexedDBData";
import jwtDecode from "jwt-decode";
import {
  getHgCompatibleDbData,
  isHgCompatibleDbDataPresent,
  initHgCompatibleDbData,
  saveTokens,
  setItem,
  clearDb,
  setAuthenticatingRealm,
  clearAuthenticatingRealm,
  setIdleTimeout,
  getIdleTimeout,
  clearIdleTimeout,
  clearSoupParameters,
} from "../indexedDb";
import {
  ISignatureAuthStrategy,
  BrowserSignedRequestAuthorizer,
  ISignatureAuthorization,
  IBrowserMaterialEnvelope,
} from "../client/authorization/BrowserSignedRequestAuthorizer";
import { v4 as uuidV4 } from "uuid";
import { IdentityToken } from "../IdentityToken";
import { decodeState, saveUrlState, recoverState, constructState } from "../urlStateHandler";
import { getWindow } from "../WindowHelper";
import { SecurityToken } from "../SecurityToken";
import {
  CLOCK_SKEW_SIGN_IN_ERROR,
  INVALID_SIGN_IN_URL,
  SESSION_TIMEOUT,
  TENANCY_OVERRIDE_FAILURE_404,
  TENANCY_OVERRIDE_FAILURE_401,
  UNKNOWN_SIGN_IN_ERROR,
  TENANCY_OVERRIDE_FAILURE_404_TENANT_NOT_FOUND,
} from "../../utils/publicErrorCode";
import { isTokenDead, dateStringToTimestamp } from "../../utils/tokenUtils";
import { normalizeRestoreRoute } from "../../utils/urlUtils";
import { createSessionRequestId, headersHas } from "../../utils/headersUtils";
import { OPC_REQUEST_ID } from "../signingString";
import {
  TokenAuthenticator,
  FetchApiSignature,
  RestoreRouteFunction,
  ReportErrorFunction,
  CreateJwtApiSignature,
  TryRefreshFunction,
  RedirectPayload,
  RedirectToAuthOptions,
} from "./interfaces";
import { SECURITY_TOKEN_PARAMETER_NAME, ID_TOKEN_PARAMETER_NAME } from "./urlHelpers";
import { RealmConfig, SoupParameters } from "./RealmConfig";
import { Region } from "oci-console-regions";
import Logger from "../../telemetry/Logger";
import MetricsClient from "../../telemetry/MetricsClient";

/**
 * Used to tell SOUP to shortcut to preferred tenancy
 * @type {string}
 */
const TENANT_QUERY_STRING = "tenant";

/**
 * Used to tell SOUP to shortcut to preferred IdP
 * @type {string}
 */
const PROVIDER_QUERY_STRING = "provider";

/**
 * Used to tell SOUP to shortcut to preferred domain
 * @type {string}
 */
const DOMAIN_QUERY_STRING = "domain";

/**
 * domain is mutable under henosis, so need domain_ocid that is immutable
 * @type {string}
 */
const DOMAIN_OCID_QUERY_STRING = "domain_ocid";

/**
 * Url path for logon, defined by SOUP
 */
const LOGON_PATH = "oauth2/authorize";

/**
 * Url path for logout, defined by SOUP
 */
const LOGOUT_PATH = "logout";

/**
 * Url parameter name for state that is passed to/back from SOUP
 */
const STATE_PARAMETER_NAME = "state";

/**
 * Url parameter name for redirect Uri defined by SOUP
 */
const REDIRECT_URI_PARAMETER_NAME = "redirect_uri";

/**
 * Url parameter name for redirect Uri defined by SOUP
 */
const POST_LOGOUT_REDIRECT_URI_PARAMETER_NAME = "post_logout_redirect_uri";

/**
 * Url parameter used to override tenant parameter for BOAT users
 * @type {string}
 */
const OVERRIDE_TENANT_PARAMETER_NAME = "override_tenant";

/**
 * Url parameter name used to generate timeout messaging on SOUP login page
 * @type {string}
 */
const TIMEOUT_PARAMETER_NAME = "timeout";

/**
 * Url parameter name used to pass Keep me Signed flag on SOUP login page
 * @type {string}
 */
const PROMPT_PARAMETER_NAME = "prompt";

/**
 * Url parameter name used to pass username to SOUP
 * @type {string}
 */
const USERNAME_PARAMETER_NAME = "username";

/**
 * Url parameter name used to pass telemetry to SOUP
 * @type {string}
 */
const TELEMETRY_PARAMETER_NAME = "telemetry";

/**
 * Url parameter name used to pass login url
 */
const LOGIN_URL_PARAMETER_NAME = "loginUrl";

/**
 * Url parameter name used to pass region for identity service
 */
const IDENTITY_REGION_PARAMETER_NAME = "identityRegion";

/**
 * Two slashes in Url
 */
const TWO_SLASHES = "//";

const QUESTION_MARK = "?";

/**
 * Key words in clock skew sign-in error message
 */
const CLOCK_SKEW_ERROR_KEYWORD = "clock skew";

/**
 * OCI Console's Client ID with SOUP
 */
const OCI_CONSOLE_CLIENT_ID = "iaas_console";

const ACCEPT_LANGUAGE = "Accept-Language";

const KMSI_PARAMETER_VALUE = "login_kmsi";

export class SoupAuthenticator implements TokenAuthenticator {
  private authenticationServerBaseUrl: string;
  private client: BMCClient<ISignatureAuthStrategy, IBrowserMaterialEnvelope, ISignatureAuthorization>;

  /**
   * Reference to the authentication-capable fetch Api
   *
   * @private
   * @type {FetchApiSignature}
   * @memberof OraclePublicCloudAuthenticator
   */
  private authenticatedFetchApi: FetchApiSignature;

  private createJwtApi: CreateJwtApiSignature;

  private tryRefresh: TryRefreshFunction;

  private sessionId: string;

  private tab: Window;

  /**
   * Accept language header language. Default is English
   */
  private language: string = "en";

  /**
   * Creates an instance of OraclePublicCloudAuthenticator.
   * @param {RestoreRouteFunction} restoreRouteCallback The callback to pass back router path
   * Before this library redirects to SOUP login page, it gets router path and computes a state from it.
   * The state is passed to SOUP. When SOUP redirects back to console, the state is passed back. This library extracts the original
   * router path, and calls restoreRouteCallback, so app status can be restored
   * @param {ReportErrorFunction} reportError The callback to report error happened inside this library
   * @param realmConfig
   * @memberof OraclePublicCloudAuthenticator
   */
  constructor(
    private restoreRouteCallback: RestoreRouteFunction,
    private reportError: ReportErrorFunction,
    private realmConfig: RealmConfig,
    private logger?: Logger,
    private metricsClient?: MetricsClient,
  ) {
    this.authenticationServerBaseUrl = realmConfig.loginServiceUrl;
    this.sessionId = `csid${uuidV4().substr(4)}`.replace(/-/g, "");
  }

  public get SessionId(): string {
    return this.sessionId;
  }

  public get Config(): RealmConfig {
    return this.realmConfig;
  }

  public updateConfigUrls(urls: Partial<RealmConfig>) {
    this.realmConfig = { ...this.realmConfig, ...urls };
    this.authenticationServerBaseUrl = this.realmConfig.loginServiceUrl;
  }

  public getLoggerClient(): Logger {
    return this.logger;
  }

  public setLoggerClient(logger: Logger) {
    this.logger = logger;
  }

  public getMetricsClient(): MetricsClient {
    return this.metricsClient;
  }

  public setMetricsClient(metricsClient: MetricsClient) {
    this.metricsClient = metricsClient;
  }

  /**
   * Returns fetch Api that will do Oracle Public Cloud authentication for you
   *
   * @returns {FetchApiSignature} The fetch Api that will do Oracle Public Cloud authentication for you
   * @memberof OraclePublicCloudAuthenticator
   */
  public getFetchApi(): FetchApiSignature {
    if (!this.authenticatedFetchApi) {
      throw new Error("Did you forgot to call initializeFetchApi first?");
    }

    return this.authenticatedFetchApi;
  }

  /**
   * Sets the Accept-Language header in authentication headers to the current language selected in console.
   * @param language
   */
  public setCurrentLanguage(language: string): void {
    this.language = language;
  }

  /**
   * Returns createJwt Api that will create a Json Web Token signed with the user's private key
   *
   * @returns {CreateJwtApiSignature} The createJwt Api that will create a Json Web Token signed with the user's private key
   * @memberof OraclePublicCloudAuthenticator
   */
  public getCreateJwtApi(): CreateJwtApiSignature {
    if (!this.authenticatedFetchApi) {
      throw new Error("Did you forgot to call initializeFetchApi first?");
    }

    return this.createJwtApi;
  }

  /**
   * Refresh the security token.
   * @param force - Whether or not to refresh token if the session time left is outside the "about to expire" window.
   */
  public tryRefreshToken(force?: boolean): Promise<void> {
    if (!this.authenticatedFetchApi) {
      throw new Error("Did you forgot to call initializeFetchApi first?");
    }
    return this.tryRefresh(force);
  }

  /**
   * Returns a copy of the request with authentication headers.
   * @param request the http request
   */
  public async createAuthorizedRequest(request: Request): Promise<Request> {
    if (!headersHas(request.headers, OPC_REQUEST_ID)) {
      const sessionRequestId = await createSessionRequestId(this.realmConfig.realm);
      request.headers.set(OPC_REQUEST_ID, sessionRequestId);
    }
    this.setLanguageHeader(request);
    return this.client.createAuthorizedRequest(request);
  }

  /**
   * Url to verify tenancy override
   * @param issuerRegionId home region ID of the tenancy
   * @returns Identity service url, for example: https://identity.us-ashburn-1.oraclecloud.com
   */
  private getTenancyUrl(issuerRegionId: string): string {
    const tenanciesPrefix = "/tenancies/";
    let identityServiceUrl = this.realmConfig.identityServiceUrl;
    if (identityServiceUrl.includes("{region}")) {
      // Unified url with no region param (use home region of overriding BOAT tenancy)
      const regionName = Region[issuerRegionId as keyof typeof Region];
      return identityServiceUrl.replace("{region}", regionName) + tenanciesPrefix;
    }
    // Regional Url
    return `${this.realmConfig.identityServiceUrl}${tenanciesPrefix}`;
  }

  /**
   * Check whether the authenticated user has authorization to cross to the override tenancy they're requesting.
   * If they do have authorization, the identity control plane will return the name of the override tenant.
   *
   * @param overrideTenant Tenant to override to
   * @param issuerRegion Home region ID (eg: IAD) of the overriding BOAT tenancy (Eg: bmc_operator_access)
   */
  private async verifyTenancyOverride(overrideTenant: string, issuerRegion: string): Promise<Error> {
    let tenancyOverrideError = null;
    try {
      let tenancyOverrideCheckUrl = this.getTenancyUrl(issuerRegion) + encodeURIComponent(overrideTenant);
      let tenancyOverrideQuery = await this.getFetchApi()(tenancyOverrideCheckUrl);

      let tenancyResponse = await tenancyOverrideQuery.json();
      if (tenancyOverrideQuery.ok) {
        // Tenant override is authorized. Store in IndexedDB and log the authorization.
        const hgIndexedDBData: HgIndexedDBData = await getHgCompatibleDbData(this.realmConfig.realm);
        hgIndexedDBData.override_tenant_name = tenancyResponse["name"];
        await setItem(this.realmConfig.realm, hgIndexedDBData);
        console.log("Tenancy override authorized; override_tenant_name saved to IndexedDB. ");
      } else if (tenancyOverrideQuery.status === 404) {
        if (tenancyResponse.code === "TenantNotFound") {
          tenancyOverrideError = new Error(TENANCY_OVERRIDE_FAILURE_404_TENANT_NOT_FOUND);
        } else {
          tenancyOverrideError = new Error(TENANCY_OVERRIDE_FAILURE_404);
        }
        console.warn("Unable or unauthorized to resolve requested OCID for tenancy override.");
      } else if (tenancyOverrideQuery.status === 401) {
        tenancyOverrideError = new Error(TENANCY_OVERRIDE_FAILURE_401);
        console.warn("This tenancy is unauthorized for cross-tenancy access.");
      } else {
        tenancyOverrideError = new Error(TENANCY_OVERRIDE_FAILURE_404);
        console.error(`Failed to override tenant. Status: ${tenancyOverrideQuery.status}`);
      }
    } catch (error) {
      console.error(`Unable to override tenant. ${error}`);
      tenancyOverrideError = new Error(TENANCY_OVERRIDE_FAILURE_404);
    }
    return tenancyOverrideError;
  }

  /**
   * Handles redirections to and from SOUP (https://confluence.oci.oraclecorp.com/pages/viewpage.action?pageId=5769528)
   * This function must be called first before CSS/other JS are loaded. Because by SOUP design, redirects will happen and
   * loading any CSS/JS other than this component is a waste of performance
   * @returns null if authenticated
   * @param @optional clientId
   * @param @optional username
   * @param @optional soupParameters Override soup parameters provided on init.
   */
  public async authenticateUser(clientId?: string, username?: string, soupParameters?: SoupParameters): Promise<Error> {
    const isPlugin = !this.restoreRouteCallback;
    let authenticationError: Error = new Error(UNKNOWN_SIGN_IN_ERROR);
    let sessionExpirationError: Error;
    const overrideTenant = this.getOverrideTenantSearchParameter();

    // If there is an override_tenant query parameter present remove auth material and redirect to the auth server
    if (overrideTenant) {
      await this.logOff(undefined, clientId);
    }

    try {
      const isTimeout = await getIdleTimeout();
      sessionExpirationError = await this.setupFetchApi();
      // If user refreshes page, and user session hasn't expired, render app without going to SOUP
      if (!sessionExpirationError) {
        authenticationError = null;
        clearIdleTimeout();
        clearSoupParameters();
      } else if (isTimeout) {
        // If session expired due to idle timeout then redirect user to SOUP logon passing prompt=login_kmsi
        await this.logon(this.getLoginUrl(), true, clientId, username, soupParameters);
      } else if (!isPlugin) {
        // If we are inside this else-if the user needs authenticate.
        // Redirecting user away to perform logon. Any code after won't be executed
        if (soupParameters) {
          this.realmConfig.soupParameters = soupParameters;
        }

        const unifiedUrlNoAuthRootRedirect = this.realmConfig && this.realmConfig.unifiedUrlNoAuthRootRedirect;
        if (unifiedUrlNoAuthRootRedirect) {
          authenticationError = null;
          getWindow().location.replace(unifiedUrlNoAuthRootRedirect);
        } else {
          await this.logon(this.getLoginUrl(), undefined, clientId, username, soupParameters);
        }
      }
      const consoleLandingTimestamp: string = localStorage.getItem("ConsoleLandingTimestamp");
      if (consoleLandingTimestamp) {
        localStorage.removeItem("ConsoleLandingTimestamp");
        this.metricsClient.sendMetric("TotalSignInTime", new Date().getTime() - parseInt(consoleLandingTimestamp));
      }
    } catch (error) {
      authenticationError = error;
      this.reportError(`OraclePublicCloudAuthenticator::authenticateUser: ${error}`);
    } finally {
      try {
        if ((authenticationError && !isPlugin) || sessionExpirationError) {
          // There is something wrong, let's clear everything so that user can start fresh next time
          await clearDb(this.realmConfig.realm);
          // Set url to clean console, so when user refresh browser, it will go to logon page
          // instead of stuck at the SOUP callback Url
          history.pushState(null, null, this.realmConfig.landingUrl);
        }
      } catch (_) {
        // ignore error
      }
    }

    return authenticationError;
  }

  public async handleLogInFromSoupLandingPage(
    currentUrlParameters: URLSearchParams,
    isPlugin: boolean,
  ): Promise<Error> {
    // if callback url was not initialized from browser, there will not be any private/public key
    const hgCompatibleDbData: HgIndexedDBData = await getHgCompatibleDbData(this.realmConfig.realm);
    await clearAuthenticatingRealm();

    if (!hgCompatibleDbData || !hgCompatibleDbData.private || !hgCompatibleDbData.public) {
      return new Error(INVALID_SIGN_IN_URL);
    }

    // Page loaded after SOUP redirect back.
    const idTokenParam = currentUrlParameters.get(ID_TOKEN_PARAMETER_NAME);
    const securityTokenParam = currentUrlParameters.get(SECURITY_TOKEN_PARAMETER_NAME);
    const decodedIdentityToken = jwtDecode(idTokenParam) as IdentityToken;
    await saveTokens(this.realmConfig.realm, securityTokenParam, decodedIdentityToken);

    const sessionExpirationError = await this.setupFetchApi();
    if (sessionExpirationError) {
      console.error("Failed to validate incoming token", sessionExpirationError.message);
      return SoupAuthenticator.getAuthenticationError(sessionExpirationError.message);
    }
    // Check with identity whether any provided tenant override is authorized
    if (hgCompatibleDbData.override_tenant && !hgCompatibleDbData.override_tenant_name) {
      console.log("Requesting authorization to override tenant.");
      const tenancyOverrideError = await this.verifyTenancyOverride(
        hgCompatibleDbData.override_tenant,
        decodedIdentityToken && decodedIdentityToken.issuer_region,
      );
      if (tenancyOverrideError) {
        return tenancyOverrideError;
      }
    }

    const rootPath: string = ""; // remove id token, security token and other OAUTH materials from url
    let destinationPath: string = "";
    const recoveredState = await recoverState(this.realmConfig.realm);

    // Restore application state
    if (currentUrlParameters.has(STATE_PARAMETER_NAME)) {
      destinationPath = await decodeState(this.realmConfig.realm, currentUrlParameters.get(STATE_PARAMETER_NAME));
    } else if (recoveredState) {
      destinationPath = recoveredState;
    } else if (!isPlugin && !(await this.isRegularSignIn())) {
      destinationPath = rootPath;
    }

    destinationPath = normalizeRestoreRoute(destinationPath);
    console.log("Restoring URL State to: " + destinationPath);
    this.restoreRouteCallback(destinationPath);

    return null;
  }

  private static getAuthenticationError(sessionExpirationErrorMessage: string) {
    let authenticationError;
    if (sessionExpirationErrorMessage.includes(CLOCK_SKEW_ERROR_KEYWORD)) {
      // Clock on user's machine is wrong
      authenticationError = new Error(CLOCK_SKEW_SIGN_IN_ERROR);
    } else if (sessionExpirationErrorMessage.includes(SESSION_TIMEOUT)) {
      authenticationError = new Error(SESSION_TIMEOUT);
    } else {
      // Browser might still have the old key pair, and user bookmarked a SOUP landing page that not match the key pair in indexedDB
      authenticationError = new Error(INVALID_SIGN_IN_URL);
    }
    return authenticationError;
  }

  /**
   * @returns true if authenticated, otherwise returns an error
   */
  public async isSessionActive(): Promise<boolean> {
    const sessionExpirationError = await this.setupFetchApi();
    return !sessionExpirationError;
  }

  /**
   * Sets up the fetch API to be used by the console.
   * @returns An error if the token doesn't exist or is expired, otherwise returns undefined
   * if the HG Indexed Data is found and the current identity token is valid.
   */
  private async setupFetchApi(): Promise<Error> {
    // If token doesn't exist, installing fetch API will throw error
    try {
      const hgCompatibleDbData: HgIndexedDBData = await getHgCompatibleDbData(this.realmConfig.realm);
      if (!isHgCompatibleDbDataPresent(hgCompatibleDbData)) {
        return new Error(); // This will trigger logon
      }
      await this.initializeFetchApi();
    } catch (error) {
      return error;
    }
    return await this.validateToken();
  }

  /**
   * Replace fetch API with Loom's, who includes OCI authentication support.
   */
  private async initializeFetchApi() {
    const factory = <IProvidesAuthorizationContext<ISignatureAuthStrategy, IBrowserMaterialEnvelope>>{
      createAuthorizationContext: async request => {
        const hgCompatibleDbData: HgIndexedDBData = await getHgCompatibleDbData(this.realmConfig.realm);
        if (isHgCompatibleDbDataPresent(hgCompatibleDbData)) {
          const result = {
            materials: Object.assign(
              {},
              {
                materials: {
                  privateKey: hgCompatibleDbData.private,
                  publicKey: hgCompatibleDbData.public,
                },
              } as IKeyPairEnvelope,
              { securityToken: hgCompatibleDbData.security_token },
            ),
            request: request,
          };
          return Promise.resolve(result);
        } else {
          return Promise.reject("No authentication material present.");
        }
      },
    };

    const authorizer = new BrowserSignedRequestAuthorizer(fetchFluorineCrypto, factory);
    this.client = new BMCClient<ISignatureAuthStrategy, IBrowserMaterialEnvelope, ISignatureAuthorization>(
      this.realmConfig.realm,
      this.realmConfig.authorizationServiceUrl,
      new BrowserIOKernel(),
      authorizer,
    );
    this.authenticatedFetchApi = this.client.fetch.bind(this.client);
    this.createJwtApi = this.client.createJwt.bind(this.client);
    this.tryRefresh = this.client.tryRefresh.bind(this.client);
  }

  /**
   * Test if current identity token is valid.
   * @returns undefined if token is valid, otherwise returns an Error wrapped in a Promise
   */
  private async validateToken(): Promise<Error> {
    try {
      const hgCompatibleDbData: HgIndexedDBData = await getHgCompatibleDbData(this.realmConfig.realm);
      const securityToken = hgCompatibleDbData.security_token;
      const result = isTokenDead(securityToken);
      if (!result) {
        return undefined;
      }

      return new Error(SESSION_TIMEOUT);
    } catch (e) {
      return e;
    }
  }

  /**
   * Save override tenant to IndexedDB if present in the current URL
   * This allows us to process post-authentication cross-tenancy authorization through identity for BOAT users.
   */
  private async storeOverrideTenantIfPresent(): Promise<any> {
    const overrideTenant = this.getOverrideTenantSearchParameter();

    if (overrideTenant) {
      const hgIndexedDBData: HgIndexedDBData = await getHgCompatibleDbData(this.realmConfig.realm);
      hgIndexedDBData.override_tenant = overrideTenant;
      await setItem(this.realmConfig.realm, hgIndexedDBData);
      console.log("Override tenant saved from query parameter to IndexedDB.");
    }
  }

  /**
   * Get override tenant search parameter from current URL
   */
  private getOverrideTenantSearchParameter(): string {
    const currentUrl = new URL(getWindow().location.href);
    let tenant = currentUrl.searchParams.get(OVERRIDE_TENANT_PARAMETER_NAME);
    if (!tenant && this.realmConfig.soupParameters) {
      tenant = this.realmConfig.soupParameters.overrideTenant;
    }
    return tenant;
  }

  /**
   * Logon user, by redirecting to SOUP.
   * This function purposely return a promise that will never resolved except if there is an error,
   * to give enough time to perform browser redirect.
   *
   * @param loginUrl - SOUP server url for login. Example "https://login.us-phoenix-1.oraclecloud.com/v1/oauth2/authorize"
   * @param {boolean} timeout - Boolean representing whether the logon page is being generated as the result of a session timeout
   * @param clientId
   * @param username
   * @param @optional {SoupParameters} soupParameters - Overrides soup parametes passed on init.
   */
  public logon(
    loginUrl: string,
    timeout?: boolean,
    clientId?: string,
    username?: string,
    soupParameters?: SoupParameters,
  ): Promise<void> {
    return new Promise<void>(async (_, rejecter) => {
      try {
        const cryptoObject = fetchFluorineCrypto();

        if (soupParameters) {
          this.realmConfig.soupParameters = soupParameters;
        }

        let hgCompatibleDbData = await getHgCompatibleDbData(this.realmConfig.realm);
        // Create new CryptoKeyPair only if one does not exist in IndexDb
        if (!hgCompatibleDbData || !hgCompatibleDbData.public || !hgCompatibleDbData.private) {
          hgCompatibleDbData = await initHgCompatibleDbData(this.realmConfig.realm, cryptoObject);
        } else {
          // Always recreate nonce.
          // Nonce value in IndexDb will match the nonce in the url parameter of the last tab the user logged off from
          hgCompatibleDbData = await setItem(this.realmConfig.realm, {
            ...hgCompatibleDbData,
            nonce: uuidV4(),
          });
        }
        await setAuthenticatingRealm(this.realmConfig.realm);

        const publicKey = hgCompatibleDbData.iePublic ? hgCompatibleDbData.iePublic : hgCompatibleDbData.public;
        const jwk: Jwk = await cryptoObject.exportPublicKey(publicKey);

        const routingPath = this.getRoutingPath();
        const state: string = await constructState(this.realmConfig.realm, routingPath);

        // Pull override tenant and url state into IndexedDB since we can't rely on SAML to relay state back to us
        await this.storeOverrideTenantIfPresent();
        await saveUrlState(this.realmConfig.realm, routingPath);
        if (timeout) {
          // On session idle timeout we want to clear the tokens
          await this.recoverHgCompatibleDbData();
        }
        const parameters = this.createLogonUrlParameters(
          this.getRedirectUrl(),
          hgCompatibleDbData.nonce,
          state,
          clientId,
          jwk,
          uuidV4(),
          timeout,
          username,
        );

        const soupUrl = loginUrl + QUESTION_MARK + parameters.toString();
        getWindow().location.replace(soupUrl);
        // Purposely do not resolve promise to give enough time for "window.location.replace" to do its job
      } catch (error) {
        this.reportError(`OraclePublicCloudAuthenticator::logon: ${error}`);
        rejecter(error);
      }
    });
  }

  /**
   * Create SOUP Url parameters
   * @param callbackUrl - The url Soup redirects to. It must includes port as well, and ended with '/', like "https://<hostname>:<port>/"
   * @param nonce - The nonce. It's UUID v4 unique string.
   * @param state - The state that console passes to SOUP, who passes verbatimly back. Console can use this to restore client state between redirections
   * @param clientId - the client ID to pass to SOUP
   * @param publicKey - The public key generated for this session
   * @param publicKeyUuid - Unique string that will be attached to the public key
   * @param {boolean} [timeout] - Boolean representing whether to display timeout messaging on login page
   * @param username - User name to be pass to SOUP and be return on callback
   * @return The Url parameters
   * Note: This method is public because otherwise test case won't be able to access it.
   */
  public createLogonUrlParameters(
    callbackUrl: string,
    nonce: string,
    state: string,
    clientId: string,
    publicKey: Jwk,
    publicKeyUuid: string,
    timeout?: boolean,
    username?: string,
  ): URLSearchParams {
    const soupParameters = new URLSearchParams();
    const currentUrl = new URL(getWindow().location.href);

    const actionParameterName = "action";
    soupParameters.set(actionParameterName, "login");

    const clientIdParameterName = "client_id";
    // If the requesting user didn't specify a Client ID, default to OCI Console's Client ID.
    if (!clientId) {
      clientId = OCI_CONSOLE_CLIENT_ID;
    }

    soupParameters.set(clientIdParameterName, currentUrl.searchParams.get(clientIdParameterName) || clientId);

    const telemetry = currentUrl.searchParams.get(TELEMETRY_PARAMETER_NAME);

    soupParameters.set(REDIRECT_URI_PARAMETER_NAME, callbackUrl);

    const responseTypeParameterName = "response_type";
    const responseTypeParameterValue = "token id_token";
    soupParameters.set(responseTypeParameterName, responseTypeParameterValue);

    const nonceParameterName = "nonce";
    soupParameters.set(nonceParameterName, nonce);

    soupParameters.set(STATE_PARAMETER_NAME, state);

    const scopeParameterName = "scope";
    const scopeParameterValue = "openid";
    soupParameters.set(scopeParameterName, scopeParameterValue);

    if (typeof timeout === "boolean") {
      soupParameters.set(TIMEOUT_PARAMETER_NAME, timeout.toString());
      // Need to send prompt=login_kmsi to soup to handle user session based on whether user checked kmsi or not
      soupParameters.set(PROMPT_PARAMETER_NAME, KMSI_PARAMETER_VALUE);
      // We need to store idle timeout in index db so that when user refreshes/open new tab we know that user session was timed out
      setIdleTimeout(timeout);
      // As we delete tokens on timeout and call SOUP we need to store domain and tenant information so that SOUP does not get stuck on session picker
    }

    if (username) {
      soupParameters.append(USERNAME_PARAMETER_NAME, username);
    }

    if (telemetry) {
      soupParameters.append(TELEMETRY_PARAMETER_NAME, telemetry);
    }

    const publicKeyParameterName = "public_key";
    let publicKeyObject = new Jwk();
    Object.assign(publicKeyObject, publicKey);

    publicKeyObject.kid = "pubkey-" + publicKeyUuid;
    const publicKeyString = JSON.stringify(publicKeyObject);
    const publicKeyParameterValue = btoa(publicKeyString);
    soupParameters.set(publicKeyParameterName, publicKeyParameterValue);

    const presetsToForward = [TENANT_QUERY_STRING, PROVIDER_QUERY_STRING, DOMAIN_QUERY_STRING];
    const configSoupParams = this.realmConfig.soupParameters || {};
    presetsToForward.forEach(qsp => {
      const preset = currentUrl.searchParams.get(qsp) || (configSoupParams as any)[qsp];
      if (preset) {
        soupParameters.append(qsp, preset);
      }
    });

    return soupParameters;
  }

  /**
   * Create SOUP Url parameters for changing password
   * @param callbackUrl - The url Soup redirects to. It must includes port as well, and ended with '/', like "https://<hostname>:<port>/"
   * @param securityToken - The security token of current authenticated user
   * @return The Url parameters
   * Note: This method is public because otherwise test case won't be able to access it.
   */
  public createChangePasswordUrlParameters(callbackUrl: string, securityToken: string): URLSearchParams {
    let soupParameters = new URLSearchParams();

    soupParameters.append(SECURITY_TOKEN_PARAMETER_NAME, securityToken);
    soupParameters.append(REDIRECT_URI_PARAMETER_NAME, callbackUrl);

    return soupParameters;
  }

  /**
   * Change current user's password, by redirecting to SOUP
   * @param changePasswordUrl - SOUP server url for changing password. Example "https://login.us-phoenix-1.oraclecloud.com/v1/password/change"
   */
  public async changePassword(changePasswordUrl: string) {
    try {
      const hgCompatibleDbData: HgIndexedDBData = await getHgCompatibleDbData(this.realmConfig.realm);
      const soupParameters: URLSearchParams = this.createChangePasswordUrlParameters(
        this.getRedirectUrl(),
        hgCompatibleDbData.security_token,
      );
      const soupUrl = changePasswordUrl + QUESTION_MARK + soupParameters.toString();
      window.location.replace(soupUrl);
    } catch (error) {
      this.reportError(`OraclePublicCloudAuthenticator::changePassword: ${error}`);
    }
  }

  /**
   * Removes the config override from the query string
   * @param location - URL of a page with config override
   * @param replaceHistory
   */
  private static removeConfigOverrideFromQueryString(location: Location, replaceHistory: boolean = true): string {
    const CONFIG_OVERRIDE_KEY = "configoverride";
    const search = location.search;
    const params = new URLSearchParams(search);
    params.delete(CONFIG_OVERRIDE_KEY);

    // Reconstruct a url with new search params. If there's no search params, remove question mark.
    const searchParamsString = params.toString();
    const fullSearchParamsString = searchParamsString ? "?" + searchParamsString : "";
    const newUrl = `${location.origin}${location.pathname}${fullSearchParamsString}`;

    // replace url without reload the page
    if (replaceHistory) {
      history.pushState({}, null, newUrl);
    }

    return newUrl;
  }

  /**
   * Redirect to SOUP Authentication
   * @param baseUrl - The URL to soup authentication
   * @param redirectUrl - The URL to redirect back once the user is authenticated
   * @param options - Additional parameter for the authentication payload
   */
  public async redirectToAuth(baseUrl: string, redirectUrl: Location, options?: RedirectToAuthOptions): Promise<void> {
    const newUrl = SoupAuthenticator.removeConfigOverrideFromQueryString(redirectUrl);

    try {
      const hgCompatibleDbData: HgIndexedDBData = await getHgCompatibleDbData(this.realmConfig.realm);

      const payload: RedirectPayload = {
        redirect_uri: newUrl,
      };

      if (options.nonce) {
        payload.nonce = hgCompatibleDbData.nonce;
      }

      if (options.securityToken) {
        payload.security_token = hgCompatibleDbData.security_token;
      }

      if (options.additionalParams) {
        for (const key in options.additionalParams) {
          if (options.additionalParams.hasOwnProperty(key)) {
            payload[key] = options.additionalParams[key];
          }
        }
      }

      const form = document.createElement("form");
      form.style.visibility = "hidden";
      form.method = "POST";
      form.action = baseUrl;

      const keys = Object.keys(payload);
      keys.map(key => {
        const input = document.createElement("input");
        input.name = key;
        input.value = payload[key];
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      this.reportError(`OraclePublicCloudAuthenticator::redirectToAuth: ${error}`);
    }
  }

  /**
   * Get OAuth token for UPI
   * @param oauthTokenEndpoint - endpoint for retrieving the OAuth token
   * @param queryString - URL encoded query parameters
   */
  public async getOAuthToken(oauthTokenEndpoint: string, queryString: string): Promise<string> {
    try {
      const authenticatedFetch = this.getFetchApi();
      const response = await authenticatedFetch(oauthTokenEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: queryString,
      });
      if (response.status === 200) {
        const responseJSON = await response.json();
        return Promise.resolve(responseJSON.access_token);
      } else {
        throw new Error(`Couldn't fetch token:: Status: ${response.status}, Status Text: ${response.statusText}`);
      }
    } catch (error) {
      this.logger.error(`SoupAuthenticator::getOAuthToken: ${error}`);
      this.metricsClient.sendMetric("getOAuthTokenMetric", 1);
      throw new Error(`SoupAuthenticator::getOAuthToken: ${error}`);
    }
  }

  /**
   * This method will save the some of the information that can be reused (private, public and state)
   * This is needed if and when a user has multiple tabs open and they get logged out due to session timeout,
   * which would create new keys every time without this method and cause issues when they log in from a tab
   * different than the one they were last logged out from.
   * More details in: https://jira.oci.oraclecorp.com/browse/UX-7127 & https://bitbucket.oci.oraclecorp.com/projects/UX/repos/duplo-authentication/pull-requests/107/overview
   */
  private async recoverHgCompatibleDbData(): Promise<void> {
    const hgCompatibleDbData: HgIndexedDBData = await getHgCompatibleDbData(this.realmConfig.realm);

    if (hgCompatibleDbData) {
      // Recover createdAt, privateKey, publicKey, and state
      const recoveredHgCompatibleDbData = {} as HgIndexedDBData;
      recoveredHgCompatibleDbData.createdAt = hgCompatibleDbData.createdAt;
      recoveredHgCompatibleDbData.id = hgCompatibleDbData.id;
      recoveredHgCompatibleDbData.private = hgCompatibleDbData.private;
      recoveredHgCompatibleDbData.public = hgCompatibleDbData.public;
      recoveredHgCompatibleDbData.state = hgCompatibleDbData.state;

      await setItem(this.realmConfig.realm, recoveredHgCompatibleDbData);
    }
  }

  /**
   * @deprecated - This function is being replaced by logout(). This method does not take care of clearing tokens on SOUP end.
   *               Link to issue: https://jira.oci.oraclecorp.com/browse/UX-8426
   * Log off user
   * @param {boolean} [timeout] - represents whether the user is being logged off for session inactivity timeout
   * @param {string} [clientId] - the client ID to pass to SOUP when redirecting to login page
   */
  public async logOff(timeout?: boolean, clientId?: string): Promise<void> {
    try {
      await this.recoverHgCompatibleDbData();
    } catch (error) {
      this.reportError(`OraclePublicCloudAuthenticator::logOff: ${error}`);
    }
    // Go to log on page
    await this.logon(this.getLoginUrl(), timeout, clientId);
  }

  /**
   * Logout user and clear soup jwt using soup endpoint
   * @param tenantName - My services tenant name
   * @param consoleHost - Current Console Url/host
   * @param isConsoleUnifiedUrl - Is user using Unified Url
   * @param domain - domain name. Needed for Henosis
   * @param domainOcid - domain name is mutable so Henosis needs immutable domain ocid also
   */
  public async logout(
    tenantName?: string,
    consoleHost?: string,
    isConsoleUnifiedUrl?: boolean,
    domain?: string,
    domainOcid?: string,
  ): Promise<void> {
    try {
      await this.recoverHgCompatibleDbData();
      const postLogoutUrl = new URL(consoleHost || window.location.origin);
      if (isConsoleUnifiedUrl) {
        postLogoutUrl.searchParams.set(TENANT_QUERY_STRING, tenantName);
      }

      // For R1U, set the original loginUrl and identityRegion for postLogoutUrl
      if (consoleHost.includes("console-staging")) {
        const regionMatch = this.realmConfig.identityServiceUrl.match(/alpha|beta|gamma/i);
        postLogoutUrl.searchParams.set(LOGIN_URL_PARAMETER_NAME, this.realmConfig.loginServiceUrl);
        regionMatch && postLogoutUrl.searchParams.set(IDENTITY_REGION_PARAMETER_NAME, regionMatch[0]);
      }

      const redirectUrl = new URL(this.getLogoutUrl());
      redirectUrl.searchParams.set(TENANT_QUERY_STRING, tenantName);
      redirectUrl.searchParams.set(POST_LOGOUT_REDIRECT_URI_PARAMETER_NAME, postLogoutUrl.toString());
      if (domain) {
        redirectUrl.searchParams.set(DOMAIN_QUERY_STRING, domain);
      }
      if (domainOcid) {
        redirectUrl.searchParams.set(DOMAIN_OCID_QUERY_STRING, domainOcid);
      }
      getWindow().location.replace(redirectUrl.toString());
    } catch (error) {
      this.reportError(`OraclePublicCloudAuthenticator::logout: ${error}`);
    }
  }

  private sleep(ms: number): Promise<{}> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  /**
   * Perform IDCS Logout and re-route to main logout responsible for clearing soup jwt
   * @param logOffUrl - IDCS log out url
   * @param tenantName - OCI tenant name
   * @param consoleHost - Current Console Url/host
   * @param isConsoleUnifiedUrl - Is user using Unified Url
   * @param waitTime - Time in milleseconds to wait for IDCS Logout to finish before redirecting to SOUP Page
   */
  public async idcsLogout(
    logOffUrl?: string,
    tenantName?: string,
    consoleHost?: string,
    isConsoleUnifiedUrl?: boolean,
    waitTime?: number,
    postIdcsLogoutRedirect?: boolean,
  ): Promise<void> {
    try {
      if (postIdcsLogoutRedirect) {
        // Need to configure ?post_logout_redirect_uri=Unified Url
        await this.recoverHgCompatibleDbData();
        const redirectUrl = new URL(logOffUrl + `?${POST_LOGOUT_REDIRECT_URI_PARAMETER_NAME}=${consoleHost}`);
        getWindow().location.replace(redirectUrl.toString());
        return;
      }

      // Call IDCS logout endpoint in an iframe
      const logoutFrame = document.createElement("iframe");

      logoutFrame.onload = async () => {
        try {
          // Used to check if tokens have already been removed so we don't have to wait when multiple tabs are open
          const hgCompatibleDbData: HgIndexedDBData = await getHgCompatibleDbData(this.realmConfig.realm);
          if (waitTime && hgCompatibleDbData?.identity_token) {
            // This sleep/wait exists so that we wait for the logOutUrl to load and can be configured using capability
            await this.sleep(waitTime);
          }
        } finally {
          // Perform logout mechanism
          await this.logout(tenantName, consoleHost, isConsoleUnifiedUrl);
        }
      };

      logoutFrame.setAttribute("src", logOffUrl);
      document.body.appendChild(logoutFrame);
    } catch (error) {
      this.reportError(`OraclePublicCloudAuthenticator::idcsLogOff: ${error}`);
    }
  }

  /**
   * Get console Url for SOUP callback
   * e.g "https://console.us-phoenix-1.oraclecloud.com/
   * This needs to be the url sans path for oauth by default.
   * However, if an override is provided, the url may contain the path.
   * @returns The Url from where this console instance is published
   */
  public getRedirectUrl(): string {
    if (this.realmConfig.getRedirectUrlOverride) {
      return this.realmConfig.getRedirectUrlOverride();
    }

    return window.location.protocol + TWO_SLASHES + window.location.host;
  }

  /**
   * Get path (include query params) for url restoring after OAUTH redirection.
   *
   * Case url contains "#"
   *  - everything after "#"
   *
   * Case url doesn't contain "#"
   *  - everything after host
   *
   * @returns partial url
   */
  public getRoutingPath(pathName = getWindow().location.pathname): string {
    const url = new URL(getWindow().location.href);
    url.pathname = pathName;

    // Remove search parameters that are only meant to be forwarded to the identity provider service
    url.searchParams.delete(TENANT_QUERY_STRING);
    url.searchParams.delete(PROVIDER_QUERY_STRING);
    url.searchParams.delete(OVERRIDE_TENANT_PARAMETER_NAME);

    if (url.pathname === "/" && !!url.hash) {
      // To compatible with Hg url format
      return url.hash.substr(1);
    }

    return url.pathname + url.search;
  }

  /**
   * Get login Url
   * @returns The login Url
   */
  private getLoginUrl(): string {
    return `${this.authenticationServerBaseUrl}/${LOGON_PATH}`;
  }

  /**
   * Get logout Url
   * @returns The logout Url
   */
  private getLogoutUrl(): string {
    return `${this.authenticationServerBaseUrl}/${LOGOUT_PATH}`;
  }

  /**
   * Get a value from the security token
   * @param field The field of the SecurityToken that you need the value from. Must be in whitelistedFields.
   * @returns a promise for the security token.
   */
  private async getSecurityTokenValue<F extends keyof SecurityToken>(field: F): Promise<SecurityToken[F]> {
    // NOTE: should never expose raw security token
    const whitelistedFields = ["iat", "exp", "sess_exp"];
    try {
      if (whitelistedFields.indexOf(field) === -1) {
        return Promise.reject(`Field ${field} has not been whitelisted for access from the security token.`);
      }
      const hgCompatibleDbData: HgIndexedDBData = await getHgCompatibleDbData(this.realmConfig.realm);
      if (hgCompatibleDbData && hgCompatibleDbData.security_token) {
        const securityToken = jwtDecode(hgCompatibleDbData.security_token) as SecurityToken;
        return Promise.resolve(securityToken[field]);
      } else {
        return Promise.reject("hgCompatibleDbData.security_token is undefined.");
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Get SOUP identity token
   * @returns a promise for the identity token. Notice it is ES6 native promise.
   * https://stackoverflow.com/questions/27573365/how-to-use-typescript-with-native-es6-promises
   */
  public async getIdentityToken(): Promise<IdentityToken> {
    const hgCompatibleDbData: HgIndexedDBData = await getHgCompatibleDbData(this.realmConfig.realm);
    if (hgCompatibleDbData && hgCompatibleDbData.identity_token) {
      return hgCompatibleDbData.identity_token;
    } else {
      throw new Error("hgCompatibleDbData.identity_token is undefined.");
    }
  }

  /**
   * Get value for whether the user session is timed out
   * @returns a promise for the session timed out. Notice it is ES6 native promise.
   */
  public async getSessionTimeout(): Promise<boolean> {
    const isTimeout = await getIdleTimeout();
    return !!isTimeout;
  }

  /**
   * Get the token issued at - iat from the security token
   * @returns token issued at as a timestamp in seconds
   */
  public async getIssuedAt(): Promise<number> {
    return this.getSecurityTokenValue("iat");
  }

  /**
   * Get the token expiration - exp from the security token
   * @returns token expiration as a timestamp in seconds
   */
  public async getExpiration(): Promise<number> {
    return this.getSecurityTokenValue("exp");
  }

  /**
   * Get the session expiration - sess_exp from the security token
   * @returns session expiration as a timestamp in seconds
   */
  public async getSessionExpiration(): Promise<number> {
    return dateStringToTimestamp(await this.getSecurityTokenValue("sess_exp"));
  }

  /**
   * Get the derived inactivity limit in seconds or -1 if the limit can't be determined
   * @returns limit in seconds or -1
   */
  public async getInactivityLimit(): Promise<number> {
    const sessionExpiration = await this.getSessionExpiration();
    const tokenIssuedAt = await this.getIssuedAt();
    const tokenExpiration = await this.getExpiration();

    // When the token will expire before the session expires, then we are able to derive the
    // max inactivity limit for the token by looking token exp - iat. However Identity never
    // sets the token to expire after the session expires, so exp - iat can not always be
    // depended on to be the inactivity limit.
    // In the case that the token expiry is equal to the session expiry, then
    // there is no way on the client side to derive the inactivity limit and we return -1.
    // This should not be an issue since the session would expire before the inactivity
    // limit was reached anyway.

    return tokenExpiration < sessionExpiration ? tokenExpiration - tokenIssuedAt : -1;
  }

  async isRegularSignIn(): Promise<boolean> {
    const hgCompatibleDbData: HgIndexedDBData = await getHgCompatibleDbData(this.realmConfig.realm);
    const securityToken = jwtDecode(hgCompatibleDbData.security_token) as SecurityToken;
    // if user sign-in with regular username password, ttype will carry value "login"
    // if thru other provider, could be "saml" or later "openid" etc ...
    return securityToken.ttype === "login";
  }

  async isSamlSignIn(): Promise<boolean> {
    const hgCompatibleDbData: HgIndexedDBData = await getHgCompatibleDbData(this.realmConfig.realm);
    const securityToken = jwtDecode(hgCompatibleDbData.security_token) as SecurityToken;

    // federated users login with saml
    return securityToken.ttype === "saml";
  }

  async isBoatSignIn(): Promise<boolean> {
    const hgCompatibleDbData: HgIndexedDBData = await getHgCompatibleDbData(this.realmConfig.realm);
    const securityToken = jwtDecode(hgCompatibleDbData.security_token) as SecurityToken;

    // boat users login with samlc
    return securityToken.ttype === "samlc";
  }

  /**
   * Returns the OCID of the tenant the user has requested to override to (undefined if no tenancy override requested)
   */
  public async getOverrideTenant(): Promise<string> {
    const hgCompatibleDbData: HgIndexedDBData = await getHgCompatibleDbData(this.realmConfig.realm);
    return hgCompatibleDbData.override_tenant;
  }

  /**
   * Returns the name of the tenant the user has requested to override to (undefined where unauthorized)
   */
  public async getOverrideTenantName(): Promise<string> {
    const hgCompatibleDbData: HgIndexedDBData = await getHgCompatibleDbData(this.realmConfig.realm);
    return hgCompatibleDbData.override_tenant_name;
  }

  private async setLanguageHeader(request: Request) {
    const commercialServicesLanguage: string[] = ["pt_BR", "pt", "zh_CN", "zh_TW"];

    if (
      !(
        request.headers &&
        request.headers.has(ACCEPT_LANGUAGE) &&
        commercialServicesLanguage.indexOf(request.headers.get(ACCEPT_LANGUAGE)) !== -1
      )
    ) {
      request.headers.set(ACCEPT_LANGUAGE, this.language);
    }
  }
}
