const ENCODING = "utf-8";

export interface IMaterialEnvelope<T> {
  readonly materials: T;
}

export interface IKeyPairEnvelope extends IMaterialEnvelope<CryptoKeyPair> {}

export class Jwk {
  alg: string;
  e: string;
  ext: boolean;
  // tslint:disable-next-line:variable-name
  key_ops: Array<string>;
  kty: string;
  n: string;
  kid?: string;
}

/**
 * Defines the minimum compatible known-good WebCrypto
 * functionality needed by Consoles for Platform Auth.
 *
 * We purposefully bind to a specific crypto impl (RSA keypair), and
 * purposefully narrow the import/export interface precisely because
 * browsers are terrible. They have missing "standard" implementations,
 * nonstandard implementations (AHEM IE8 AHEM EDGE), and bugs in the
 * ones they purportedly support (AHEM FIREFOX AHEM SAFARI)
 */
export interface IFluorineCrypto<TMaterial, TEnvelope extends IMaterialEnvelope<TMaterial>> {
  sign(privateKey: CryptoKey, contents: ArrayBuffer): Promise<ArrayBuffer>;
  generateKeyPair(): Promise<TEnvelope>;
  verify(materials: TEnvelope, expected: ArrayBuffer, contents: ArrayBuffer): Promise<boolean>;
  exportPublicKey(publicKey: CryptoKey): Promise<Jwk>;
}

export const fetchNativeSubtleCrypto = (): SubtleCrypto => {
  return (
    (<any>window.crypto && (<any>window.crypto.subtle || (<any>window.crypto).webkitSubtle)) ||
    ((<any>window).msCrypto && (<any>window).msCrypto.subtle)
  );
};

export function fetchFluorineCrypto(): IFluorineCrypto<CryptoKeyPair, IKeyPairEnvelope> {
  let s = fetchNativeSubtleCrypto();
  if (s) {
    return new NativeCryptoWrapper(s);
  } else {
    throw new Error("No native crypto component found.");
  }
}

export function str2ab(str: string): ArrayBuffer {
  if (!(window as any).TextEncoder) {
    throw new Error("Missing TextEncoder support in your browser.");
  }

  const encoded = new (window as any).TextEncoder(ENCODING).encode(str);
  return encoded.buffer;
}

class NativeMaterialEnvelope implements IKeyPairEnvelope {
  constructor(readonly materials: CryptoKeyPair) {}
}

export interface INativeCrypto extends IFluorineCrypto<CryptoKeyPair, IKeyPairEnvelope> {}

export class NativeCryptoWrapper implements INativeCrypto {
  static algorithmName = "RSASSA-PKCS1-v1_5";
  static digestAlgorithm = "SHA-256";
  static algorithm = {
    name: NativeCryptoWrapper.algorithmName,
    modulusLength: 2048,
    publicExponent: new Uint8Array([0x01, 0x00, 0x01]), // 65537
    hash: { name: NativeCryptoWrapper.digestAlgorithm },
  };

  constructor(readonly subtle: SubtleCrypto) {}

  async generateKeyPair(): Promise<IKeyPairEnvelope> {
    const materials = (await this.subtle.generateKey(NativeCryptoWrapper.algorithm, false, [
      "sign",
      "verify",
    ])) as CryptoKeyPair;
    return new NativeMaterialEnvelope(materials);
  }

  sign(privateKey: CryptoKey, contents: ArrayBuffer): Promise<ArrayBuffer> {
    return <Promise<ArrayBuffer>>this.subtle.sign(NativeCryptoWrapper.algorithm, privateKey, contents);
  }

  /**
   * WE ASSUME we are always signing with the private, and verifying with the public.
   * Further, WE ASSUME that we are always passed an envelope, rather than a WebKey. This
   * is because the Fluorine API is meant to enforce constraints rising from bugs in
   * browser APIs - namely, that Firefox can't import, and Safari can't export.
   * Because of this, we don't expose an import API. When we do, we'll only allow them to
   * import a public key, and enforce that with the param type contract between import and verify.
   * @param envelope The public/private keys
   * @param expected Signature to verify against
   * @param contents Content to be verified
   */
  verify(envelope: IKeyPairEnvelope, expected: ArrayBuffer, contents: ArrayBuffer): Promise<boolean> {
    return <Promise<boolean>>(
      this.subtle.verify(NativeCryptoWrapper.algorithm, envelope.materials.publicKey, expected, contents)
    );
  }

  /**
   * Export key
   * @param publicKey The key to be exported
   * We can't re-import this key, nor should we have to. Exporting is used solely for baking the key into
   * the signed SOUP token, so that the service can verify requests.
   */
  exportPublicKey(publicKey: CryptoKey): Promise<Jwk> {
    return <Promise<Jwk>>this.subtle.exportKey("jwk", publicKey);
  }
}
