export enum Dispatch {
  ADDRESS_VALIDATION = "ADDRESS_VALIDATION",
  AZ_TENANT_ID = "AZ_TENANT_ID",
  AZURE_SUBSCRIPTIONS = "AZURE_SUBSCRIPTIONS",
  CLEAR_ERROR_STATE = "CLEAR_ERROR_STATE",
  EXPERIMENTS_SET_CAMPAIGNS = "EXPERIMENTS_SET_CAMPAIGNS",
  EXPERIMENTS_SET_LOADING = "EXPERIMENTS_SET_LOADING",
  GETTING_AUTH = "GETTING_AUTH",
  HOME_REGION = "HOME_REGION",
  HOME_REGIONS_LIST = "HOME_REGIONS_LIST",
  INVALID_PARAMETER_LINKING_ERROR = "INVALID_PARAMETER_LINKING_ERROR",
  LINKING_FAILURE = "LINKING_FAILURE",
  NON_HENOSIS_LINKING_ERROR = "NON_HENOSIS_LINKING_ERROR",
  NOT_AUTHENTICATED_LINKING_ERROR = "NOT_AUTHENTICATED_LINKING_ERROR",
  OCI_TENANT = "OCI_TENANT",
  OPERATION_ID = "OPERATION_ID",
  ORDER_HAS_EXPIRED = "ORDER_HAS_EXPIRED",
  PAYMENT_ERROR = "PAYMENT_ERROR",
  PAYMENT_RESPONSE = "PAYMENT_RESPONSE",
  PAYMENT_VERIFICATION_TIMES = "PAYMENT_VERIFICATION_TIMES",
  PLACE_DEFAULTS = "PLACE_DEFAULTS",
  RESET_DEFAULT_ADDRESS = "RESET_DEFAULT_ADDRESS",
  SHOW_ACCOUNT_NOT_UNIQUE = "SHOW_ACCOUNT_NOT_UNIQUE",
  SUBMIT_CREATE_TENANCY_INFO = "SUBMIT_CREATE_TENANCY_INFO",
  UPDATE_ACC_NAME = "UPDATE_ACC_NAME",
  UPDATE_ACCOUNT_O4A_ERROR = "UPDATE_ACCOUNT_O4A_ERROR",
  UPDATE_ADDRESS = "UPDATE_ADDRESS",
  UPDATE_AlT_COMPANY_NAME = "UPDATE_AlT_COMPANY_NAME",
  UPDATE_API_ERROR_DETAILS = "UPDATE_API_ERROR_DETAILS",
  UPDATE_API403ERROR = "UPDATE_API403ERROR",
  UPDATE_APIERROR = "UPDATE_APIERROR",
  UPDATE_BASIC_DETAILS = "UPDATE_BASIC_DETAILS",
  UPDATE_BILLING_INFO = "UPDATE_BILLING_INFO",
  UPDATE_CONFIRM_PASSWORD = "UPDATE_CONFIRM_PASSWORD",
  UPDATE_COUNTRIES = "UPDATE_COUNTRIES",
  UPDATE_COUNTRY = "UPDATE_COUNTRY",
  UPDATE_DEFAULT_PROMO = "UPDATE_DEFAULT_PROMO",
  UPDATE_DEPT_NAME = "UPDATE_DEPT_NAME",
  UPDATE_EMAIL_ADDRESS = "UPDATE_EMAIL_ADDRESS",
  UPDATE_FIRST_NAME = "UPDATE_FIRST_NAME",
  UPDATE_HOME_REGION = "UPDATE_HOME_REGION",
  UPDATE_JOB_TITLE = "UPDATE_JOB_TITLE",
  UPDATE_LAST_NAME = "UPDATE_LAST_NAME",
  UPDATE_LOADING = "UPDATE_LOADING",
  UPDATE_NAME = "UPDATE_NAME",
  UPDATE_NONAUTH = "UPDATE_NONAUTH",
  UPDATE_NONAUTH403 = "UPDATE_NONAUTH403",
  UPDATE_NONPENDING = "UPDATE_NONPENDING",
  UPDATE_ORACLE_EMPLOYEE = "UPDATE_ORACLE_EMPLOYEE",
  UPDATE_PASSWORD = "UPDATE_PASSWORD",
  UPDATE_PHONE = "UPDATE_PHONE",
  UPDATE_PHONE_COUNTRY_CODE = "UPDATE_PHONE_COUNTRY_CODE",
  UPDATE_PROMO_ADDRESS = "UPDATE_PROMO_ADDRESS",
  UPDATE_PROMO_COUNTRY = "UPDATE_PROMO_COUNTRY",
  UPDATE_SELECTED_PROMO_ID = "UPDATE_SELECTED_PROMO_ID",
  UPDATE_SHOW_BANNER = "UPDATE_SHOW_BANNER",
  UPDATE_STATES = "UPDATE_STATES",
  UPDATE_TAX_FIELDS = "UPDATE_TAX_FIELDS",
  UPDATE_TENANCY_NAME = "UPDATE_TENANCY_NAME",
  UPDATE_URI = "UPDATE_URI",
  UPDATE_USER_SESSION = "UPDATE_USER_SESSION",
  VALID_PHONE_NUMBER = "VALID_PHONE_NUMBER",
  VALIDATE_EMAIL = "VALIDATE_EMAIL",
}

export type State = {
  user_session: string;
  oracleEmployee: boolean;
  render: boolean;
  country: string; // country ID
  username: string;
  p1: string;
  p2: string;
  accountName: string;
  homeRegion: null;
  homeRegions: Object;
  firstName: string;
  lastName: string;
  countries: [{ value: Object; label: string }];
  countryObject: null; // getCountries API call
  countryInfo: { tc: { orgName: string } }; // Static Config file
  companyName: string;
  departmentName: string;
  altEnglishCompanyName: string;
  jobTitle: string;
  address: {
    address1: string;
    address2: string;
    address3: string;
    address4: string;
    city: string;
    selectedState: null;
    selectedProvince: null;
    state: string;
    province: string;
    postalcode: string;
    county: string;
  };
  states: Object;
  intendedUse: string;
  taxPayerId: string;
  phoneNumber: string;
  selectedPromo: Object;
  defaultPromo: Object;
  orgId: string;
  miniBobEnabled: boolean;
  sourceType: string;
  billingAddress: Object;
  paymentOptions: Object;
  paymentError: Object;
  paymentResponse: Object;
  operationId: string;
  consoleUri: string;
  language: string;
  paymentStatus: string;
  addressValidation: boolean;
  numPaymentVerifyBtnClicked: number;
  phoneCountryCode: string;
  validPhoneNumber: boolean;
  apiError: boolean;
  api403Error: boolean;
  validateEmail: boolean;
  showBanner: boolean;
  featureFlags: Object;
  addressContinueBtn: boolean;
  accountNotUnique: boolean;
  linkingFailure: boolean;
  subscriptionIds: string[];
};

export type DispatchType = (arg0: {
  type: string;
  payload:
    | any[]
    | boolean
    | { label: string; options: { label: string; value: { id: string }; group: string }[] }[]
    | { errorMessage?: string; errorTimestamp?: string; opcRequestId?: string };
}) => void;

export type HomeRegionType = {
  value: { id: string };
  label: string;
  endpoint: string;
  supportedCloudRegionPeer?: string;
};

export enum FeatureFlags {
  ORACLE_EMPLOYEE = "oracle_employee",
  CAPTCHA = "captcha",
  PAYPAL = "enable_paypal",
  SMS = "sms",
}

export enum ProgramType {
  SE_TRIAL = "SE-TRIAL",
  OUTBOUND_TRIALS = "OUTBOUND-TRIALS",
}

export enum PaymentsPromoType {
  ODSA = "ODSA",
  ODASWS = "ODSAWS",
  SIGNUP = "SIGNUP",
}

export enum SourceType {
  PERSONAL = "PERSONAL",
  COMPANY = "COMPANY",
  ENTERPRISE = "ENTERPRISE",
}

export enum IconType {
  WARNING = "warning",
  INFO = "info",
  ERROR = "error",
  CLOSE = "close",
  GREENCHECK = "greenCheck",
  GRAYCHECK = "grayCheck",
  REDCHECK = "redCheck",
  PAYPAL = "paypal",
  ACCORDIONARROWUP = "accordionArrowUp",
  ACCORDIONARROWDOWN = "accordionArrowDown",
  VISA = "visa",
  AMEX = "amex",
  MASTERCARD = "mastercard",
  DISCOVER = "discover",
  DINER = "diner",
  JCB = "jcb",
}

export enum NotifierType {
  EMAIL = "email",
  EMAIL_VERIFICATION_TIMER = "emailVerificationTimer",
  SEND_EMAIL_FAILURE = "sendEmailFailure",
  NORMAL = "normal",
  MOBILE = "mobile",
  MAX_ATTEMPTS_ERROR = "maxAttemptsError",
  MOBILE_MAX_ERROR = "mobileMaxAttemptsError",
  EMAIL_MAX_ERROR = "emailMaxAttemptsError",
  API_ERROR = "apiError",
  API_403_ERROR = "api403Error",
  ACTIVATION_API_ERROR = "activationAPIError",
  CREATE_ERROR = "createError",
}

export enum Routes {
  ACTIVATE = "activate",
  AUTOMATED = "AUTOMATED",
  AZURE = "azure",
  GUIDED = "GUIDED",
}

export enum SessionStorage {
  AUTHENTICATED = "ociAuthN",
  AZURE_SUBSCRIPTIONS = "subscriptions",
  AWS_ID_TOKEN = "awsIdToken",
  CANARY = "canary",
  QUERY_STRING = "queryString",
  SELECTED_SUBSCRIPTIONS = "selectedSubscriptions",
  SESSION_URL = "sessionURL",
  TENANT = "tenant",
  TENANT_NAME = "tenantName",
  URL_PATH = "urlPath",
  CL_VALIDATED = "clValidated",
  PROJECT_FETCHED = "projectFetched",
  ORGANIZATION_FETCHED = "organizationFetched",

  // AWS Keys
  ACCOUNT_ID = "accountID",
  AUTH_CODE = "authCode",
  AWS_AUTH = "awsAuth",
  AWS_USER_TOKENS = "awsUserTokens",
  CLOUDLINK_REGION = "cloudLinkRegion",
  SIGN_IN_DETAILS = "signInDetails",
  USERPOOL_ID = "userpoolID",
  EXTERNAL_ID = "externalID",

  // GCP Keys
  GCP_AUTH = "gcpAuth",
  GCP_ORGANIZATION_ID = "gcpOrganizationId",
  GCP_ORGANIZATION_NAME = "gcpOrganizationName",
  GCP_ACCESS_TOKEN = "gcpAccessToken",
  GCP_CLOUDLINKING_FLOW = "gcpCloudLinkingFlow",
}

export enum LifecycleState {
  ACTIVE = "ACTIVE",
  FAILED = "FAILED",
}

export enum WorkFlowOperationStatus {
  ACCEPTED = "ACCEPTED",
  IN_PROGRESS = "IN_PROGRESS",
  WAITING = "WAITING",
  FAILED = "FAILED",
  SUCCEEDED = "SUCCEEDED",
  CANCELING = "CANCELING",
  CANCELED = "CANCELED",
}

export enum CloudLinkType {
  AZURE = "AZURE",
  AWS = "AWS",
  GCP = "GCP",
}

export type ProviderType = "ODSAWS" | "ODSA" | "ODSGCP" | "";

export enum Countries {
  ANDORRA = "AD",
  UNITED_ARAB_EMIRATES = "AE",
  AFGHANISTAN = "AF",
  ANTIGUA_BARBUDA = "AG",
  ANGUILLA = "AI",
  ALBANIA = "AL",
  ARMENIA = "AM",
  ANGOLA = "AO",
  ANTARCTICA = "AQ",
  ARGENTINA = "AR",
  AMERICAN_SAMOA = "AS",
  AUSTRIA = "AT",
  AUSTRALIA = "AU",
  ARUBA = "AW",
  ALAND_ISLANDS = "AX",
  AZERBAIJAN = "AZ",
  BOSNIA_HERZEGOVINA = "BA",
  BARBADOS = "BB",
  BANGLADESH = "BD",
  BELGIUM = "BE",
  BURKINA_FASO = "BF",
  BULGARIA = "BG",
  BAHRAIN = "BH",
  BURUNDI = "BI",
  BENIN = "BJ",
  ST_BARTHELEMY = "BL",
  BERMUDA = "BM",
  BRUNEI = "BN",
  BOLIVIA = "BO",
  CARIBBEAN_NETHERLANDS = "BQ",
  BRAZIL = "BR",
  BAHAMAS = "BS",
  BHUTAN = "BT",
  BOUVET_ISLAND = "BV",
  BOTSWANA = "BW",
  BELARUS = "BY",
  BELIZE = "BZ",
  CANADA = "CA",
  COCOS_KEELING_ISLANDS = "CC",
  CONGO_KINSHASA = "CD",
  CENTRAL_AFRICAN_REPUBLIC = "CF",
  CONGO_BRAZZAVILLE = "CG",
  SWITZERLAND = "CH",
  COTE_DIVOIRE = "CI",
  COOK_ISLANDS = "CK",
  CHILE = "CL",
  CAMEROON = "CM",
  CHINA = "CN",
  COLOMBIA = "CO",
  COSTA_RICA = "CR",
  CAPE_VERDE = "CV",
  CURACAO = "CW",
  CHRISTMAS_ISLAND = "CX",
  CYPRUS = "CY",
  CZECHIA = "CZ",
  GERMANY = "DE",
  DJIBOUTI = "DJ",
  DENMARK = "DK",
  DOMINICA = "DM",
  DOMINICAN_REPUBLIC = "DO",
  ALGERIA = "DZ",
  ECUADOR = "EC",
  ESTONIA = "EE",
  EGYPT = "EG",
  WESTERN_SAHARA = "EH",
  ERITREA = "ER",
  SPAIN = "ES",
  ETHIOPIA = "ET",
  FINLAND = "FI",
  FIJI = "FJ",
  FALKLAND_ISLANDS = "FK",
  MICRONESIA = "FM",
  FAROE_ISLANDS = "FO",
  FRANCE = "FR",
  GABON = "GA",
  UNITED_KINGDOM = "GB",
  GRENADA = "GD",
  GEORGIA = "GE",
  FRENCH_GUIANA = "GF",
  GUERNSEY = "GG",
  GHANA = "GH",
  GIBRALTAR = "GI",
  GREENLAND = "GL",
  GAMBIA = "GM",
  GUINEA = "GN",
  GUADELOUPE = "GP",
  EQUATORIAL_GUINEA = "GQ",
  GREECE = "GR",
  SOUTH_GEORGIA_SOUTH_SANDWICH_ISLANDS = "GS",
  GUATEMALA = "GT",
  GUAM = "GU",
  GUINEA_BISSAU = "GW",
  GUYANA = "GY",
  HONG_KONG_SAR_CHINA = "HK",
  HEARD_MCDONALD_ISLANDS = "HM",
  HONDURAS = "HN",
  CROATIA = "HR",
  HAITI = "HT",
  HUNGARY = "HU",
  INDONESIA = "ID",
  IRELAND = "IE",
  ISRAEL = "IL",
  ISLE_OF_MAN = "IM",
  INDIA = "IN",
  BRITISH_INDIAN_OCEAN_TERRITORY = "IO",
  IRAQ = "IQ",
  ICELAND = "IS",
  ITALY = "IT",
  JERSEY = "JE",
  JAMAICA = "JM",
  JORDAN = "JO",
  JAPAN = "JP",
  KENYA = "KE",
  KYRGYZSTAN = "KG",
  CAMBODIA = "KH",
  KIRIBATI = "KI",
  COMOROS = "KM",
  ST_KITTS_NEVIS = "KN",
  SOUTH_KOREA = "KR",
  KUWAIT = "KW",
  CAYMAN_ISLANDS = "KY",
  KAZAKHSTAN = "KZ",
  LAOS = "LA",
  LEBANON = "LB",
  ST_LUCIA = "LC",
  LIECHTENSTEIN = "LI",
  SRI_LANKA = "LK",
  LIBERIA = "LR",
  LESOTHO = "LS",
  LITHUANIA = "LT",
  LUXEMBOURG = "LU",
  LATVIA = "LV",
  LIBYA = "LY",
  MOROCCO = "MA",
  MONACO = "MC",
  MOLDOVA = "MD",
  MONTENEGRO = "ME",
  ST_MARTIN = "MF",
  MADAGASCAR = "MG",
  MARSHALL_ISLANDS = "MH",
  MACEDONIA = "MK",
  MALI = "ML",
  MYANMAR_BURMA = "MM",
  MONGOLIA = "MN",
  MACAU_SAR_CHINA = "MO",
  NORTHERN_MARIANA_ISLANDS = "MP",
  MARTINIQUE = "MQ",
  MAURITANIA = "MR",
  MONTSERRAT = "MS",
  MALTA = "MT",
  MAURITIUS = "MU",
  MALDIVES = "MV",
  MALAWI = "MW",
  MEXICO = "MX",
  MALAYSIA = "MY",
  MOZAMBIQUE = "MZ",
  NAMIBIA = "NA",
  NEW_CALEDONIA = "NC",
  NIGER = "NE",
  NORFOLK_ISLAND = "NF",
  NIGERIA = "NG",
  NICARAGUA = "NI",
  NETHERLANDS = "NL",
  NORWAY = "NO",
  NEPAL = "NP",
  NAURU = "NR",
  NIUE = "NU",
  NEW_ZEALAND = "NZ",
  OMAN = "OM",
  PANAMA = "PA",
  PERU = "PE",
  FRENCH_POLYNESIA = "PF",
  PAPUA_NEW_GUINEA = "PG",
  PHILIPPINES = "PH",
  PAKISTAN = "PK",
  POLAND = "PL",
  ST_PIERRE_MIQUELON = "PM",
  PITCAIRN_ISLANDS = "PN",
  PUERTO_RICO = "PR",
  PALESTINIAN_TERRITORIES = "PS",
  PORTUGAL = "PT",
  PALAU = "PW",
  PARAGUAY = "PY",
  QATAR = "QA",
  REUNION = "RE",
  ROMANIA = "RO",
  SERBIA = "RS",
  RUSSIA = "RU",
  RWANDA = "RW",
  SAUDI_ARABIA = "SA",
  SOLOMON_ISLANDS = "SB",
  SEYCHELLES = "SC",
  SWEDEN = "SE",
  SINGAPORE = "SG",
  ST_HELENA = "SH",
  SLOVENIA = "SI",
  SVALBARD_JAN_MAYEN = "SJ",
  SLOVAKIA = "SK",
  SIERRA_LEONE = "SL",
  SAN_MARINO = "SM",
  SENEGAL = "SN",
  SOMALIA = "SO",
  SURINAME = "SR",
  SOUTH_SUDAN = "SS",
  SAO_TOME_PRINCIPE = "ST",
  EL_SALVADOR = "SV",
  SINT_MAARTEN = "SX",
  SWAZILAND = "SZ",
  TURKS_CAICOS_ISLANDS = "TC",
  CHAD = "TD",
  FRENCH_SOUTHERN_TERRITORIES = "TF",
  TOGO = "TG",
  THAILAND = "TH",
  TAJIKISTAN = "TJ",
  TOKELAU = "TK",
  TIMOR_LESTE = "TL",
  TURKMENISTAN = "TM",
  TUNISIA = "TN",
  TONGA = "TO",
  TURKEY = "TR",
  TRINIDAD_TOBAGO = "TT",
  TUVALU = "TV",
  TAIWAN = "TW",
  TANZANIA = "TZ",
  UKRAINE = "UA",
  UGANDA = "UG",
  US_OUTLYING_ISLANDS = "UM",
  UNITED_STATES = "US",
  URUGUAY = "UY",
  UZBEKISTAN = "UZ",
  VATICAN_CITY = "VA",
  ST_VINCENT_GRENADINES = "VC",
  VENEZUELA = "VE",
  BRITISH_VIRGIN_ISLANDS = "VG",
  US_VIRGIN_ISLANDS = "VI",
  VIETNAM = "VN",
  VANUATU = "VU",
  WALLIS_FUTUNA = "WF",
  SAMOA = "WS",
  YEMEN = "YE",
  MAYOTTE = "YT",
  SOUTH_AFRICA = "ZA",
  ZAMBIA = "ZM",
  ZIMBABWE = "ZW",
}

export enum EventType {
  PageViewEvent = 0,
  ClickEvent = 1,
}

export declare enum LogLevel {
  Error = 1,
  Warning = 2,
  Info = 4,
  Debug = 8,
}

export type AwsSignInDetails = {
  cognitoDomain: string;
  cognitoRegion: string;
  clientId: string;
  scope: string;
};

export enum ValidationStatus {
  NOACCESS = "NOACCESS",
  INVALID = "INVALID",
  READY = "READY",
}

export type HomeRegionInternal = {
  value: { id: string };
  label: string;
  endpoint: string;
};

export type Truste = {
  cma?: {
    version: string;
    callApi(methodName: string, consented: string): { consentDecision: number };
  };
  eu?: {
    icon: {
      initialize: () => void;
    };
  };
};
