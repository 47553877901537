import React from "react";
import * as Messages from "../../codegen/Messages";
import { useFormState } from "../../components/Context/ApplicationContext";
import "./Modal.scss";

interface TroubleShootingModalProps {
  modalClassName: string;
}

const TroubleShootingModal = ({ modalClassName }: TroubleShootingModalProps) => {
  const { errorMessage, opcRequestId, errorTimestamp } = useFormState();
  return (
    <>
      {(opcRequestId || errorMessage) && (
        <div
          id="troubleshooting-modal"
          className={`${modalClassName} troubleshootingModal`}
          data-testid="troubleshootingModal"
        >
          {<h4>{Messages.troubleShootingModal.troubleShootingTitle()}</h4>}
          <div className="troubleshootingContent">
            {opcRequestId && (
              <div data-testid="opcRequestId">
                <b>{Messages.troubleShootingModal.requestId()}</b> {opcRequestId}
              </div>
            )}
            {errorTimestamp && (
              <div data-testid="errorTimestamp">
                <b>{Messages.troubleShootingModal.timestamp()}</b> {errorTimestamp}
              </div>
            )}
            {errorMessage && (
              <div data-testid="errorMessage">
                <b>{Messages.troubleShootingModal.errorMessage()}</b> {errorMessage}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TroubleShootingModal;
