import { TopNavigation } from "o4aws-react";
import React from "react";
import { RenderMetricsProps, withRenderMetrics } from "../../CourierService/RenderMetrics";
import oracleLogo from "../../images/oci-red-logo.png";

const AwsHeader = withRenderMetrics(({ renderMetrics }: RenderMetricsProps) => {
  React.useEffect(() => {
    renderMetrics && renderMetrics.rendered("AwsHeader");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <TopNavigation
      identity={{
        href: "#",
        title: "Oracle Database Service for AWS",
        logo: {
          src: oracleLogo,
          alt: "ODAWS",
        },
      }}
      i18nStrings={{
        overflowMenuTriggerText: "More",
        overflowMenuTitleText: "All",
      }}
    />
  );
});

export default AwsHeader;
