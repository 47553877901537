import * as React from "react";
import "./CircularLoader.css";

interface Props {
  className: string;
}

const CircularLoader = (props: Props) => {
  const { ...rest } = props;
  return (
    <div data-testid="circular-loader" {...rest} className={`loader ${props.className}`}>
      <div className="loader-well" />
      <div className="loader-track" />
    </div>
  );
};

export default CircularLoader;
