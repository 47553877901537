import * as React from "react";
import { Box, SpaceBetween } from "../../index";

export type LabelValuePairFlex = "horizontal" | "vertical";

export interface LabelValuePairProps {
  labelName: string;
  value?: string | JSX.Element;
  flex?: LabelValuePairFlex;
}

export function LabelValuePair({ labelName, value, flex }: LabelValuePairProps): JSX.Element {
  if (!flex) {
    flex = "vertical";
  }

  return (
    <SpaceBetween size="s" direction={flex}>
      <Box variant="awsui-key-label">{labelName}</Box>
      <div>{value}</div>
    </SpaceBetween>
  );
}

export default LabelValuePair;
