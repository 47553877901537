import React from "react";
import { RenderMetricsProps, withRenderMetrics } from "../../CourierService/RenderMetrics";

type OciHeaderProps = {
  includeLogo: boolean;
};

type Props = OciHeaderProps & RenderMetricsProps;
const Header = withRenderMetrics(({ includeLogo, renderMetrics }: Props) => {
  React.useEffect(() => {
    renderMetrics && renderMetrics.rendered("Header");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <header className="ociBrandingBar" data-testid="header-container">
      {includeLogo && <div className="oLogo" />}
      <div className={`oLogoWords title7 ${includeLogo ? "" : "padding-left-med"}`}>
        {"Cloud Infrastructure"}
      </div>
    </header>
  );
});

export default Header;
