import * as React from "react";
import "./Button.css";
import CircularLoader from "../CircularLoader/CircularLoader";

interface ButtonProps {
  className?: string;
  disabled?: boolean;
  id?: string;
  loading?: boolean;
  onClick?: () => void;
  style?: {};
  text?: string;
  type?: string;
}

const _extends =
  Object.assign ||
  // @ts-ignore
  function (target) {
    for (let i = 1; i < arguments.length; i++) {
      const source = arguments[i];
      for (let key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

// @ts-ignore
function _objectWithoutProperties(obj, keys) {
  const target = {};
  for (let i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    // @ts-ignore
    target[i] = obj[i];
  }
  return target;
}

const Button = function Button(props: ButtonProps) {
  const type = props.type,
    text = props.text,
    loading = props.loading,
    rest = _objectWithoutProperties(props, ["type", "text", "loading"]);

  return React.createElement(
    React.Fragment,
    null,
    React.createElement(
      "button",
      _extends(
        {
          type: "button",
          className: type,
        },
        rest,
      ),
      loading && React.createElement(CircularLoader, { className: "loader-sm" }),
      text,
    ),
  );
};

export default Button;
