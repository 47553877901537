import _ from "lodash";
import { Region } from "oci-console-regions";
import {
  AutomatedAzureAppIds,
  AwsCfnTemplateURLs,
  AWSGetCloudLinkURLs,
  AzureAppIds,
  CloudLinkUrls,
  GcpClientIds,
  GCPGetCloudLinkURLs,
  MchubPlatformURLs,
  MchubProxyURLs,
  MchubSignupURLs,
  ODSAConsoleUrls,
  ODSAWSConsoleURLs,
  ODSGCPConsoleURLs,
  SignUpURLs,
} from "../config/apiConfig";
import { CloudLinkType, Routes } from "../Type";
import { isLocalhostEnv, isPintlabEnv, isStagingEnv } from "../utils/ApiConfigUtil";

export const getAnalytics = () => {
  if (isPintlabEnv() || isStagingEnv() || isLocalhostEnv()) {
    return false;
  }
  return true;
};

export const isAutomatedFlow = () => {
  return window.location.href?.includes(Routes.AUTOMATED.toLowerCase()) ? true : false;
};

export const fetchTenant = () => {
  const urlParameters = new URLSearchParams(window.location.search);
  return urlParameters.get("tenancyId") || undefined;
};

export const getAzureAppId = (automatedFlow?: boolean) => {
  const automated = automatedFlow === undefined ? isAutomatedFlow() : automatedFlow;
  return automated ? getAutomatedAzureAppId() : getGuidedAzureAppId();
};

export const getGuidedAzureAppId = () => {
  if (isPintlabEnv() || isLocalhostEnv()) {
    return AzureAppIds.DEV;
  } else if (isStagingEnv()) {
    return AzureAppIds.PREPROD;
  } else {
    return AzureAppIds.PROD;
  }
};

export const getAutomatedAzureAppId = () => {
  if (isPintlabEnv() || isLocalhostEnv()) {
    return AutomatedAzureAppIds.DEV;
  } else if (isStagingEnv()) {
    return AutomatedAzureAppIds.PREPROD;
  } else {
    return AutomatedAzureAppIds.PROD;
  }
};

export const getConsoleEndpoint = (cloudType: CloudLinkType) => {
  if (isPintlabEnv() || isLocalhostEnv()) {
    if (cloudType === CloudLinkType.AZURE) {
      return ODSAConsoleUrls.DEV;
    } else if (cloudType === CloudLinkType.AWS) {
      return ODSAWSConsoleURLs.DEV;
    } else if (cloudType === CloudLinkType.GCP) {
      return ODSGCPConsoleURLs.DEV;
    }
  } else if (isStagingEnv()) {
    if (cloudType === CloudLinkType.AZURE) {
      return ODSAConsoleUrls.PREPROD;
    } else if (cloudType === CloudLinkType.AWS) {
      return ODSAWSConsoleURLs.PREPROD;
    } else if (cloudType === CloudLinkType.GCP) {
      return ODSGCPConsoleURLs.PREPROD;
    }
  } else {
    if (cloudType === CloudLinkType.AZURE) {
      return ODSAConsoleUrls.PROD;
    } else if (cloudType === CloudLinkType.AWS) {
      return ODSAWSConsoleURLs.PROD;
    } else if (cloudType === CloudLinkType.GCP) {
      return ODSGCPConsoleURLs.PROD;
    }
  }
};

export const getMchubEndpoint = (urlRegion?: Region) => {
  if (isPintlabEnv() || isLocalhostEnv()) {
    return CloudLinkUrls.setRegion(urlRegion).DEV;
  } else if (isStagingEnv()) {
    return CloudLinkUrls.setRegion(urlRegion).PREPROD;
  } else {
    return CloudLinkUrls.setRegion(urlRegion).PROD;
  }
};

export const getMchubAzureProxyEndpoint = (urlRegion?: Region) => {
  if (isPintlabEnv() || isLocalhostEnv()) {
    return MchubProxyURLs.setRegion(urlRegion).DEV;
  } else if (isStagingEnv()) {
    return MchubProxyURLs.setRegion(urlRegion).PREPROD;
  } else {
    return MchubProxyURLs.setRegion(urlRegion).PROD;
  }
};

export const AWSGetCloudLinkEndpoint = (accountId: string, urlRegion?: Region) => {
  if (isPintlabEnv() || isLocalhostEnv()) {
    return AWSGetCloudLinkURLs.setRegion(accountId, urlRegion).DEV;
  } else if (isStagingEnv()) {
    return AWSGetCloudLinkURLs.setRegion(accountId, urlRegion).PREPROD;
  } else {
    return AWSGetCloudLinkURLs.setRegion(accountId, urlRegion).PROD;
  }
};

export const getAwsCfnTemplateEndpoint = () => {
  if (isPintlabEnv() || isLocalhostEnv()) {
    return AwsCfnTemplateURLs.DEV;
  } else if (isStagingEnv()) {
    return AwsCfnTemplateURLs.PREPROD;
  } else {
    return AwsCfnTemplateURLs.PROD;
  }
};

export const getMchubSignupEndpoint = (urlRegion?: Region) => {
  if (isPintlabEnv() || isLocalhostEnv()) {
    return MchubSignupURLs.setRegion(urlRegion).DEV;
  } else if (isStagingEnv()) {
    return MchubSignupURLs.setRegion(urlRegion).PREPROD;
  } else {
    return MchubSignupURLs.setRegion(urlRegion).PROD;
  }
};

export const getEventNameByFlow = (eventName: string): string => {
  return window.location.href?.includes(Routes.AUTOMATED.toLowerCase())
    ? `${eventName} automated`
    : window.location.href?.includes(Routes.GUIDED.toLowerCase())
    ? `${eventName} guided`
    : eventName;
};

export const getGcpClientId = () => {
  if (isPintlabEnv() || isLocalhostEnv()) {
    return GcpClientIds.DEV;
  } else if (isStagingEnv()) {
    return GcpClientIds.PREPROD;
  } else {
    return GcpClientIds.PROD;
  }
};

export const GCPGetCloudLinkEndpoint = (tenantId: string, urlRegion?: Region) => {
  if (isPintlabEnv() || isLocalhostEnv()) {
    return GCPGetCloudLinkURLs.setRegion(tenantId, urlRegion).DEV;
  } else if (isStagingEnv()) {
    return GCPGetCloudLinkURLs.setRegion(tenantId, urlRegion).PREPROD;
  } else {
    return GCPGetCloudLinkURLs.setRegion(tenantId, urlRegion).PROD;
  }
};

export const getSignupUrl = () => {
  if (isPintlabEnv() || isLocalhostEnv()) {
    return `https://${SignUpURLs.PINTLAB}`;
  } else if (isStagingEnv()) {
    return `https://${SignUpURLs.STAGE}`;
  } else {
    return `https://${SignUpURLs.PROD}`;
  }
};

/**
 * Can be lazily updated when adding regions.
 * Note: This list is only used to break the chicken and egg problem of supported regions.
 * This region list is not the source of truth and MCCP `/regions` API will be the one
 */
export const getBootstrapRegionList = (cloudLinkType: CloudLinkType): Region[] => {
  // Shuffle to randomize load
  if (cloudLinkType === CloudLinkType.AZURE) {
    if (isPintlabEnv() || isLocalhostEnv()) {
      return [Region.IAD, Region.FRA];
    } else if (isStagingEnv()) {
      return [Region.IAD, Region.FRA];
    } else {
      let otherRegions = _.shuffle([
        Region.LHR,
        Region.FRA,
        Region.PHX,
        Region.NRT,
        Region.SJC,
        Region.YYZ,
        Region.AMS,
        Region.VCP,
        Region.ICN,
        Region.SIN,
        Region.JNB,
      ]);
      return [Region.IAD, ...otherRegions];
    }
  } else if (cloudLinkType === CloudLinkType.AWS) {
    if (isPintlabEnv() || isLocalhostEnv()) {
      return [Region.IAD, Region.FRA];
    } else if (isStagingEnv()) {
      return [Region.IAD, Region.FRA];
    } else {
      let otherRegions = _.shuffle([
        Region.IAD,
        Region.LHR,
        Region.FRA,
        Region.NRT,
        Region.SJC,
        Region.VCP,
        Region.SIN,
        Region.YUL,
      ]);
      return [Region.IAD, ...otherRegions];
    }
  } else if (cloudLinkType === CloudLinkType.GCP) {
    if (isPintlabEnv() || isLocalhostEnv()) {
      return [Region.IAD, Region.FRA];
    } else if (isStagingEnv()) {
      return [Region.IAD, Region.FRA];
    } else {
      let otherRegions = _.shuffle([Region.LHR, Region.FRA, Region.NRT, Region.YYZ]);
      return [Region.IAD, ...otherRegions];
    }
  } else {
    return [];
  }
};
