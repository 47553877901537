/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */

import jwtDecode from "jwt-decode";
import pkceChallenge from "pkce-challenge";
import {
  getCognitoUrl,
  getCognitoTokenUrl,
  removeParameterFromQueryString,
  getCognitoLogoutUrl,
} from "./utils/urlUtils";
import {
  getAccessTokenValue,
  isAccessTokenAboutToExpire,
  isAccessTokenDead,
  isIdentityTokenDead,
} from "./utils/tokenUtills";
import { TokenState } from "./enums/tokenState";
import {
  getItemFromLocalStore,
  removeItemFromLocalStore,
  setItemToLocalStore,
} from "./utils/storageUtils";
import { IdentityToken } from "./interfaces/identityToken";
import { AwsAuthConfig } from "./interfaces/awsAuthConfig";
import { getWindow } from "./utils/browserHelpers";
import StorageKeys from "./constants/storageKeys";
import QueryParams from "./constants/queryParams";
import UrlConstants from "./constants/urls";
import ErrorUtils from "./utils/errorUtils";
import { FetchApiSignature, UserInfo, UserTokens } from "./interfaces";
import { AccountInfo } from "./interfaces/accountInfo";

class AwsAuth {
  private awsConfig: AwsAuthConfig;
  private isHandleRedirectInProcess: boolean;

  private constructor(config: AwsAuthConfig) {
    this.awsConfig = config;
    this.isHandleRedirectInProcess = false;
  }

  private static singleton: AwsAuth;

  public static async init(config: AwsAuthConfig): Promise<AwsAuth> {
    const storeUserInfo = getItemFromLocalStore(StorageKeys.UserInfo) || {};

    config.domain = config.domain || storeUserInfo.domain;
    config.region = config.region || storeUserInfo.region;
    config.clientId = config.clientId || storeUserInfo.clientId;

    AwsAuth.singleton = new AwsAuth(config);

    /**
     * If we dont have info or the session timeout request =>  Send the user to MissingInfoPage
     * but no need if the user token is empty
     * If we have data with empty token then user has just made the MCCP call to get the data
     */

    const tokenState = AwsAuth.singleton.getTokenState();
    const invalidToken = tokenState === TokenState.INVALID;
    if (!config.domain || !config.region || !config.clientId || invalidToken) {
      if (invalidToken) {
        removeItemFromLocalStore(StorageKeys.UserTokensKey);
        removeItemFromLocalStore(StorageKeys.UserInfo);
      }
      console.error(ErrorUtils.missingInfoError);
      throw ErrorUtils.missingInfoError;
    }

    // If the store data needs to be written/overwritten
    if (tokenState !== TokenState.VALID) {
      const newStoreData: AwsAuthConfig = {
        domain: config.domain,
        region: config.region,
        clientId: config.clientId,
        callbackUrl: config.callbackUrl,
        signoutUrl: config.signoutUrl,
        scopes: config.scopes,
        userPoolId: config.userPoolId,
      };
      setItemToLocalStore(StorageKeys.UserInfo, newStoreData);
    } else {
      // the user is still logged in (but store is outdated) - can this scenario even happen?
      AwsAuth.singleton.awsConfig.domain = storeUserInfo.domain;
      AwsAuth.singleton.awsConfig.region = storeUserInfo.region;
      AwsAuth.singleton.awsConfig.clientId = storeUserInfo.clientId;
    }

    await AwsAuth.singleton.handleRedirect();
    return AwsAuth.singleton;
  }

  public static get instance(): AwsAuth {
    if (!AwsAuth.singleton) {
      throw ErrorUtils.authNotInitializedError;
    }
    return AwsAuth.singleton;
  }

  public async authenticate(force: boolean = false) {
    try {
      if (!(this.getTokenState() === TokenState.VALID) || force) {
        const challenge = this.generateCodeChallenge();
        const url = getCognitoUrl(UrlConstants.CognitoLoginURLTemplate, this.awsConfig, challenge);
        getWindow().location.href = url;
      }
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
  }

  private generateCodeChallenge() {
    const challenge = pkceChallenge(128);
    const codeVerifier = challenge.code_verifier;
    const codeChallenge = challenge.code_challenge;
    this.saveVerifier(codeVerifier);
    return codeChallenge;
  }

  private saveVerifier(codeVerifier: string) {
    setItemToLocalStore(StorageKeys.CodeVerifierKey, codeVerifier);
  }
  private getVerifier() {
    return getItemFromLocalStore(StorageKeys.CodeVerifierKey);
  }

  private getTokenState(): TokenState {
    let tokenState = TokenState.EMPTY;
    const tokens = getItemFromLocalStore(StorageKeys.UserTokensKey);
    if (tokens) {
      tokenState = !isAccessTokenDead(tokens.access_token) ? TokenState.VALID : TokenState.INVALID;
    }
    return tokenState;
  }

  public async logOut() {
    const url = getCognitoLogoutUrl(UrlConstants.CognitoLogoutURLTemplate, this.awsConfig);
    removeItemFromLocalStore(StorageKeys.UserTokensKey);
    removeItemFromLocalStore(StorageKeys.UserInfo);
    getWindow().location.href = url;
  }

  public async getAccessToken() {
    const tokens = getItemFromLocalStore(StorageKeys.UserTokensKey);
    if (tokens) {
      const isInValid =
        isAccessTokenDead(tokens.access_token) || isAccessTokenAboutToExpire(tokens.access_token);
      if (isInValid) {
        const { access_token } = await this.getRefreshedUserTokens(); // eslint-disable-line camelcase
        return access_token; // eslint-disable-line camelcase
      }
      return tokens.access_token;
    }
    throw ErrorUtils.userNotLoggedInError;
  }

  public getIdentityTokenDetails(): IdentityToken | undefined {
    let idDetails: IdentityToken | undefined;
    const tokens = getItemFromLocalStore(StorageKeys.UserTokensKey);
    if (tokens) {
      const isInValid = isIdentityTokenDead(tokens.id_token);
      if (!isInValid) {
        idDetails = jwtDecode(tokens.id_token);
      }
    }
    return idDetails;
  }

  public async getAccount(): Promise<AccountInfo | undefined> {
    let accountInfo: AccountInfo | undefined;
    const identityDetails = this.getIdentityTokenDetails();
    if (identityDetails) {
      accountInfo = {
        email: identityDetails.email,
        name: identityDetails.name,
        idTokenClaims: identityDetails,
      };
    }
    return accountInfo;
  }

  public async signRequest(request: Request): Promise<Request> {
    const intRequest = new Request(request);
    const accessToken = await this.getAccessToken();
    const bearer = `Bearer ${accessToken}`;
    intRequest.headers.append("Authorization", bearer);
    return intRequest;
  }

  private async getRefreshedUserTokens() {
    const tokens = await this.refreshUserTokens();
    this.setRefreshedUserTokensToStorage(tokens);
    return tokens;
  }

  private setRefreshedUserTokensToStorage(newTokens: UserTokens) {
    const tokens = getItemFromLocalStore(StorageKeys.UserTokensKey);
    if (tokens) {
      tokens.access_token = newTokens.access_token;
      tokens.id_token = newTokens.id_token;
      tokens.expires = newTokens.expires;
      setItemToLocalStore(StorageKeys.UserTokensKey, tokens);
    }
  }

  private async handleRedirect() {
    if (this.isHandleRedirectInProcess) {
      return;
    }
    try {
      this.isHandleRedirectInProcess = true;
      const params = new URLSearchParams(getWindow().location.search);
      if (params && params.has(QueryParams.Code)) {
        const code = params.get(QueryParams.Code);
        if (code) {
          const response = await this.fetchToken(code);
          if (!response.error) {
            setItemToLocalStore(StorageKeys.UserTokensKey, response);
          }
          removeParameterFromQueryString(
            QueryParams.Code,
            getWindow().location,
            this.awsConfig.restoreRouteCallback,
          );
        }
      }
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      throw error;
    } finally {
      this.isHandleRedirectInProcess = false;
    }
  }

  public async getUserInfo(): Promise<UserInfo | undefined> {
    let userInfo: UserInfo | undefined;
    const identityDetails = this.getIdentityTokenDetails();
    if (identityDetails) {
      userInfo = {
        email: identityDetails.email,
        username: identityDetails["cognito:username"],
      };
    }
    return userInfo;
  }

  public getFetchApi(): FetchApiSignature {
    return async (input: string | Request, init?: RequestInit) => {
      const request = await this.signRequest(new Request(input, init));
      return fetch(request);
    };
  }

  /**
   * @returns true if authenticated, otherwise returns an error
   */
  public async isSessionActive(): Promise<boolean> {
    const tokens = getItemFromLocalStore(StorageKeys.UserTokensKey);
    return tokens && !isAccessTokenDead(tokens.access_token);
  }

  /**
   * Get the token issued at - iat from the security token
   * @returns token issued at as a timestamp in seconds
   */
  public async getIssuedAt(): Promise<number> {
    return getAccessTokenValue("iat");
  }

  /**
   * Get the token expiration - exp from the security token
   * @returns token expiration as a timestamp in seconds
   */
  public async getExpiration(): Promise<number> {
    return getAccessTokenValue("exp");
  }

  private async fetchToken(code: string) {
    let response = null;
    const verifier = this.getVerifier();
    const url = getCognitoTokenUrl(this.awsConfig);
    const data = `grant_type=authorization_code&client_id=${this.awsConfig.clientId}&code=${code}&redirect_uri=${this.awsConfig.callbackUrl}&code_verifier=${verifier}`;
    try {
      response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: data,
        cache: "no-cache",
      });
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      throw error;
    }
    return response?.json();
  }

  private async refreshUserTokens() {
    let response = null;
    const tokens = getItemFromLocalStore(StorageKeys.UserTokensKey);

    if (tokens) {
      const url = getCognitoTokenUrl(this.awsConfig);
      const data = `grant_type=refresh_token&client_id=${this.awsConfig.clientId}&refresh_token=${tokens.refresh_token}`;

      try {
        response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: data,
          cache: "no-cache",
        });
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
        throw error;
      }
    } else {
      throw ErrorUtils.userNotLoggedInError;
    }
    return response?.json();
  }
}

export default AwsAuth;
