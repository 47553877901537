import * as React from "react";
import { useEffect, useState } from "react";
import {
  NonCancelableCustomEvent,
  PaginationProps,
  TableProps,
  TextFilterProps,
} from "../../index";
import { startCase, titleCase } from "../../utils/textTransform";
import Highlight from "../Highlight/Highlight";
import { DynamicTableColumnDefinition } from "./ResourceListTableDynamic";
import {
  getFilteredData,
  getPagedData,
  getSortedData,
  PaginatorArg,
  SortArgs,
  sortColumnDefinitions,
} from "./utils";

type SortState<TEntity> = {
  sortingColumn: TableProps.ColumnDefinition<TEntity>;
} & SortArgs<TEntity>;

type PaginatorState = {
  pageCount: number;
} & PaginatorArg;

type ColumnsMetadata<TEntity> = Pick<TableProps<TEntity>, "columnDefinitions" | "visibleColumns">;

export type UseDynamicTableDataSourceArg<TEntity> = {
  baseColumnDefinitions?: readonly DynamicTableColumnDefinition<TEntity>[];
  baseVisibleColumns?: readonly (keyof TEntity)[];
  columnsOrder?: readonly (keyof TEntity)[];
  dataSource: readonly TEntity[];
  filterCaseSensitive: boolean;
  highlightSearch?: boolean;
  initialPageSize: number;
  onFilterChanged?(_filterText: string): void;
};

export type UseDynamicTableDataSourceResp<TEntity> = {
  columnMetadata: ColumnsMetadata<TEntity>;
  filterText: string;
  handleFilterTextChange(_event: NonCancelableCustomEvent<TextFilterProps.ChangeDetail>): void;
  handlePageChange(_event: NonCancelableCustomEvent<PaginationProps.ChangeDetail>): void;
  handleSortingChange(_event: NonCancelableCustomEvent<TableProps.SortingState<TEntity>>): void;
  items: TEntity[];
  paginator: PaginatorState;
  sort: SortState<TEntity>;
};

function useDynamicTableDataSource<TEntity>({
  baseColumnDefinitions,
  baseVisibleColumns,
  columnsOrder,
  dataSource,
  filterCaseSensitive,
  highlightSearch,
  initialPageSize,
  onFilterChanged,
}: UseDynamicTableDataSourceArg<TEntity>): UseDynamicTableDataSourceResp<TEntity> {
  const [allItems, setAllItems] = useState<TEntity[]>();
  const [filteredItems, setFilteredItems] = useState<TEntity[]>();
  const [paginator, setPaginator] = useState<PaginatorState>({
    currentPageIndex: 1,
    pageCount: 0,
    pageSize: initialPageSize,
  });
  const [sort, setSort] = useState<SortState<TEntity>>();
  const [filterText, setFilterText] = useState<string>("");
  const [columnMetadata, setColumnMetadata] = useState<ColumnsMetadata<TEntity>>({
    columnDefinitions: ensureBaseColumnDefinitionDefault(),
    visibleColumns: baseVisibleColumns as readonly string[],
  });

  useEffect(() => setAllItems([...dataSource]), [dataSource]);
  useEffect(initColumnMetadata, [
    allItems,
    filterText,
    columnsOrder,
    baseColumnDefinitions,
    baseVisibleColumns,
  ]);

  useEffect(() => {
    loadFilterItems();
  }, [allItems, filterText, paginator.currentPageIndex, paginator.pageSize, sort]);

  function loadFilterItems(): void {
    const filteredData = getSortedData(
      sort,
      getFilteredData({
        search: filterText,
        data: allItems?.length ? [...allItems] : [],
        caseSensitive: filterCaseSensitive,
      }),
      sort?.sortingColumn?.sortingComparator,
    );

    const newPageCount = Math.ceil(filteredData.length / paginator.pageSize);
    setPaginator({
      ...paginator,
      pageCount: newPageCount,
      currentPageIndex: newPageCount !== paginator.pageCount ? 1 : paginator.currentPageIndex,
    });
    setFilteredItems(getPagedData(paginator, filteredData));
  }

  function initColumnMetadata(): void {
    if (!allItems?.length) return;

    const entity = allItems[0];
    const allColumnsVisible: string[] = [];
    const columnDefinitions = (Object.keys(entity) as (keyof TEntity)[]).map(
      (entityKey: keyof TEntity) => {
        allColumnsVisible.push(entityKey as string);
        const configuredColumn = baseColumnDefinitions?.find(c => c.id === (entityKey as string));
        return {
          ...configuredColumn,
          id: entityKey as string,
          header: configuredColumn?.header ?? titleCase(startCase(entityKey as string)),
          cell: getColumnDefCell(entityKey, configuredColumn),
          sortingField: configuredColumn?.sortingDisabled ? undefined : (entityKey as string),
          sortingComparator: configuredColumn?.sortingComparator,
        } as TableProps.ColumnDefinition<TEntity>;
      },
    );

    sortColumnDefinitions(columnDefinitions, columnsOrder);

    setColumnMetadata({
      columnDefinitions,
      visibleColumns: columnMetadata?.visibleColumns ?? allColumnsVisible,
    });
  }

  function handleSortingChange(
    event: NonCancelableCustomEvent<TableProps.SortingState<TEntity>>,
  ): void {
    const sortingColumn = columnMetadata.columnDefinitions.find(
      c => c.sortingField === event.detail.sortingColumn.sortingField,
    );

    if (!sortingColumn) return;

    setSort({
      sortingColumn,
      direction: event.detail.isDescending ? "desc" : "asc",
      sortingColumnName: event.detail.sortingColumn.sortingField as keyof TEntity,
    });
  }

  function getColumnDefCell(
    entityKey: keyof TEntity,
    configuredColumn: DynamicTableColumnDefinition<TEntity>,
  ): (_item: TEntity) => React.ReactNode {
    return (
      configuredColumn?.cell ??
      ((cellEntity: TEntity) =>
        !highlightSearch ? (
          cellEntity[entityKey]
        ) : (
          <Highlight
            key={entityKey as string}
            inputValue={cellEntity[entityKey].toString()}
            search={filterText}
            caseSensitive={filterCaseSensitive}
          />
        ))
    );
  }

  function ensureBaseColumnDefinitionDefault(): readonly TableProps.ColumnDefinition<TEntity>[] {
    if (!baseColumnDefinitions) {
      return [];
    }
    return baseColumnDefinitions.map(
      cd =>
        ({
          ...cd,
          cell: cd.cell ?? ((cellEntity: TEntity) => cellEntity[cd.id as keyof TEntity]),
        } as TableProps.ColumnDefinition<TEntity>),
    );
  }

  return {
    items: filteredItems,
    filterText,
    handleFilterTextChange(event: NonCancelableCustomEvent<TextFilterProps.ChangeDetail>) {
      setFilterText(event.detail.filteringText);
      onFilterChanged && onFilterChanged(event.detail.filteringText);
    },
    paginator,
    handlePageChange(event: NonCancelableCustomEvent<PaginationProps.ChangeDetail>): void {
      setPaginator({ ...paginator, currentPageIndex: event.detail.currentPageIndex });
    },
    sort,
    handleSortingChange,
    columnMetadata,
  };
}

export default useDynamicTableDataSource;
