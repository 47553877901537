export function startCase(str?: string | null): string | undefined | null {
  return !str ? str : str.replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2").trim();
}

export function titleCase(str?: string | null) {
  return !str
    ? str
    : str
        .toLowerCase()
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}
