import { StorageLoc } from "../../utils/BrowserStorageService";

export const MAXY_STORAGE_LOCATION: StorageLoc = "session";
export const MAXY_BASE_URL = "https://api.maxymiser.net/us";
export const MAXY_STORAGE_KEY = "maxy_visitor_state";
export const UNIFIED_EXPERIMENT_SITE_ID = "MDAyMzU0";

export function getGenerationUrl(siteId: string): string {
  return `${MAXY_BASE_URL}/v1/sites/${siteId}/generations`;
}

export function actionMetricUrl(siteId: string): string {
  return `${MAXY_BASE_URL}/v1/sites/${siteId}/actions`;
}
