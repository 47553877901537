/*
 * This import has to be done this way since this is not exported by the library
 * and it was throwing an error at compilation time since we're using webpack 5
 * and respecting the package.json exports is enabled there.
 */
// eslint-disable-next-line import/no-relative-packages
import { preset } from "../../../../node_modules/@awsui/components-react/internal/generated/theming/index.js";

import { getDocument } from "../utils/browser";
import { toCamelCase } from "../utils/string";
import { CssVariables, variableOverrides } from "./variables";

export const TOKEN_VARS_ID = "o4aws-react-token-vars";
export const RAW_VARS_ID = "o4aws-react-vars";

async function buildAddVariableStyleNode(
  nodeId: string,
  variables: CssVariables,
  varNameTransformer: (varName: string) => string | null,
): Promise<void> {
  let content = "";
  const newLine = "\n";
  const selectors = Object.keys(variables);
  for (let i = 0, selectorsLength = selectors.length; i < selectorsLength; ++i) {
    const selector = selectors[i];
    const varNames = Object.keys(variables[selector]);
    content += `${selector} {`;
    for (let j = 0, varLength = varNames.length; j < varLength; ++j) {
      const varName = varNameTransformer(varNames[j]);
      varName && (content += `${newLine}  ${varName}: ${variables[selector][varNames[j]]};`);
    }
    content += `${newLine}}${newLine}`;
  }

  const documentRef = getDocument();
  const styleNode = documentRef.createElement("style");
  styleNode.id = nodeId;
  styleNode.appendChild(documentRef.createTextNode(content));
  documentRef.body.appendChild(styleNode);
}

function initVariables(): Promise<[void, void]> {
  return Promise.all([
    // Variable with tokens overrides
    buildAddVariableStyleNode(
      TOKEN_VARS_ID,
      variableOverrides,
      (varName: string) => preset.propertiesMap[toCamelCase(varName)],
    ),

    // Expose variables without tokens
    buildAddVariableStyleNode(RAW_VARS_ID, variableOverrides, (varName: string) => `--${varName}`),
  ]);
}

export default initVariables;
