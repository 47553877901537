import * as React from "react";
import { Container, Header } from "../../index";
import { CustomLinkProps, LinkList } from "../CustomLinks/CustomLinks";
import "./CustomContainer.scss";

export type CustomListContainerProps = {
  itemList?: CustomLinkProps[];
  headerLabel?: string;
};

function CustomListContainer({ customProps }: { customProps: CustomListContainerProps }) {
  const { itemList, headerLabel } = customProps;
  return (
    <div className="o4aws-react-list-container">
      <Container header={<Header variant="h2">{headerLabel}</Header>}>
        <LinkList items={itemList} />
      </Container>
    </div>
  );
}

export default CustomListContainer;
