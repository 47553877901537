import React from "react";
import { RenderMetricsProps, withRenderMetrics } from "../../CourierService/RenderMetrics";
import * as Messages from "../../codegen/Messages";
import "./AwsFooter.scss";
import { TermsOfUseLinks } from "../../constants";

type FooterProps = {
  isTrusteAvailable: boolean;
};

const AwsFooter = withRenderMetrics(
  ({ renderMetrics, isTrusteAvailable }: FooterProps & RenderMetricsProps) => {
    const year = new Date().getFullYear();

    React.useEffect(() => {
      renderMetrics && renderMetrics.rendered("AwsFooter");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <footer className="awsFooter" data-testid="aws-footer">
        <div>{`Copyright © ${year}, Oracle and/or its affiliates. All rights reserved.`}</div>
        <a
          href={TermsOfUseLinks.privacyLink} //TODO: Update AWS specific URL
          target="_blank"
          rel="noreferrer"
        >
          {Messages.awsFooter.privacy()}
        </a>

        <a
          href={TermsOfUseLinks.termsOfUseLink} //TODO: Update AWS specific URL
          target="_blank"
          rel="noreferrer"
        >
          {Messages.awsFooter.terms()}
        </a>

        {isTrusteAvailable ? (
          // eslint-disable-next-line
          <a
            data-testid="cookiesPref"
            id="teconsent"
            className="cookie-prefs truste_caIcon_display"
            aria-label="Open Cookie Preferences Modal"
            role="complementary"
          />
        ) : (
          <span className="aws-link-item">{Messages.footer.cookiePreferenceUnavailable()}</span>
        )}
      </footer>
    );
  },
);

export default AwsFooter;
