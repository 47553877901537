/**
 * Helper function for determining if an error has JSON content
 */
export const hasJsonContent = (res: Response): boolean => {
  const contentType = res.headers?.get("Content-Type");
  return !!(contentType && contentType.includes("application/json"));
};

export const defaultGetRequestSupplier = (endpoint: string) =>
  new Request(endpoint, {
    method: "GET",
    headers: { "content-type": "application/json" },
  });

export interface FetchApiSignature {
  (input: string | Request, init?: RequestInit): Promise<Response>;
}
