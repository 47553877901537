/* eslint-disable no-unused-vars */

import { TelemetryHelperConfig } from "./TelemetryHelperConfig";

/* eslint-disable no-shadow */
export const API_CALL_METRIC_NAME = "MulticloudApiCall";
export const API_LATENCY_METRIC_NAME = "MulticloudApiCallLatency";
export const RENDER_COMPONENT_METRIC_NAME = "MulticloudRenderComponent";
export const RENDER_COMPONENT_LATENCY_MS_METRIC_NAME = "MulticloudRenderComponentLatency";
export const EMPTY_DIMENSION_VALUE = "-";

export enum StatusFamily {
  _1XX = "1XX",
  _2XX = "2XX",
  _3XX = "3XX",
  _4XX = "4XX",
  _5XX = "5XX",
}

export enum Status {
  Success = "success",
  Failure = "failure",
  Timeout = "timeout",
  Unknown = "unknown",
}

export const determineStatus = (
  statusCode: number,
): { status: Status; statusFamily: StatusFamily } => {
  if (statusCode >= 500) {
    return {
      status: Status.Failure,
      statusFamily: StatusFamily._5XX,
    };
  }
  if (statusCode >= 400) {
    return {
      status: Status.Failure,
      statusFamily: StatusFamily._4XX,
    };
  }
  if (statusCode >= 300) {
    return {
      status: Status.Failure,
      statusFamily: StatusFamily._3XX,
    };
  }
  if (statusCode >= 200) {
    return {
      status: Status.Success,
      statusFamily: StatusFamily._2XX,
    };
  }
  if (statusCode >= 100) {
    return {
      status: Status.Failure,
      statusFamily: StatusFamily._1XX,
    };
  }
  return {
    status: Status.Unknown,
    statusFamily: null,
  };
};

// <app>:<csp>:<env>:<eventName>
export const buildAnalyticsName = (eventName: string, telemetryConfig: TelemetryHelperConfig) =>
  `${telemetryConfig?.app || EMPTY_DIMENSION_VALUE}:${
    telemetryConfig?.csp || EMPTY_DIMENSION_VALUE
  }:${telemetryConfig?.env || EMPTY_DIMENSION_VALUE}:${eventName}`;

export enum EventType {
  PageView = 0,
  Click = 1,
}
