import { useEffect } from "react";
import { useFormDispatch, useFormState } from "../../components/Context/ApplicationContext";
import { Dispatch } from "../../Type";
import CourierTransport, { Severity } from "../../CourierService/CourierServiceTransport";
import { Metrics } from "../../CourierService/metrics";
import { getWindow } from "../../utils/BrowserHelpers";
import BrowserStorage from "../../utils/BrowserStorageService";
import { CampaignNames, Experiments as ExperimentsRegistry } from "./experimentConstants";
import { Campaign, CampaignAction, CustomAttributes, Experiment } from "./experimentTypes";
import {
  actionMetricUrl,
  getGenerationUrl,
  MAXY_STORAGE_KEY,
  MAXY_STORAGE_LOCATION,
  UNIFIED_EXPERIMENT_SITE_ID,
} from "./maxymiserConstants";

/**
 * Custom hook to initialize experiment in the application context
 */
export function useInitializeExperiments(): void {
  const dispatcher = useFormDispatch();

  useEffect(() => {
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function init(): Promise<void> {
    dispatcher({ type: Dispatch.EXPERIMENTS_SET_LOADING, payload: true });
    dispatcher({
      type: Dispatch.EXPERIMENTS_SET_CAMPAIGNS,
      payload: await getGenerations(ExperimentsRegistry),
    });
  }
}

/**
 * Returns if an experiment is enabled using the provided name and optionally evaluating the
 * predicate passing the experience value from the provided name if found.
 *
 * ```typescript
 * const testExperimentEnabled = useExperimentEnabled({
 *   experimentName: "Shell-AlwaysFreePage",
 *   predicate: ({ element1 }) => element1 === "Default",
 * });
 * ```
 *
 * @returns {boolean} Whether the experiment is enabled or not
 */
export function useExperimentEnabled<TExperience extends { [key: string]: string }>({
  experimentName,
  predicate = (_exp: TExperience) => true,
}: {
  experimentName: CampaignNames;
  predicate?(exp: TExperience): boolean;
}): boolean {
  const { experiments } = useFormState();

  if (!experiments || experiments.loading || !experiments.campaigns?.length) {
    return false;
  }

  return !!experiments.campaigns.find(
    (experiment: Campaign) =>
      experiment.campaignName === experimentName && predicate(experiment.experience as TExperience),
  );
}

/**
 * Send Action metric used in report, user can send multiple actions also
 */
export async function sendActionsMetric(actions: CampaignAction[]): Promise<void> {
  const visitorState = BrowserStorage.get(MAXY_STORAGE_LOCATION, MAXY_STORAGE_KEY);

  try {
    const response = await (
      await getWindow().fetch(actionMetricUrl(UNIFIED_EXPERIMENT_SITE_ID), {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          visitorState,
          actions,
        }),
      })
    ).json();

    CourierTransport.processLog(
      Severity.INFO,
      `Sending action metrics was successfully.\n${JSON.stringify(response, null, 2)}`,
    );
    CourierTransport.processMetric(Metrics.ExperimentsSendActionSuccess, 1);
  } catch (error) {
    CourierTransport.processLog(
      Severity.ERROR,
      `Failed send action metric to Maxymiser.\n${JSON.stringify(error, null, 2)}`,
    );
    CourierTransport.processMetric(Metrics.ExperimentsSendActionSuccess, 1);
  }
}

/**
 * Inspired by https://bitbucket.oci.oraclecorp.com/projects/UX/repos/oci-console/browse/apps/shell/src/models/experiments
 *
 * For campaign delivery api we don't need auth key so we can use it directly.
 * FYI : https://docs.oracle.com/en/cloud/saas/marketing/maxymiser-rest-api-campaign-delivery/op-sites-site-id-generations-post.html
 * The Generations endpoint returns an experience generated for a given visitor and campaign,
 * a Boolean indicating whether the visitor qualified and the visitorState to be passed in the subsequent request.
 *
 * ### Example To manually test campaigns without maxymiser access, values use the snippet before the creation of the campaign const
 * ```typescript
 * // Modify experiment
 * response.generations = response.generations.map((c: Campaign) =>
 *   c.campaignName !== "Shell-AlwaysFreePage"
 *     ? c
 *     : { ...c, experience: { alwaysFreePg: "default" } },
 * );
 * // Delete experiment
 * response.generations = response.generations.filter(
 *   (c: Campaign) => c.campaignName !== "Shell-AlwaysFreePage",
 * );
 * // Add experiment
 * response.generations = response.generations.push({
 *   campaignName: "Shell-GuidedJourney",
 *   status: "success",
 *   experience: { element1: "Default" },
 *   visitorQualified: false,
 * });
 * ```
 */
export async function getGenerations(experiments: Experiment[]): Promise<Campaign[]> {
  if (!experiments.length) {
    return [];
  }

  // If we want to check generation in non live campaign then we need to pass ip address (Google whats my ip).
  // For keeping consistent behavior for user we can store visitor state returned by generation in Index DB.
  const generationReqPayload = {
    // customAttributes used for targeting and segmentation in reporting.
    customAttributes: getCustomAttributes(),
    visitorState: BrowserStorage.get(MAXY_STORAGE_LOCATION, MAXY_STORAGE_KEY) ?? "",
    campaigns: ExperimentsRegistry.map(e => ({ name: e.name })),
  };

  try {
    const response = await (
      await getWindow().fetch(getGenerationUrl(UNIFIED_EXPERIMENT_SITE_ID), {
        method: "POST",
        body: JSON.stringify(generationReqPayload),
      })
    ).json();
    BrowserStorage.set(MAXY_STORAGE_LOCATION, MAXY_STORAGE_KEY, response.visitorState);
    /**
     * The custom attributes passed can be used on the Maxymiser UI side to determine if a visitor
     * is qualified to view an experiment. We only want to select experiments where the user is
     * qualified.
     */
    const campaigns = response.generations.filter((experiment: Campaign) => {
      return experiment.visitorQualified === true;
    });

    CourierTransport.processLog(
      Severity.INFO,
      `Sending action metrics was successfully. No of qualified campaigns ${campaigns.length}`,
    );
    CourierTransport.processMetric(Metrics.ExperimentsGenSuccess, 1);

    return campaigns;
  } catch (error) {
    BrowserStorage.remove(MAXY_STORAGE_LOCATION, MAXY_STORAGE_KEY);
    CourierTransport.processLog(
      Severity.ERROR,
      `Failed fetch generations from Maxymiser.\n${JSON.stringify(error, null, 2)}`,
    );
    CourierTransport.processMetric(Metrics.ExperimentsGenFail, 1);
    return [];
  }
}

/**
 * Add custom attribute for targeting visitors and reporting.
 * @returns custom attributes
 */
function getCustomAttributes(): CustomAttributes {
  return {
    url: getWindow().location.href.split("?")[0],
  };
}
