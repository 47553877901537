export type CssVariables = {
  [selector: string]: {
    [rawCssVarName: string]: string;
  };
};

export const variableOverrides: CssVariables = {
  ":root": {
    "color-transparent": "transparent",
    "color-charts-red-300": "#ea7158",
    "color-charts-red-400": "#dc5032",
    "color-charts-red-500": "#d13313",
    "color-charts-red-600": "#ba2e0f",
    "color-charts-red-700": "#a82a0c",
    "color-charts-red-800": "#972709",
    "color-charts-red-900": "#892407",
    "color-charts-red-1000": "#7d2105",
    "color-charts-red-1100": "#721e03",
    "color-charts-red-1200": "#671c00",
    "color-charts-orange-300": "#e07941",
    "color-charts-orange-400": "#cc5f21",
    "color-charts-orange-500": "#bc4d01",
    "color-charts-orange-600": "#a84401",
    "color-charts-orange-700": "#983c02",
    "color-charts-orange-800": "#8a3603",
    "color-charts-orange-900": "#7e3103",
    "color-charts-orange-1000": "#732c02",
    "color-charts-orange-1100": "#692801",
    "color-charts-orange-1200": "#602400",
    "color-charts-yellow-300": "#b2911c",
    "color-charts-yellow-400": "#9c7b0b",
    "color-charts-yellow-500": "#8a6b05",
    "color-charts-yellow-600": "#7b5f04",
    "color-charts-yellow-700": "#6f5504",
    "color-charts-yellow-800": "#654d03",
    "color-charts-yellow-900": "#5d4503",
    "color-charts-yellow-1000": "#553f03",
    "color-charts-yellow-1100": "#4d3901",
    "color-charts-yellow-1200": "#483300",
    "color-charts-green-300": "#67a353",
    "color-charts-green-400": "#41902c",
    "color-charts-green-500": "#1f8104",
    "color-charts-green-600": "#1a7302",
    "color-charts-green-700": "#176702",
    "color-charts-green-800": "#145d02",
    "color-charts-green-900": "#125502",
    "color-charts-green-1000": "#104d01",
    "color-charts-green-1100": "#0f4601",
    "color-charts-green-1200": "#0d4000",
    "color-charts-teal-300": "#2ea597",
    "color-charts-teal-400": "#1c8e81",
    "color-charts-teal-500": "#0d7d70",
    "color-charts-teal-600": "#096f64",
    "color-charts-teal-700": "#06645a",
    "color-charts-teal-800": "#045b52",
    "color-charts-teal-900": "#03524a",
    "color-charts-teal-1000": "#014b44",
    "color-charts-teal-1100": "#01443e",
    "color-charts-teal-1200": "#003e38",
    "color-charts-blue-1-300": "#529ccb",
    "color-charts-blue-1-400": "#3184c2",
    "color-charts-blue-1-500": "#0273bb",
    "color-charts-blue-1-600": "#0166ab",
    "color-charts-blue-1-700": "#015b9d",
    "color-charts-blue-1-800": "#015292",
    "color-charts-blue-1-900": "#014a87",
    "color-charts-blue-1-1000": "#01437d",
    "color-charts-blue-1-1100": "#003c75",
    "color-charts-blue-1-1200": "#00366d",
    "color-charts-blue-2-300": "#7492e7",
    "color-charts-blue-2-400": "#5978e3",
    "color-charts-blue-2-500": "#4066df",
    "color-charts-blue-2-600": "#3759ce",
    "color-charts-blue-2-700": "#314fbf",
    "color-charts-blue-2-800": "#2c46b1",
    "color-charts-blue-2-900": "#273ea5",
    "color-charts-blue-2-1000": "#23379b",
    "color-charts-blue-2-1100": "#1f3191",
    "color-charts-blue-2-1200": "#1b2b88",
    "color-charts-purple-300": "#a783e1",
    "color-charts-purple-400": "#9469d6",
    "color-charts-purple-500": "#8456ce",
    "color-charts-purple-600": "#7749bf",
    "color-charts-purple-700": "#6b40b2",
    "color-charts-purple-800": "#6237a7",
    "color-charts-purple-900": "#59309d",
    "color-charts-purple-1000": "#512994",
    "color-charts-purple-1100": "#4a238b",
    "color-charts-purple-1200": "#431d84",
    "color-charts-pink-300": "#da7596",
    "color-charts-pink-400": "#ce567c",
    "color-charts-pink-500": "#c33d69",
    "color-charts-pink-600": "#b1325c",
    "color-charts-pink-700": "#a32952",
    "color-charts-pink-800": "#962249",
    "color-charts-pink-900": "#8b1b42",
    "color-charts-pink-1000": "#81143b",
    "color-charts-pink-1100": "#780d35",
    "color-charts-pink-1200": "#6f062f",
    "color-charts-status-critical": "#7d2105",
    "color-charts-status-high": "#ba2e0f",
    "color-charts-status-medium": "#cc5f21",
    "color-charts-status-low": "#b2911c",
    "color-charts-status-positive": "#67a353",
    "color-charts-status-info": "#3184c2",
    "color-charts-status-neutral": "#879596",
    "color-charts-threshold-negative": "#d13212",
    "color-charts-threshold-positive": "#1d8102",
    "color-charts-threshold-info": "#0073bb",
    "color-charts-threshold-neutral": "#687078",
    "color-charts-line-grid": "#d5dbdb",
    "color-charts-line-tick": "#d5dbdb",
    "color-charts-line-axis": "#d5dbdb",
    "color-charts-palette-categorical-1": "#7492e7",
    "color-charts-palette-categorical-2": "#c33d69",
    "color-charts-palette-categorical-3": "#2ea597",
    "color-charts-palette-categorical-4": "#8456ce",
    "color-charts-palette-categorical-5": "#e07941",
    "color-charts-palette-categorical-6": "#3759ce",
    "color-charts-palette-categorical-7": "#962249",
    "color-charts-palette-categorical-8": "#096f64",
    "color-charts-palette-categorical-9": "#6237a7",
    "color-charts-palette-categorical-10": "#a84401",
    "color-charts-palette-categorical-11": "#273ea5",
    "color-charts-palette-categorical-12": "#780d35",
    "color-charts-palette-categorical-13": "#03524a",
    "color-charts-palette-categorical-14": "#4a238b",
    "color-charts-palette-categorical-15": "#7e3103",
    "color-charts-palette-categorical-16": "#1b2b88",
    "color-charts-palette-categorical-17": "#ce567c",
    "color-charts-palette-categorical-18": "#003e38",
    "color-charts-palette-categorical-19": "#9469d6",
    "color-charts-palette-categorical-20": "#602400",
    "color-charts-palette-categorical-21": "#4066df",
    "color-charts-palette-categorical-22": "#a32952",
    "color-charts-palette-categorical-23": "#0d7d70",
    "color-charts-palette-categorical-24": "#6b40b2",
    "color-charts-palette-categorical-25": "#bc4d01",
    "color-charts-palette-categorical-26": "#2c46b1",
    "color-charts-palette-categorical-27": "#81143b",
    "color-charts-palette-categorical-28": "#045b52",
    "color-charts-palette-categorical-29": "#512994",
    "color-charts-palette-categorical-30": "#8a3603",
    "color-charts-palette-categorical-31": "#1f3191",
    "color-charts-palette-categorical-32": "#da7596",
    "color-charts-palette-categorical-33": "#01443e",
    "color-charts-palette-categorical-34": "#a783e1",
    "color-charts-palette-categorical-35": "#692801",
    "color-charts-palette-categorical-36": "#5978e3",
    "color-charts-palette-categorical-37": "#b1325c",
    "color-charts-palette-categorical-38": "#1c8e81",
    "color-charts-palette-categorical-39": "#7749bf",
    "color-charts-palette-categorical-40": "#cc5f21",
    "color-charts-palette-categorical-41": "#314fbf",
    "color-charts-palette-categorical-42": "#8b1b42",
    "color-charts-palette-categorical-43": "#06645a",
    "color-charts-palette-categorical-44": "#59309d",
    "color-charts-palette-categorical-45": "#983c02",
    "color-charts-palette-categorical-46": "#23379b",
    "color-charts-palette-categorical-47": "#6f062f",
    "color-charts-palette-categorical-48": "#014b44",
    "color-charts-palette-categorical-49": "#431d84",
    "color-charts-palette-categorical-50": "#732c02",
    "color-background-button-link-active": "#eaeded",
    "color-background-button-link-hover": "#fafafa",
    "color-background-button-normal-active": "#eaeded",
    "color-background-button-normal-default": "#fff",
    "color-background-button-normal-disabled": "#fff",
    "color-background-button-normal-hover": "#fafafa",
    "color-background-button-primary-active": "#dd6b10",
    "color-background-button-primary-default": "#ec7211",
    "color-background-button-primary-disabled": "#fff",
    "color-background-button-primary-hover": "#eb5f07",
    "color-background-calendar-today": "#f2f3f3",
    "color-background-code-editor-gutter-active-line-default": "#687078",
    "color-background-code-editor-gutter-active-line-error": "#d13212",
    "color-background-code-editor-gutter-default": "#f2f3f3",
    "color-background-code-editor-loading": "#f2f3f3",
    "color-background-code-editor-pane-item-hover": "#eaeded",
    "color-background-code-editor-status-bar": "#f2f3f3",
    "color-background-container-content": "#fff",
    "color-background-container-header": "#fafafa",
    "color-background-control-checked": "#0073bb",
    "color-background-control-default": "#fff",
    "color-background-control-disabled": "#d5dbdb",
    "color-background-dropdown-item-default": "#fff",
    "color-background-dropdown-item-dimmed": "transparent",
    "color-background-dropdown-item-filter-match": "#f1faff",
    "color-background-dropdown-item-hover": "#f2f3f3",
    "color-background-dropdown-item-selected": "#f1faff",
    "color-background-home-header": "#232f3e",
    "color-background-input-default": "#fff",
    "color-background-input-disabled": "#eaeded",
    "color-background-item-selected": "#f1faff",
    "color-background-layout-main": "#f2f3f3",
    "color-background-layout-mobile-panel": "#fff",
    "color-background-layout-panel-content": "#fff",
    "color-background-layout-panel-hover": "#eaeded",
    "color-background-layout-toggle-active": "#2a2e33",
    "color-background-layout-toggle-default": "#2a2e33",
    "color-background-layout-toggle-hover": "#545b64",
    "color-background-layout-toggle-selected-active": "#0073bb",
    "color-background-layout-toggle-selected-default": "#0073bb",
    "color-background-layout-toggle-selected-hover": "#0a4a74",
    "color-background-modal-overlay": "rgba(242, 243, 243, 0.9)",
    "color-background-notification-blue": "#0073bb",
    "color-background-notification-green": "#1d8102",
    "color-background-notification-grey": "#545b64",
    "color-background-notification-red": "#d13212",
    "color-background-popover": "#fff",
    "color-background-progress-bar-content-default": "#0073bb",
    "color-background-progress-bar-content-in-flash": "hsla(0, 0%, 100%, 0.7)",
    "color-background-progress-bar-layout-default": "#eaeded",
    "color-background-progress-bar-layout-in-flash": "hsla(0, 0%, 100%, 0.25)",
    "color-background-segment-active": "#eaeded",
    "color-background-segment-default": "#fff",
    "color-background-segment-disabled": "#fff",
    "color-background-segment-hover": "#fafafa",
    "color-background-status-error": "#fdf3f1",
    "color-background-status-info": "#f1faff",
    "color-background-status-success": "#f2f8f0",
    "color-background-status-warning": "#fff",
    "color-background-table-header": "#fafafa",
    "color-background-tiles-disabled": "#eaeded",
    "color-background-toggle-checked-disabled": "#99cbe4",
    "color-background-toggle-default": "#545b64",

    "color-border-button-normal-active": "#545b64",
    "color-border-button-normal-default": "#545b64",
    "color-border-button-normal-disabled": "#d5dbdb",
    "color-border-button-normal-hover": "#16191f",
    "color-border-button-primary-disabled": "#d5dbdb",
    "color-border-calendar-grid": "#eaeded",
    "color-border-calendar-grid-selected-focus-ring": "#00a1c9",
    "color-border-code-editor-ace-active-line-light-theme": "#d5dbdb",
    "color-border-code-editor-ace-active-line-dark-theme": "#687078",
    "color-border-code-editor-default": "#d5dbdb",
    "color-border-code-editor-pane-item-hover": "#687078",
    "color-border-container-divider": "#eaeded",
    "color-border-container-top": "#eaeded",
    "color-border-control-checked": "#0073bb",
    "color-border-control-default": "#aab7b8",
    "color-border-control-disabled": "#d5dbdb",
    "color-border-divider-active": "#879596",
    "color-border-divider-default": "#eaeded",
    "color-border-divider-panel-bottom": "rgba(0, 28, 36, 0.15)",
    "color-border-divider-panel-side": "transparent",
    "color-border-dropdown-container": "transparent",
    "color-border-dropdown-group": "#eaeded",
    "color-border-dropdown-item-default": "#eaeded",
    "color-border-dropdown-item-hover": "#879596",
    "color-border-dropdown-item-dimmed-hover": "#879596",
    "color-border-dropdown-item-selected": "#00a1c9",
    "color-border-dropdown-item-top": "#eaeded",
    "color-border-input-default": "#aab7b8",
    "color-border-input-disabled": "#eaeded",
    "color-border-item-focused": "#00a1c9",
    "color-border-item-placeholder": "transparent",
    "color-border-item-selected": "#00a1c9",
    "color-border-layout": "#d5dbdb",
    "color-border-popover": "#d5dbdb",
    "color-border-segment-active": "#16191f",
    "color-border-segment-default": "#687078",
    "color-border-segment-disabled": "#d5dbdb",
    "color-border-segment-hover": "#16191f",
    "color-border-status-error": "#d13212",
    "color-border-status-info": "#0073bb",
    "color-border-status-success": "#1d8102",
    "color-border-status-warning": "#aab7b8",
    "color-border-tabs-divider": "#aab7b8",
    "color-border-tabs-shadow": "#eaeded",
    "color-border-tabs-underline": "#16191f",
    "color-border-tiles-disabled": "transparent",
    "color-border-tutorial": "#aab7b8",
    "color-foreground-control-default": "#fff",
    "color-foreground-control-disabled": "#fff",
    "color-shadow-default": "rgba(0, 28, 36, 0.5)",
    "color-text-accent": "#ec7211",
    "color-text-body-default": "#16191f",
    "color-text-body-secondary": "#545b64",
    "color-text-breadcrumb-current": "#687078",
    "color-text-breadcrumb-icon": "#aab7b8",
    "color-text-button-inline-icon-default": "#545b64",
    "color-text-button-inline-icon-disabled": "#aab7b8",
    "color-text-button-inline-icon-hover": "#16191f",
    "color-text-button-normal-active": "#16191f",
    "color-text-button-normal-default": "#545b64",
    "color-text-button-normal-hover": "#16191f",
    "color-text-button-primary-active": "#fff",
    "color-text-button-primary-default": "#fff",
    "color-text-button-primary-hover": "#fff",
    "color-text-calendar-day-hover": "#16191f",
    "color-text-calendar-day-selected": "#16191f",
    "color-text-calendar-month": "#545b64",
    "color-text-code-editor-gutter-active-line": "#fff",
    "color-text-code-editor-gutter-default": "#16191f",
    "color-text-code-editor-status-bar-disabled": "#879596",
    "color-text-code-editor-tab-button-error": "#fff",
    "color-text-column-header": "#545b64",
    "color-text-column-sorting-icon": "#879596",
    "color-text-control-disabled": "#aab7b8",
    "color-text-counter": "#687078",
    "color-text-disabled": "#aab7b8",
    "color-text-dropdown-footer": "#687078",
    "color-text-dropdown-group-label": "#545b64",
    "color-text-dropdown-header": "#16191f",
    "color-text-dropdown-item-default": "#16191f",
    "color-text-dropdown-item-dimmed": "#aab7b8",
    "color-text-dropdown-item-disabled": "#aab7b8",
    "color-text-dropdown-item-filter-match": "#0073bb",
    "color-text-dropdown-item-highlighted": "#16191f",
    "color-text-dropdown-item-secondary": "#687078",
    "color-text-empty": "#687078",
    "color-text-expandable-section-default": "#545b64",
    "color-text-expandable-section-hover": "#16191f",
    "color-text-expandable-section-navigation-icon-default": "#879596",
    "color-text-form-default": "#16191f",
    "color-text-form-label": "#16191f",
    "color-text-form-secondary": "#687078",
    "color-text-group-label": "#545b64",
    "color-text-heading-default": "#16191f",
    "color-text-heading-secondary": "#545b64",
    "color-text-home-header-default": "#fff",
    "color-text-home-header-secondary": "#d5dbdb",
    "color-text-icon-subtle": "#687078",
    "color-text-input-disabled": "#879596",
    "color-text-input-placeholder": "#aab7b8",
    "color-text-input-placeholder-disabled": "#aab7b8",
    "color-text-interactive-active": "#16191f",
    "color-text-interactive-default": "#545b64",
    "color-text-interactive-disabled": "#aab7b8",
    "color-text-interactive-hover": "#16191f",
    "color-text-interactive-inverted-default": "#d5dbdb",
    "color-text-interactive-inverted-hover": "#fafafa",
    "color-text-inverted": "#fff",
    "color-text-label": "#545b64",
    "color-text-layout-toggle": "#fff",
    "color-text-layout-toggle-selected": "#fff",
    "color-text-link-default": "#0073bb",
    "color-text-link-hover": "#0073bb",
    "color-text-link-inverted-hover": "#fafafa",
    "color-text-link-button-underline": "currentColor",
    "color-text-link-button-underline-hover": "currentColor",
    "color-text-link-primary-underline": "transparent",
    "color-text-notification-default": "#fafafa",
    "color-text-pagination-page-number-active-disabled": "#545b64",
    "color-text-pagination-page-number-default": "#545b64",
    "color-text-segment-active": "#16191f",
    "color-text-segment-default": "#545b64",
    "color-text-segment-hover": "#16191f",
    "color-text-small": "#687078",
    "color-text-status-error": "#d13212",
    "color-text-status-inactive": "#687078",
    "color-text-status-info": "#0073bb",
    "color-text-status-success": "#1d8102",
    "color-text-status-warning": "#d13212",
    "color-text-top-navigation-title": "#16191f",
    "font-body-m-line-height": "22px",
    "font-body-m-size": "14px",
    "font-body-s-letter-spacing": "normal",
    "font-body-s-line-height": "16px",
    "font-body-s-size": "12px",
    "font-button-letter-spacing": "0.25px",
    "font-button-weight": "700",
    "font-chart-detail-size": "14px",
    "font-display-l-letter-spacing": "normal",
    "font-display-l-line-height": "56px",
    "font-display-l-size": "44px",
    "font-display-label-weight": "400",
    "font-expandable-heading-size": "14px",
    "font-family-base": '"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif',
    "font-family-monospace":
      'Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace',
    "font-header-h2-description-line-height": "16px",
    "font-header-h2-description-size": "12px",
    "font-heading-l-letter-spacing": "normal",
    "font-heading-l-line-height": "22px",
    "font-heading-l-size": "18px",
    "font-heading-l-weight": "700",
    "font-heading-m-letter-spacing": "normal",
    "font-heading-m-line-height": "22px",
    "font-heading-m-size": "18px",
    "font-heading-m-weight": "400",
    "font-heading-s-letter-spacing": "normal",
    "font-heading-s-line-height": "20px",
    "font-heading-s-size": "16px",
    "font-heading-s-weight": "700",
    "font-heading-xl-letter-spacing": "normal",
    "font-heading-xl-line-height": "36px",
    "font-heading-xl-size": "28px",
    "font-heading-xl-weight": "400",
    "font-heading-xs-line-height": "20px",
    "font-heading-xs-size": "16px",
    "font-heading-xs-weight": "400",
    "font-box-value-large-weight": "300",
    "font-link-button-letter-spacing": "normal",
    "font-link-button-weight": "400",
    "font-link-primary-decoration": "none",
    "font-link-primary-letter-spacing": "0.005em",
    "font-link-primary-weight": "700",
    "font-panel-header-line-height": "22px",
    "font-panel-header-size": "18px",
    "font-tabs-disabled-weight": "400",
    "font-tabs-line-height": "22px",
    "font-tabs-size": "14px",
    "font-wayfinding-link-active-weight": "700",
    "font-weight-heavy": "700",
    "border-alert-radius": "2px",
    "border-active-radius": "0px",
    "border-active-width": "2px",
    "border-badge-radius": "16px",
    "border-button-radius": "2px",
    "border-calendar-grid-focus-ring-radius": "2px",
    "border-calendar-grid-radius": "0px",
    "border-code-editor-status-divider-width": "0px",
    "border-container-radius": "0px",
    "border-container-sticky-width": "1px",
    "border-container-top-width": "1px",
    "border-control-focus-ring-radius-circle": "50%",
    "border-control-focus-ring-radius-default": "2px",
    "border-control-focus-ring-shadow-spread": "1px",
    "border-control-invalid-focus-ring-shadow-spread": "1px",
    "border-divider-list-width": "1px",
    "border-divider-section-width": "1px",
    "border-dropdown-radius": "0px",
    "border-dropdown-virtual-offset-width": "0px",
    "border-field-radius": "2px",
    "border-field-width": "1px",
    "border-flashbar-radius": "0px",
    "border-invalid-width": "4px",
    "border-item-radius": "0px",
    "border-item-width": "1px",
    "border-line-chart-dash-array": "3 5",
    "border-line-chart-line-join": "miter",
    "border-line-chart-width": "2px",
    "border-panel-header-width": "0px",
    "border-panel-top-width": "0px",
    "border-table-sticky-width": "0px",
    "border-link-focus-ring-outline": "5px auto Highlight",
    "border-link-focus-ring-shadow-spread": "0px",
    "motion-duration-extra-slow": "270ms",
    "motion-duration-fast": "90ms",
    "motion-duration-moderate": "135ms",
    "motion-duration-refresh-only-fast": "115ms",
    "motion-duration-refresh-only-medium": "165ms",
    "motion-duration-rotate-180": "135ms",
    "motion-duration-rotate-90": "135ms",
    "motion-duration-show-paced": "180ms",
    "motion-duration-show-quick": "135ms",
    "motion-duration-slow": "180ms",
    "motion-duration-transition-quick": "90ms",
    "motion-duration-transition-show-paced": "180ms",
    "motion-easing-ease-out-quart": "cubic-bezier(0.165, 0.84, 0.44, 1)",
    "motion-easing-refresh-only-a": "cubic-bezier(0, 0, 0, 1)",
    "motion-easing-refresh-only-b": "cubic-bezier(1, 0, 0.83, 1)",
    "motion-easing-refresh-only-c": "cubic-bezier(0.84, 0, 0.16, 1)",
    "motion-easing-rotate-180": "cubic-bezier(0.165, 0.84, 0.44, 1)",
    "motion-easing-rotate-90": "cubic-bezier(0.165, 0.84, 0.44, 1)",
    "motion-easing-show-paced": "ease-out",
    "motion-easing-show-quick": "ease-out",
    "motion-easing-transition-quick": "linear",
    "motion-easing-transition-show-paced": "ease-out",
    "size-calendar-grid-width": "234px",
    "size-control": "14px",
    "size-icon-big": "32px",
    "size-icon-large": "48px",
    "size-icon-medium": "16px",
    "size-icon-normal": "16px",
    "size-vertical-input": "32px",
    "size-vertical-panel-icon-offset": "15px",
    "space-alert-action-left": "20px",
    "space-alert-horizontal": "20px",
    "space-alert-message-right": "0px",
    "space-alert-vertical": "12px",
    "space-button-focus-outline-gutter": "3px",
    "space-button-icon-focus-outline-gutter-vertical": "3px",
    "space-button-icon-only-horizontal": "16px",
    "space-button-inline-icon-focus-outline-gutter": "3px",
    "space-button-modal-dismiss-vertical": "4px",
    "space-calendar-grid-focus-outline-gutter": "0px",
    "space-calendar-grid-selected-focus-outline-gutter": "2px",
    "space-card-horizontal": "20px",
    "space-code-editor-status-focus-outline-gutter": "3px",
    "space-container-content-top": "16px",
    "space-container-header-vertical": "12px",
    "space-container-horizontal": "20px",
    "space-dark-header-padding-bottom": "24px",
    "space-dark-header-overlap-distance": "0px",
    "space-expandable-section-icon-offset-top": "8px",
    "space-field-horizontal": "8px",
    "space-field-icon-offset": "32px",
    "space-filtering-token-dismiss-button-focus-outline-gutter": "0px",
    "space-filtering-token-operation-select-focus-outline-gutter": "0px",
    "space-flashbar-action-left": "16px",
    "space-flashbar-dismiss-right": "4px",
    "space-flashbar-horizontal": "12px",
    "space-grid-gutter": "20px",
    "space-layout-content-bottom": "20px",
    "space-layout-toggle-diameter": "40px",
    "space-layout-toggle-padding": "20px",
    "space-modal-content-bottom": "20px",
    "space-modal-horizontal": "20px",
    "space-panel-nav-left": "32px",
    "space-panel-side-left": "32px",
    "space-panel-side-right": "32px",
    "space-panel-split-top": "0px",
    "space-segmented-control-focus-outline-gutter": "0px",
    "space-table-header-focus-outline-gutter": "0px",
    "space-tabs-content-top": "0px",
    "space-table-horizontal": "0px",
    "space-table-header-horizontal": "20px",
    "space-table-content-bottom": "0px",
    "space-table-content-top": "0px",
    "space-table-embedded-content-bottom": "0px",
    "space-table-embedded-header-top": "12px",
    "space-table-footer-horizontal": "20px",
    "space-tabs-focus-outline-gutter": "0px",
    "space-text-grid-vertical": "20px",
    "space-l": "20px",
    "space-m": "16px",
    "space-none": "0",
    "space-s": "12px",
    "space-scaled-2x-l": "20px",
    "space-scaled-2x-s": "12px",
    "space-scaled-2x-xl": "24px",
    "space-scaled-2x-xxs": "4px",
    "space-scaled-2x-xxxl": "40px",
    "space-scaled-l": "20px",
    "space-scaled-m": "16px",
    "space-scaled-none": "0",
    "space-scaled-s": "12px",
    "space-scaled-xl": "24px",
    "space-scaled-xs": "8px",
    "space-scaled-xxl": "32px",
    "space-scaled-xxs": "4px",
    "space-scaled-xxxl": "40px",
    "space-scaled-xxxs": "2px",
    "space-xl": "24px",
    "space-xs": "8px",
    "space-xxl": "32px",
    "space-xxs": "4px",
    "space-xxxl": "40px",
    "space-xxxs": "2px",
    "shadow-container":
      "0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15)",
    "shadow-container-stacked":
      "0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15)",
    "shadow-dropdown":
      "0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15)",
    "shadow-dropup":
      "0 -1px 1px 0 rgba(0, 28, 36, 0.3), 1px -1px 1px 0 rgba(0, 28, 36, 0.15), -1px -1px 1px 0 rgba(0, 28, 36, 0.15)",
    "shadow-flash-sticky":
      "0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15)",
    "shadow-modal":
      "0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15)",
    "shadow-panel":
      "0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15)",
    "shadow-panel-toggle":
      "0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15)",
    "shadow-popover": "0px 1px 4px -2px rgba(0, 28, 36, 0.5)",
    "shadow-split-bottom":
      "0 -2px 1px -1px rgba(0, 28, 36, 0.15), 0 -1px 1px -1px rgba(0, 28, 36, 0.3)",
    "shadow-split-side":
      "0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15)",
    "shadow-sticky": "0px 1px 4px -2px rgba(0, 28, 36, 0.5)",
  },
  ".awsui-dark-mode:not(#\\9)": {
    "color-charts-red-300": "#d63f38",
    "color-charts-red-400": "#ed5958",
    "color-charts-red-500": "#fe6e73",
    "color-charts-red-600": "#ff8a8a",
    "color-charts-red-700": "#ffa09e",
    "color-charts-red-800": "#ffb3b0",
    "color-charts-red-900": "#ffc4c0",
    "color-charts-red-1000": "#ffd2cf",
    "color-charts-red-1100": "#ffe0dd",
    "color-charts-red-1200": "#ffecea",
    "color-charts-orange-300": "#c55305",
    "color-charts-orange-400": "#de6923",
    "color-charts-orange-500": "#f27c36",
    "color-charts-orange-600": "#f89256",
    "color-charts-orange-700": "#fca572",
    "color-charts-orange-800": "#ffb68b",
    "color-charts-orange-900": "#ffc6a4",
    "color-charts-orange-1000": "#ffd4bb",
    "color-charts-orange-1100": "#ffe1cf",
    "color-charts-orange-1200": "#ffede2",
    "color-charts-yellow-300": "#977001",
    "color-charts-yellow-400": "#b08400",
    "color-charts-yellow-500": "#c59600",
    "color-charts-yellow-600": "#d3a61c",
    "color-charts-yellow-700": "#dfb52c",
    "color-charts-yellow-800": "#eac33a",
    "color-charts-yellow-900": "#f1cf65",
    "color-charts-yellow-1000": "#f7db8a",
    "color-charts-yellow-1100": "#fce5a8",
    "color-charts-yellow-1200": "#ffefc9",
    "color-charts-green-300": "#48851a",
    "color-charts-green-400": "#5a9b29",
    "color-charts-green-500": "#69ae34",
    "color-charts-green-600": "#7dbd4c",
    "color-charts-green-700": "#8fca61",
    "color-charts-green-800": "#9fd673",
    "color-charts-green-900": "#b2df8d",
    "color-charts-green-1000": "#c5e7a8",
    "color-charts-green-1100": "#d5efbe",
    "color-charts-green-1200": "#e4f7d5",
    "color-charts-teal-300": "#018977",
    "color-charts-teal-400": "#009d89",
    "color-charts-teal-500": "#00b09b",
    "color-charts-teal-600": "#40bfa9",
    "color-charts-teal-700": "#5fccb7",
    "color-charts-teal-800": "#77d7c3",
    "color-charts-teal-900": "#94e0d0",
    "color-charts-teal-1000": "#ace9db",
    "color-charts-teal-1100": "#c2f0e6",
    "color-charts-teal-1200": "#d7f7f0",
    "color-charts-blue-1-300": "#00819c",
    "color-charts-blue-1-400": "#0497ba",
    "color-charts-blue-1-500": "#08aad2",
    "color-charts-blue-1-600": "#44b9dd",
    "color-charts-blue-1-700": "#63c6e7",
    "color-charts-blue-1-800": "#79d2f0",
    "color-charts-blue-1-900": "#98dcf5",
    "color-charts-blue-1-1000": "#b3e4f8",
    "color-charts-blue-1-1100": "#caedfc",
    "color-charts-blue-1-1200": "#ddf4ff",
    "color-charts-blue-2-300": "#486de8",
    "color-charts-blue-2-400": "#6384f5",
    "color-charts-blue-2-500": "#7698fe",
    "color-charts-blue-2-600": "#8ea9ff",
    "color-charts-blue-2-700": "#a2b8ff",
    "color-charts-blue-2-800": "#b1c5ff",
    "color-charts-blue-2-900": "#c3d1ff",
    "color-charts-blue-2-1000": "#d2dcff",
    "color-charts-blue-2-1100": "#dfe6ff",
    "color-charts-blue-2-1200": "#ecf0ff",
    "color-charts-purple-300": "#8d59de",
    "color-charts-purple-400": "#a173ea",
    "color-charts-purple-500": "#b088f5",
    "color-charts-purple-600": "#bf9bf9",
    "color-charts-purple-700": "#cbabfc",
    "color-charts-purple-800": "#d6baff",
    "color-charts-purple-900": "#dfc8ff",
    "color-charts-purple-1000": "#e8d5ff",
    "color-charts-purple-1100": "#efe2ff",
    "color-charts-purple-1200": "#f5edff",
    "color-charts-pink-300": "#c64a70",
    "color-charts-pink-400": "#d56889",
    "color-charts-pink-500": "#e07f9d",
    "color-charts-pink-600": "#eb92ad",
    "color-charts-pink-700": "#f5a2bb",
    "color-charts-pink-800": "#ffb0c8",
    "color-charts-pink-900": "#ffc1d4",
    "color-charts-pink-1000": "#ffd1de",
    "color-charts-pink-1100": "#ffdfe8",
    "color-charts-pink-1200": "#ffecf1",
    "color-charts-status-critical": "#d63f38",
    "color-charts-status-high": "#fe6e73",
    "color-charts-status-medium": "#f89256",
    "color-charts-status-low": "#dfb52c",
    "color-charts-status-positive": "#69ae34",
    "color-charts-status-info": "#08aad2",
    "color-charts-threshold-negative": "#ff5d64",
    "color-charts-threshold-positive": "#6aaf35",
    "color-charts-threshold-info": "#44b9d6",
    "color-charts-threshold-neutral": "#95a5a6",
    "color-charts-line-grid": "#414750",
    "color-charts-line-tick": "#414750",
    "color-charts-line-axis": "#414750",
    "color-charts-palette-categorical-1": "#486de8",
    "color-charts-palette-categorical-2": "#e07f9d",
    "color-charts-palette-categorical-3": "#018977",
    "color-charts-palette-categorical-4": "#b088f5",
    "color-charts-palette-categorical-5": "#c55305",
    "color-charts-palette-categorical-6": "#8ea9ff",
    "color-charts-palette-categorical-7": "#ffb0c8",
    "color-charts-palette-categorical-8": "#40bfa9",
    "color-charts-palette-categorical-9": "#d6baff",
    "color-charts-palette-categorical-10": "#f89256",
    "color-charts-palette-categorical-11": "#c3d1ff",
    "color-charts-palette-categorical-12": "#ffdfe8",
    "color-charts-palette-categorical-13": "#94e0d0",
    "color-charts-palette-categorical-14": "#efe2ff",
    "color-charts-palette-categorical-15": "#ffc6a4",
    "color-charts-palette-categorical-16": "#ecf0ff",
    "color-charts-palette-categorical-17": "#d56889",
    "color-charts-palette-categorical-18": "#d7f7f0",
    "color-charts-palette-categorical-19": "#a173ea",
    "color-charts-palette-categorical-20": "#ffede2",
    "color-charts-palette-categorical-21": "#7698fe",
    "color-charts-palette-categorical-22": "#f5a2bb",
    "color-charts-palette-categorical-23": "#00b09b",
    "color-charts-palette-categorical-24": "#cbabfc",
    "color-charts-palette-categorical-25": "#f27c36",
    "color-charts-palette-categorical-26": "#b1c5ff",
    "color-charts-palette-categorical-27": "#ffd1de",
    "color-charts-palette-categorical-28": "#77d7c3",
    "color-charts-palette-categorical-29": "#e8d5ff",
    "color-charts-palette-categorical-30": "#ffb68b",
    "color-charts-palette-categorical-31": "#dfe6ff",
    "color-charts-palette-categorical-32": "#c64a70",
    "color-charts-palette-categorical-33": "#c2f0e6",
    "color-charts-palette-categorical-34": "#8d59de",
    "color-charts-palette-categorical-35": "#ffe1cf",
    "color-charts-palette-categorical-36": "#6384f5",
    "color-charts-palette-categorical-37": "#eb92ad",
    "color-charts-palette-categorical-38": "#009d89",
    "color-charts-palette-categorical-39": "#bf9bf9",
    "color-charts-palette-categorical-40": "#de6923",
    "color-charts-palette-categorical-41": "#a2b8ff",
    "color-charts-palette-categorical-42": "#ffc1d4",
    "color-charts-palette-categorical-43": "#5fccb7",
    "color-charts-palette-categorical-44": "#dfc8ff",
    "color-charts-palette-categorical-45": "#fca572",
    "color-charts-palette-categorical-46": "#d2dcff",
    "color-charts-palette-categorical-47": "#ffecf1",
    "color-charts-palette-categorical-48": "#ace9db",
    "color-charts-palette-categorical-49": "#f5edff",
    "color-charts-palette-categorical-50": "#ffd4bb",
    "color-background-button-link-active": "#16191f",
    "color-background-button-link-hover": "#21252c",
    "color-background-button-normal-active": "#16191f",
    "color-background-button-normal-default": "#2a2e33",
    "color-background-button-normal-disabled": "#2a2e33",
    "color-background-button-normal-hover": "#21252c",
    "color-background-button-primary-active": "#dd6b10",
    "color-background-button-primary-default": "#ec7211",
    "color-background-button-primary-disabled": "#fff",
    "color-background-button-primary-hover": "#eb5f07",
    "color-background-calendar-today": "#16191f",
    "color-background-code-editor-gutter-active-line-default": "#879596",
    "color-background-code-editor-gutter-active-line-error": "#ff5d64",
    "color-background-code-editor-gutter-default": "#21252c",
    "color-background-code-editor-loading": "#21252c",
    "color-background-code-editor-pane-item-hover": "#414750",
    "color-background-code-editor-status-bar": "#21252c",
    "color-background-container-content": "#2a2e33",
    "color-background-container-header": "#21252c",
    "color-background-control-checked": "#00a1c9",
    "color-background-control-default": "#1a2029",
    "color-background-control-disabled": "#414750",
    "color-background-dropdown-item-default": "#2a2e33",
    "color-background-dropdown-item-filter-match": "#12293b",
    "color-background-dropdown-item-hover": "#414750",
    "color-background-dropdown-item-selected": "#12293b",
    "color-background-home-header": "#000",
    "color-background-input-default": "#1a2029",
    "color-background-input-disabled": "#414750",
    "color-background-item-selected": "#12293b",
    "color-background-layout-main": "#16191f",
    "color-background-layout-mobile-panel": "#2a2e33",
    "color-background-layout-panel-content": "#2a2e33",
    "color-background-layout-panel-hover": "#414750",
    "color-background-layout-toggle-selected-active": "#00a1c9",
    "color-background-layout-toggle-selected-default": "#00a1c9",
    "color-background-layout-toggle-selected-hover": "#44b9d6",
    "color-background-modal-overlay": "rgba(22, 25, 31, 0.8)",
    "color-background-notification-grey": "#687078",
    "color-background-popover": "#21252c",
    "color-background-progress-bar-content-default": "#00a1c9",
    "color-background-progress-bar-content-in-flash": "#fafafa",
    "color-background-progress-bar-layout-default": "#414750",
    "color-background-segment-active": "#16191f",
    "color-background-segment-default": "#2a2e33",
    "color-background-segment-disabled": "#2a2e33",
    "color-background-segment-hover": "#21252c",
    "color-background-status-error": "#270a11",
    "color-background-status-info": "#12293b",
    "color-background-status-success": "#172211",
    "color-background-status-warning": "#2a2e33",
    "color-background-table-header": "#21252c",
    "color-background-tiles-disabled": "#2a2e33",
    "color-background-toggle-checked-disabled": "#0a4a74",
    "color-background-toggle-default": "#879596",
    "color-border-button-normal-active": "#879596",
    "color-border-button-normal-default": "#879596",
    "color-border-button-normal-disabled": "#414750",
    "color-border-button-normal-hover": "#aab7b8",
    "color-border-button-primary-disabled": "#414750",
    "color-border-calendar-grid": "#414750",
    "color-border-code-editor-default": "#687078",
    "color-border-code-editor-pane-item-hover": "#879596",
    "color-border-container-divider": "#414750",
    "color-border-container-top": "#2a2e33",
    "color-border-control-checked": "#00a1c9",
    "color-border-control-default": "#879596",
    "color-border-control-disabled": "#414750",
    "color-border-divider-default": "#414750",
    "color-border-divider-panel-bottom": "rgba(0, 0, 0, 0.3)",
    "color-border-dropdown-group": "#414750",
    "color-border-dropdown-item-default": "#414750",
    "color-border-dropdown-item-top": "#414750",
    "color-border-input-default": "#879596",
    "color-border-input-disabled": "#414750",
    "color-border-layout": "#414750",
    "color-border-popover": "#545b64",
    "color-border-segment-active": "#fff",
    "color-border-segment-default": "#879596",
    "color-border-segment-disabled": "#414750",
    "color-border-segment-hover": "#fff",
    "color-border-status-info": "#00a1c9",
    "color-border-status-warning": "#879596",
    "color-border-tabs-divider": "#414750",
    "color-border-tabs-shadow": "rgba(0, 0, 0, 0.3)",
    "color-border-tabs-underline": "#fafafa",
    "color-border-tiles-disabled": "#414750",
    "color-border-tutorial": "#879596",
    "color-foreground-control-disabled": "#687078",
    "color-shadow-default": "rgba(0, 0, 0, 0.5)",
    "color-text-accent": "#ec7211",
    "color-text-body-default": "#d5dbdb",
    "color-text-body-secondary": "#d5dbdb",
    "color-text-breadcrumb-current": "#d5dbdb",
    "color-text-breadcrumb-icon": "#687078",
    "color-text-button-inline-icon-default": "#d5dbdb",
    "color-text-button-inline-icon-disabled": "#687078",
    "color-text-button-inline-icon-hover": "#fafafa",
    "color-text-button-normal-active": "#fafafa",
    "color-text-button-normal-default": "#d5dbdb",
    "color-text-button-normal-hover": "#fafafa",
    "color-text-button-primary-active": "#16191f",
    "color-text-button-primary-default": "#16191f",
    "color-text-button-primary-hover": "#16191f",
    "color-text-calendar-day-hover": "#eaeded",
    "color-text-calendar-day-selected": "#eaeded",
    "color-text-calendar-month": "#d5dbdb",
    "color-text-code-editor-gutter-active-line": "#16191f",
    "color-text-code-editor-gutter-default": "#d5dbdb",
    "color-text-code-editor-status-bar-disabled": "#687078",
    "color-text-code-editor-tab-button-error": "#16191f",
    "color-text-column-header": "#95a5a6",
    "color-text-column-sorting-icon": "#95a5a6",
    "color-text-control-disabled": "#687078",
    "color-text-counter": "#95a5a6",
    "color-text-disabled": "#687078",
    "color-text-dropdown-footer": "#95a5a6",
    "color-text-dropdown-group-label": "#95a5a6",
    "color-text-dropdown-header": "#fafafa",
    "color-text-dropdown-item-default": "#d5dbdb",
    "color-text-dropdown-item-dimmed": "#687078",
    "color-text-dropdown-item-disabled": "#687078",
    "color-text-dropdown-item-filter-match": "#44b9d6",
    "color-text-dropdown-item-highlighted": "#eaeded",
    "color-text-dropdown-item-secondary": "#95a5a6",
    "color-text-empty": "#d5dbdb",
    "color-text-expandable-section-default": "#d5dbdb",
    "color-text-expandable-section-hover": "#fafafa",
    "color-text-expandable-section-navigation-icon-default": "#95a5a6",
    "color-text-form-default": "#d5dbdb",
    "color-text-form-label": "#d5dbdb",
    "color-text-form-secondary": "#95a5a6",
    "color-text-group-label": "#95a5a6",
    "color-text-heading-default": "#eaeded",
    "color-text-heading-secondary": "#d5dbdb",
    "color-text-home-header-default": "#eaeded",
    "color-text-icon-subtle": "#aab7b8",
    "color-text-input-disabled": "#687078",
    "color-text-input-placeholder": "#687078",
    "color-text-input-placeholder-disabled": "#687078",
    "color-text-interactive-active": "#fafafa",
    "color-text-interactive-default": "#d5dbdb",
    "color-text-interactive-disabled": "#687078",
    "color-text-interactive-hover": "#fafafa",
    "color-text-inverted": "#16191f",
    "color-text-label": "#95a5a6",
    "color-text-layout-toggle-selected": "#16191f",
    "color-text-link-default": "#44b9d6",
    "color-text-link-hover": "#44b9d6",
    "color-text-pagination-page-number-active-disabled": "#d5dbdb",
    "color-text-pagination-page-number-default": "#d5dbdb",
    "color-text-segment-active": "#fafafa",
    "color-text-segment-default": "#d5dbdb",
    "color-text-segment-hover": "#fafafa",
    "color-text-small": "#95a5a6",
    "color-text-status-error": "#ff5d64",
    "color-text-status-inactive": "#95a5a6",
    "color-text-status-info": "#44b9d6",
    "color-text-status-success": "#6aaf35",
    "color-text-status-warning": "#ff5d64",
    "color-text-top-navigation-title": "#fafafa",
    "shadow-container":
      "0 1px 1px 0 rgba(0, 0, 0, 0.3), 1px 1px 1px 0 rgba(0, 0, 0, 0.3), -1px 1px 1px 0 rgba(0, 0, 0, 0.3)",
    "shadow-container-stacked":
      "0 1px 1px 0 rgba(0, 0, 0, 0.3), 1px 1px 1px 0 rgba(0, 0, 0, 0.3), -1px 1px 1px 0 rgba(0, 0, 0, 0.3)",
    "shadow-dropdown":
      "0 1px 1px 0 rgba(0, 0, 0, 0.3), 1px 1px 1px 0 rgba(0, 0, 0, 0.3), -1px 1px 1px 0 rgba(0, 0, 0, 0.3)",
    "shadow-dropup":
      "0 -1px 1px 0 rgba(0, 0, 0, 0.3), 1px -1px 1px 0 rgba(0, 0, 0, 0.3), -1px -1px 1px 0 rgba(0, 0, 0, 0.3)",
    "shadow-flash-sticky":
      "0 1px 1px 0 rgba(0, 0, 0, 0.3), 1px 1px 1px 0 rgba(0, 0, 0, 0.3), -1px 1px 1px 0 rgba(0, 0, 0, 0.3)",
    "shadow-modal":
      "0 1px 1px 0 rgba(0, 0, 0, 0.3), 1px 1px 1px 0 rgba(0, 0, 0, 0.3), -1px 1px 1px 0 rgba(0, 0, 0, 0.3)",
    "shadow-panel":
      "0 1px 1px 0 rgba(0, 0, 0, 0.3), 1px 1px 1px 0 rgba(0, 0, 0, 0.3), -1px 1px 1px 0 rgba(0, 0, 0, 0.3)",
    "shadow-panel-toggle":
      "0 1px 1px 0 rgba(0, 0, 0, 0.3), 1px 1px 1px 0 rgba(0, 0, 0, 0.3), -1px 1px 1px 0 rgba(0, 0, 0, 0.3)",
    "shadow-popover": "0px 1px 4px -2px rgba(0, 0, 0, 0.5)",
    "shadow-split-bottom": "0 -2px 1px -1px rgba(0, 0, 0, 0.3), 0 -1px 1px -1px rgba(0, 0, 0, 0.3)",
    "shadow-split-side":
      "0 1px 1px 0 rgba(0, 0, 0, 0.3), 1px 1px 1px 0 rgba(0, 0, 0, 0.3), -1px 1px 1px 0 rgba(0, 0, 0, 0.3)",
    "shadow-sticky": "0px 1px 4px -2px rgba(0, 0, 0, 0.5)",
  },
  ".awsui-compact-mode:not(#\\9)": {
    "size-vertical-input": "28px",
    "size-vertical-panel-icon-offset": "13px",
    "space-alert-vertical": "8px",
    "space-button-modal-dismiss-vertical": "2px",
    "space-container-content-top": "12px",
    "space-container-header-vertical": "8px",
    "space-dark-header-padding-bottom": "16px",
    "space-expandable-section-icon-offset-top": "4px",
    "space-grid-gutter": "16px",
    "space-layout-content-bottom": "16px",
    "space-layout-toggle-padding": "16px",
    "space-modal-content-bottom": "16px",
    "space-panel-side-left": "24px",
    "space-panel-side-right": "24px",
    "space-table-embedded-header-top": "8px",
    "space-text-grid-vertical": "16px",
    "space-scaled-2x-l": "12px",
    "space-scaled-2x-s": "4px",
    "space-scaled-2x-xl": "16px",
    "space-scaled-2x-xxs": "0",
    "space-scaled-2x-xxxl": "24px",
    "space-scaled-l": "16px",
    "space-scaled-m": "12px",
    "space-scaled-s": "8px",
    "space-scaled-xl": "20px",
    "space-scaled-xs": "4px",
    "space-scaled-xxl": "24px",
    "space-scaled-xxs": "2px",
    "space-scaled-xxxl": "32px",
    "space-scaled-xxxs": "0",
  },
  ".awsui-motion-disabled:not(#\\9)": {
    "motion-duration-extra-slow": "0ms",
    "motion-duration-fast": "0ms",
    "motion-duration-moderate": "0ms",
    "motion-duration-refresh-only-fast": "0ms",
    "motion-duration-refresh-only-medium": "0ms",
    "motion-duration-rotate-180": "0ms",
    "motion-duration-rotate-90": "0ms",
    "motion-duration-show-paced": "0ms",
    "motion-duration-show-quick": "0ms",
    "motion-duration-slow": "0ms",
    "motion-duration-transition-quick": "0ms",
    "motion-duration-transition-show-paced": "0ms",
  },
  ".awsui-context-top-navigation:not(#\\9)": {
    "color-background-button-link-active": "#16191f",
    "color-background-button-link-hover": "#21252c",
    "color-background-button-normal-active": "#16191f",
    "color-background-button-normal-default": "#2a2e33",
    "color-background-button-normal-disabled": "#2a2e33",
    "color-background-button-normal-hover": "#21252c",
    "color-background-button-primary-active": "#ec7211",
    "color-background-button-primary-disabled": "#2a2e33",
    "color-background-calendar-today": "#16191f",
    "color-background-code-editor-gutter-active-line-default": "#879596",
    "color-background-code-editor-gutter-active-line-error": "#ff5d64",
    "color-background-code-editor-gutter-default": "#21252c",
    "color-background-code-editor-loading": "#21252c",
    "color-background-code-editor-pane-item-hover": "#414750",
    "color-background-code-editor-status-bar": "#21252c",
    "color-background-container-content": "#232f3e",
    "color-background-container-header": "#21252c",
    "color-background-control-checked": "#00a1c9",
    "color-background-control-default": "#1a2029",
    "color-background-control-disabled": "#414750",
    "color-background-dropdown-item-default": "#232f3e",
    "color-background-dropdown-item-filter-match": "#12293b",
    "color-background-dropdown-item-hover": "#414750",
    "color-background-dropdown-item-selected": "#12293b",
    "color-background-home-header": "#000",
    "color-background-input-default": "#1a2029",
    "color-background-input-disabled": "#414750",
    "color-background-item-selected": "#12293b",
    "color-background-layout-main": "#16191f",
    "color-background-layout-mobile-panel": "#232f3e",
    "color-background-layout-panel-content": "#232f3e",
    "color-background-layout-panel-hover": "#414750",
    "color-background-layout-toggle-selected-active": "#00a1c9",
    "color-background-layout-toggle-selected-default": "#00a1c9",
    "color-background-layout-toggle-selected-hover": "#44b9d6",
    "color-background-modal-overlay": "rgba(22, 25, 31, 0.8)",
    "color-background-notification-grey": "#687078",
    "color-background-popover": "#21252c",
    "color-background-progress-bar-content-default": "#00a1c9",
    "color-background-progress-bar-content-in-flash": "#fafafa",
    "color-background-progress-bar-layout-default": "#414750",
    "color-background-segment-active": "#16191f",
    "color-background-segment-default": "#2a2e33",
    "color-background-segment-disabled": "#2a2e33",
    "color-background-segment-hover": "#21252c",
    "color-background-status-error": "#270a11",
    "color-background-status-info": "#12293b",
    "color-background-status-success": "#172211",
    "color-background-status-warning": "#2a2e33",
    "color-background-table-header": "#21252c",
    "color-background-tiles-disabled": "#2a2e33",
    "color-background-toggle-checked-disabled": "#0a4a74",
    "color-background-toggle-default": "#879596",
    "color-border-button-normal-active": "#879596",
    "color-border-button-normal-default": "#879596",
    "color-border-button-normal-disabled": "#414750",
    "color-border-button-normal-hover": "#aab7b8",
    "color-border-button-primary-disabled": "#414750",
    "color-border-calendar-grid": "#414750",
    "color-border-code-editor-default": "#687078",
    "color-border-code-editor-pane-item-hover": "#879596",
    "color-border-container-divider": "#414750",
    "color-border-container-top": "#2a2e33",
    "color-border-control-checked": "#00a1c9",
    "color-border-control-default": "#879596",
    "color-border-control-disabled": "#414750",
    "color-border-divider-default": "#414750",
    "color-border-divider-panel-bottom": "rgba(0, 0, 0, 0.3)",
    "color-border-dropdown-group": "#414750",
    "color-border-dropdown-item-default": "#414750",
    "color-border-dropdown-item-top": "#414750",
    "color-border-input-default": "#879596",
    "color-border-input-disabled": "#414750",
    "color-border-layout": "#414750",
    "color-border-popover": "#545b64",
    "color-border-segment-active": "#fff",
    "color-border-segment-default": "#879596",
    "color-border-segment-disabled": "#414750",
    "color-border-segment-hover": "#fff",
    "color-border-status-info": "#00a1c9",
    "color-border-status-warning": "#879596",
    "color-border-tabs-divider": "#414750",
    "color-border-tabs-shadow": "rgba(0, 0, 0, 0.3)",
    "color-border-tabs-underline": "#fafafa",
    "color-border-tiles-disabled": "#414750",
    "color-border-tutorial": "#879596",
    "color-foreground-control-disabled": "#687078",
    "color-shadow-default": "rgba(0, 0, 0, 0.5)",
    "color-text-body-default": "#d5dbdb",
    "color-text-body-secondary": "#d5dbdb",
    "color-text-breadcrumb-current": "#d5dbdb",
    "color-text-breadcrumb-icon": "#687078",
    "color-text-button-inline-icon-default": "#d5dbdb",
    "color-text-button-inline-icon-disabled": "#687078",
    "color-text-button-inline-icon-hover": "#fafafa",
    "color-text-button-normal-active": "#fafafa",
    "color-text-button-normal-default": "#d5dbdb",
    "color-text-button-normal-hover": "#fafafa",
    "color-text-button-primary-active": "#16191f",
    "color-text-button-primary-default": "#16191f",
    "color-text-button-primary-hover": "#16191f",
    "color-text-calendar-day-hover": "#eaeded",
    "color-text-calendar-day-selected": "#eaeded",
    "color-text-calendar-month": "#d5dbdb",
    "color-text-code-editor-gutter-active-line": "#16191f",
    "color-text-code-editor-gutter-default": "#d5dbdb",
    "color-text-code-editor-status-bar-disabled": "#687078",
    "color-text-code-editor-tab-button-error": "#16191f",
    "color-text-column-header": "#95a5a6",
    "color-text-column-sorting-icon": "#95a5a6",
    "color-text-control-disabled": "#687078",
    "color-text-counter": "#95a5a6",
    "color-text-disabled": "#687078",
    "color-text-dropdown-footer": "#95a5a6",
    "color-text-dropdown-group-label": "#95a5a6",
    "color-text-dropdown-header": "#fafafa",
    "color-text-dropdown-item-default": "#d5dbdb",
    "color-text-dropdown-item-dimmed": "#687078",
    "color-text-dropdown-item-disabled": "#687078",
    "color-text-dropdown-item-filter-match": "#44b9d6",
    "color-text-dropdown-item-highlighted": "#eaeded",
    "color-text-dropdown-item-secondary": "#95a5a6",
    "color-text-empty": "#d5dbdb",
    "color-text-expandable-section-default": "#d5dbdb",
    "color-text-expandable-section-hover": "#fafafa",
    "color-text-expandable-section-navigation-icon-default": "#95a5a6",
    "color-text-form-default": "#d5dbdb",
    "color-text-form-label": "#d5dbdb",
    "color-text-form-secondary": "#95a5a6",
    "color-text-group-label": "#95a5a6",
    "color-text-heading-default": "#eaeded",
    "color-text-heading-secondary": "#d5dbdb",
    "color-text-home-header-default": "#eaeded",
    "color-text-icon-subtle": "#aab7b8",
    "color-text-input-disabled": "#687078",
    "color-text-input-placeholder": "#687078",
    "color-text-input-placeholder-disabled": "#687078",
    "color-text-interactive-active": "#fafafa",
    "color-text-interactive-default": "#d5dbdb",
    "color-text-interactive-disabled": "#687078",
    "color-text-interactive-hover": "#fafafa",
    "color-text-inverted": "#16191f",
    "color-text-label": "#95a5a6",
    "color-text-layout-toggle-selected": "#16191f",
    "color-text-link-default": "#44b9d6",
    "color-text-link-hover": "#44b9d6",
    "color-text-pagination-page-number-active-disabled": "#d5dbdb",
    "color-text-pagination-page-number-default": "#d5dbdb",
    "color-text-segment-active": "#fafafa",
    "color-text-segment-default": "#d5dbdb",
    "color-text-segment-hover": "#fafafa",
    "color-text-small": "#95a5a6",
    "color-text-status-error": "#ff5d64",
    "color-text-status-inactive": "#95a5a6",
    "color-text-status-info": "#44b9d6",
    "color-text-status-success": "#6aaf35",
    "color-text-status-warning": "#ff5d64",
    "color-text-top-navigation-title": "#fafafa",
  },
  ".awsui-visual-refresh:not(#\\9)": {
    "color-charts-status-neutral": "#7d8998",
    "color-charts-threshold-negative": "#d91515",
    "color-charts-threshold-positive": "#037f0c",
    "color-charts-threshold-info": "#0972d3",
    "color-charts-threshold-neutral": "#5f6b7a",
    "color-charts-line-grid": "#d1d5db",
    "color-charts-line-tick": "#d1d5db",
    "color-charts-line-axis": "#d1d5db",
    "color-background-button-link-active": "#d3e7f9",
    "color-background-button-link-hover": "#f2f8fd",
    "color-background-button-normal-active": "#d3e7f9",
    "color-background-button-normal-hover": "#f2f8fd",
    "color-background-button-primary-disabled": "#e9ebed",
    "color-background-calendar-today": "#f4f4f4",
    "color-background-code-editor-gutter-active-line-default": "#5f6b7a",
    "color-background-code-editor-gutter-active-line-error": "#d91515",
    "color-background-code-editor-gutter-default": "#f4f4f4",
    "color-background-code-editor-loading": "#fbfbfb",
    "color-background-code-editor-pane-item-hover": "#e9ebed",
    "color-background-code-editor-status-bar": "#f4f4f4",
    "color-background-container-header": "#fff",
    "color-background-control-checked": "#0972d3",
    "color-background-control-disabled": "#d1d5db",
    "color-background-dropdown-item-filter-match": "#f2f8fd",
    "color-background-dropdown-item-hover": "#f4f4f4",
    "color-background-dropdown-item-selected": "#f2f8fd",
    "color-background-home-header": "#000716",
    "color-background-input-disabled": "#e9ebed",
    "color-background-item-selected": "#f2f8fd",
    "color-background-layout-main": "#fff",
    "color-background-layout-mobile-panel": "#000716",
    "color-background-layout-panel-hover": "#e9ebed",
    "color-background-layout-toggle-active": "#232f3e",
    "color-background-layout-toggle-default": "#232f3e",
    "color-background-layout-toggle-hover": "#414d5c",
    "color-background-layout-toggle-selected-active": "#0972d3",
    "color-background-layout-toggle-selected-default": "#0972d3",
    "color-background-layout-toggle-selected-hover": "#065299",
    "color-background-modal-overlay": "rgba(35, 47, 62, 0.7)",
    "color-background-notification-blue": "#0972d3",
    "color-background-notification-green": "#037f0c",
    "color-background-notification-grey": "#414d5c",
    "color-background-notification-red": "#d91515",
    "color-background-progress-bar-content-default": "#0972d3",
    "color-background-progress-bar-content-in-flash": "#fff",
    "color-background-progress-bar-layout-default": "#e9ebed",
    "color-background-segment-active": "#0972d3",
    "color-background-segment-hover": "#fff",
    "color-background-status-error": "#fff7f7",
    "color-background-status-info": "#f2f8fd",
    "color-background-status-success": "#f2fcf3",
    "color-background-table-header": "#fff",
    "color-background-tiles-disabled": "#e9ebed",
    "color-background-toggle-checked-disabled": "#b5d6f4",
    "color-background-toggle-default": "#414d5c",
    "color-border-button-normal-active": "#033160",
    "color-border-button-normal-default": "#0972d3",
    "color-border-button-normal-disabled": "#9ba7b6",
    "color-border-button-normal-hover": "#033160",
    "color-border-button-primary-disabled": "#e9ebed",
    "color-border-calendar-grid": "transparent",
    "color-border-calendar-grid-selected-focus-ring": "#fbfbfb",
    "color-border-code-editor-ace-active-line-light-theme": "#d1d5db",
    "color-border-code-editor-ace-active-line-dark-theme": "#5f6b7a",
    "color-border-code-editor-default": "#d1d5db",
    "color-border-code-editor-pane-item-hover": "#7d8998",
    "color-border-container-divider": "transparent",
    "color-border-container-top": "transparent",
    "color-border-control-checked": "#0972d3",
    "color-border-control-default": "#7d8998",
    "color-border-control-disabled": "#d1d5db",
    "color-border-divider-active": "#7d8998",
    "color-border-divider-default": "#e9ebed",
    "color-border-divider-panel-bottom": "#e9ebed",
    "color-border-divider-panel-side": "#e9ebed",
    "color-border-dropdown-container": "#9ba7b6",
    "color-border-dropdown-group": "#e9ebed",
    "color-border-dropdown-item-default": "#e9ebed",
    "color-border-dropdown-item-hover": "#7d8998",
    "color-border-dropdown-item-dimmed-hover": "#7d8998",
    "color-border-dropdown-item-selected": "#0972d3",
    "color-border-dropdown-item-top": "transparent",
    "color-border-input-default": "#9ba7b6",
    "color-border-input-disabled": "#e9ebed",
    "color-border-item-focused": "#0972d3",
    "color-border-item-placeholder": "#0972d3",
    "color-border-item-selected": "#0972d3",
    "color-border-layout": "#d1d5db",
    "color-border-popover": "#9ba7b6",
    "color-border-segment-active": "#414d5c",
    "color-border-segment-default": "#414d5c",
    "color-border-segment-disabled": "#414d5c",
    "color-border-segment-hover": "#414d5c",
    "color-border-status-error": "#d91515",
    "color-border-status-info": "#0972d3",
    "color-border-status-success": "#037f0c",
    "color-border-status-warning": "#7d8998",
    "color-border-tabs-divider": "#e9ebed",
    "color-border-tabs-shadow": "rgba(0, 7, 22, 0.12)",
    "color-border-tabs-underline": "#0972d3",
    "color-border-tiles-disabled": "#e9ebed",
    "color-border-tutorial": "#d1d5db",
    "color-shadow-default": "rgba(0, 7, 22, 0.12)",
    "color-text-accent": "#0972d3",
    "color-text-body-default": "#000716",
    "color-text-body-secondary": "#414d5c",
    "color-text-breadcrumb-current": "#5f6b7a",
    "color-text-breadcrumb-icon": "#7d8998",
    "color-text-button-inline-icon-default": "#0972d3",
    "color-text-button-inline-icon-disabled": "#9ba7b6",
    "color-text-button-inline-icon-hover": "#033160",
    "color-text-button-normal-active": "#033160",
    "color-text-button-normal-default": "#0972d3",
    "color-text-button-normal-hover": "#033160",
    "color-text-calendar-day-hover": "#000716",
    "color-text-calendar-day-selected": "#0972d3",
    "color-text-calendar-month": "#5f6b7a",
    "color-text-code-editor-gutter-default": "#000716",
    "color-text-code-editor-status-bar-disabled": "#7d8998",
    "color-text-column-header": "#414d5c",
    "color-text-column-sorting-icon": "#414d5c",
    "color-text-control-disabled": "#9ba7b6",
    "color-text-counter": "#5f6b7a",
    "color-text-disabled": "#9ba7b6",
    "color-text-dropdown-footer": "#5f6b7a",
    "color-text-dropdown-group-label": "#414d5c",
    "color-text-dropdown-header": "#000716",
    "color-text-dropdown-item-default": "#000716",
    "color-text-dropdown-item-dimmed": "#9ba7b6",
    "color-text-dropdown-item-disabled": "#9ba7b6",
    "color-text-dropdown-item-filter-match": "#0972d3",
    "color-text-dropdown-item-highlighted": "#000716",
    "color-text-dropdown-item-secondary": "#5f6b7a",
    "color-text-empty": "#5f6b7a",
    "color-text-expandable-section-default": "#000716",
    "color-text-expandable-section-hover": "#0972d3",
    "color-text-expandable-section-navigation-icon-default": "#414d5c",
    "color-text-form-default": "#000716",
    "color-text-form-label": "#000716",
    "color-text-form-secondary": "#5f6b7a",
    "color-text-group-label": "#414d5c",
    "color-text-heading-default": "#000716",
    "color-text-heading-secondary": "#414d5c",
    "color-text-home-header-secondary": "#d1d5db",
    "color-text-icon-subtle": "#5f6b7a",
    "color-text-input-disabled": "#9ba7b6",
    "color-text-input-placeholder": "#5f6b7a",
    "color-text-input-placeholder-disabled": "#9ba7b6",
    "color-text-interactive-active": "#000716",
    "color-text-interactive-default": "#414d5c",
    "color-text-interactive-disabled": "#9ba7b6",
    "color-text-interactive-hover": "#000716",
    "color-text-interactive-inverted-default": "#d1d5db",
    "color-text-interactive-inverted-hover": "#fbfbfb",
    "color-text-label": "#000716",
    "color-text-link-default": "#0972d3",
    "color-text-link-hover": "#033160",
    "color-text-link-inverted-hover": "#fff",
    "color-text-link-button-underline": "transparent",
    "color-text-link-button-underline-hover": "transparent",
    "color-text-link-primary-underline": "#0972d3",
    "color-text-notification-default": "#fbfbfb",
    "color-text-pagination-page-number-active-disabled": "#9ba7b6",
    "color-text-pagination-page-number-default": "#414d5c",
    "color-text-segment-active": "#fff",
    "color-text-segment-default": "#414d5c",
    "color-text-segment-hover": "#0972d3",
    "color-text-small": "#5f6b7a",
    "color-text-status-error": "#d91515",
    "color-text-status-inactive": "#5f6b7a",
    "color-text-status-info": "#0972d3",
    "color-text-status-success": "#037f0c",
    "color-text-status-warning": "#d91515",
    "color-text-top-navigation-title": "#000716",
    "font-body-s-letter-spacing": "0.005em",
    "font-button-letter-spacing": "0.005em",
    "font-button-weight": "900",
    "font-chart-detail-size": "12px",
    "font-display-l-letter-spacing": "-0.03em",
    "font-display-l-line-height": "54px",
    "font-display-l-size": "48px",
    "font-display-label-weight": "700",
    "font-expandable-heading-size": "16px",
    "font-header-h2-description-line-height": "22px",
    "font-header-h2-description-size": "14px",
    "font-heading-l-letter-spacing": "-0.015em",
    "font-heading-l-line-height": "30px",
    "font-heading-l-size": "24px",
    "font-heading-l-weight": "900",
    "font-heading-m-letter-spacing": "-0.01em",
    "font-heading-m-line-height": "24px",
    "font-heading-m-size": "20px",
    "font-heading-m-weight": "900",
    "font-heading-s-letter-spacing": "-0.005em",
    "font-heading-s-weight": "900",
    "font-heading-xl-letter-spacing": "-0.02em",
    "font-heading-xl-line-height": "40px",
    "font-heading-xl-size": "32px",
    "font-heading-xl-weight": "900",
    "font-heading-xs-line-height": "18px",
    "font-heading-xs-size": "14px",
    "font-heading-xs-weight": "900",
    "font-box-value-large-weight": "900",
    "font-link-button-letter-spacing": "0.005em",
    "font-link-button-weight": "900",
    "font-link-primary-decoration": "underline",
    "font-link-primary-letter-spacing": '"inherit"',
    "font-link-primary-weight": '"inherit"',
    "font-panel-header-line-height": "24px",
    "font-panel-header-size": "20px",
    "font-tabs-disabled-weight": "900",
    "font-tabs-line-height": "20px",
    "font-tabs-size": "16px",
    "font-wayfinding-link-active-weight": "900",
    "font-weight-heavy": "900",
    "border-alert-radius": "12px",
    "border-active-radius": "20px",
    "border-active-width": "4px",
    "border-badge-radius": "4px",
    "border-button-radius": "20px",
    "border-calendar-grid-focus-ring-radius": "3px",
    "border-calendar-grid-radius": "8px",
    "border-code-editor-status-divider-width": "2px",
    "border-container-radius": "16px",
    "border-container-sticky-width": "0px",
    "border-container-top-width": "0px",
    "border-control-focus-ring-radius-circle": "4px",
    "border-control-focus-ring-radius-default": "4px",
    "border-control-focus-ring-shadow-spread": "0px",
    "border-control-invalid-focus-ring-shadow-spread": "2px",
    "border-divider-section-width": "2px",
    "border-dropdown-radius": "8px",
    "border-dropdown-virtual-offset-width": "2px",
    "border-field-radius": "8px",
    "border-field-width": "2px",
    "border-flashbar-radius": "12px",
    "border-invalid-width": "8px",
    "border-item-radius": "8px",
    "border-item-width": "2px",
    "border-line-chart-line-join": "round",
    "border-panel-header-width": "2px",
    "border-panel-top-width": "1px",
    "border-table-sticky-width": "2px",
    "border-link-focus-ring-outline": "0",
    "border-link-focus-ring-shadow-spread": "2px",
    "size-calendar-grid-width": "238px",
    "size-control": "16px",
    "size-icon-medium": "20px",
    "size-vertical-input": "34px",
    "space-alert-action-left": "12px",
    "space-alert-horizontal": "16px",
    "space-alert-message-right": "4px",
    "space-alert-vertical": "8px",
    "space-button-focus-outline-gutter": "4px",
    "space-button-icon-focus-outline-gutter-vertical": "0px",
    "space-button-icon-only-horizontal": "7px",
    "space-button-inline-icon-focus-outline-gutter": "0px",
    "space-button-modal-dismiss-vertical": "0px",
    "space-calendar-grid-focus-outline-gutter": "-5px",
    "space-calendar-grid-selected-focus-outline-gutter": "-5px",
    "space-card-horizontal": "24px",
    "space-code-editor-status-focus-outline-gutter": "-7px",
    "space-container-content-top": "0px",
    "space-container-horizontal": "24px",
    "space-dark-header-overlap-distance": "36px",
    "space-expandable-section-icon-offset-top": "4px",
    "space-field-horizontal": "12px",
    "space-field-icon-offset": "36px",
    "space-filtering-token-dismiss-button-focus-outline-gutter": "-5px",
    "space-filtering-token-operation-select-focus-outline-gutter": "-5px",
    "space-flashbar-action-left": "12px",
    "space-flashbar-dismiss-right": "0px",
    "space-flashbar-horizontal": "16px",
    "space-layout-content-bottom": "40px",
    "space-modal-content-bottom": "16px",
    "space-modal-horizontal": "24px",
    "space-panel-nav-left": "28px",
    "space-panel-side-left": "28px",
    "space-panel-side-right": "24px",
    "space-panel-split-top": "20px",
    "space-segmented-control-focus-outline-gutter": "4px",
    "space-tabs-content-top": "12px",
    "space-table-horizontal": "24px",
    "space-table-header-horizontal": "0px",
    "space-table-content-bottom": "4px",
    "space-table-content-top": "12px",
    "space-table-embedded-header-top": "0px",
    "space-table-footer-horizontal": "0px",
    "space-tabs-focus-outline-gutter": "-8px",
    "space-text-grid-vertical": "16px",
    "shadow-container": "0px 0px 1px 1px #e9ebed, 0px 1px 8px 2px rgba(0, 7, 22, 0.12)",
    "shadow-container-stacked":
      "-1px 1px 1px 0px #e9ebed, 1px 1px 1px 0px #e9ebed, 0px 9px 8px -7px rgba(0, 7, 22, 0.12), 8px 0px 8px -7px rgba(0, 7, 22, 0.12), -8px 0px 8px -7px rgba(0, 7, 22, 0.12)",
    "shadow-dropdown": "0px 4px 20px 1px rgba(0, 7, 22, 0.1)",
    "shadow-dropup": "0px 4px 20px 1px rgba(0, 7, 22, 0.1)",
    "shadow-flash-sticky": "0px 6px 36px rgba(0, 7, 22, 0.1)",
    "shadow-modal": "0px 4px 20px 1px rgba(0, 7, 22, 0.1)",
    "shadow-panel": "0px 1px 1px 1px #e9ebed, 0px 6px 36px rgba(0, 7, 22, 0.1)",
    "shadow-panel-toggle": "0px 6px 12px 1px rgba(0, 7, 22, 0.12)",
    "shadow-popover": "0px 4px 20px 1px rgba(0, 7, 22, 0.1)",
    "shadow-split-bottom": "0px -36px 36px -36px rgba(0, 7, 22, 0.1)",
    "shadow-split-side": "-1px 0px 1px 0px #e9ebed, -36px 6px 36px -36px rgba(0, 7, 22, 0.1)",
    "shadow-sticky": "0px 4px 20px 1px rgba(0, 7, 22, 0.1)",
  },
  ".awsui-dark-mode.awsui-visual-refresh:not(#\\9)": {
    "color-charts-threshold-negative": "#eb6f6f",
    "color-charts-threshold-positive": "#29ad32",
    "color-charts-threshold-info": "#89bdee",
    "color-charts-threshold-neutral": "#8d99a8",
    "color-charts-line-grid": "#414d5c",
    "color-charts-line-tick": "#414d5c",
    "color-charts-line-axis": "#414d5c",
    "color-background-button-link-active": "#232f3e",
    "color-background-button-link-hover": "#192534",
    "color-background-button-normal-active": "#232f3e",
    "color-background-button-normal-default": "#0f1b2a",
    "color-background-button-normal-disabled": "#0f1b2a",
    "color-background-button-normal-hover": "#192534",
    "color-background-button-primary-disabled": "#232f3e",
    "color-background-calendar-today": "#232f3e",
    "color-background-code-editor-gutter-active-line-default": "#7d8998",
    "color-background-code-editor-gutter-active-line-error": "#eb6f6f",
    "color-background-code-editor-gutter-default": "#192534",
    "color-background-code-editor-loading": "#192534",
    "color-background-code-editor-pane-item-hover": "#354150",
    "color-background-code-editor-status-bar": "#192534",
    "color-background-container-content": "#0f1b2a",
    "color-background-container-header": "#0f1b2a",
    "color-background-control-checked": "#539fe5",
    "color-background-control-default": "#0f1b2a",
    "color-background-control-disabled": "#354150",
    "color-background-dropdown-item-default": "#192534",
    "color-background-dropdown-item-filter-match": "#232f3e",
    "color-background-dropdown-item-hover": "#354150",
    "color-background-dropdown-item-selected": "#232f3e",
    "color-background-input-default": "#0f1b2a",
    "color-background-input-disabled": "#192534",
    "color-background-item-selected": "#232f3e",
    "color-background-layout-main": "#0f1b2a",
    "color-background-layout-panel-content": "#0f1b2a",
    "color-background-layout-panel-hover": "#354150",
    "color-background-layout-toggle-selected-active": "#539fe5",
    "color-background-layout-toggle-selected-default": "#539fe5",
    "color-background-layout-toggle-selected-hover": "#89bdee",
    "color-background-modal-overlay": "rgba(0, 7, 22, 0.7)",
    "color-background-notification-grey": "#5f6b7a",
    "color-background-popover": "#192534",
    "color-background-progress-bar-content-default": "#539fe5",
    "color-background-progress-bar-layout-default": "#354150",
    "color-background-segment-active": "#539fe5",
    "color-background-segment-default": "#0f1b2a",
    "color-background-segment-disabled": "#0f1b2a",
    "color-background-segment-hover": "#0f1b2a",
    "color-background-status-error": "#1a0000",
    "color-background-status-info": "#00142b",
    "color-background-status-success": "#001a02",
    "color-background-status-warning": "#0f1b2a",
    "color-background-table-header": "#0f1b2a",
    "color-background-tiles-disabled": "#192534",
    "color-background-toggle-checked-disabled": "#033160",
    "color-background-toggle-default": "#7d8998",
    "color-border-button-normal-active": "#89bdee",
    "color-border-button-normal-default": "#539fe5",
    "color-border-button-normal-disabled": "#5f6b7a",
    "color-border-button-normal-hover": "#89bdee",
    "color-border-button-primary-disabled": "#232f3e",
    "color-border-calendar-grid-selected-focus-ring": "#0f1b2a",
    "color-border-code-editor-default": "#5f6b7a",
    "color-border-code-editor-pane-item-hover": "#5f6b7a",
    "color-border-control-checked": "#539fe5",
    "color-border-control-disabled": "#354150",
    "color-border-divider-default": "#414d5c",
    "color-border-divider-panel-bottom": "#000716",
    "color-border-divider-panel-side": "#414d5c",
    "color-border-dropdown-container": "#5f6b7a",
    "color-border-dropdown-group": "#414d5c",
    "color-border-dropdown-item-default": "#414d5c",
    "color-border-dropdown-item-hover": "#5f6b7a",
    "color-border-dropdown-item-selected": "#539fe5",
    "color-border-input-default": "#5f6b7a",
    "color-border-input-disabled": "#192534",
    "color-border-item-focused": "#539fe5",
    "color-border-item-placeholder": "#539fe5",
    "color-border-item-selected": "#539fe5",
    "color-border-layout": "#354150",
    "color-border-popover": "#5f6b7a",
    "color-border-segment-active": "#d1d5db",
    "color-border-segment-default": "#d1d5db",
    "color-border-segment-disabled": "#d1d5db",
    "color-border-segment-hover": "#d1d5db",
    "color-border-status-error": "#eb6f6f",
    "color-border-status-info": "#539fe5",
    "color-border-status-success": "#29ad32",
    "color-border-status-warning": "#8d99a8",
    "color-border-tabs-divider": "#414d5c",
    "color-border-tabs-shadow": "#000716",
    "color-border-tabs-underline": "#539fe5",
    "color-border-tiles-disabled": "#192534",
    "color-border-tutorial": "#414d5c",
    "color-foreground-control-default": "#000716",
    "color-foreground-control-disabled": "#0f1b2a",
    "color-shadow-default": "#000716",
    "color-text-accent": "#539fe5",
    "color-text-body-default": "#d1d5db",
    "color-text-body-secondary": "#d1d5db",
    "color-text-breadcrumb-current": "#7d8998",
    "color-text-breadcrumb-icon": "#5f6b7a",
    "color-text-button-inline-icon-default": "#539fe5",
    "color-text-button-inline-icon-disabled": "#5f6b7a",
    "color-text-button-inline-icon-hover": "#89bdee",
    "color-text-button-normal-active": "#89bdee",
    "color-text-button-normal-default": "#539fe5",
    "color-text-button-normal-hover": "#89bdee",
    "color-text-button-primary-active": "#000716",
    "color-text-button-primary-default": "#000716",
    "color-text-button-primary-hover": "#000716",
    "color-text-calendar-day-hover": "#d1d5db",
    "color-text-calendar-day-selected": "#539fe5",
    "color-text-calendar-month": "#8d99a8",
    "color-text-code-editor-gutter-active-line": "#000716",
    "color-text-code-editor-gutter-default": "#d1d5db",
    "color-text-code-editor-status-bar-disabled": "#5f6b7a",
    "color-text-code-editor-tab-button-error": "#000716",
    "color-text-column-header": "#9ba7b6",
    "color-text-column-sorting-icon": "#9ba7b6",
    "color-text-control-disabled": "#5f6b7a",
    "color-text-counter": "#8d99a8",
    "color-text-disabled": "#5f6b7a",
    "color-text-dropdown-footer": "#8d99a8",
    "color-text-dropdown-group-label": "#8d99a8",
    "color-text-dropdown-header": "#fbfbfb",
    "color-text-dropdown-item-default": "#d1d5db",
    "color-text-dropdown-item-dimmed": "#5f6b7a",
    "color-text-dropdown-item-disabled": "#5f6b7a",
    "color-text-dropdown-item-filter-match": "#89bdee",
    "color-text-dropdown-item-highlighted": "#e9ebed",
    "color-text-dropdown-item-secondary": "#8d99a8",
    "color-text-empty": "#d1d5db",
    "color-text-expandable-section-default": "#fbfbfb",
    "color-text-expandable-section-hover": "#539fe5",
    "color-text-expandable-section-navigation-icon-default": "#d1d5db",
    "color-text-form-default": "#d1d5db",
    "color-text-form-label": "#d1d5db",
    "color-text-form-secondary": "#8d99a8",
    "color-text-group-label": "#8d99a8",
    "color-text-heading-default": "#e9ebed",
    "color-text-heading-secondary": "#8d99a8",
    "color-text-home-header-default": "#fff",
    "color-text-icon-subtle": "#9ba7b6",
    "color-text-input-disabled": "#5f6b7a",
    "color-text-input-placeholder": "#7d8998",
    "color-text-input-placeholder-disabled": "#5f6b7a",
    "color-text-interactive-active": "#fbfbfb",
    "color-text-interactive-default": "#d1d5db",
    "color-text-interactive-disabled": "#5f6b7a",
    "color-text-interactive-hover": "#fbfbfb",
    "color-text-inverted": "#000716",
    "color-text-label": "#d1d5db",
    "color-text-layout-toggle-selected": "#000716",
    "color-text-link-default": "#539fe5",
    "color-text-link-hover": "#89bdee",
    "color-text-link-primary-underline": "#539fe5",
    "color-text-pagination-page-number-active-disabled": "#5f6b7a",
    "color-text-pagination-page-number-default": "#9ba7b6",
    "color-text-segment-active": "#000716",
    "color-text-segment-default": "#d1d5db",
    "color-text-segment-hover": "#539fe5",
    "color-text-small": "#8d99a8",
    "color-text-status-error": "#eb6f6f",
    "color-text-status-inactive": "#8d99a8",
    "color-text-status-info": "#539fe5",
    "color-text-status-success": "#29ad32",
    "color-text-status-warning": "#eb6f6f",
    "color-text-top-navigation-title": "#fbfbfb",
    "shadow-container": "0px 0px 1px 1px #192534, 0px 1px 8px 2px #000716",
    "shadow-container-stacked":
      "-1px 1px 1px 0px #192534, 1px 1px 1px 0px #192534, 0px 9px 8px -7px #000716, 8px 0px 8px -7px #000716, -8px 0px 8px -7px #000716",
    "shadow-dropdown": "0px 4px 20px 1px #000716",
    "shadow-dropup": "0px 4px 20px 1px #000716",
    "shadow-flash-sticky": "0px 6px 36px #000716",
    "shadow-modal": "0px 4px 20px 1px #000716",
    "shadow-panel": "0px 1px 1px 1px #192534, 0px 6px 36px #000716",
    "shadow-panel-toggle": "0px 6px 12px 1px #000716",
    "shadow-popover": "0px 4px 20px 1px #000716",
    "shadow-split-bottom": "0px -36px 36px -36px #000716",
    "shadow-split-side": "-1px 0px 1px 0px #192534, -36px 6px 36px -36px #000716",
    "shadow-sticky": "0px 4px 20px 1px #000716",
  },
  ".awsui-compact-mode.awsui-visual-refresh:not(#\\9)": {
    "size-vertical-input": "30px",
    "space-alert-vertical": "4px",
    "space-button-icon-only-horizontal": "5px",
    "space-container-header-vertical": "12px",
    "space-dark-header-padding-bottom": "12px",
    "space-dark-header-overlap-distance": "32px",
    "space-expandable-section-icon-offset-top": "0",
    "space-layout-content-bottom": "24px",
    "space-modal-content-bottom": "8px",
    "space-panel-side-right": "20px",
    "space-panel-split-top": "16px",
    "space-table-header-focus-outline-gutter": "-1px",
    "space-tabs-content-top": "8px",
    "space-table-content-top": "8px",
    "space-text-grid-vertical": "12px",
  },
  ".awsui-visual-refresh .awsui-context-top-navigation:not(#\\9)": {
    "color-background-button-link-active": "#232f3e",
    "color-background-button-link-hover": "#192534",
    "color-background-button-normal-active": "#232f3e",
    "color-background-button-normal-default": "#0f1b2a",
    "color-background-button-normal-disabled": "#0f1b2a",
    "color-background-button-normal-hover": "#192534",
    "color-background-button-primary-disabled": "#232f3e",
    "color-background-calendar-today": "#232f3e",
    "color-background-code-editor-gutter-active-line-default": "#7d8998",
    "color-background-code-editor-gutter-active-line-error": "#eb6f6f",
    "color-background-code-editor-gutter-default": "#192534",
    "color-background-code-editor-loading": "#192534",
    "color-background-code-editor-pane-item-hover": "#354150",
    "color-background-code-editor-status-bar": "#192534",
    "color-background-container-header": "#0f1b2a",
    "color-background-control-checked": "#539fe5",
    "color-background-control-default": "#0f1b2a",
    "color-background-control-disabled": "#354150",
    "color-background-dropdown-item-filter-match": "#232f3e",
    "color-background-dropdown-item-hover": "#354150",
    "color-background-dropdown-item-selected": "#232f3e",
    "color-background-home-header": "#000716",
    "color-background-input-default": "#0f1b2a",
    "color-background-input-disabled": "#192534",
    "color-background-item-selected": "#232f3e",
    "color-background-layout-main": "#0f1b2a",
    "color-background-layout-mobile-panel": "#000716",
    "color-background-layout-panel-hover": "#354150",
    "color-background-layout-toggle-selected-active": "#539fe5",
    "color-background-layout-toggle-selected-default": "#539fe5",
    "color-background-layout-toggle-selected-hover": "#89bdee",
    "color-background-modal-overlay": "rgba(0, 7, 22, 0.7)",
    "color-background-notification-grey": "#5f6b7a",
    "color-background-popover": "#192534",
    "color-background-progress-bar-content-default": "#539fe5",
    "color-background-progress-bar-content-in-flash": "#fff",
    "color-background-progress-bar-layout-default": "#354150",
    "color-background-segment-active": "#539fe5",
    "color-background-segment-default": "#0f1b2a",
    "color-background-segment-disabled": "#0f1b2a",
    "color-background-segment-hover": "#0f1b2a",
    "color-background-status-error": "#1a0000",
    "color-background-status-info": "#00142b",
    "color-background-status-success": "#001a02",
    "color-background-status-warning": "#0f1b2a",
    "color-background-table-header": "#0f1b2a",
    "color-background-tiles-disabled": "#192534",
    "color-background-toggle-checked-disabled": "#033160",
    "color-background-toggle-default": "#7d8998",
    "color-border-button-normal-active": "#89bdee",
    "color-border-button-normal-default": "#539fe5",
    "color-border-button-normal-disabled": "#5f6b7a",
    "color-border-button-normal-hover": "#89bdee",
    "color-border-button-primary-disabled": "#232f3e",
    "color-border-calendar-grid": "transparent",
    "color-border-calendar-grid-selected-focus-ring": "#0f1b2a",
    "color-border-code-editor-default": "#5f6b7a",
    "color-border-code-editor-pane-item-hover": "#5f6b7a",
    "color-border-container-divider": "transparent",
    "color-border-container-top": "transparent",
    "color-border-control-checked": "#539fe5",
    "color-border-control-default": "#7d8998",
    "color-border-control-disabled": "#354150",
    "color-border-divider-default": "#414d5c",
    "color-border-divider-panel-bottom": "#000716",
    "color-border-divider-panel-side": "#414d5c",
    "color-border-dropdown-container": "#5f6b7a",
    "color-border-dropdown-group": "#414d5c",
    "color-border-dropdown-item-default": "#414d5c",
    "color-border-dropdown-item-hover": "#5f6b7a",
    "color-border-dropdown-item-selected": "#539fe5",
    "color-border-dropdown-item-top": "transparent",
    "color-border-input-default": "#5f6b7a",
    "color-border-input-disabled": "#192534",
    "color-border-item-focused": "#539fe5",
    "color-border-item-placeholder": "#539fe5",
    "color-border-item-selected": "#539fe5",
    "color-border-layout": "#354150",
    "color-border-popover": "#5f6b7a",
    "color-border-segment-active": "#d1d5db",
    "color-border-segment-default": "#d1d5db",
    "color-border-segment-disabled": "#d1d5db",
    "color-border-segment-hover": "#d1d5db",
    "color-border-status-error": "#eb6f6f",
    "color-border-status-info": "#539fe5",
    "color-border-status-success": "#29ad32",
    "color-border-status-warning": "#8d99a8",
    "color-border-tabs-divider": "#414d5c",
    "color-border-tabs-shadow": "#000716",
    "color-border-tabs-underline": "#539fe5",
    "color-border-tiles-disabled": "#192534",
    "color-border-tutorial": "#414d5c",
    "color-foreground-control-default": "#000716",
    "color-foreground-control-disabled": "#0f1b2a",
    "color-shadow-default": "#000716",
    "color-text-accent": "#539fe5",
    "color-text-body-default": "#d1d5db",
    "color-text-body-secondary": "#d1d5db",
    "color-text-breadcrumb-current": "#7d8998",
    "color-text-breadcrumb-icon": "#5f6b7a",
    "color-text-button-inline-icon-default": "#539fe5",
    "color-text-button-inline-icon-disabled": "#5f6b7a",
    "color-text-button-inline-icon-hover": "#89bdee",
    "color-text-button-normal-active": "#89bdee",
    "color-text-button-normal-default": "#539fe5",
    "color-text-button-normal-hover": "#89bdee",
    "color-text-button-primary-active": "#000716",
    "color-text-button-primary-default": "#000716",
    "color-text-button-primary-hover": "#000716",
    "color-text-calendar-day-hover": "#d1d5db",
    "color-text-calendar-day-selected": "#539fe5",
    "color-text-calendar-month": "#8d99a8",
    "color-text-code-editor-gutter-active-line": "#000716",
    "color-text-code-editor-gutter-default": "#d1d5db",
    "color-text-code-editor-status-bar-disabled": "#5f6b7a",
    "color-text-code-editor-tab-button-error": "#000716",
    "color-text-column-header": "#9ba7b6",
    "color-text-column-sorting-icon": "#9ba7b6",
    "color-text-control-disabled": "#5f6b7a",
    "color-text-counter": "#8d99a8",
    "color-text-disabled": "#5f6b7a",
    "color-text-dropdown-footer": "#8d99a8",
    "color-text-dropdown-group-label": "#8d99a8",
    "color-text-dropdown-header": "#fbfbfb",
    "color-text-dropdown-item-default": "#d1d5db",
    "color-text-dropdown-item-dimmed": "#5f6b7a",
    "color-text-dropdown-item-disabled": "#5f6b7a",
    "color-text-dropdown-item-filter-match": "#89bdee",
    "color-text-dropdown-item-highlighted": "#e9ebed",
    "color-text-dropdown-item-secondary": "#8d99a8",
    "color-text-empty": "#d1d5db",
    "color-text-expandable-section-default": "#fbfbfb",
    "color-text-expandable-section-hover": "#539fe5",
    "color-text-expandable-section-navigation-icon-default": "#d1d5db",
    "color-text-form-default": "#d1d5db",
    "color-text-form-label": "#d1d5db",
    "color-text-form-secondary": "#8d99a8",
    "color-text-group-label": "#8d99a8",
    "color-text-heading-default": "#e9ebed",
    "color-text-heading-secondary": "#8d99a8",
    "color-text-home-header-default": "#fff",
    "color-text-icon-subtle": "#9ba7b6",
    "color-text-input-disabled": "#5f6b7a",
    "color-text-input-placeholder": "#7d8998",
    "color-text-input-placeholder-disabled": "#5f6b7a",
    "color-text-interactive-active": "#fbfbfb",
    "color-text-interactive-default": "#d1d5db",
    "color-text-interactive-disabled": "#5f6b7a",
    "color-text-interactive-hover": "#fbfbfb",
    "color-text-inverted": "#000716",
    "color-text-label": "#d1d5db",
    "color-text-layout-toggle-selected": "#000716",
    "color-text-link-default": "#539fe5",
    "color-text-link-hover": "#89bdee",
    "color-text-link-primary-underline": "#539fe5",
    "color-text-pagination-page-number-active-disabled": "#5f6b7a",
    "color-text-pagination-page-number-default": "#9ba7b6",
    "color-text-segment-active": "#000716",
    "color-text-segment-default": "#d1d5db",
    "color-text-segment-hover": "#539fe5",
    "color-text-small": "#8d99a8",
    "color-text-status-error": "#eb6f6f",
    "color-text-status-inactive": "#8d99a8",
    "color-text-status-info": "#539fe5",
    "color-text-status-success": "#29ad32",
    "color-text-status-warning": "#eb6f6f",
    "color-text-top-navigation-title": "#fbfbfb",
  },
  ".awsui-context-top-navigation.awsui-visual-refresh:not(#\\9)": {
    "color-background-button-link-active": "#232f3e",
    "color-background-button-link-hover": "#192534",
    "color-background-button-normal-active": "#232f3e",
    "color-background-button-normal-default": "#0f1b2a",
    "color-background-button-normal-disabled": "#0f1b2a",
    "color-background-button-normal-hover": "#192534",
    "color-background-button-primary-disabled": "#232f3e",
    "color-background-calendar-today": "#232f3e",
    "color-background-code-editor-gutter-active-line-default": "#7d8998",
    "color-background-code-editor-gutter-active-line-error": "#eb6f6f",
    "color-background-code-editor-gutter-default": "#192534",
    "color-background-code-editor-loading": "#192534",
    "color-background-code-editor-pane-item-hover": "#354150",
    "color-background-code-editor-status-bar": "#192534",
    "color-background-container-header": "#0f1b2a",
    "color-background-control-checked": "#539fe5",
    "color-background-control-default": "#0f1b2a",
    "color-background-control-disabled": "#354150",
    "color-background-dropdown-item-filter-match": "#232f3e",
    "color-background-dropdown-item-hover": "#354150",
    "color-background-dropdown-item-selected": "#232f3e",
    "color-background-input-default": "#0f1b2a",
    "color-background-input-disabled": "#192534",
    "color-background-item-selected": "#232f3e",
    "color-background-layout-main": "#0f1b2a",
    "color-background-layout-panel-hover": "#354150",
    "color-background-layout-toggle-selected-active": "#539fe5",
    "color-background-layout-toggle-selected-default": "#539fe5",
    "color-background-layout-toggle-selected-hover": "#89bdee",
    "color-background-modal-overlay": "rgba(0, 7, 22, 0.7)",
    "color-background-notification-grey": "#5f6b7a",
    "color-background-popover": "#192534",
    "color-background-progress-bar-content-default": "#539fe5",
    "color-background-progress-bar-layout-default": "#354150",
    "color-background-segment-active": "#539fe5",
    "color-background-segment-default": "#0f1b2a",
    "color-background-segment-disabled": "#0f1b2a",
    "color-background-segment-hover": "#0f1b2a",
    "color-background-status-error": "#1a0000",
    "color-background-status-info": "#00142b",
    "color-background-status-success": "#001a02",
    "color-background-status-warning": "#0f1b2a",
    "color-background-table-header": "#0f1b2a",
    "color-background-tiles-disabled": "#192534",
    "color-background-toggle-checked-disabled": "#033160",
    "color-background-toggle-default": "#7d8998",
    "color-border-button-normal-active": "#89bdee",
    "color-border-button-normal-default": "#539fe5",
    "color-border-button-normal-disabled": "#5f6b7a",
    "color-border-button-normal-hover": "#89bdee",
    "color-border-button-primary-disabled": "#232f3e",
    "color-border-calendar-grid-selected-focus-ring": "#0f1b2a",
    "color-border-code-editor-default": "#5f6b7a",
    "color-border-code-editor-pane-item-hover": "#5f6b7a",
    "color-border-control-checked": "#539fe5",
    "color-border-control-disabled": "#354150",
    "color-border-divider-default": "#414d5c",
    "color-border-divider-panel-bottom": "#000716",
    "color-border-divider-panel-side": "#414d5c",
    "color-border-dropdown-container": "#5f6b7a",
    "color-border-dropdown-group": "#414d5c",
    "color-border-dropdown-item-default": "#414d5c",
    "color-border-dropdown-item-hover": "#5f6b7a",
    "color-border-dropdown-item-selected": "#539fe5",
    "color-border-input-default": "#5f6b7a",
    "color-border-input-disabled": "#192534",
    "color-border-item-focused": "#539fe5",
    "color-border-item-placeholder": "#539fe5",
    "color-border-item-selected": "#539fe5",
    "color-border-layout": "#354150",
    "color-border-popover": "#5f6b7a",
    "color-border-segment-active": "#d1d5db",
    "color-border-segment-default": "#d1d5db",
    "color-border-segment-disabled": "#d1d5db",
    "color-border-segment-hover": "#d1d5db",
    "color-border-status-error": "#eb6f6f",
    "color-border-status-info": "#539fe5",
    "color-border-status-success": "#29ad32",
    "color-border-status-warning": "#8d99a8",
    "color-border-tabs-divider": "#414d5c",
    "color-border-tabs-shadow": "#000716",
    "color-border-tabs-underline": "#539fe5",
    "color-border-tiles-disabled": "#192534",
    "color-border-tutorial": "#414d5c",
    "color-foreground-control-default": "#000716",
    "color-foreground-control-disabled": "#0f1b2a",
    "color-shadow-default": "#000716",
    "color-text-accent": "#539fe5",
    "color-text-body-default": "#d1d5db",
    "color-text-body-secondary": "#d1d5db",
    "color-text-breadcrumb-current": "#7d8998",
    "color-text-breadcrumb-icon": "#5f6b7a",
    "color-text-button-inline-icon-default": "#539fe5",
    "color-text-button-inline-icon-disabled": "#5f6b7a",
    "color-text-button-inline-icon-hover": "#89bdee",
    "color-text-button-normal-active": "#89bdee",
    "color-text-button-normal-default": "#539fe5",
    "color-text-button-normal-hover": "#89bdee",
    "color-text-button-primary-active": "#000716",
    "color-text-button-primary-default": "#000716",
    "color-text-button-primary-hover": "#000716",
    "color-text-calendar-day-hover": "#d1d5db",
    "color-text-calendar-day-selected": "#539fe5",
    "color-text-calendar-month": "#8d99a8",
    "color-text-code-editor-gutter-active-line": "#000716",
    "color-text-code-editor-gutter-default": "#d1d5db",
    "color-text-code-editor-status-bar-disabled": "#5f6b7a",
    "color-text-code-editor-tab-button-error": "#000716",
    "color-text-column-header": "#9ba7b6",
    "color-text-column-sorting-icon": "#9ba7b6",
    "color-text-control-disabled": "#5f6b7a",
    "color-text-counter": "#8d99a8",
    "color-text-disabled": "#5f6b7a",
    "color-text-dropdown-footer": "#8d99a8",
    "color-text-dropdown-group-label": "#8d99a8",
    "color-text-dropdown-header": "#fbfbfb",
    "color-text-dropdown-item-default": "#d1d5db",
    "color-text-dropdown-item-dimmed": "#5f6b7a",
    "color-text-dropdown-item-disabled": "#5f6b7a",
    "color-text-dropdown-item-filter-match": "#89bdee",
    "color-text-dropdown-item-highlighted": "#e9ebed",
    "color-text-dropdown-item-secondary": "#8d99a8",
    "color-text-empty": "#d1d5db",
    "color-text-expandable-section-default": "#fbfbfb",
    "color-text-expandable-section-hover": "#539fe5",
    "color-text-expandable-section-navigation-icon-default": "#d1d5db",
    "color-text-form-default": "#d1d5db",
    "color-text-form-label": "#d1d5db",
    "color-text-form-secondary": "#8d99a8",
    "color-text-group-label": "#8d99a8",
    "color-text-heading-default": "#e9ebed",
    "color-text-heading-secondary": "#8d99a8",
    "color-text-home-header-default": "#fff",
    "color-text-icon-subtle": "#9ba7b6",
    "color-text-input-disabled": "#5f6b7a",
    "color-text-input-placeholder": "#7d8998",
    "color-text-input-placeholder-disabled": "#5f6b7a",
    "color-text-interactive-active": "#fbfbfb",
    "color-text-interactive-default": "#d1d5db",
    "color-text-interactive-disabled": "#5f6b7a",
    "color-text-interactive-hover": "#fbfbfb",
    "color-text-inverted": "#000716",
    "color-text-label": "#d1d5db",
    "color-text-layout-toggle-selected": "#000716",
    "color-text-link-default": "#539fe5",
    "color-text-link-hover": "#89bdee",
    "color-text-link-primary-underline": "#539fe5",
    "color-text-pagination-page-number-active-disabled": "#5f6b7a",
    "color-text-pagination-page-number-default": "#9ba7b6",
    "color-text-segment-active": "#000716",
    "color-text-segment-default": "#d1d5db",
    "color-text-segment-hover": "#539fe5",
    "color-text-small": "#8d99a8",
    "color-text-status-error": "#eb6f6f",
    "color-text-status-inactive": "#8d99a8",
    "color-text-status-info": "#539fe5",
    "color-text-status-success": "#29ad32",
    "color-text-status-warning": "#eb6f6f",
    "color-text-top-navigation-title": "#fbfbfb",
  },
  ".awsui-context-content-header.awsui-visual-refresh:not(#\\9), .awsui-visual-refresh .awsui-context-content-header:not(#\\9)":
    {
      "color-background-button-link-active": "#232f3e",
      "color-background-button-link-hover": "#192534",
      "color-background-button-normal-active": "#232f3e",
      "color-background-button-normal-default": "#000716",
      "color-background-button-normal-disabled": "#000716",
      "color-background-button-normal-hover": "#192534",
      "color-background-button-primary-active": "#ec7211",
      "color-background-button-primary-disabled": "#232f3e",
      "color-background-calendar-today": "#232f3e",
      "color-background-code-editor-gutter-active-line-default": "#7d8998",
      "color-background-code-editor-gutter-active-line-error": "#eb6f6f",
      "color-background-code-editor-gutter-default": "#192534",
      "color-background-code-editor-loading": "#192534",
      "color-background-code-editor-pane-item-hover": "#354150",
      "color-background-code-editor-status-bar": "#192534",
      "color-background-container-content": "#0f1b2a",
      "color-background-container-header": "#0f1b2a",
      "color-background-control-checked": "#539fe5",
      "color-background-control-default": "#000716",
      "color-background-control-disabled": "#354150",
      "color-background-dropdown-item-default": "#192534",
      "color-background-dropdown-item-filter-match": "#232f3e",
      "color-background-dropdown-item-hover": "#354150",
      "color-background-dropdown-item-selected": "#232f3e",
      "color-background-input-default": "#000716",
      "color-background-input-disabled": "#192534",
      "color-background-item-selected": "#232f3e",
      "color-background-layout-main": "#000716",
      "color-background-layout-panel-content": "#0f1b2a",
      "color-background-layout-panel-hover": "#354150",
      "color-background-layout-toggle-selected-active": "#539fe5",
      "color-background-layout-toggle-selected-default": "#539fe5",
      "color-background-layout-toggle-selected-hover": "#89bdee",
      "color-background-modal-overlay": "rgba(0, 7, 22, 0.7)",
      "color-background-notification-grey": "#5f6b7a",
      "color-background-popover": "#192534",
      "color-background-progress-bar-content-default": "#539fe5",
      "color-background-progress-bar-layout-default": "#354150",
      "color-background-segment-active": "#539fe5",
      "color-background-segment-default": "#000716",
      "color-background-segment-disabled": "#000716",
      "color-background-segment-hover": "#000716",
      "color-background-status-error": "#1a0000",
      "color-background-status-info": "#00142b",
      "color-background-status-success": "#001a02",
      "color-background-status-warning": "#0f1b2a",
      "color-background-table-header": "#000716",
      "color-background-tiles-disabled": "#192534",
      "color-background-toggle-checked-disabled": "#033160",
      "color-background-toggle-default": "#7d8998",
      "color-border-button-normal-active": "#89bdee",
      "color-border-button-normal-default": "#539fe5",
      "color-border-button-normal-disabled": "#5f6b7a",
      "color-border-button-normal-hover": "#89bdee",
      "color-border-button-primary-disabled": "#232f3e",
      "color-border-calendar-grid-selected-focus-ring": "#0f1b2a",
      "color-border-code-editor-default": "#5f6b7a",
      "color-border-code-editor-pane-item-hover": "#5f6b7a",
      "color-border-control-checked": "#539fe5",
      "color-border-control-disabled": "#354150",
      "color-border-divider-default": "#414d5c",
      "color-border-divider-panel-bottom": "#000716",
      "color-border-divider-panel-side": "#414d5c",
      "color-border-dropdown-container": "#5f6b7a",
      "color-border-dropdown-group": "#414d5c",
      "color-border-dropdown-item-default": "#414d5c",
      "color-border-dropdown-item-hover": "#5f6b7a",
      "color-border-dropdown-item-selected": "#539fe5",
      "color-border-input-default": "#5f6b7a",
      "color-border-input-disabled": "#192534",
      "color-border-item-focused": "#539fe5",
      "color-border-item-placeholder": "#539fe5",
      "color-border-item-selected": "#539fe5",
      "color-border-layout": "#354150",
      "color-border-popover": "#5f6b7a",
      "color-border-segment-active": "#d1d5db",
      "color-border-segment-default": "#d1d5db",
      "color-border-segment-disabled": "#d1d5db",
      "color-border-segment-hover": "#d1d5db",
      "color-border-status-error": "#eb6f6f",
      "color-border-status-info": "#539fe5",
      "color-border-status-success": "#29ad32",
      "color-border-status-warning": "#8d99a8",
      "color-border-tabs-divider": "#414d5c",
      "color-border-tabs-shadow": "#000716",
      "color-border-tabs-underline": "#539fe5",
      "color-border-tiles-disabled": "#192534",
      "color-border-tutorial": "#414d5c",
      "color-foreground-control-default": "#000716",
      "color-foreground-control-disabled": "#0f1b2a",
      "color-shadow-default": "#000716",
      "color-text-accent": "#539fe5",
      "color-text-body-default": "#d1d5db",
      "color-text-body-secondary": "#d1d5db",
      "color-text-breadcrumb-current": "#7d8998",
      "color-text-breadcrumb-icon": "#5f6b7a",
      "color-text-button-inline-icon-default": "#539fe5",
      "color-text-button-inline-icon-disabled": "#5f6b7a",
      "color-text-button-inline-icon-hover": "#89bdee",
      "color-text-button-normal-active": "#89bdee",
      "color-text-button-normal-default": "#539fe5",
      "color-text-button-normal-hover": "#89bdee",
      "color-text-button-primary-active": "#000716",
      "color-text-button-primary-default": "#000716",
      "color-text-button-primary-hover": "#000716",
      "color-text-calendar-day-hover": "#d1d5db",
      "color-text-calendar-day-selected": "#539fe5",
      "color-text-calendar-month": "#8d99a8",
      "color-text-code-editor-gutter-active-line": "#000716",
      "color-text-code-editor-gutter-default": "#d1d5db",
      "color-text-code-editor-status-bar-disabled": "#5f6b7a",
      "color-text-code-editor-tab-button-error": "#000716",
      "color-text-column-header": "#9ba7b6",
      "color-text-column-sorting-icon": "#9ba7b6",
      "color-text-control-disabled": "#5f6b7a",
      "color-text-counter": "#8d99a8",
      "color-text-disabled": "#5f6b7a",
      "color-text-dropdown-footer": "#8d99a8",
      "color-text-dropdown-group-label": "#8d99a8",
      "color-text-dropdown-header": "#fbfbfb",
      "color-text-dropdown-item-default": "#d1d5db",
      "color-text-dropdown-item-dimmed": "#5f6b7a",
      "color-text-dropdown-item-disabled": "#5f6b7a",
      "color-text-dropdown-item-filter-match": "#89bdee",
      "color-text-dropdown-item-highlighted": "#e9ebed",
      "color-text-dropdown-item-secondary": "#8d99a8",
      "color-text-empty": "#d1d5db",
      "color-text-expandable-section-default": "#fbfbfb",
      "color-text-expandable-section-hover": "#539fe5",
      "color-text-expandable-section-navigation-icon-default": "#d1d5db",
      "color-text-form-default": "#d1d5db",
      "color-text-form-label": "#d1d5db",
      "color-text-form-secondary": "#8d99a8",
      "color-text-group-label": "#8d99a8",
      "color-text-heading-default": "#e9ebed",
      "color-text-heading-secondary": "#8d99a8",
      "color-text-icon-subtle": "#9ba7b6",
      "color-text-input-disabled": "#5f6b7a",
      "color-text-input-placeholder": "#7d8998",
      "color-text-input-placeholder-disabled": "#5f6b7a",
      "color-text-interactive-active": "#fbfbfb",
      "color-text-interactive-default": "#d1d5db",
      "color-text-interactive-disabled": "#5f6b7a",
      "color-text-interactive-hover": "#fbfbfb",
      "color-text-inverted": "#000716",
      "color-text-label": "#d1d5db",
      "color-text-layout-toggle-selected": "#000716",
      "color-text-link-default": "#539fe5",
      "color-text-link-hover": "#89bdee",
      "color-text-link-primary-underline": "#539fe5",
      "color-text-pagination-page-number-active-disabled": "#5f6b7a",
      "color-text-pagination-page-number-default": "#9ba7b6",
      "color-text-segment-active": "#000716",
      "color-text-segment-default": "#d1d5db",
      "color-text-segment-hover": "#539fe5",
      "color-text-small": "#8d99a8",
      "color-text-status-error": "#eb6f6f",
      "color-text-status-inactive": "#8d99a8",
      "color-text-status-info": "#539fe5",
      "color-text-status-success": "#29ad32",
      "color-text-status-warning": "#eb6f6f",
      "color-text-top-navigation-title": "#fbfbfb",
      "shadow-container": "none",
      "shadow-container-stacked":
        "-1px 1px 1px 0px #192534, 1px 1px 1px 0px #192534, 0px 9px 8px -7px #000716, 8px 0px 8px -7px #000716 -8px 0px 8px -7px #000716",
      "shadow-dropdown": "0px 4px 20px 1px #000716",
      "shadow-dropup": "0px 4px 20px 1px #000716",
      "shadow-modal": "0px 4px 20px 1px #000716",
      "shadow-popover": "0px 4px 20px 1px #000716",
      "shadow-split-bottom": "0px -36px 36px -36px #000716",
      "shadow-split-side": "-1px 0px 1px 0px #192534, -36px 6px 36px -36px #000716",
      "shadow-sticky": "0px 4px 20px 1px #000716",
    },
  ".awsui-context-flashbar.awsui-visual-refresh:not(#\\9), .awsui-visual-refresh .awsui-context-flashbar:not(#\\9)":
    {
      "color-background-button-normal-active": "rgba(0, 7, 22, 0.2)",
      "color-background-button-normal-default": "transparent",
      "color-background-button-normal-hover": "rgba(0, 7, 22, 0.15)",
      "color-background-segment-default": "transparent",
      "color-background-segment-hover": "transparent",
      "color-border-button-normal-active": "#fff",
      "color-border-button-normal-default": "#fbfbfb",
      "color-border-button-normal-hover": "#fff",
      "color-border-item-focused": "#fbfbfb",
      "color-text-button-normal-active": "#fff",
      "color-text-button-normal-default": "#fbfbfb",
      "color-text-button-normal-hover": "#fff",
    },
  ".awsui-context-alert.awsui-visual-refresh:not(#\\9), .awsui-visual-refresh .awsui-context-alert:not(#\\9)":
    {
      "color-background-button-normal-active": "rgba(0, 7, 22, 0.1)",
      "color-background-button-normal-default": "transparent",
      "color-background-button-normal-hover": "rgba(0, 7, 22, 0.05)",
      "color-background-segment-default": "transparent",
      "color-background-segment-hover": "transparent",
      "color-border-button-normal-active": "#000716",
      "color-border-button-normal-default": "#414d5c",
      "color-border-button-normal-hover": "#000716",
      "color-text-button-normal-active": "#000716",
      "color-text-button-normal-default": "#414d5c",
      "color-text-button-normal-hover": "#000716",
    },
  ".awsui-context-top-navigation.awsui-dark-mode.awsui-visual-refresh:not(#\\9)": {
    "color-background-container-content": "#232f3e",
    "color-background-dropdown-item-default": "#232f3e",
    "color-background-layout-panel-content": "#232f3e",
  },
  ".awsui-context-alert.awsui-dark-mode.awsui-visual-refresh:not(#\\9), .awsui-dark-mode.awsui-visual-refresh .awsui-context-alert:not(#\\9)":
    {
      "color-background-button-normal-active": "hsla(0, 0%, 100%, 0.15)",
      "color-background-button-normal-hover": "hsla(0, 0%, 100%, 0.1)",
      "color-border-button-normal-active": "#fff",
      "color-border-button-normal-default": "#d1d5db",
      "color-border-button-normal-hover": "#fff",
      "color-border-item-focused": "#fbfbfb",
      "color-text-button-normal-active": "#fff",
      "color-text-button-normal-default": "#d1d5db",
      "color-text-button-normal-hover": "#fff",
    },
};
