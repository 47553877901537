import "./GcpHeader.scss";
import * as React from "react";
import { AppBar, Divider, IconButton, Toolbar, Typography } from "@mui/material";
import { RenderMetricsProps, withRenderMetrics } from "../../CourierService/RenderMetrics";
import oracleLogo from "../../images/oci-red-logo.png";

const GcpHeader = withRenderMetrics(({ renderMetrics }: RenderMetricsProps) => {
  React.useEffect(() => {
    renderMetrics && renderMetrics.rendered("GcpHeader");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppBar
      className="gcp"
      position="sticky"
      elevation={0}
      sx={{
        zIndex: 1250,
        backgroundColor: "#ffffff",
        color: "#000000",
      }}
    >
      <Toolbar variant="dense">
        <IconButton className="oci-white" size="small" disabled>
          <div className="header-logo__icon">
            <img className="ociLogo" src={oracleLogo} alt="Oracle logo red" />
          </div>
        </IconButton>
        <Typography aria-hidden="true" variant="subtitle1" className="gcpFont">
          {"Oracle Database Service for Google Cloud"}
        </Typography>
      </Toolbar>
      <Divider variant="fullWidth" />
    </AppBar>
  );
});

export default GcpHeader;
