import { fetchNativeSubtleCrypto, str2ab } from "../fluorine/crypto";
import { getHgCompatibleDbData, setItem } from "./indexedDb";
import { HgIndexedDBData } from "./HgIndexedDBData";
import { fromByteArray } from "base64-js";

const EMPTY_STRING = "";

/**
 * State payload
 */
interface PayLoad {
  destination_uri: string;
}

interface StateParam {
  payload: PayLoad;
  signature: string;
}

class StateObject {
  /**
   * Payload to be signed/encoded
   */
  private readonly payload = {} as PayLoad;

  /**
   * Signature is signed payload
   */
  public signature = EMPTY_STRING;

  /**
   * Constructor
   * @param destinationUri The url as payload of state
   */
  constructor(destinationUri: string) {
    this.payload.destination_uri = destinationUri;
  }

  /**
   * @returns Head and payload as ArrayBuffer
   */
  public getPayloadForSigning(): ArrayBuffer {
    return str2ab(JSON.stringify(this.payload));
  }

  /**
   * Serialize this object as string
   */
  public serialize(): string {
    const stateParam: StateParam = {
      payload: this.payload,
      signature: this.signature,
    };

    return JSON.stringify(stateParam);
  }

  public static async extractPayload(realm: string, state: string): Promise<PayLoad> {
    try {
      const incomingStatePayload = JSON.parse(b64DecodeUnicode(state)) as StateParam;

      // verification
      const stateObject = new StateObject(incomingStatePayload.payload.destination_uri);
      const signatureVerification = await createSignature(realm, stateObject.getPayloadForSigning());

      if (incomingStatePayload.signature === signatureVerification) {
        return Promise.resolve(incomingStatePayload.payload);
      } else {
        return Promise.reject("State signature invalid.");
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

/**
 * Signing algorithm
 */
const stateSigningAlgorithm = {
  name: "HMAC",
  hash: { name: "SHA-256" },
};

/**
 * Generate key for signing state
 * @returns The key promise
 */
export function generateStateSigningKey(): PromiseLike<CryptoKey> {
  let browserCryptoObject = fetchNativeSubtleCrypto();
  return browserCryptoObject.generateKey(stateSigningAlgorithm, false, ["sign", "verify"]) as PromiseLike<CryptoKey>;
}

/**
 * Create signature
 * @param content The content to be signed
 * @returns The signature promise
 */
export async function createSignature(realm: string, content: ArrayBuffer): Promise<string> {
  try {
    const browserCryptoObject: SubtleCrypto = fetchNativeSubtleCrypto();
    const hgIndexedDBData: HgIndexedDBData = await getHgCompatibleDbData(realm);
    const signature = await browserCryptoObject.sign(stateSigningAlgorithm, hgIndexedDBData.state, content);
    return Promise.resolve(fromByteArray(new Uint8Array(signature)));
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * Construct state string from current Url
 */
export async function constructState(realm: string, destinationUri: string): Promise<string> {
  try {
    const stateObject = new StateObject(destinationUri);
    stateObject.signature = await createSignature(realm, stateObject.getPayloadForSigning());
    return Promise.resolve(b64EncodeUnicode(stateObject.serialize()));
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Save url_state (a string representing the current URL) to IndexedDB. We do this for consistent behavior
 * between authentication providers, since OCNA-SAML doesnt' pass state back when we use that mechanism.
 */
export async function saveUrlState(realm: string, destinationUri: string): Promise<string> {
  try {
    const hgIndexedDBData: HgIndexedDBData = await getHgCompatibleDbData(realm);
    hgIndexedDBData.url_state = destinationUri;
    hgIndexedDBData.realm = realm;
    await setItem(realm, hgIndexedDBData);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Retrieve url_state from IndexedDB
 */
export async function recoverState(realm: string): Promise<string> {
  try {
    const hgIndexedDBData: HgIndexedDBData = await getHgCompatibleDbData(realm);
    return Promise.resolve(hgIndexedDBData.url_state);
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * Get payload from state
 * @param realm
 * @param state The encoded state string
 */
export async function decodeState(realm: string, state: string): Promise<string> {
  try {
    const payload: PayLoad = await StateObject.extractPayload(realm, state);
    return Promise.resolve(payload.destination_uri);
  } catch (e) {
    return Promise.reject(e);
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
export function b64EncodeUnicode(str: string) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) => {
      return String.fromCharCode.apply(null, ["0x" + p1]);
    }),
  );
}

// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
export function b64DecodeUnicode(str: string) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(c => {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );
}
