import * as React from "react";
import { Dispatch, SourceType } from "../../Type";
import { Campaign } from "../Experiments/experimentTypes";

export type Form = {
  showBanner: boolean;
  oracleEmployee: boolean;
  nonAuth: boolean;
  nonAuth403: boolean;
  nonPending: boolean;
  render: boolean;
  country: string;
  apiError: boolean;
  orderNumber: string;
  companyName: string;
  firstName: string;
  lastName: string;
  accountName: string;
  countries: { value: Object; label: string }[];
  countryObject: any;
  countryInfo: {
    currency: { code: string };
    addressFields: {
      County: string[];
      City: string[];
      Address1: string[];
      Address2: string[];
      Address3: string[];
      Address4: string[];
      State: string[];
      Province: string[];
      PostalCode: string[];
    };
    taxFields: { TaxPayerID: string[] };
    promos: any;
  };
  address: {
    address1: string;
    address2: string;
    address3: string;
    address4: string;
    city: string;
    selectedState: { value: { id: string } };
    selectedProvince: null;
    state: string;
    province: string;
    postalcode: string;
    county: string;
  };
  states: Object;
  emailAddress: string;
  username: string;
  departmentName: string;
  altEnglishCompanyName: string;
  jobTitle: string;
  subscriptionIds: string[];
  dataCenterRegion: string;
  homeRegions: [];
  p1: string; //Password
  p2: string; //Confirm Password
  tenant: string;
  tenancyName: string;
  homeRegion: any;
  processing: boolean;
  submitting: boolean;
  loading: boolean;
  loading_wr: boolean;
  consoleUri: string;
  singleOrderState: any;
  tokenHasExpired: boolean;
  intendedUse: SourceType;
  taxPayerId: string;
  phoneNumber: string;
  selectedPromo: {
    id: string;
    ranking: string;
    promoId: string;
    country: string;
    intendedUse: string;
    creditCardRequired: boolean;
    emailValidation: boolean;
    validationCodeRequired: boolean;
    miniBobEnabled: boolean;
    firstName: string;
    lastName: string;
    customerName: string;
    programType: string;
    partNumber: string;
    promoAmount: string;
    promoDurationInDays: string;
    promoGuestsSupported: boolean;
    idNoTaxFields: string;
    urlParam: boolean;
    default: boolean;
    localToLocalOnly: boolean;
    companyUseRequired: boolean;
    programOptOut: boolean;
    type: string;
    campaignId: string;
    address: {
      state: string;
      address1: string;
      address2: string;
      address3: string;
      address4: string;
      city: string;
      postalcode: string;
    };
    province: string;
  };
  defaultPromo: { partNumber?: string; type?: string; programType: string; id: string };
  orgId: string;
  miniBobEnabled: boolean;
  sourceType: string;
  apiaryId: string;
  billingAddress: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
    country: string;
    departmentName: string;
    line1: string;
    line2: string;
    line3: string;
    line4: string;
    city: string;
    state: string;
    county: string;
    postalCode: string;
    province: string;
  };
  promoId: string;
  paymentOptions: {
    creditCardFirstName: string;
    creditCardLastName: string;
    paymentMethod?: string;
    paypalId?: string;
    creditCardType: string;
    creditCardLastDigits?: string;
    creditCardExpirationDate: string;
  };
  paymentError: { paymentGatewayAuthReasonCode: string; paymentGatewayAuthAVSCode: string };
  paymentResponse: Object;
  operationId: string;
  language: string;
  paymentStatus: string;
  addressValidation: boolean;
  numPaymentVerifyBtnClicked: number;
  phoneCountryCode: string;
  validPhoneNumber: boolean;
  api403Error: boolean;
  validateEmail: boolean;
  addressContinueBtn: boolean;
  accountNotUnique: boolean;
  linkingFailure?: boolean;
  opcRequestId: string;
  errorTimestamp: string;
  errorMessage: string;
  createAccountO4AError: boolean;
  azTenantId: string;
  invalidParameterLinkingFailure?: boolean;
  notAuthenticatedLinkingFailure?: boolean;
  notAuthenticatedLinkingErrMsg: string;
  invalidParamLinkingErrMsg: string;
  experiments?: {
    loading?: boolean;
    campaigns?: Campaign[];
  };
};

export const blankForm: Form = {
  apiaryId: "",
  promoId: "",
  nonAuth: false,
  nonAuth403: false,
  nonPending: false,
  apiError: false,
  oracleEmployee: false,
  render: true,
  country: "",
  username: "",
  p1: "",
  p2: "",
  accountName: "",
  homeRegion: null,
  homeRegions: [],
  firstName: "",
  lastName: "",
  countries: [{ value: {}, label: "" }],
  countryObject: null, // getCountries API call
  countryInfo: {
    currency: { code: "" },
    addressFields: {
      County: [""],
      City: [""],
      State: [""],
      Province: [""],
      PostalCode: [""],
      Address1: [""],
      Address2: [""],
      Address3: [""],
      Address4: [""],
    },
    taxFields: { TaxPayerID: [""] },
    promos: {},
  }, // Static Config file
  companyName: "",
  departmentName: "",
  altEnglishCompanyName: "",
  jobTitle: "",
  address: {
    address1: "",
    address2: "",
    address3: "",
    address4: "",
    city: "",
    selectedState: { value: { id: "" } },
    selectedProvince: null,
    state: "",
    province: "",
    postalcode: "",
    county: "",
  },
  subscriptionIds: [],
  states: [],
  intendedUse: SourceType.PERSONAL,
  taxPayerId: "",
  phoneNumber: "",
  selectedPromo: {
    promoDurationInDays: "30",
    creditCardRequired: true,
    validationCodeRequired: true,
    emailValidation: true,
    promoAmount: "300",
    miniBobEnabled: true,
    type: "Standard",
    idNoTaxFields: "OIH-PROMOCM-CREATE_V2",
    urlParam: true,
    default: true,
    localToLocalOnly: false,
    companyUseRequired: false,
    programOptOut: false,
    partNumber: "B88385",
    ranking: "0",
    id: "OIH-PROMOCM-CREATE_V2",
    promoGuestsSupported: true,
    promoId: "",
    intendedUse: "",
    programType: "",
    campaignId: "",
    province: "",
    country: "",
    firstName: "",
    lastName: "",
    customerName: "",
    address: {
      state: "",
      address1: "",
      address2: "",
      address3: "",
      address4: "",
      city: "",
      postalcode: "",
    },
  },
  defaultPromo: { programType: "", id: "" },
  orgId: "",
  miniBobEnabled: true,
  sourceType: "",
  emailAddress: "",
  billingAddress: {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    country: "",
    departmentName: "",
    line1: "",
    line2: "",
    line3: "",
    line4: "",
    city: "",
    state: "",
    county: "",
    postalCode: "",
    province: "",
  },
  paymentOptions: {
    creditCardExpirationDate: "",
    creditCardFirstName: "",
    creditCardLastName: "",
    creditCardType: "",
  },
  paymentError: { paymentGatewayAuthAVSCode: "", paymentGatewayAuthReasonCode: "" },
  paymentResponse: {},
  operationId: "",
  consoleUri: "",
  language: "",
  tenant: "",
  tenancyName: "",
  paymentStatus: "",
  addressValidation: true,
  numPaymentVerifyBtnClicked: 0,
  dataCenterRegion: "",
  phoneCountryCode: "",
  validPhoneNumber: false,
  api403Error: false,
  validateEmail: false,
  showBanner: false,
  processing: false,
  submitting: false,
  loading: true,
  loading_wr: false,
  orderNumber: "",
  singleOrderState: null,
  tokenHasExpired: false,
  addressContinueBtn: true,
  accountNotUnique: false,
  opcRequestId: "",
  errorTimestamp: "",
  errorMessage: "",
  createAccountO4AError: false,
  azTenantId: "",
  invalidParameterLinkingFailure: false,
  invalidParamLinkingErrMsg: "",
  notAuthenticatedLinkingFailure: false,
  notAuthenticatedLinkingErrMsg: "",
};

type FormProviderProps = { children: React.ReactNode; state?: Form; dispatch?: FormDispatch };
type FormAction = { type: string; payload: any; object?: any; boolean?: boolean };
export type FormDispatch = (action: FormAction) => void;

const FormStateContext = React.createContext<Form | undefined>(undefined);
const FormDispatchContext = React.createContext<FormDispatch | undefined>(undefined);

function formReducer(form: Form, action: FormAction) {
  switch (action.type) {
    case Dispatch.UPDATE_NAME:
      return {
        ...form,
        username: action.payload,
        emailAddress: action.payload,
      };
    case Dispatch.UPDATE_COUNTRY:
      return {
        ...form,
        country: action.payload[0],
        countryObject: action.payload[1],
        countryInfo: action.payload[2],
        orgId: action.payload[3],
      };
    case Dispatch.UPDATE_PROMO_COUNTRY:
      return {
        ...form,
        countryObject: action.payload,
      };
    case Dispatch.UPDATE_ACC_NAME:
      return {
        ...form,
        accountName: action.payload,
      };
    case Dispatch.UPDATE_HOME_REGION:
      return {
        ...form,
        homeRegion: action.payload,
      };
    case Dispatch.HOME_REGIONS_LIST:
      return {
        ...form,
        homeRegions: action.payload,
      };
    case Dispatch.UPDATE_FIRST_NAME:
      return {
        ...form,
        firstName: action.payload,
      };
    case Dispatch.UPDATE_LAST_NAME:
      return {
        ...form,
        lastName: action.payload,
      };
    case Dispatch.UPDATE_COUNTRIES:
      return {
        ...form,
        countries: action.payload,
      };
    case Dispatch.UPDATE_DEPT_NAME:
      return {
        ...form,
        departmentName: action.payload,
      };
    case Dispatch.UPDATE_AlT_COMPANY_NAME:
      return {
        ...form,
        altEnglishCompanyName: action.payload,
      };
    case Dispatch.UPDATE_JOB_TITLE:
      return {
        ...form,
        jobTitle: action.payload,
      };
    case Dispatch.UPDATE_ADDRESS:
      return {
        ...form,
        address: { ...form.address, [action.payload[0]]: action.payload[1] },
      };
    case Dispatch.UPDATE_PROMO_ADDRESS:
      return {
        ...form,
        address: action.payload,
        addressContinueBtn: false,
      };
    case Dispatch.RESET_DEFAULT_ADDRESS:
      return {
        ...form,
        address: blankForm.address,
      };
    case Dispatch.UPDATE_STATES:
      return {
        ...form,
        states: action.payload,
      };
    case Dispatch.UPDATE_TAX_FIELDS:
      return {
        ...form,
        taxPayerId: action.payload[1],
      };
    case Dispatch.UPDATE_PHONE:
      return {
        ...form,
        phoneNumber: action.payload,
      };
    case Dispatch.UPDATE_SELECTED_PROMO_ID:
      return {
        ...form,
        selectedPromoId: action.payload,
      };
    case Dispatch.UPDATE_DEFAULT_PROMO:
      return {
        ...form,
        defaultPromo: action.payload,
      };
    case Dispatch.UPDATE_ORACLE_EMPLOYEE:
      return {
        ...form,
        oracleEmployee: action.payload,
      };
    case Dispatch.UPDATE_PASSWORD:
      return {
        ...form,
        p1: action.payload,
      };
    case Dispatch.UPDATE_CONFIRM_PASSWORD:
      return {
        ...form,
        p2: action.payload,
      };
    case Dispatch.UPDATE_BILLING_INFO:
      return {
        ...form,
        billingAddress: action.payload[0],
        paymentOptions: action.payload[1],
      };
    case Dispatch.UPDATE_BASIC_DETAILS:
      return {
        ...form,
        language: action.payload[0],
      };
    case Dispatch.PAYMENT_RESPONSE:
      return {
        ...form,
        paymentResponse: action.payload,
      };
    case Dispatch.OPERATION_ID:
      return {
        ...form,
        operationId: action.payload[0],
        paymentStatus: action.payload[1],
      };

    case Dispatch.UPDATE_LOADING:
      return {
        ...form,
        loading_wr: action.payload,
      };

    case Dispatch.UPDATE_ACCOUNT_O4A_ERROR:
      return {
        ...form,
        createAccountO4AError: action.payload,
      };

    case Dispatch.PAYMENT_ERROR:
      return {
        ...form,
        paymentError: action.payload,
      };

    case Dispatch.ADDRESS_VALIDATION:
      return {
        ...form,
        addressValidation: action.payload,
      };
    case Dispatch.VALID_PHONE_NUMBER:
      return {
        ...form,
        validPhoneNumber: action.payload,
      };
    case Dispatch.UPDATE_URI:
      return {
        ...form,
        consoleUri: action.payload,
      };
    case Dispatch.PAYMENT_VERIFICATION_TIMES:
      return {
        ...form,
        numPaymentVerifyBtnClicked: action.payload,
      };

    case Dispatch.UPDATE_PHONE_COUNTRY_CODE:
      return {
        ...form,
        phoneCountryCode: action.payload,
      };

    case Dispatch.UPDATE_APIERROR:
      return {
        ...form,
        apiError: action.payload,
      };

    case Dispatch.UPDATE_API403ERROR:
      return {
        ...form,
        api403Error: action.payload,
      };
    case Dispatch.VALIDATE_EMAIL:
      return {
        ...form,
        validateEmail: action.payload,
      };
    case Dispatch.UPDATE_SHOW_BANNER:
      return {
        ...form,
        showBanner: action.payload,
      };

    case Dispatch.SHOW_ACCOUNT_NOT_UNIQUE:
      return {
        ...form,
        accountNotUnique: action.payload,
      };
    case Dispatch.UPDATE_NONAUTH:
      return {
        ...form,
        nonAuth: action.payload ? action.payload : false,
      };
    case Dispatch.UPDATE_NONAUTH403:
      return {
        ...form,
        nonAuth403: action.payload ? action.payload : false,
      };
    case Dispatch.UPDATE_NONPENDING:
      return {
        ...form,
        nonPending: action.payload ? action.payload : false,
        singleOrderState: action.payload,
      };
    case Dispatch.PLACE_DEFAULTS:
      return {
        ...form,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        username: action.payload.emailAddress,
        emailAddress: action.payload.emailAddress,
        orderNumber: action.payload.orderNumber,
        subscriptionIds: action.payload.subscriptionIds,
        dataCenterRegion: action.payload.dataCenterRegion,
      };
    case Dispatch.UPDATE_EMAIL_ADDRESS:
      return {
        ...form,
        username: action.payload,
        emailAddress: action.payload,
      };
    case Dispatch.OCI_TENANT:
      return {
        ...form,
        tenant: action.payload,
      };
    case Dispatch.UPDATE_TENANCY_NAME:
      return {
        ...form,
        tenancyName: action.payload,
      };
    case Dispatch.AZ_TENANT_ID:
      return {
        ...form,
        azTenantId: action.payload,
      };
    case Dispatch.SUBMIT_CREATE_TENANCY_INFO:
      return {
        ...form,
        processing: action.payload,
        submitting: action.payload,
      };
    case Dispatch.GETTING_AUTH:
      return {
        ...form,
        loading: action.payload,
      };

    case Dispatch.ORDER_HAS_EXPIRED:
      return {
        ...form,
        tokenHasExpired: action.payload,
      };
    case Dispatch.LINKING_FAILURE:
      return {
        ...form,
        linkingFailure: action.payload,
      };
    case Dispatch.INVALID_PARAMETER_LINKING_ERROR:
      return {
        ...form,
        invalidParameterLinkingFailure: action.payload.showError,
        invalidParamLinkingErrMsg: action.payload.errorMessage,
      };
    case Dispatch.NOT_AUTHENTICATED_LINKING_ERROR:
      return {
        ...form,
        notAuthenticatedLinkingFailure: action.payload.showError,
        notAuthenticatedLinkingErrMsg: action.payload.errorMessage,
      };
    case Dispatch.UPDATE_API_ERROR_DETAILS:
      return {
        ...form,
        opcRequestId: action.payload?.opcRequestId,
        errorTimestamp: action.payload?.errorTimestamp,
        errorMessage: action.payload.errorMessage,
      };
    case Dispatch.EXPERIMENTS_SET_LOADING:
      return {
        ...form,
        experiments: { ...form.experiments, loading: action.payload },
      };
    case Dispatch.EXPERIMENTS_SET_CAMPAIGNS:
      return {
        ...form,
        experiments: { campaigns: action.payload, loading: false },
      };
    case Dispatch.CLEAR_ERROR_STATE:
      return {
        ...form,
        invalidParameterLinkingFailure: false,
        invalidParamLinkingErrMsg: "",
        notAuthenticatedLinkingFailure: false,
        notAuthenticatedLinkingErrMsg: "",
        linkingFailure: false,
      };
    default: {
      throw new Error(`Unhandled action type ${action.type}`);
    }
  }
}

function FormProvider({ children, state, dispatch }: FormProviderProps) {
  const [state_blank, dispatch_blank] = React.useReducer(formReducer, blankForm);
  return (
    <FormStateContext.Provider value={state || state_blank}>
      <FormDispatchContext.Provider value={dispatch || dispatch_blank}>
        {children}
      </FormDispatchContext.Provider>
    </FormStateContext.Provider>
  );
}

function useFormState() {
  const context = React.useContext(FormStateContext);
  if (context === undefined) {
    throw new Error("useFormState must be used within a FormProvider");
  }
  return context;
}

function useFormDispatch() {
  const context = React.useContext(FormDispatchContext);
  if (context === undefined) {
    throw new Error("useFormDispatch must be used within a FormProvider");
  }
  return context;
}

export { FormProvider, useFormState, useFormDispatch };
