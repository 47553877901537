import React from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { FormHelperText, Grid, Stack } from "@mui/material";

import * as Messages from "../../codegen/Messages";

const DEBUG = process.env.DEBUG === "true";

export const ErrorFallback: React.FC<{ error?: Error }> = ({ error }) => (
  <Grid container>
    <Grid item xs={12}>
      <FormHelperText error={true}>
        <Stack direction="row" alignItems="center" gap={1}>
          <ErrorIcon color="error" fontSize="small" />
          {Messages.errorPage.unexpectedError()}
        </Stack>
      </FormHelperText>
    </Grid>
    {DEBUG && (
      <Grid item xs={12}>
        {error?.message}
      </Grid>
    )}
  </Grid>
);
