import * as React from "react";
import { Container } from "../../index";
import "./NotificationsContainer.scss";

export type NotificationsContainerProps = {
  headerTitle: string;
  content: React.ReactNode;
  footer?: React.ReactNode;
};

function NotificationsContainer({ headerTitle, content, footer }: NotificationsContainerProps) {
  return (
    <div className="notifications-cont">
      <div className="header">
        <h2>{headerTitle}</h2>
      </div>
      <Container>{content}</Container>
      <footer>{footer}</footer>
    </div>
  );
}

export default NotificationsContainer;
