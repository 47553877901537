import * as React from "react";
import { Icon, IconProps, Link, LinkProps, SpaceBetween, TextContent } from "../../index";
import { genShortUid } from "../../utils/guid";
import "./CustomLinks.scss";

export type CustomLinkProps = {
  linkText?: string;
  iconProps?: IconProps;
  linkProps?: LinkProps;
  infoText?: string;
  description?: string;
  bordered?: boolean;
};

const infoTextColor: string = "#DF00DF";

function CustomLink(props: CustomLinkProps) {
  const { linkText, infoText, description, iconProps, linkProps, bordered } = props;
  return (
    <SpaceBetween size="s">
      <div className="custom-link-content">
        {iconProps && (
          <div className="link-icon">
            <Icon {...iconProps} />
          </div>
        )}
        <SpaceBetween size="xxs" direction="vertical" className="custom-link-text">
          <SpaceBetween size="s" direction="horizontal">
            <Link {...linkProps} variant="secondary">
              {linkText}
            </Link>
            <TextContent>
              <small style={{ color: infoTextColor }}>{infoText}</small>
            </TextContent>
          </SpaceBetween>
          {description && (
            <TextContent>
              <div className="link-description-text">{description}</div>
            </TextContent>
          )}
        </SpaceBetween>
      </div>
      <div className={bordered === false ? "" : "row-divider"} />
    </SpaceBetween>
  );
}

export function LinkList(props: { items: CustomLinkProps[] }) {
  const { items } = props;
  return (
    <SpaceBetween size="m" direction="vertical" className="o4aws-react-space-between">
      {items.map(item => (
        <CustomLink {...item} key={genShortUid()} />
      ))}
    </SpaceBetween>
  );
}

export default CustomLink;
