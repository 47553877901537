import { Realm, Region, RegionToRealmMap } from "oci-console-regions";
import { OCI } from "../constants";
import { SessionStorage } from "../Type";
import { getWindow } from "./BrowserHelpers";

const ENDPOINT_VERSION_1 = "v1";
const ENDPOINT_VERSION_20160918 = "20160918";
const SERVICE_LOGIN = "login";
const SERVICE_CONSOLE = "console";
const SERVICE_IDENTITY = "identity";
const SERVICE_AUTHENTICATION = "auth";

export const mustNotEmptyNullOrUndefined = (name: string, value: any, errorMessage?: string) => {
  if (!value) {
    throw new Error(errorMessage || `${name} must not be empty, null or undefined.`);
  }
};

const realmTopLevels = new Map<Realm | string, string>([
  [Realm.R1, "oracleiaas.com"],
  [Realm.OC1, "oraclecloud.com"],
  [OCI, "oraclecloud.com"],
]);

export const getTopLevel = (region: string): string => {
  const regionEnum = region as Region;
  const realm = RegionToRealmMap[regionEnum];
  return realmTopLevels.get(realm) || realmTopLevels.get(region) || "";
};

export const getSanitizedRegion = (regionName: string): string => {
  return regionName.toLowerCase() === "us-seattle-1" ? "r1" : regionName;
};

export const buildServiceUrl = (
  serviceName: string,
  region: string,
  secondLevelDomain: string,
  version?: string,
  pattern?: string,
): string => {
  mustNotEmptyNullOrUndefined("serviceName", serviceName);
  mustNotEmptyNullOrUndefined("secondLevelDomain", secondLevelDomain);

  // take care of r1 special case where it doesn't use region name as sub-domain
  let serviceUrl = pattern || "https://{serviceName}.{region}.{secondLevelDomain}/{version}";

  serviceUrl = serviceUrl
    .replace(/{serviceName}/gi, serviceName)
    .replace(/{secondLevelDomain}/gi, secondLevelDomain)
    .replace(/{version}/gi, version || "")
    .replace(/\/$/, ""); // remove trailing "/"

  return region
    ? serviceUrl.replace(/{region}/gi, getSanitizedRegion(region))
    : serviceUrl.replace(/{region}\./gi, "");
};

/**
 * Returns the Login URL for the given regionName
 * @param regionName
 * @returns string
 */
export const buildLoginUrl = (regionName: string): string => {
  /**
   * Add a special case to point SOUP to beta unstable for r1-staging.
   * This is done to enable better testing in unstable regions
   */

  return buildServiceUrl(SERVICE_LOGIN, regionName, getTopLevel(regionName), ENDPOINT_VERSION_1);
};

/**
 * Returns the Console URL for the given regionName
 * @param regionName
 * @returns string
 */
export const buildConsoleUrl = (regionName: string): string => {
  return buildServiceUrl(SERVICE_CONSOLE, regionName, getTopLevel(regionName));
};

/**
 * Returns the Identity URL for the given regionName
 * @param regionName
 * @returns string
 */
export const buildIdentityUrl = (regionName: string): string => {
  return buildServiceUrl(
    SERVICE_IDENTITY,
    regionName,
    getTopLevel(regionName),
    ENDPOINT_VERSION_20160918,
  );
};

/**
 * Returns the Authorization URL for the given regionName
 * @param regionName
 * @returns string
 */
export const buildAuthorizationUrl = (regionName: string): string => {
  return buildServiceUrl(
    SERVICE_AUTHENTICATION,
    regionName,
    getTopLevel(regionName),
    ENDPOINT_VERSION_1,
  );
};

export function getParamFromQueryString(paramName: string): string | null {
  const paramValue = new URLSearchParams(getWindow().location.search).get(paramName);
  return paramValue !== null ? decodeURIComponent(paramValue) : null;
}

export const saveQueryStringToSessionStorage = (replaceQueryString?: boolean) => {
  const uri = window.location.href;
  const urlParts = uri.toString().split("?");
  if (
    urlParts.length > 1 &&
    (replaceQueryString || !sessionStorage.getItem(SessionStorage.QUERY_STRING))
  ) {
    sessionStorage.setItem(SessionStorage.QUERY_STRING, urlParts[1]);
  }
};

export const getParamFromStoredQueryString = (paramName: string): string | null => {
  const queryString = window.sessionStorage.getItem(SessionStorage.QUERY_STRING) || "";
  return new URLSearchParams(queryString).get(paramName);
};
