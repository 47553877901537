import React, { ReactElement } from "react";
import Icon from "../Icon/Icon";
import classnames from "classnames";
import Button from "../Button/Button";
import HeaderContainer from "../../components/Headers/HeaderContainer";
import TroubleShootingModal from "./TroubleshootingModal";
import { Environment, signup_environment } from "../../config/apiConfig";
import { SHOW_TROUBLESHOOTING_MODAL } from "../../constants";
import { CloudLinkType, IconType } from "../../Type";
import "./Modal.scss";
import "./AwsModal.scss";
import { getCloudType } from "../../utils";

export type ModalProps = {
  btnFn?: any;
  btn2Fn?: any;
  buttonName?: string;
  button2Name?: string;
  closeBtn?: boolean;
  fullContainer?: boolean;
  noPadding?: boolean;
  onClose?: any;
  show: boolean;
  title?: string | ReactElement;
  wrapperClass?: string;
  showHeader?: boolean;
};

const Modal: React.FC<ModalProps> = ({
  btn2Fn,
  btnFn,
  button2Name,
  buttonName,
  children,
  closeBtn,
  fullContainer,
  onClose,
  show,
  showHeader,
  title,
  wrapperClass,
}) => {
  const cloudType = getCloudType();
  let containerClasses = classnames(
    {
      modalContainer: true,
      errorContainer: fullContainer,
    },
    wrapperClass,
  );

  let errorContainerClassName = "errorContentContainer";
  let modalClassName = "modal";
  let contentClassName = "modal-content";
  let actionsClassName = "modal-actions";
  let button1ClassName = "modal-btn modal-primary-btn";
  let button2ClassName = "modal-btn modal-secondary-btn";
  let titleClassName = "modal-title";

  if (cloudType === CloudLinkType.AWS) {
    containerClasses = classnames(
      {
        awsModalContainer: true,
        errorContainer: fullContainer,
      },
      wrapperClass,
    );
    errorContainerClassName = "awsErrorContentContainer";
    modalClassName = "awsModal";
    contentClassName = "awsModal-content";
    actionsClassName = "awsModal-actions";
    button1ClassName = "awsModal-btn awsModal-primary-btn";
    button2ClassName = "awsModal-btn awsModal-secondary-btn";
    titleClassName = "awsModal-title";
  }

  if (!show) {
    return null;
  }

  return (
    <div className={containerClasses} data-testid="modal-container">
      {showHeader && <HeaderContainer includeLogo={false} />}

      <div className={errorContainerClassName}>
        <div id="modal" className={modalClassName}>
          <h2 className={titleClassName} data-testid="modal-container-title">
            {title}
          </h2>

          {closeBtn && <Icon name={IconType.CLOSE} onClick={onClose} testId="close-icon" />}

          <div className={contentClassName} data-testid="modal-content">
            {children}
          </div>

          {buttonName || button2Name ? (
            <div className={actionsClassName}>
              {buttonName && (
                <Button
                  type="solid"
                  className={button1ClassName}
                  onClick={btnFn ? btnFn : onClose}
                  text={buttonName}
                  data-testid="button1"
                ></Button>
              )}
              {button2Name && (
                <Button
                  type="solid"
                  className={button2ClassName}
                  onClick={btn2Fn ? btn2Fn : onClose}
                  text={button2Name}
                  data-testid="button2"
                ></Button>
              )}
            </div>
          ) : (
            <div className="modal-bottom-spacing" />
          )}
        </div>

        {signup_environment !== Environment.PROD_ORACLE && SHOW_TROUBLESHOOTING_MODAL && (
          <TroubleShootingModal modalClassName={modalClassName} />
        )}
      </div>
    </div>
  );
};

export default Modal;
