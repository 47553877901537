import { TableProps } from "../../index";
import { removeTags } from "../../utils/string";

const JOIN_CHAR = "◬";

export type SortArgs<TEntity = any> = {
  direction: "asc" | "desc" | "";
  sortingColumnName: keyof TEntity;
};

export function getSortedData<TEntity>(
  sort: SortArgs<TEntity>,
  data: TEntity[],
  cellSortingComparator?: (_a: TEntity, _b: TEntity) => number,
): TEntity[] {
  if (!sort?.sortingColumnName || !sort.direction) {
    return data;
  }

  return data.sort(
    (a, b) =>
      (cellSortingComparator ?? defaultSortCompare(sort))(a, b) *
      (sort.direction === "asc" ? 1 : -1),
  );
}

/**
 * Gets a sorted copy of the data array based on the state of the any. Called
 * after changes are made to the filtered data or when sort changes are emitted from any.
 * By default, the function retrieves the active sort and its direction and compares data
 * by retrieving data using the sortDataAccessor. May be overridden for a custom implementation
 * of data ordering.
 *
 * @param data The array of data that should be sorted.
 * @param sort The connected any that holds the current sort state.
 */
export function defaultSortCompare<TEntity>(sort: SortArgs<TEntity>) {
  const { sortingColumnName } = sort;
  if (!sortingColumnName) {
    return (_a: TEntity, _b: TEntity): number => 0;
  }

  return (a: TEntity, b: TEntity): number => {
    let valueA = sortDataAccessor(a, sortingColumnName);
    let valueB = sortDataAccessor(b, sortingColumnName);

    const valueAType = typeof valueA;
    const valueBType = typeof valueB;

    if (valueAType !== valueBType) {
      if (valueAType === "number") {
        valueA += "";
      }
      if (valueBType === "number") {
        valueB += "";
      }
    }

    let comparatorResult = 0;
    if (valueA > valueB) {
      comparatorResult = 1;
    } else if (valueA < valueB) {
      comparatorResult = -1;
    }

    return comparatorResult;
  };
}

/**
 * Returns the data for the prop in the entity
 *
 * @param entity Entity to get the value
 * @param prop Header Id
 */
export function sortDataAccessor<TEntity>(entity: TEntity, prop: keyof TEntity): string | number {
  const value = entity[prop];
  if (!isNumber(value)) {
    return value as unknown as string;
  }
  const numberValue = Number(value);
  return numberValue < Number.MAX_SAFE_INTEGER ? numberValue : (value as unknown as number);
}

export function sortColumnDefinitions<TEntity>(
  cd: TableProps.ColumnDefinition<TEntity>[],
  columnsOrder: readonly (keyof TEntity)[],
): void {
  if (!columnsOrder?.length) return;

  const findIndexCache: { [key: string]: number } = {};
  function findIndex(id: string): number {
    return findIndexCache[id] >= 0
      ? findIndexCache[id]
      : (findIndexCache[id] = columnsOrder.findIndex(co => id === co));
  }

  cd.sort((a: TableProps.ColumnDefinition<TEntity>, b: TableProps.ColumnDefinition<TEntity>) => {
    const indexA = findIndex(a.id);
    if (indexA === -1) return 1;
    const indexB = findIndex(b.id);
    if (indexB === -1) return -1;
    return indexA - indexB;
  });
}

export type PaginatorArg = {
  currentPageIndex: number;
  pageSize: number;
};

export function getPagedData<TEntity>(paginator: PaginatorArg, data: TEntity[]): TEntity[] {
  if (!paginator) {
    return data;
  }
  const startIndex = (paginator.currentPageIndex - 1) * paginator.pageSize;
  return data.splice(startIndex, paginator.pageSize);
}

export function getFilteredData<TEntity>({
  caseSensitive,
  data,
  search,
}: {
  caseSensitive: boolean;
  data: TEntity[];
  search?: string;
}): TEntity[] {
  if (!search?.length) {
    return data;
  }

  const searchTerms = (!caseSensitive ? search.toLowerCase() : search)
    .split(" ")
    .filter(searchTerm => !!searchTerm);

  return data.filter((entity: TEntity) =>
    filterPredicate({ caseSensitive, data: entity, searchTerms }),
  );
}

/**
 * Checks if a data object matches the data source's filter string. By default, each data object
 * is converted to a string of its properties and returns true if the filter has
 * at least one occurrence in that string. By default, the filter string has its whitespace
 * trimmed and the match is case-insensitive. May be overridden for a custom implementation of
 * filter matching.
 *
 * @param data Data object used to check against the filter.
 * @param filter Filter string that has been set on the data source.
 * @returns Whether the filter matches against the data
 */
export function filterPredicate<TEntity>({
  caseSensitive,
  data,
  searchTerms,
}: {
  caseSensitive: boolean;
  data: TEntity;
  searchTerms: string[];
}): boolean {
  const emptyObjectStr = {}.toString();
  let dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
    const term = filterDataAccessor(data, key as keyof TEntity);
    return term && !term.includes(emptyObjectStr)
      ? `${currentTerm + term}${JOIN_CHAR}`
      : currentTerm;
  }, "");
  if (!caseSensitive) {
    dataStr = dataStr.toLowerCase();
  }
  return searchTerms.some((tf: string) => dataStr.indexOf(tf) !== -1);
}

export function filterDataAccessor<TEntity>(data: TEntity, prop: keyof TEntity): string {
  return removeTags(
    Array.isArray(data[prop])
      ? (data[prop] as unknown as any[]).map(filterStringValRep).join(JOIN_CHAR)
      : filterStringValRep(data[prop]),
  );
}

function filterStringValRep(v: number | boolean | string | null | undefined | any): string {
  if (!v) {
    return "";
  }
  switch (typeof v) {
    case "number":
    case "boolean":
      return (v as unknown as number | boolean).toString();
    case "object":
      return Object.values(v).join(JOIN_CHAR);
    default:
      return v;
  }
}

function isNumber(n: number | any): boolean {
  return !Number.isNaN(Number(n));
}
