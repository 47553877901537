import { OraclePublicCloudAuthenticatorSingleton } from "../fluorine/authenticator/OraclePublicCloudAuthenticatorSingleton";

export default class MetricsClient {
  private metricsClient: (realm: string, name: string, value: number) => void;

  constructor(private realm: string) {
    this.metricsClient = OraclePublicCloudAuthenticatorSingleton.getInstance(this.realm).Config.sendMetric;
  }

  public sendMetric(name: string, value: number) {
    if (this.metricsClient) {
      this.metricsClient(this.realm, name, value);
    } else {
      const logger = OraclePublicCloudAuthenticatorSingleton.getInstance(this.realm).getLoggerClient();
      logger.error(`Could not send metric ${name}, ${value} because Metrics Client is not provided.`);
    }
  }
}
