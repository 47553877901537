import React from "react";
import { RenderMetricsProps, withRenderMetrics } from "../../CourierService/RenderMetrics";

const AzureHeader = withRenderMetrics(({ renderMetrics }: RenderMetricsProps) => {
  React.useEffect(() => {
    renderMetrics && renderMetrics.rendered("Header");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <header className="brandingBar" data-testid="header-container">
      <div className="oLogoWords title7 padding-left-med">
        {"Oracle Database Service for Azure"}
      </div>
    </header>
  );
});

export default AzureHeader;
