import * as React from "react";
import { Pagination, PaginationProps, TableProps, TextFilter } from "../../index";
import ResourceListTable, { ResourceListTableProps } from "../ResourceListTable/ResourceListTable";
import "./ResourceListTableDynamic.scss";
import useDynamicTableDataSource from "./useResourceListTableDynamicData";

export type DynamicTableColumnDefinition<TEntity> = Omit<
  TableProps.ColumnDefinition<TEntity>,
  "sortingField" | "id" | "cell" | "header"
> & {
  cell?(_item: TEntity): React.ReactNode;
  header?: React.ReactNode;
  id: keyof TEntity;
  sortingDisabled?: boolean;
};

export type ResourceListTableDynamicProps<TEntity = any> = {
  ariaLabels?: TableProps.AriaLabels<TEntity> &
    PaginationProps.Labels & { filteringPlaceHolder?: string };
  columnDefinitions?: readonly DynamicTableColumnDefinition<TEntity>[];
  columnsOrder?: (keyof TEntity)[];
  dataSource: readonly TEntity[];
  filterCaseSensitive?: boolean;
  highlightSearch?: boolean;
  onFilterChanged?(_filterText: string): void;
  pageSize?: number;
  refreshEnabled?: boolean;
  visibleColumns?: readonly (keyof TEntity)[];
} & Omit<
  ResourceListTableProps,
  "items" | "filter" | "pagination" | "ariaLabels" | "columnDefinitions" | "visibleColumns"
>;

function ResourceListTableDynamic<TEntity>(props: ResourceListTableDynamicProps<TEntity>) {
  const {
    ariaLabels,
    columnDefinitions,
    columnsOrder,
    dataSource,
    filterCaseSensitive,
    highlightSearch,
    loading,
    onFilterChanged,
    pageSize,
    visibleColumns,
  } = props;

  const {
    columnMetadata,
    filterText,
    handleFilterTextChange,
    handlePageChange,
    handleSortingChange,
    items,
    paginator,
    sort,
  } = useDynamicTableDataSource({
    baseColumnDefinitions: columnDefinitions,
    baseVisibleColumns: visibleColumns,
    columnsOrder,
    dataSource,
    filterCaseSensitive: filterCaseSensitive ?? false,
    highlightSearch,
    initialPageSize: pageSize ?? 10,
    onFilterChanged,
  });

  return (
    <ResourceListTable
      {...props}
      columnDefinitions={columnMetadata.columnDefinitions}
      visibleColumns={columnMetadata.visibleColumns}
      onSortingChange={handleSortingChange}
      sortingDescending={sort?.direction === "desc"}
      sortingColumn={sort?.sortingColumn}
      sortingDisabled={loading}
      items={items}
      filter={
        <TextFilter
          filteringPlaceholder={ariaLabels?.filteringPlaceHolder}
          filteringText={filterText}
          onChange={handleFilterTextChange}
        />
      }
      pagination={
        !!paginator.pageCount && (
          <Pagination
            currentPageIndex={paginator.currentPageIndex}
            pagesCount={paginator.pageCount}
            ariaLabels={{
              nextPageLabel: ariaLabels?.nextPageLabel,
              previousPageLabel: ariaLabels?.previousPageLabel,
              pageLabel: ariaLabels?.pageLabel,
            }}
            onChange={handlePageChange}
          />
        )
      }
    />
  );
}

export default ResourceListTableDynamic;
