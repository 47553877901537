//Routing paths
export const ACTIVATION_PATH = "/azure/activate";
export const LINKING_PATH = "/azure/linking";
export const SIGN_UP_PATH = "/azure/signup";
export const GUIDED_PATH = "/guided";
export const AUTOMATED_PATH = "/automated";
export const AZURE_ROOT_PATH = "/azure";
export const AWS_ROOT_PATH = "/aws";
export const AWS_LINKING_PATH = "/aws/linking";
export const AWS_SIGNUP_PATH = "/aws/signup";
export const AWS_ACTIVATION_PATH = "/aws/activate";
export const GCP_ACTIVATION_PATH = "/gcp/activate";

export namespace GcpPaths {
  export const ROOT_PATH = "/gcp";
  export const SIGNUP_PATH = `${ROOT_PATH}/signup`;
  export const WILDCARD_PATH = `${ROOT_PATH}/*`;
}

//Common constants
export const COURIER_SERVICE_NAMESPACE = "o4a_signup_client";
export const BLOCK_SUPPORT_BUTTON = false;
export const SHOW_TROUBLESHOOTING_MODAL = true;
export const INDEXED_DB_MISSING_ERROR = "No available storage method found.";
export const TOO_MANY_REQUESTS_ERROR = "Too many requests";
export const ACCOUNT_NOT_CREATED = "account not created";
export const PRE_HENOSIS_ERROR_MSG = "Only Henosis tenancy is supported";
export const INVALID_PARAMETER_ERROR_CODE = "InvalidParameter";
export const NOT_AUTHENTICATED_ERROR_CODE = "NotAuthenticated";
export const INVALID_PARAMETER_ROOT_COMP_ERROR_MSG =
  "Only root compartments (tenancies) are supported for Multicloud link creation.";
export const ORACLE_DOMAIN = "oracle.com";
export const fiveMinTimer = 300000;
export const ACTIVATION_TOKEN = "activationToken";
export const fieldLengths = {
  addressLinesCharLength: 240,
  otherAddressDetailsCharLength: 60,
  nameCharLength: 200,
  companyNameCharLength: 360,
};
export const OrderLifeCycleState = {
  active: "ACTIVE",
  activating: "ACTIVATING",
  creating: "CREATING",
  deleted: "DELETED",
  hold: "HOLD",
  pendingActivation: "PENDING_ACTIVATION",
};
export const copyright = () => {
  const year = new Date().getFullYear();
  return `Copyright © 2016-${year}, Oracle and/or its affiliates. All rights reserved.`;
};

//URLs
export const SUPPORT_URL = "https://docs.oracle.com/iaas/Content/multicloud/help.htm"; // TODO: old support link "https://www.oracle.com/support/contact.html"
export const ODSA_TROUBLESHOOTING_GUIDE_URL =
  "https://docs.oracle.com/en-us/iaas/Content/multicloud/odsa_troubleshooting.htm";
export const ODSA_PREREQUISITES_URL =
  "https://docs.oracle.com/en-us/iaas/Content/multicloud/prerequisites.htm";
export const FAQ_URL = "https://www.oracle.com/cloud/free/faq.html";
export const CYBERSOURCE_PRIVACY_URL = "http://www.cybersource.com/privacy";
export const TermsOfUseLinks = {
  contractsLink: "http://oracle.com/contracts",
  oracleGeneralPrivacyLink: "https://www.oracle.com/legal/privacy/privacy-policy.html",
  termsOfUseLink: "https://www.oracle.com/us/legal/terms/index.html",
  privacyLink: "https://www.oracle.com/legal/privacy/index.html",
  japanPrivacyLink:
    "https://www.oracle.com/jp/legal/information-protection/basic-policy/public-notice/index.html",
  affiliatesGloballyLink: "https://www.oracle.com/us/corporate/contact/global-070511.html",
  thirdPartyLink: "https://www.oracle.com/corporate/contact/suppliers.html",
  koreaPrivacyLink: "https://www.oracle.com/us/legal/privacy/privacy-policy/index.html",
};
export const SESSION_URL = "sessionURL";
export const LOCALHOST_URL = "https://localhost:3000";
export const OCI = "oci";

//Azure Constants
export const AZ_ACCESS_DENIED_ERROR_CODE = "access_denied";
export const AZ_CONSENT_ERROR_CODE = "consent_required";
export const AZ_INTERACTION_IN_PROGRESS = "interaction_in_progress";
export const AZ_BLOCK_NESTED_POPUPS = "block_nested_popups";
export const AZ_POPUP_WINDOW_ERROR = "popup_window_error";
export const AZ_USER_CANCELLED = "user_cancelled";
export const INVALID_PARAMETER_EXIST_AZURE_ERROR_MSG =
  "Specified Azure tenant ID is already associated with another Multicloud link instance.";
export const INVALID_PARAMETER_EXIST_OCI_ERROR_MSG =
  "Invalid cloudLinkType. Multicloud link of type 'Azure' already exists in this tenancy";
export const INSUFFICIENT_PRIVILEGES_ERROR_MESSAGE =
  "Insufficient privileges to complete the operation";
export const PRINCIPAL_ID_PERMISSIONS_ERROR_MESSAGE =
  "PrincipalId does not have the right permission";
export const AZ_ARM_API_SCOPE = "https://management.azure.com//.default openid profile";
export const AZURE_SQL_URL = "https://azure.microsoft.com/en-us/products/azure-sql/database/";
export const AZURE_AUTHORITY = "https://login.microsoftonline.com/common/";
export const TENANCIES_O4A = "tenanciesO4A";

// AWS Constants
export const AUTH_CODE_QUERY_PARAM = "code";
export const AWS_ACCOUNT_ID_PARAM = "accountId";
export const ACCOUNTID_FIELD_HINT_URL =
  "https://docs.aws.amazon.com/general/latest/gr/acct-identifiers.html";
export const documentationLink = "https://docs.oracle.com/iaas/Content/multicloud/odsaws-intro.htm";
export const supportLink = "https://docs.oracle.com/en-us/iaas/Content/multicloud/help.htm"; // TODO: Change once we have a specific support link
export const INVALID_PARAMETER_EXIST_AWS_ERROR_MSG =
  "Specified AWS Account id is already associated with another Multicloud link instance.";
export const INVALID_PARAMETER_EXIST_OCI_ERROR_AWS_MSG =
  "Invalid cloudLinkType. Multicloud link of type 'AWS' already exists in this tenancy";
export const CFN_STACK_NAME = "odsaws";
export const CFN_STACK_URI =
  "https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?";
export const CFN_TERMS_URL = "https://docs.oracle.com/iaas/Content/multicloud/odsaws_overview.htm ";
export const CFN_PREREQUISITES =
  "https://docs.oracle.com/iaas/Content/multicloud/odsaws_prerequisites.htm";
export const TENANCIES_O4AWS = "tenanciesO4Aws";

//GCP Constants
export const GCP_ORGANIZATION_ID_PARAM = "organizationId";
export const INVALID_PARAMETER_EXIST_GCP_ERROR_MSG =
  "Specified Gcp project ID is already associated with another Multicloud link instance.";
export const INVALID_PARAMETER_EXIST_OCI_ERROR_GCP_MSG =
  "Invalid cloudLinkType. Multicloud link of type 'GCP' already exists in this tenancy";
export const GCP_ARM_API_SCOPE = "https://cloudresourcemanager.googleapis.com/v1/projects";
export const TENANCIES_O4GCP = "tenanciesO4Gcp";

// Signup API
export const CREATE_ACCOUNT_O4A = "createAccountO4A";
export const CREATE_ACCOUNT_O4AWS = "createAccountO4AWS";

// Query Parameters
export const TENANT_QUERY_PARAM_KEY = "tenant";

// Gcp Constants
export const GCP_SCOPES = [
  "openid",
  "https://www.googleapis.com/auth/iam",
  "https://www.googleapis.com/auth/userinfo.profile",
  "https://www.googleapis.com/auth/userinfo.email",
  "https://www.googleapis.com/auth/cloud-platform.read-only",
];

export const gcpProjectsURL = "https://cloudresourcemanager.googleapis.com/v1/projects";
export const gcpOrganizationURL =
  "https://cloudresourcemanager.googleapis.com/v1beta1/organizations";
export const gcpServiceAccountURL =
  "https://iam.googleapis.com/v1/projects/{project_id}/serviceAccounts";
