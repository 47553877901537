class UrlPlaceholders {
  public static readonly DomainPlaceholder = "<domain>";

  public static readonly RegionPlaceholder = "<region>";

  public static readonly ClientIdPlaceholder = "<client_id>";

  public static readonly ScopePlaceholder = "<scope>";

  public static readonly RedirectUriPlaceholder = "<redirect_uri>";

  public static readonly LogoutUriPlaceholder = "<logout_uri>";

  public static readonly CodeChallengePlaceholder = "<code_challenge>";
}

export default UrlPlaceholders;
