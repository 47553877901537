import { OraclePublicCloudAuthenticatorSingleton } from "../fluorine/authenticator/OraclePublicCloudAuthenticatorSingleton";
import { v4 as uuidV4 } from "uuid";

interface HttpHeaders extends Headers {
  entries: () => Iterator<string[]>;
}

/**
 * Edge/IE 11/Chrome/Latest Firefox supports ".forEach"
 * IE/Chrome/4x Firefox supports ".entries" (latest Edge drop supports for ".entries")
 *
 * To cover all browsers, we will have to support both loop function
 */
export const headersForEach = (headers: Headers, callback: (value: string, name: string) => void) => {
  if (headers.forEach) {
    headers.forEach(callback);
  } else {
    const headerEntries = (headers as HttpHeaders).entries();
    let headerEntry = headerEntries.next();
    while (!headerEntry.done) {
      const headerName = headerEntry.value[0];
      const headerValue = headerEntry.value[1];
      callback(headerValue, headerName);
      headerEntry = headerEntries.next();
    }
  }
};

/**
 * Case insensitive check if headers contains the header key
 */
export const headersHas = (headers: Headers, headerKey: string): boolean => {
  let has = false;
  headerKey = headerKey.toLowerCase();
  headersForEach(headers, (_, name) => {
    if (name.toLowerCase() === headerKey) {
      has = true;
      return;
    }
  });

  return has;
};

export const createSessionRequestId = async (realm: string): Promise<string> => {
  const sessionId = OraclePublicCloudAuthenticatorSingleton.getInstance(realm).SessionId;
  const requestId = uuidV4().replace(/-/g, "");
  return `${sessionId}/${requestId}`;
};
