import * as React from "react";
import { IconType } from "../../Type";
import "./Icon.css";

type IconProps = {
  name: IconType;
  onClick?: any;
  testId?: string;
};

class Icon extends React.Component<IconProps> {
  render() {
    return (
      <div
        className={"icon " + this.props.name}
        onClick={this.props.onClick}
        data-testid={this.props.testId}
      ></div>
    );
  }
}

export default Icon;
