/* eslint-disable react/button-has-type */
/* eslint-disable no-restricted-syntax */
import * as React from "react";
import "./Button.css";
import CircularLoader from "../CircularLoader/CircularLoader";

interface ButtonProps {
  className?: string;
  disabled?: boolean;
  id?: string;
  loading?: boolean;
  onClick?: () => void;
  style?: {};
  text?: string;
  type?: string;
}

// @ts-ignore
function _objectWithoutProperties(obj, keys) {
  const target = {};
  for (const i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    // @ts-ignore
    target[i] = obj[i];
  }
  return target;
}

const Button = (props: ButtonProps) => {
  const { type, text, loading } = props;
  const rest = _objectWithoutProperties(props, ["type", "text", "loading", "href"]);

  return (
    <div className="oci-button">
      <button className={type} {...rest}>
        {loading && <CircularLoader className="loader-sm" />}
        {text}
      </button>
    </div>
  );
};

export default Button;
