import { CloudLinkType } from "../Type";
import { getCloudType } from "../utils/CommonUtil";
import CourierTransport, { CourierServiceMetadata } from "./CourierServiceTransport";

export enum Metrics {
  CaptchaTokenGenerationExpiration = "CaptchaTokenGeneration.Count.Metrics.Expire",
  CaptchaTokenGenerationFailure = "CaptchaTokenGeneration.Count.Metrics.Failure",
  CaptchaTokenGenerationLoad = "CaptchaTokenGeneration.Count.Metrics.Load",
  CaptchaTokenGenerationSuccess = "CaptchaTokenGeneration.Count.Metrics.Success",
  CountrySelectionCount = "Country.Selection.Count.Metrics",
  CreateAccountAccepted = "CreateAccount.Count.Metrics.Accept",
  CreateAccountDuplicate = "CreateAccount.Count.Metrics.Duplicate",
  CreateAccountFailure = "CreateAccount.Count.Metrics.Fail",
  CreateAccountFault = "CreateAccount.Count.Metrics.Fault",
  CreateAccountInvalid = "CreateAccount.Count.Metrics.InvalidValue",
  CreateAccountPaymentsMissing = "CreateAccount.Count.Metrics.MissingPaymentOptions",
  CreateAccountRejected = "CreateAccount.Count.Metrics.Reject",
  CreateAccountReview = "CreateAccount.Count.Metrics.Review",
  CreateAccountSuccess = "CreateAccount.Count.Metrics",
  CreateAccountUnauthorized = "CreateAccount.Count.Metrics.Unauthorized",
  ExperimentsGenFail = "Experiments.MaximizerGeneration.Fail",
  ExperimentsGenSuccess = "Experiments.MaximizerGeneration.Success",
  ExperimentsSendActionFail = "Experiments.MaximizerGeneration.Fail",
  ExperimentsSendActionSuccess = "Experiments.MaximizerGeneration.Success",
  FetchFeaturesManifestFail = "FeaturesManifest.Fail",
  OPAYClosed = "OPayModal.Count.Metrics.Closed",
  OPAYFailure = "OPayModal.Count.Metrics.Fail",
  OPAYSuccess = "OPayModal.Count.Metrics",
  OracleEmployee = "OracleEmployee.Count.Metrics",
  SignupDifferentAccount = "Signup.UseDifferentAccount.Count",
  SignupExistingTenancy = "Signup.OciLandingPage.ExistingTenancy.Count",
  SignupLinkingAzureLinkedFailure = "Signup.Linking.Failure.AzureLinked.Count",
  SignupLinkingAwsLinkedFailure = "Signup.Linking.Failure.AwsLinked.Count",
  SignupLinkingGcpLinkedFailure = "Signup.Linking.Failure.GcpLinked.Count",
  SignupLinkingFailure = "Signup.Linking.Failure.Count",
  SignupLinkingInvalidParameterFailure = "Signup.Linking.Failure.InvalidParameter.Count",
  SignupLinkingNonHenosisFailure = "Signup.Linking.Failure.NonHenosis.Count",
  SignupLinkingNonTenancyFailure = "Signup.Linking.Failure.NonTenancy.Count",
  SignupLinkingNotAuthenticatedFailure = "Signup.Linking.Failure.NotAuthenticated.Count",
  SignupLinkingOciLinkedFailure = "Signup.Linking.Failure.OciLinked.Count",
  SignupLinkingSuccess = "Signup.Linking.Success.Count",
  SignupNewTenancy = "Signup.OciLandingPage.NewTenancy.Count",
  ValidateAccountNameDuplicate = "ValidateAccountName.Count.Metrics.Duplicate",
}

export enum MetricPrefixes {
  ActivationBanner = "ActivationBanner",
  ActivationCreateAccount = "Activation.CreateAccount",
  ActivationSubmit = "ActivationSubmit",
  ActivationValidateTenancy = "ActivationValidateTenancyName",
  ActivationValidateToken = "Activation.TokenValidation",
  AwsPreReqValidation = "AwsAuth.PreReqValidation",
  Banner = "Banner",
  CreateAccount = "CreateAccount",
  Email = "Email",
  FetchCloudLinks = "FetchCloudLinks",
  FetchProjects = "FetchProjects",
  FetchServiceAccount = "FetchServiceAccounts",
  fetchOrganizationId = "FetchOrganizations",
  Payments = "Payments",
  PostCloudLinks = "PostCloudLinks",
  Regions = "Region",
  RegionsByTenancy = "RegionsByTenancy",
  SendAssociatedAccount = "SendAssociatedAccountsByEmail",
  SignupCreateAccount = "Signup.CreateAccount",
  SignupLinking = "Signup.Linking",
  States = "States",
  Subscription = "Subscription",
  ValidateAccountName = "ValidateAccountName",
  VerifyCaptchaToken = "VerifyCaptchaToken",
}

export enum AuthMetrics {
  AuthCount = "Auth.Authenticate.Count",
  AuthFailure = "Auth.Authenticate.Failure",
  AuthInitError = "Auth.Init.Failure",
  AuthSuccess = "Auth.Authenticate.Success",
  OCIAuthCount = "OCIAuth.Authenticate.Count",
  OCIAuthInitError = "OCIAuth.Init.Failure",
  OCIFailure = "OCIAuth.Authenticate.Failure",
  OCILogout = "OCIAuth.Logout.Count",
  OCISuccess = "OCIAuth.Authenticate.Success",
}

export enum PerformanceMetrics {
  // Azure Metrics
  AzureActivationPageAutomated = "Render.Azure.Activation.Automated",
  AzureActivationPageGuided = "Render.Azure.Activation.Guided",
  AzureApp = "Render.AzureApp",
  AzureLandingPage = "Render.Azure.LandingPage",
  AzureOciLandingPagePageGuided = "Render.Azure.OciLandingPage.Guided",
  AzureSignupPageAutomated = "Render.Azure.Signup.Automated",
  AzureSignupPageGuided = "Render.Azure.Signup.Guided",
  // AWS Metrics
  AwsApp = "Render.AwsApp",
  AwsLandingPage = "Render.Aws.LandingPage",
  AwsWizard = "Render.AwsWizard",
  // FCP Metrics
  FCPActivation = "Render.Activation",
  FCPOciLandingPage = "Render.OciLandingPage",
  FCPSignup = "Render.Signup",
  // GCP Metrics
  GcpLandingPage = "Render.Gcp.LandingPage",
  GcpSignupPage = "Render.Gcp.SignupPage",
  // OCI Metrics
  OciLandingPageAutomated = "Render.Oci.LandingPage.Automated",
  OciLandingPageGuided = "Render.Oci.LandingPage.Guided",
}

export enum AvailabilityMetrics {
  ActivationFormAutomated = "ActivationForm.Automated.Availability",
  ActivationFormGuided = "ActivationForm.Guided.Availability",
  ActivationHomeAutomated = "ActivationHome.Automated.Availability",
  ActivationHomeGuided = "ActivationHome.Guided.Availability",
  App = "App.Availability",
  AwsLandingPage = "OdsawsLandingPage.Availability",
  GcpLandingPage = "OdsgcpLandingPage.Availability",
  GcpSignupPage = "OdsgcpSignupPage.Availability",
  O4ALandingPage = "O4ALandingPage.Availability",
  O4ALandingPage_API = "O4ALandingPageWithAPI.Availability",
  OciLandingPageAutomated = "OciLandingPage.Automated.Availability",
  OciLandingPageGuided = "OciLandingPage.Guided.Availability",
  SignupAutomated = "Signup.Automated.Availability",
  SignupGuided = "Signup.Guided.Availability",
  UberLandingPage = "UberLandingPage.Availability",
}

export enum ApiMetricType {
  _2xx = "2xx",
  _4xx = "4xx",
  _403 = "403",
  _5xx = "5xx",
  Exception = "Exception",
  Latency = "Latency",
  ExpiredToken = "ExpiredToken",
  SuccessiveFailure = "SuccessiveFailure",
}

export function emitEvent(name: string, value = 1, metadata?: CourierServiceMetadata) {
  CourierTransport.processMetric(name, value, metadata);
}

export function emitLatencyEvent(name: string, startTime: number) {
  const latency = new Date().getTime() - startTime;
  CourierTransport.processMetric(name, latency);
}

export function emitCloudSpecificEvent(
  events: {
    name: string;
    value?: number;
    cloudType?: CloudLinkType;
    startTime?: number;
    metadata?: CourierServiceMetadata;
  }[],
) {
  events.forEach(event => {
    const eventName = `${(event.cloudType || getCloudType()).toLowerCase()}_${event.name}`;
    if (event.startTime) {
      emitLatencyEvent(eventName, event.startTime);
    } else {
      emitEvent(eventName, event.value || 1, event.metadata);
    }
  });
}

export function getApiMetricName(MetricPrefix: MetricPrefixes, apiMetricType: ApiMetricType) {
  if (apiMetricType === ApiMetricType.Latency) {
    return `${MetricPrefix}.${ApiMetricType.Latency}`;
  } else {
    return `${MetricPrefix}.Count.Metrics.${apiMetricType}`;
  }
}
