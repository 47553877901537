// Legacy Event Names

//Common event Names
export const ACTIVATION_PAGE = "O4A Activation Page";
export const APP_NAME = "Multi Cloud Sign-Up";
export const COUNT = "Count";
export const GET_CLOUD_LINK_FAILED = "Get Multicloud Link Failed";
export const GET_CLOUD_LINK_SUCCESS = "Get Multicloud Link Success";
export const GET_PROJECTS_FAILED = "Get projects Failed";
export const GET_PROJECTS_SUCCESS = "Get projects Success";
export const GET_SERVICE_ACCOUNTS_FAILED = "Get service accounts Failed";
export const GET_SERVICE_ACCOUNTS_SUCCESS = "Get service accounts Success";
export const GET_ORGANIZATION_SUCCESS = "Get organizations Success";
export const GET_ORGANIZATION_FAILED = "Get organizations Success";
export const AWS_INIT_FAILURE = "AWS Init Failure";
export const POST_CLOUD_LINK_FAILED = "Post Multicloud Link Failed";
export const POST_CLOUD_LINK_SUCCESS = "Post Multicloud Link Success";
export const GET_REGION_SUCCESS = "Get region Success";
export const GET_REGION_FAILED = "Get region Success";
export const SIGNOUT = "Signout";
export const AUTH_FAILURE = "Authentication Failure";
export const CLOUD_ACCOUNTS_LINKED_SUCCESSFULLY = "Accounts linked successfully";
export const CLOUD_ACCOUNTS_LINKED_SUCCESSFULLY_SIGNUP_ACTIVATION =
  "Accounts created and linked successfully- Signup/Activation";
export const CREATE_ACCOUNT = "Create Account clicked on sign up flow";
export const CREATE_OCI_ACCOUNT_SUCCESS = "Create OCI Account successfully";
export const REGIONS_BY_TENANCY = "Regions by Tenancy";
export const NEW_TENANCY_SIGN_UP = "New Tenancy Sign up";

//Aws Specific events
export const AWS_AUTHENTICATED = "AWS Authenticated";
export const AWS_PREREQ_FAILURE = "AWS Pre Requisite Validation Failure";
export const AWS_PREREQ_SUCCESS = "AWS Pre Requisite Validation Success";
export const AWS_SUCCESS = "AWS Sign in Success";
export const AWS_TOKEN_FAILURE = "AWS Token Failure";
export const AWS_WIZARD = "AWS Wizard";
export const CLOUD_FORMATION_CLICK = "Cloud Formation button clicked";
export const CLOUD_FORMATION_SUCCESSFUL = "Cloud Formation Successful";
export const VALIDATE_ACCOUNT_BUTTON_CLICKED = "Validate Account Button in Step 1 Aws Wizard";

//Azure specific events
export const EXISTING_TENANCY_SIGN_UP = "Existing Tenancy Sign up";
export const GUIDED = "guided";
export const OCI_LANDING_PAGE = "OCI Landing Page";
export const LANDING_PAGE = "O4A Landing Page";
export const OCI_ACCOUNT_VALIDATION_SUCCESS = "OCI Account Validation Success";
export const OCI_SIGNOUT = "OCI Signout";
export const SUBSCRIPTION_TABLE = "Subscription Table";

//Gcp specific events
export const GCP_INIT_FAILURE = "GCP Init Failure";

//Infinity URL's
export const PROD_INFINITY_URL =
  "//d.oracleinfinity.io/infy/acs/account/g81cwxmeg3/js/multi_cloud_signup/odc.js";
export const TESTING_INFINITY_URL =
  "//d.oracleinfinity.io/infy/acs/account/g81cwxmeg3/js/multi_cloud_signup/odc.js?_ora.config=analytics:test";

// New Event Names

export namespace Analytics {
  // Pages
  export const HomePage = "HomePage";
  export const WelcomePage = "WelcomePage";
  export const OciSignInPage = "OciSignInPage";
  export const MulticloudLinkExistsPage = "MulticloudLinkExistsPage";
  export const MulticloudLinkFailedFetchPage = "MulticloudLinkFailedFetchPage";
  export const RegionSelectPage = "RegionSelectPage";
  export const MulticloudLinkRequestedPage = "MulticloudLinkRequestedPage";
  export const MulticloudLinkPollingPage = "MulticloudLinkPollingPage";
  export const MulticloudLinkErrorPage = "MulticloudLinkErrorPage";
  export const MulticloudLinkSuccessPage = "MulticloudLinkSuccessPage";

  // Clicks
  export const BeginAzure = "BeginAzure";
  export const BeginAws = "BeginAws";
  export const BeginGcp = "BeginGcp";
  export const GetStarted = "GetStarted";
  export const OciSignUp = "OciSignUp";
  export const IdentityFederationLearnMore = "IdentityFederationLearnMore";
  export const MulticloudLinkExistsCancel = "MulticloudLinkExistsCancel";
  export const MulticloudLinkExistsRedirect = "MulticloudLinkExistsRedirect";
  export const MulticloudLinkFailedFetchCancel = "MulticloudLinkFailedFetchCancel";
  export const MulticloudLinkFailedFetchSupport = "MulticloudLinkFailedFetchSupport";
  export const RegionSelectCancel = "RegionSelectCancel";
  export const MulticloudLinkErrorRetry = "MulticloudLinkErrorRetry";
  export const MulticloudLinkErrorCancel = "MulticloudLinkErrorCancel";
  export const MulticloudLinkErrorEditRegion = "MulticloudLinkErrorEditRegion";

  export namespace Gcp {
    // Pages
    export const GcpSignInPage = "GcpSignInPage";
    export const OrganizationSelectPage = "OrganizationSelectPage";
    export const ServiceAccountSelectPage = "ServiceAccountSelectPage";
    export const ProjectSelectPage = "ProjectSelectPage";

    // Clicks
    export const OrganizationSelectCancel = "OrganizationSelectCancel";
    export const ServiceAccountSelectCancel = "ServiceAccountSelectCancel";
    export const ProjectSelectCancel = "ProjectSelectCancel";
    export const MulticloudLinkErrorEditOrganization = "MulticloudLinkErrorEditOrganization";
    export const MulticloudLinkErrorEditServiceAccount = "MulticloudLinkErrorEditServiceAccount";
    export const MulticloudLinkErrorEditProject = "MulticloudLinkErrorEditProject";
  }
}
