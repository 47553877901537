import { DeliverRequestSignature } from "./BMCClient";
import { OraclePublicCloudAuthenticatorSingleton, GetAuthorizationUrl } from "./authenticator";
import { HgIndexedDBData } from "./HgIndexedDBData";
import { getHgCompatibleDbData, saveTokens, clearDb } from "./indexedDb";
import { SESSION_TIMEOUT } from "../utils/publicErrorCode";
import { isTokenAboutToExpire, isTokenDead } from "../utils/tokenUtils";

export interface RefreshedSecurityToken {
  token: string;
}

export class TokenRefreshHandler {
  constructor(private getAuthorizationUrl: GetAuthorizationUrl) {}

  /**
   * Check if token has less than 10 minutes lifetime
   *
   * @param token Raw security token
   */
  private static isSecurityTokenAboutToExpire(token: string) {
    return isTokenAboutToExpire(token);
  }

  /**
   * Token has to be refreshed within 1 hour time frame and has a maximum of 24 hours lifespan
   * If token has been there for more than 1 hour, it is dead. We have to inform user to re-sign.
   *
   * @param token Raw security token
   */
  private static isSecurityTokenDead(token: string): boolean {
    return isTokenDead(token);
  }

  public async tryRefresh(realm: string, deliverRequest: DeliverRequestSignature, force?: boolean) {
    const hgCompatibleDbData: HgIndexedDBData = await getHgCompatibleDbData(realm);

    if (
      hgCompatibleDbData &&
      hgCompatibleDbData.security_token &&
      TokenRefreshHandler.isSecurityTokenDead(hgCompatibleDbData.security_token)
    ) {
      // session is dead, notify upstream and clear AUTH material from db
      OraclePublicCloudAuthenticatorSingleton.reportError(SESSION_TIMEOUT);
      await clearDb(realm);
      return;
    }

    const shouldNotRefresh =
      !hgCompatibleDbData ||
      !hgCompatibleDbData.security_token ||
      (!TokenRefreshHandler.isSecurityTokenAboutToExpire(hgCompatibleDbData.security_token) && !force);

    if (shouldNotRefresh) {
      // do nothing if token doesn't exist, or token is valid AND force refreshing is not allowed.
      return;
    }

    const refreshUrl = `${this.getAuthorizationUrl()}/authentication/refresh`;
    let request = new Request(refreshUrl, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({
        currentToken: hgCompatibleDbData.security_token,
      }),
    });

    try {
      const response = await deliverRequest(request);
      if (!response || !response.ok) {
        throw new Error(`Status: "${response.status}", StatusText: "${response.statusText}"`);
      }

      const responseBody: RefreshedSecurityToken = await response.json();
      // Identity token cannot be refreshed
      // Save the new security token but keep the old identity token
      await saveTokens(realm, responseBody.token, hgCompatibleDbData.identity_token);
    } catch (reason) {
      throw new Error(`Failed to refresh token. ${reason}`);
    }
  }
}
