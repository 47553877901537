import QueryParams from "../constants/queryParams";
import UrlPlaceholders from "../constants/urlPlaceholders";
import { GcpAuthConfig, RestoreRouteFunction } from "../interfaces/gcpAuthConfig";
import { getWindow } from "./browserHelpers";
import { decodeState } from "./urlStateUtils";

/**
 * Returns the url by updating the placeholders in url template
 * @param urlTemplate - url template to update the placeholders
 * @param config - auth config
 */
export function getAuthUrl(
  urlTemplate: string,
  config: GcpAuthConfig,
  codeChallenge: string,
  state: string,
): string {
  const url = urlTemplate
    .replace(UrlPlaceholders.ClientIdPlaceholder, config.clientId)
    .replace(UrlPlaceholders.RedirectUriPlaceholder, config.redirectUri)
    .replace(UrlPlaceholders.StatePlaceholder, state)
    .replace(UrlPlaceholders.CodeChallengePlaceholder, codeChallenge)
    .replace(UrlPlaceholders.ScopePlaceholder, config.scopes.join(" "));
  return url;
}

export function getRevokeTokenUrl(urlTemplate: string, token: string): string {
  const url = urlTemplate.replace(UrlPlaceholders.TokenPlaceholder, token);
  return url;
}

/**
 * Removes the specified query string parameter from the url bar of the browser
 * @param parameter - parameter of the query string to remove
 * @param currentUrlParameters - Url query parameters
 * @param restoreRouteCallback - Callback to restore the route
 */
export function removeParametersFromQueryString(restoreRouteCallback?: RestoreRouteFunction): void {
  const { location } = getWindow();
  const { search } = location;
  const params = new URLSearchParams(search);
  params.delete(QueryParams.Code);
  params.delete(QueryParams.State);
  // Reconstruct a url with new search params. If there's no search params, remove question mark.
  const searchParamsString = params.toString();
  const fullSearchParamsString = searchParamsString ? `?${searchParamsString}` : "";
  const newPathParams = `${location.pathname}${fullSearchParamsString}`;
  const newUrl = `${location.origin}${newPathParams}`;
  // replace url without reload the page
  if (restoreRouteCallback) {
    restoreRouteCallback(newPathParams);
  } else {
    getWindow().history.replaceState({}, document.title, newUrl);
  }
}

export async function restoreApplicationState(
  currentUrlParameters: URLSearchParams,
  gcpConfig: GcpAuthConfig,
) {
  try {
    let destinationPath: string = "";
    const state = currentUrlParameters.get(QueryParams.State);
    if (gcpConfig.restoreRouteCallback && state) {
      destinationPath = await decodeState(state);
      destinationPath = normalizeRestoreRoute(destinationPath);
      // Restoring URL State
      gcpConfig.restoreRouteCallback(destinationPath);
    } else {
      removeParametersFromQueryString(gcpConfig.restoreRouteCallback);
    }
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
}

/**
 * Get path (include query params) for url restoring after OAUTH redirection.
 *
 * Case url contains "#"
 *  - everything after "#"
 *
 * Case url doesn't contain "#"
 *  - everything after host
 *
 * @returns partial url
 */
export function getRoutingPath(pathName = getWindow().location.pathname): string {
  const url = new URL(getWindow().location.href);
  url.pathname = pathName;

  if (url.pathname === "/" && !!url.hash) {
    return url.hash.substr(1);
  }

  return url.pathname + url.search;
}

/**
 * Remove any extra "/" in the front, we should only keep one if there is any
 */
export function normalizeRestoreRoute(routePath: string): string {
  return routePath.replace(/^\/+/, "/");
}
