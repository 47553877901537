import { FormDispatch } from "../components/Context/ApplicationContext";
import { getAPIResponseMetadata, updateApiErrorDetails } from "./CommonUtil";

const DEFAULT_RETRY_NUMBER = 3;
const DEFAULT_RETRY_DELAY = 1000; // milliseconds

const setDelay = (delay: number) =>
  new Promise<void>(resolve => {
    setTimeout(() => {
      resolve();
    }, delay);
  });

const retryPromiseWithDelayAsync = async (
  promise: () => Promise<Response>,
  retries: number,
  delay: number,
  initialRetries: number,
  dispatch: FormDispatch,
  whitelistedErrCodes: string[],
): Promise<Response> => {
  let response: Response | undefined;
  try {
    response = await promise();
    if (response.ok) {
      return response;
    }
    throw new Error();
  } catch (e: any) {
    if (retries === 1) {
      if (response) {
        const responseMetadata = await getAPIResponseMetadata(response);
        const clonedResponse = response.clone();
        if (
          dispatch &&
          !whitelistedErrCodes.some(errCode => errCode === clonedResponse.status.toString())
        ) {
          updateApiErrorDetails(clonedResponse.url, dispatch, response.clone());
        }
        return Promise.reject({
          status: response.status,
          msg: hasJsonContent(clonedResponse)
            ? (await clonedResponse.json()).error?.message
            : response.statusText,
          retries: initialRetries,
          responseMetadata: responseMetadata,
        });
      }
      return Promise.reject({
        status: undefined,
        msg: (e as Error)?.message,
        retries: initialRetries,
      });
    }
    if (response) {
      const responseMetadata = await getAPIResponseMetadata(response);
      const clonedResponse = response.clone();
      const responseStatus = (element: string) => element === clonedResponse.status.toString();
      if (whitelistedErrCodes.some(responseStatus)) {
        return Promise.reject({
          status: response.status,
          msg: hasJsonContent(clonedResponse)
            ? (await clonedResponse.json()).error?.message
            : response.statusText,
          retries: initialRetries,
          responseMetadata: responseMetadata,
        });
      }
    }
    return setDelay(delay).then(() =>
      retryPromiseWithDelayAsync(
        promise,
        retries - 1,
        delay,
        initialRetries,
        dispatch,
        whitelistedErrCodes,
      ),
    );
  }
};

export const retryPromiseWithDelay = (
  promise: () => Promise<Response>,
  dispatch: FormDispatch,
  whitelistedErrCodes = [""],
  retries = DEFAULT_RETRY_NUMBER,
  delay = DEFAULT_RETRY_DELAY,
): Promise<Response> => {
  return retryPromiseWithDelayAsync(
    promise,
    retries,
    delay,
    retries,
    dispatch,
    whitelistedErrCodes,
  );
};

/**
 * Helper function for determining if an error has JSON content
 */
export const hasJsonContent = (res: Response): boolean => {
  const contentType = res.headers?.get("Content-Type");
  return !!(contentType && contentType.includes("application/json"));
};
