import {
  IdentityToken,
  OraclePublicCloudAuthenticatorSingleton,
  RealmConfig,
  SoupParameters,
  TokenAuthenticator,
} from "duplo-authentication";
import { RestoreRouteFunction } from "duplo-authentication";
import {
  GetAuthorizationUrl,
  GetRedirectUrl,
} from "duplo-authentication/build/fluorine/authenticator/interfaces";
import { LogLevel } from "duplo-authentication/build/telemetry/models/LogLevel";

export interface AuthContext {
  tenant: string;
  name: string;
  tenantName: string;
  domainName?: string;
  domainOcid?: string;
}

export interface AuthConfig {
  restoreRouteCallback: RestoreRouteFunction;
  identityServiceUrl: string;
  loginServiceUrl: string;
  authorizationServiceUrl: GetAuthorizationUrl;
  landingUrl: string;
  getRedirectUrlOverride?: GetRedirectUrl;
  soupParameters?: SoupParameters;
  log?: (realm: string, logLevel: LogLevel, message: string) => void;
  sendMetric?: (realm: string, name: string, value: number) => void;
  reusedTokenExpiration?: number;
}

export type AuthParameters = {
  soupParameters?: SoupParameters;
};

export class Auth {
  private readonly realm = "default";
  private authInstance: undefined | TokenAuthenticator;
  private identityToken: undefined | IdentityToken;
  private readonly AUTH_INIT_ERROR = "authInstance not initialized";
  private static singleton: Auth;

  private constructor(private config: AuthConfig) {}

  private errorHandler(error: any) {
    console.log(error);
  }

  private getRealmConfig(config: AuthConfig): RealmConfig {
    return {
      realm: this.realm,
      identityServiceUrl: config.identityServiceUrl,
      loginServiceUrl: config.loginServiceUrl,
      authorizationServiceUrl: config.authorizationServiceUrl,
      landingUrl: config.landingUrl,
      getRedirectUrlOverride: config.getRedirectUrlOverride,
      soupParameters: config.soupParameters,
      log: config.log,
      sendMetric: config.sendMetric,
    };
  }

  public static async init(config: AuthConfig): Promise<Auth> {
    if (Auth.singleton) {
      throw new Error("Attempting to reinitialize Auth");
    }
    Auth.singleton = new Auth(config);
    await Auth.singleton.initialize();
    return Auth.singleton;
  }

  public static get instance(): Auth {
    if (!Auth.singleton) {
      throw new Error("Auth has not been initialized");
    }
    return Auth.singleton;
  }

  private async initialize() {
    const configs: RealmConfig[] = [this.getRealmConfig(this.config)];

    await OraclePublicCloudAuthenticatorSingleton.initialize(
      this.config.restoreRouteCallback,
      this.errorHandler.bind(this),
      configs,
    );
    this.authInstance = OraclePublicCloudAuthenticatorSingleton.getInstance();
  }

  public async authenticate(authParameters?: AuthParameters) {
    if (!this.authInstance) {
      throw new Error(this.AUTH_INIT_ERROR);
    }
    await this.authInstance.authenticateUser(undefined, undefined, authParameters?.soupParameters);
  }

  public getFetchApi() {
    if (!this.authInstance) {
      throw new Error(this.AUTH_INIT_ERROR);
    }
    return this.authInstance.getFetchApi();
  }

  public tryRefresh(force?: boolean) {
    if (!this.authInstance) {
      throw new Error(this.AUTH_INIT_ERROR);
    }
    return this.authInstance.tryRefreshToken(force);
  }

  private async getIdentityToken(): Promise<IdentityToken> {
    if (!this.authInstance) {
      throw new Error(this.AUTH_INIT_ERROR);
    }
    if (!this.identityToken) {
      this.identityToken = await this.authInstance.getIdentityToken();
    }
    return this.identityToken;
  }

  public async getAuthContext(): Promise<AuthContext> {
    const identityToken = await this.getIdentityToken();

    return {
      tenant: identityToken.tenant,
      name: identityToken.name,
      tenantName: identityToken.tenant_name,
      domainName: identityToken.domain_name,
      domainOcid: identityToken.domain_ocid,
    };
  }

  public async getExpiration(): Promise<number> {
    if (!this.authInstance) {
      throw new Error(this.AUTH_INIT_ERROR);
    }
    return this.authInstance.getExpiration();
  }

  public async getSessionExpiration(): Promise<number> {
    if (!this.authInstance) {
      throw new Error(this.AUTH_INIT_ERROR);
    }
    return this.authInstance.getSessionExpiration();
  }

  public async getIssuedAt(): Promise<number> {
    if (!this.authInstance) {
      throw new Error(this.AUTH_INIT_ERROR);
    }
    return this.authInstance.getIssuedAt();
  }

  public async logOut(logoutParams?: { consoleHost: string }): Promise<void> {
    if (!this.authInstance) {
      return;
    }
    const identityToken = await this.getIdentityToken();
    return this.authInstance.logout(
      identityToken!.tenant_name,
      logoutParams?.consoleHost,
      false,
      identityToken?.domain_name,
      identityToken?.domain_ocid,
    );
  }
}
