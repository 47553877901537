const store = localStorage;

export function setItemToLocalStore(key: string, data: any) {
  store.setItem(key, JSON.stringify(data));
}

export function getItemFromLocalStore(key: string) {
  let data = null;
  const value = store.getItem(key);
  if (value) {
    data = JSON.parse(value);
  }
  return data;
}

export function removeItemFromLocalStore(key: string) {
  store.removeItem(key);
}
