import * as IndexDB from "localforage";
import { HgIndexedDBData } from "./HgIndexedDBData";
import { IdentityToken } from "./IdentityToken";
import { v4 as uuidV4 } from "uuid";
import { IFluorineCrypto, IKeyPairEnvelope } from "./crypto";
import { generateStateSigningKey } from "./urlStateHandler";

/**
 * The IndexDB key to store key pair. This is shared with Hg, so don't change its name
 */
const KEY_NAME = "key";

/**
 * The IndexDB key to store the realm awaiting authentication tokens after sign-in
 */
const AUTH_REALM = "auth_realm";

/**
 * IndexDB name to persist information between http redirects. This must be in sync with Hg console hg/client/auth/key_storage.cljs
 */
const DB_NAME = "opc-key-store-v2";

/**
 * The IndexDB key to store the info whether user session is timed out
 */
const IDLE_TIMEOUT = "idle_timeout";

/**
 * The IndexDB key to store the info about domain and tenant to be used by SOUP
 */
const SOUP_PARAMETERS = "soup_parameters";

/**
 * IndexDB version. This must be in sync with Hg
 */
const DB_VERSION = 2;

/**
 * Store name in above IndexDB
 */
const STORE_NAME = "keys";

const indexDB = IndexDB.createInstance({
  driver: IndexDB.INDEXEDDB,
  name: DB_NAME,
  storeName: STORE_NAME,
  version: DB_VERSION,
});

function getKey(realm: string) {
  return realm === "default" ? KEY_NAME : `${KEY_NAME}_${realm}`;
}

/**
 * Initializes HgIndexedDBData and saves it to IndexDb
 * @returns Promise of HgIndexedDBData
 */
export async function initHgCompatibleDbData(
  realm: string,
  cryptoObject: IFluorineCrypto<CryptoKeyPair, IKeyPairEnvelope>,
): Promise<HgIndexedDBData> {
  const nonce = uuidV4();
  const envelope = await cryptoObject.generateKeyPair();
  const stateSigningKey = await generateStateSigningKey();

  // Save envelop to IndexDB. When SOUP calls back, the envelope's private key is used to sign http requests.
  // Notice though only private key is needed later, we save public key as well, as existing Hg console does.
  const hgCompatibleDbData = {} as HgIndexedDBData;
  hgCompatibleDbData.createdAt = Math.ceil(new Date(Date.now()).getTime());
  hgCompatibleDbData.id = getKey(realm);
  hgCompatibleDbData.private = envelope.materials.privateKey;
  hgCompatibleDbData.public = envelope.materials.publicKey;
  hgCompatibleDbData.nonce = nonce;
  hgCompatibleDbData.state = stateSigningKey;
  await setItem(realm, hgCompatibleDbData);

  return hgCompatibleDbData;
}

/**
 * Retrieve HgIndexedDBData from IndexedDB
 * @returns Promise of HgIndexedDBData
 */
export async function getHgCompatibleDbData(realm: string): Promise<HgIndexedDBData> {
  return await (indexDB.getItem(getKey(realm)) as Promise<HgIndexedDBData>);
}

/**
 * Returns true if the provided HgIndexedDBData is not undefined and has values for the private,
 * public, and security_token properties
 * @param hgCompatibleDbData
 */
export function isHgCompatibleDbDataPresent(hgCompatibleDbData: HgIndexedDBData): boolean {
  return (
    hgCompatibleDbData &&
    !!hgCompatibleDbData.private &&
    !!hgCompatibleDbData.public &&
    !!hgCompatibleDbData.security_token
  );
}

/**
 * Persist security_token and identity_token on hgCompatibleDbData object
 * @param realm realm use to create key to save token
 * @param securityToken security token to be persisted
 * @param identityToken identity token to be persisted
 */
export async function saveTokens(realm: string, securityToken: string, identityToken: IdentityToken) {
  const key = getKey(realm);
  const hgCompatibleDbData = await (indexDB.getItem(key) as Promise<HgIndexedDBData>);
  const hgCompatibleDbDataClone: HgIndexedDBData = {
    ...hgCompatibleDbData,
    security_token: securityToken,
    identity_token: identityToken,
  };
  await indexDB.setItem(key, hgCompatibleDbDataClone);
}

/**
 * Save HgIndexedDBData to Db
 * @param realm realm use to associate the data to
 * @param hgCompatibleDbData data to be saved
 */
export function setItem(realm: string, hgCompatibleDbData: HgIndexedDBData): Promise<HgIndexedDBData> {
  return indexDB.setItem(getKey(realm), hgCompatibleDbData);
}

/**
 * Clear Db
 * @param realm realm to be cleared
 */
export function clearDb(realm?: string): Promise<void> {
  return realm ? indexDB.removeItem(getKey(realm)) : indexDB.clear();
}

/**
 * Set Authenticating Realm in Db
 * @param realm realm being authenticated
 */
export async function setAuthenticatingRealm(realm: string): Promise<void> {
  await indexDB.setItem(AUTH_REALM, realm);
}

/**
 * Get the realm which is being authenticated
 */
export async function getAuthenticatingRealm(): Promise<string | undefined> {
  return (await indexDB.getItem(AUTH_REALM)) as string;
}

/**
 * Remove placeholder which indicates the realm being authenticated
 */
export async function clearAuthenticatingRealm(): Promise<void> {
  await indexDB.removeItem(AUTH_REALM);
}

/**
 * Set the information about user session timed out
 * @param isSessionTimeout whether session timed out or not
 */
export async function setIdleTimeout(isSessionTimeout: boolean): Promise<void> {
  await indexDB.setItem(IDLE_TIMEOUT, isSessionTimeout);
}

/**
 * Get the information about whether user session is timed out
 */
export async function getIdleTimeout(): Promise<boolean | undefined> {
  return await indexDB.getItem(IDLE_TIMEOUT);
}

/**
 * Remove placeholder for user session timed out
 */
export async function clearIdleTimeout(): Promise<void> {
  await indexDB.removeItem(IDLE_TIMEOUT);
}

/**
 * Remove placeholder for soup parameters used during session timeout
 */
export async function clearSoupParameters(): Promise<void> {
  await indexDB.removeItem(SOUP_PARAMETERS);
}
