/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from "react";
import "./InputText.css";
import Icon, { IconType } from "../Icon/Icon";

export interface InputTextProps {
  id?: string;
  disabled?: boolean;
  type: string;
  label?: string;
  name: string;
  value: string;
  className?: string;
  tabIndex?: number;
  placeholder: string;
  required: boolean;
  info?: boolean;
  infoMsg?: string | React.ReactElement;
  pattern?: string;
  valid: boolean;
  error?: string | React.ReactElement;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  maxLength?: number;
  showOptional?: boolean;
  testId?: string;
}

const InputText: React.FC<InputTextProps> = ({
  valid,
  id,
  disabled,
  type,
  name,
  value,
  className,
  tabIndex,
  placeholder,
  required,
  pattern,
  onBlur,
  error,
  onChange,
  onFocus,
  label,
  info,
  infoMsg,
  maxLength,
  showOptional,
  testId,
}) => {
  const [showInfo, setShowInfo] = React.useState(false);
  const onClickHandler = () => {
    setShowInfo(true);
  };
  return (
    <div className="input-text signup-field">
      <label className={`has-float-label  ${valid === false ? "redBorder" : ""}`}>
        <input
          autoComplete="chrome-off"
          id={id}
          disabled={disabled}
          type={type}
          name={name}
          value={value}
          className={className}
          tabIndex={tabIndex}
          placeholder={placeholder || type}
          required={required}
          pattern={pattern}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            if (onBlur) {
              onBlur(e);
            }
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
              onChange(e);
            }
          }}
          onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
            if (onFocus) {
              onFocus(e);
            }
            onClickHandler();
          }}
          maxLength={maxLength}
          data-testid={testId}
        />
        {label && <span className={`inputName  ${disabled ? "disabled" : ""}`}>{label}</span>}
      </label>
      {showInfo && (
        <>
          <div className={!valid && error ? "error-message" : "hide"}>
            <Icon name={IconType.ERROR} />
            {error}
          </div>
          {infoMsg && (
            <div className={info === true ? "" : "hide"}>
              <Icon name={IconType.INFO} />
              {infoMsg}
            </div>
          )}
        </>
      )}
      {showOptional && !required && (
        <>
          <div className="optional">Optional</div>
          <div className="clear" />
        </>
      )}
      <div className="clear" />
    </div>
  );
};

export default InputText;
