import UrlPlaceholders from "../constants/urlPlaceholders";
import UrlConstants from "../constants/urls";
import { AwsAuthConfig, RestoreRouteFunction } from "../interfaces";
import { getWindow } from "./browserHelpers";

/**
 * Returns the url by updating the placeholders in url template
 * @param urlTemplate - url template to update the placeholders
 * @param config - auth config
 * The URLs have OR placeholders simply because AwsAuthConfig has domain/region client Id as optional params
 * But the expectation is anytime this function is called those params are populated.
 */
export function getCognitoUrl(
  urlTemplate: string,
  config: AwsAuthConfig,
  codeChallenge: string,
): string {
  const url = urlTemplate
    .replace(UrlPlaceholders.DomainPlaceholder, config.domain || UrlPlaceholders.DomainPlaceholder)
    .replace(UrlPlaceholders.RegionPlaceholder, config.region || UrlPlaceholders.RegionPlaceholder)
    .replace(
      UrlPlaceholders.ClientIdPlaceholder,
      config.clientId || UrlPlaceholders.ClientIdPlaceholder,
    )
    .replace(UrlPlaceholders.RedirectUriPlaceholder, config.callbackUrl)
    .replace(UrlPlaceholders.CodeChallengePlaceholder, codeChallenge);
  if (config.scopes && config.scopes.length > 0) {
    return url.replace(UrlPlaceholders.ScopePlaceholder, config.scopes.join("+"));
  }
  const scopeString = `&scope=${UrlPlaceholders.ScopePlaceholder}`;
  return url.replace(scopeString, "");
}

export function getCognitoLogoutUrl(urlTemplate: string, config: AwsAuthConfig): string {
  const url = urlTemplate
    .replace(UrlPlaceholders.DomainPlaceholder, config.domain || UrlPlaceholders.DomainPlaceholder)
    .replace(UrlPlaceholders.RegionPlaceholder, config.region || UrlPlaceholders.RegionPlaceholder)
    .replace(
      UrlPlaceholders.ClientIdPlaceholder,
      config.clientId || UrlPlaceholders.ClientIdPlaceholder,
    )
    .replace(UrlPlaceholders.LogoutUriPlaceholder, config.signoutUrl);

  if (config.scopes && config.scopes.length > 0) {
    return url.replace(UrlPlaceholders.ScopePlaceholder, config.scopes.join("+"));
  }
  const scopeString = `&scope=${UrlPlaceholders.ScopePlaceholder}`;
  return url.replace(scopeString, "");
}

/**
 * Returns the token url by updating the placeholders in url template
 * @param config - auth config
 */
export function getCognitoTokenUrl(config: AwsAuthConfig): string {
  return UrlConstants.CognitoTokenURLTemplate.replace(
    UrlPlaceholders.DomainPlaceholder,
    config.domain || "",
  ).replace(UrlPlaceholders.RegionPlaceholder, config.region || "");
}

/**
 * Removes the specified query string parameter from the url bar of the browser
 * @param parameter - parameter of the query string
 * @param location - window location
 */
export function removeParameterFromQueryString(
  parameter: string,
  location: Location,
  restoreRouteCallback?: RestoreRouteFunction,
): void {
  const { search } = location;
  const params = new URLSearchParams(search);
  params.delete(parameter);
  // Reconstruct a url with new search params. If there's no search params, remove question mark.
  const searchParamsString = params.toString();
  const fullSearchParamsString = searchParamsString ? `?${searchParamsString}` : "";
  const newPathParams = `${location.pathname}${fullSearchParamsString}`;
  const newUrl = `${location.origin}${newPathParams}`;
  const newTitle = document.title;
  // replace url without reload the page
  if (restoreRouteCallback) {
    restoreRouteCallback(newPathParams);
  } else {
    getWindow().history.replaceState({}, newTitle, newUrl);
  }
}
