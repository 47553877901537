import { Region } from "oci-console-regions";
import { Environment } from "../enums/common";

export const isPintlabEnv = () =>
  window.location.origin?.toLowerCase().includes(Environment.PINTLAB.toLowerCase());

export const isLocalhostEnv = () =>
  window.location.origin?.toLowerCase().includes(Environment.LOCALHOST.toLowerCase());

export const isStagingEnv = () =>
  window.location.origin?.toLowerCase().includes(Environment.STAGING.toLowerCase());

export const isDevEnv = () =>
  window.location.origin?.toLowerCase().includes(Environment.DEV.toLowerCase());

/**
 * Can be lazily updated when adding regions.
 * Note: This region list is not the source of truth and mchub `/regions` API will be the one
 */
export const getBootstrapRegionList = (): string[] => [Region.IAD, Region.FRA];

export class MchubPlatformURLs {
  static setRegion(region: string) {
    if (region) {
      this.DEV = `https://mchub-platform-dev.${region}.oci.oraclecloud.com/20220401`;
      this.PREPROD = `https://mchub-platform-preprod.${region}.oci.oraclecloud.com/20220401`;
      this.PROD = `https://mchub-platform.${region}.oci.oraclecloud.com/20220401`;
    }
    return MchubPlatformURLs;
  }
  toString() {
    return MchubPlatformURLs;
  }
  static DEV = "https://mchub-platform-dev.us-ashburn-1.oci.oraclecloud.com/20220401";
  static PREPROD = "https://mchub-platform-preprod.us-ashburn-1.oci.oraclecloud.com/20220401";
  static PROD = "https://mchub-platform.us-ashburn-1.oci.oraclecloud.com/20220401";
}

export const getMchubPlatformEndpoint = (region: string) => {
  if (isPintlabEnv() || isLocalhostEnv() || isDevEnv()) {
    return MchubPlatformURLs.setRegion(region).DEV;
  }
  if (isStagingEnv()) {
    return MchubPlatformURLs.setRegion(region).PREPROD;
  }
  return MchubPlatformURLs.setRegion(region).PROD;
};

export const getMchubPlatformGetTokenEndpoint = (region: string) =>
  `${getMchubPlatformEndpoint(region)}/clouds/gcp/accessTokens`;
