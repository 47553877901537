// Signing
import { headersForEach } from "../utils/headersUtils";
import { fetchNativeSubtleCrypto } from "./crypto";

export interface SignaturePacket {
  signingString: string;
  orderedHeaders: Array<string>;
}

class HttpMethod {
  public static readonly PUT = "put";
  public static readonly POST = "post";
  public static readonly PATCH = "patch";
}

export const CONTENT_LENGTH = "content-length";
export const DIGEST = "x-content-sha256";
export const X_DATE = "x-date";
export const HOST = "host";
export const OPC_REQUEST_ID = "opc-request-id";

export const CONTENT_TYPE = "content-type";
export const JSON_CONTENT_TYPE = "application/json";

const REQUEST_TARGET = "(request-target)";

const SINGLE_SPACE = " ";
const LINE_BREAK = "\n";
const KEY_VALUE_SEPARATOR = ": ";
const DIGEST_ALGORITHM = "SHA-256";

// The Subtle crypto implementation in IE11 will silently fail to digest an empty string.
// We have to manually define that value here to avoid hanging forever
const EMPTY_SHA = "47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=";

export async function digestContent(content: ArrayBuffer): Promise<string> {
  const hashBuffer = await fetchNativeSubtleCrypto().digest(DIGEST_ALGORITHM, content);
  // hash buffer contains hash value which are ASCII only characters, hence below conversion will work.
  return btoa(String.fromCharCode.apply(null, new Uint8Array(hashBuffer)));
}

const appendHeaderToSigningStringInNewLine = (
  signingString: string,
  headerKey: string,
  headerValue: string,
): string => {
  if (signingString.length > 0) {
    signingString = signingString + LINE_BREAK;
  }

  return signingString + headerKey + KEY_VALUE_SEPARATOR + headerValue;
};

export async function createSigningString(request: Request): Promise<SignaturePacket> {
  try {
    const headers = new Array();
    let signingString = "";

    const url = new URL(request.url);
    const method = request.method.toLowerCase();
    // Method name in signing string is lowercase according to
    // https://docs.us-phoenix-1.oraclecloud.com/Content/API/Concepts/signingrequests.htm?Highlight=signature#Test
    signingString = REQUEST_TARGET + KEY_VALUE_SEPARATOR + method + SINGLE_SPACE + url.pathname + url.search;
    headers.push(REQUEST_TARGET);

    signingString = appendHeaderToSigningStringInNewLine(signingString, HOST, url.host);
    headers.push(HOST);

    // SOUP requires the x-date header
    if (!request.headers.has(X_DATE)) {
      request.headers.set(X_DATE, new Date().toUTCString());
    }

    const isPutPostPatch = method === HttpMethod.PUT || method === HttpMethod.POST || method === HttpMethod.PATCH;
    // https://docs.us-phoenix-1.oraclecloud.com/Content/API/Concepts/signingrequests.htm?Highlight=signature#Test
    // Important: For PUT and POST requests, your client must compute the x-content-sha256 and
    // include it in the request and signing string, even if the body is an empty string.
    if (isPutPostPatch) {
      const clonedRequest = request.clone();
      const bodyBuffer = await clonedRequest.arrayBuffer();
      let contentLen = 0;
      if (bodyBuffer && bodyBuffer.byteLength > 0) {
        const bodyView = new Uint8Array(bodyBuffer);
        const sha = await digestContent(bodyBuffer);
        request.headers.set(DIGEST, sha);
        contentLen = bodyView.length;
      } else {
        // if buffer is empty, it can only be an empty string payload
        request.headers.set(DIGEST, EMPTY_SHA);
      }

      signingString = appendHeaderToSigningStringInNewLine(signingString, CONTENT_LENGTH, `${contentLen}`);
      headers.push(CONTENT_LENGTH);

      if (!request.headers.has(CONTENT_TYPE)) {
        request.headers.set(CONTENT_TYPE, JSON_CONTENT_TYPE);
      }
    }

    headersForEach(request.headers, (headerValue, headerName) => {
      // Header names in signing string are lowercase according to
      // https://docs.us-phoenix-1.oraclecloud.com/Content/API/Concepts/signingrequests.htm?Highlight=signature#Test
      const lowercaseHeaderName = headerName.toLowerCase();

      signingString = appendHeaderToSigningStringInNewLine(signingString, lowercaseHeaderName, headerValue);
      headers.push(lowercaseHeaderName);
    });

    return { signingString: signingString, orderedHeaders: headers };
  } catch (error) {
    return Promise.reject(error);
  }
}
