import * as React from "react";
import { isString } from "lodash";
import { MulticloudTelemetryHelper } from "multi-cloud-shared-components";
import { ErrorBoundary } from "oui-react";

import Log from "../../CourierService/Logger";
import MetricNames from "../../telemetry/MetricNames";

import { ErrorFallback } from "./ErrorFallback";

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const ErrorBoundaryWrapper: React.FC<Props> = (props: Props): JSX.Element => {
  const [threwError, setThrewError] = React.useState<Error>();

  const getErrorMessage = (error: Error, info: React.ErrorInfo): string =>
    `caught React.Component error: ${
      isString(error)
        ? error
        : `${error.name}: ${error.message}
\n error.stack: ${error.stack || "<no_error_stack>"}`
    }
\n componentStack: ${info.componentStack}`;

  const handleUiException = (error: Error, info: React.ErrorInfo): void => {
    Log.error(getErrorMessage(error, info));
    MulticloudTelemetryHelper.emitMetric(MetricNames.ErrorBoundary, 1, {
      errorName: error.name,
      errorMessage: error.message,
    });
    setThrewError(error);
  };

  return (
    <ErrorBoundary
      onError={handleUiException}
      fallback={() => <ErrorFallback error={threwError} />}
    >
      {props.children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
