import React from "react";
import * as Messages from "../../codegen/Messages";
import Log from "../../CourierService/Logger";
import { getCloudTypeName } from "../../utils";
import Modal from "../Modal/Modal";

const IndexedDBMissingErrorPage = () => {
  const cloudType = getCloudTypeName();

  React.useEffect(() => {
    Log.error("Render Index DB missing error page");
  }, []);

  return (
    <Modal show={true} title={Messages.validationFailures.indexDBErrorTitle()} showHeader={true}>
      <div>{Messages.validationFailures.indexDBErrorSubtitle(cloudType)}</div>
      <div>
        <a
          target="_blank"
          href="https://docs.cloud.oracle.com/iaas/Content/GSG/Tasks/signingin.htm#supported_browsers"
          rel="noreferrer"
        >
          {Messages.validationFailures.supportedBrowserList()}
        </a>
      </div>
      <div>
        <a
          target="_blank"
          href="https://docs.cloud.oracle.com/iaas/Content/GSG/Reference/faq.htm#FFIssue"
          rel="noreferrer"
        >
          {Messages.validationFailures.troubleshootingGuide()}
        </a>
      </div>
    </Modal>
  );
};

export default IndexedDBMissingErrorPage;
