/****************************************************************
 * WARNING THIS FILE IS AUTOGENERATED AND SHOULD NOT BE MODIFIED *
 ****************************************************************/

// tslint:disable
import { LoomI18n, Currency } from "loom-i18n";
import enLocaleData from "loom-i18n/dist/locale-data/en";

export const bundled: { [language: string]: any } = {};
bundled["en"] = {
  activate: {
    createErrorDetails:
      "Oracle Cloud account activation could not process all of your submitted information. Verify its accuracy and try again. If the problem persists, <a href='{link}' target='_blank' rel='noopener noreferrer'>contact Oracle Support</a>."
  },
  activationForm: {
    action: {
      submit: "Submit"
    },
    activateAccountHeader: "Create Oracle Cloud account",
    email: "Email",
    error: {
      gen: "Error",
      length: "Tenancy name must be 1 to 25 characters.",
      regExpr: "Input requirements for {insert} not met.",
      required: "A value for {insert} is required.",
      tenancyReq:
        "Tenancy name does not meet requirements. Name must be lowercase, start with a letter, contain no spaces or special characters, and be 1 to 25 characters.",
      unavailable: "The chosen tenancy name is unavailable. Try another name."
    },
    firstName: "First Name",
    homeRegion: "Home Region",
    homeRegionInfo:
      "Your <a href='{link1}' target='_blank' rel='noopener noreferrer'>home region</a> is the geographic location where your account and identity (IAM) resources will be created. It cannot be changed after sign-up. <a href='{link2}' target='_blank' rel='noopener noreferrer'>See Regions</a> for service availability.",
    label: "New Cloud Account Information",
    lastName: "Last Name",
    tenancyName: "Cloud account name",
    tenancyNameInfo:
      "Name must be lowercase, start with a letter, contain no spaces or special characters, and be 1 to 25 characters. The account name is assigned to your company's or organization's environment and used when signing into the OCI Console. You can always  <a href='{link}' target='_blank' rel='noopener noreferrer'>rename</a> your account later using the Console."
  },
  agreement: {
    errorDialog1:
      "It looks like something went wrong while creating your Oracle Cloud account. Please contact Oracle support or wait a few minutes and try again.",
    errorDialog2:
      "<a href='{link}' target='_self'>Refresh</a> your browser and start the sign-up process over.",
    errorDialog3:
      "If the problem persists, <a href='{link}' target='_blank' rel='noopener noreferrer'>contact chat support</a> and Oracle will assist you with the sign-up process.",
    errorTitle: "Oops! We're sorry...",
    tryMeButton: "Try Again"
  },
  awsAccountIDPage: {
    accountIDField: "AWS account ID",
    accountIDPageTitle:
      "Please enter your AWS account ID below for validation. ",
    accountIDPageTitleInfo: "Learn how to get your AWS account ID",
    userpoolIDField: "AWS userpool ID",
    validateAccount: "Validate Account"
  },
  awsCloudformationPage: {
    agreementCheckbox:
      "I have read, understood and agree with the user acknowledgement terms for CloudFormation stack execution.",
    cloudFormationFormTitle:
      "Please provide email for CloudFormation stack creation. Once you launch the CloudFormation from AWS, please come back to this page for the further step to be completed.",
    cloudFormationPrerequisites:
      "To launch CloudFormation, you must have the required AWS IAM permissions.",
    cloudFormationSubmit: "Create CloudFormation stack from AWS",
    cloudFormationTerms:
      "We are providing this CloudFormation script/template to simplify provisioning and completion of prerequisite requirements for ODSAWS. With any configuration of resources, the user should review and understand what is in the script. If you have any questions, you can reach out to OCI Customer Support.",
    confirmEmailLabel: "Confirm email",
    documentationLink: "Documentation link",
    emailLabel: "Email",
    error: {
      confirmEmailError: "Emails do not match",
      regExpr: "Input requirements for {insert} not met.",
      required: "A value for {insert} is required.",
      userPoolError: "Invalid User Pool ID"
    },
    info: "info",
    learnMore: "Learn more",
    optional: "Optional",
    userPoolLabel: "Cognito user pool ID"
  },
  awsFooter: {
    privacy: "Privacy",
    terms: "Terms"
  },
  awsHome: {
    cloudFormationPollMaxRetriesMsg:
      "Oops! This is taking longer than usual. Please <link id='tryAgain'>try again</link>.",
    cloudFormationPollingErrorMsg:
      "CloudFormation validation failed. Please refer the support documentation.",
    cloudFormationPollingMsg:
      "Please wait while we are waiting for CloudFormation to finish...",
    collapsedStepsLabel: "Step {stepNumber} of {stepsCount}",
    documentationLink: "Documentation link",
    error: {
      preReqValidation403:
        "The number of requests has been exceeded. Reload the page or retry the operation.",
      preReqValidationGenericError:
        "It looks like something went wrong while processing your request. Please contact Oracle support or wait a few minutes and try again.",
      preReqValidationWhitelistError:
        "It looks like your AWS account ID is not whitelisted. Please contact Oracle support or wait a few minutes and try again."
    },
    preReqValidationInvalidError:
      "Something went wrong when validating your account permissions.",
    preReqValidationLoadingFailure: "Validation failed...",
    preReqValidationLoadingTitle: "Please wait while we validate...",
    signIn: "Sign In",
    signInPageRedirectMsg:
      "Please use your AWS Cognito account to login. If this is the first time you are signing in, please check your email for the username and password. You'll be redirected back into ODSAWS Signup to complete the linking.",
    stepNumberLabel: "Step {step}",
    supportLink: "Contact support",
    wizardAccountIDTitle: "Enter account ID",
    wizardAccountValidationTitle: "AWS account validation",
    wizardBannerContent1:
      "Before you can use the Oracle Database Service for AWS (ODSAWS) with your AWS applications, you must first complete several configuration steps that connect your AWS environment to Oracle Cloud Infrastructure (OCI). The configuration steps are:",
    wizardBannerStep1: "Enter the account ID and perform validation",
    wizardBannerStep2: "Setup CloudFormation Stack and validate roles present",
    wizardBannerStep3: "Sign into the cognito account",
    wizardCFValidationTitle: "CloudFormation validation",
    wizardLaunchCFTitle: "Launch CloudFormation"
  },
  cloudSignInCard: {
    cloudAccountName: "Cloud Account Name",
    forgotYourCloudAccountName: "Forgot your cloud account name? ",
    getHelp: "Get help",
    next: "Next",
    notAnOracleCloudCustomer: "Not an Oracle Cloud customer yet?",
    signUp: "Sign Up"
  },
  common: {
    activate: "Create Account",
    cancel: "Cancel",
    close: "Close",
    contactSupport: "Contact Support",
    continue: "Continue",
    edit: "Edit",
    learnMore: "Learn more",
    o4ATitle: "Oracle Database Service for Azure",
    retry: "Retry",
    tryAgain: "Try Again"
  },
  countries: {
    AD: "Andorra",
    AE: "United Arab Emirates",
    AF: "Afghanistan",
    AG: "Antigua & Barbuda",
    AI: "Anguilla",
    AL: "Albania",
    AM: "Armenia",
    AO: "Angola",
    AQ: "Antarctica",
    AR: "Argentina",
    AS: "American Samoa",
    AT: "Austria",
    AU: "Australia",
    AW: "Aruba",
    AX: "Åland Islands",
    AZ: "Azerbaijan",
    BA: "Bosnia & Herzegovina",
    BB: "Barbados",
    BD: "Bangladesh",
    BE: "Belgium",
    BF: "Burkina Faso",
    BG: "Bulgaria",
    BH: "Bahrain",
    BI: "Burundi",
    BJ: "Benin",
    BL: "St. Barthélemy",
    BM: "Bermuda",
    BN: "Brunei",
    BO: "Bolivia",
    BQ: "Caribbean Netherlands",
    BR: "Brazil",
    BS: "Bahamas",
    BT: "Bhutan",
    BV: "Bouvet Island",
    BW: "Botswana",
    BY: "Belarus",
    BZ: "Belize",
    CA: "Canada",
    CC: "Cocos (Keeling) Islands",
    CD: "Congo - Kinshasa",
    CF: "Central African Republic",
    CG: "Congo - Brazzaville",
    CH: "Switzerland",
    CI: "Côte d'Ivoire",
    CK: "Cook Islands",
    CL: "Chile",
    CM: "Cameroon",
    CN: "China",
    CO: "Colombia",
    CR: "Costa Rica",
    CV: "Cape Verde",
    CW: "Curaçao",
    CX: "Christmas Island",
    CY: "Cyprus",
    CZ: "Czechia",
    DE: "Germany",
    DJ: "Djibouti",
    DK: "Denmark",
    DM: "Dominica",
    DO: "Dominican Republic",
    DZ: "Algeria",
    EC: "Ecuador",
    EE: "Estonia",
    EG: "Egypt",
    EH: "Western Sahara",
    ER: "Eritrea",
    ES: "Spain",
    ET: "Ethiopia",
    FI: "Finland",
    FJ: "Fiji",
    FK: "Falkland Islands",
    FM: "Micronesia",
    FO: "Faroe Islands",
    FR: "France",
    GA: "Gabon",
    GB: "United Kingdom",
    GD: "Grenada",
    GE: "Georgia",
    GF: "French Guiana",
    GG: "Guernsey",
    GH: "Ghana",
    GI: "Gibraltar",
    GL: "Greenland",
    GM: "Gambia",
    GN: "Guinea",
    GP: "Guadeloupe",
    GQ: "Equatorial Guinea",
    GR: "Greece",
    GS: "South Georgia & South Sandwich Islands",
    GT: "Guatemala",
    GU: "Guam",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HK: "Hong Kong SAR China",
    HM: "Heard & McDonald Islands",
    HN: "Honduras",
    HR: "Croatia",
    HT: "Haiti",
    HU: "Hungary",
    ID: "Indonesia",
    IE: "Ireland",
    IL: "Israel",
    IM: "Isle of Man",
    IN: "India",
    IO: "British Indian Ocean Territory",
    IQ: "Iraq",
    IS: "Iceland",
    IT: "Italy",
    JE: "Jersey",
    JM: "Jamaica",
    JO: "Jordan",
    JP: "Japan",
    KE: "Kenya",
    KG: "Kyrgyzstan",
    KH: "Cambodia",
    KI: "Kiribati",
    KM: "Comoros",
    KN: "St. Kitts & Nevis",
    KR: "South Korea",
    KW: "Kuwait",
    KY: "Cayman Islands",
    KZ: "Kazakhstan",
    LA: "Laos",
    LB: "Lebanon",
    LC: "St. Lucia",
    LI: "Liechtenstein",
    LK: "Sri Lanka",
    LR: "Liberia",
    LS: "Lesotho",
    LT: "Lithuania",
    LU: "Luxembourg",
    LV: "Latvia",
    LY: "Libya",
    MA: "Morocco",
    MC: "Monaco",
    MD: "Moldova",
    ME: "Montenegro",
    MF: "St. Martin",
    MG: "Madagascar",
    MH: "Marshall Islands",
    MK: "Macedonia",
    ML: "Mali",
    MM: "Myanmar (Burma)",
    MN: "Mongolia",
    MO: "Macau SAR China",
    MP: "Northern Mariana Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MS: "Montserrat",
    MT: "Malta",
    MU: "Mauritius",
    MV: "Maldives",
    MW: "Malawi",
    MX: "Mexico",
    MY: "Malaysia",
    MZ: "Mozambique",
    NA: "Namibia",
    NC: "New Caledonia",
    NE: "Niger",
    NF: "Norfolk Island",
    NG: "Nigeria",
    NI: "Nicaragua",
    NL: "Netherlands",
    NO: "Norway",
    NP: "Nepal",
    NR: "Nauru",
    NU: "Niue",
    NZ: "New Zealand",
    OM: "Oman",
    PA: "Panama",
    PE: "Peru",
    PF: "French Polynesia",
    PG: "Papua New Guinea",
    PH: "Philippines",
    PK: "Pakistan",
    PL: "Poland",
    PM: "St. Pierre & Miquelon",
    PN: "Pitcairn Islands",
    PR: "Puerto Rico",
    PS: "Palestinian Territories",
    PT: "Portugal",
    PW: "Palau",
    PY: "Paraguay",
    QA: "Qatar",
    RE: "Réunion",
    RO: "Romania",
    RS: "Serbia",
    RU: "Russia",
    RW: "Rwanda",
    SA: "Saudi Arabia",
    SB: "Solomon Islands",
    SC: "Seychelles",
    SE: "Sweden",
    SG: "Singapore",
    SH: "St. Helena",
    SI: "Slovenia",
    SJ: "Svalbard & Jan Mayen",
    SK: "Slovakia",
    SL: "Sierra Leone",
    SM: "San Marino",
    SN: "Senegal",
    SO: "Somalia",
    SR: "Suriname",
    SS: "South Sudan",
    ST: "São Tomé & Príncipe",
    SV: "El Salvador",
    SX: "Sint Maarten",
    SZ: "Swaziland",
    TC: "Turks & Caicos Islands",
    TD: "Chad",
    TF: "French Southern Territories",
    TG: "Togo",
    TH: "Thailand",
    TJ: "Tajikistan",
    TK: "Tokelau",
    TL: "Timor-Leste",
    TM: "Turkmenistan",
    TN: "Tunisia",
    TO: "Tonga",
    TR: "Turkey",
    TT: "Trinidad & Tobago",
    TV: "Tuvalu",
    TW: "Taiwan",
    TZ: "Tanzania",
    UA: "Ukraine",
    UG: "Uganda",
    UM: "U.S. Outlying Islands",
    US: "United States",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VA: "Vatican City",
    VC: "St. Vincent & Grenadines",
    VE: "Venezuela",
    VG: "British Virgin Islands",
    VI: "U.S. Virgin Islands",
    VN: "Vietnam",
    VU: "Vanuatu",
    WF: "Wallis & Futuna",
    WS: "Samoa",
    YE: "Yemen",
    YT: "Mayotte",
    ZA: "South Africa",
    ZM: "Zambia",
    ZW: "Zimbabwe"
  },
  cyberSourceErrMsg: {
    avsHeader:
      "Incorrect billing address. Your billing address doesn't match information from your credit card provider.",
    avsMessage:
      "Please edit your payment method to edit your billing address. If you continue to encounter the error, please reach out to your issuing bank.",
    editPaymentMethod: "Edit Payment Method",
    footer:
      "If you believe you provided us with accurate payment information to verify your identity, please contact our Oracle Customer Service Team.",
    header202: "Your credit or debit card has expired.",
    header231: "You have provided an invalid account number.",
    header2xx: "Your credit or debit card has been declined",
    header481:
      "We're sorry we could not create the account as your identity information could not be verified.",
    message202:
      "Please edit your payment method to update expiration date or provide a valid credit or debit card. If you continue to encounter the error, please reach out to your issuing bank.",
    message231:
      "Please edit your payment method to provide a valid credit or debit card. If you continue to encounter the error, please reach out to your issuing bank.",
    message2xx:
      "Please edit your payment method to provide a valid credit or debit card. If you continue to encounter the error, please reach out to your issuing bank.",
    message481:
      "Did you use a prepaid or virtual card as your identity verification method? We accept only credit cards and debit cards.",
    psd2Header: "Error processing transaction",
    psd2Message:
      "Please edit your payment method to provide a valid credit card.",
    title: "Verification Failure"
  },
  errorPage: {
    blockPopupSubtitle:
      "This can happen if you are using IE or if popups are blocked in the browser. Please allow pop-ups from this site.",
    blockPopupTitle: "Error opening popup window",
    couldNotLink: "We could not link your accounts",
    existingCloudLinkAwsError:
      "Specified AWS Account ID is already associated with another Multicloud link resource in this account.",
    existingCloudLinkAzureError:
      "Specified Azure tenant ID is already associated with another Multicloud link resource in this tenancy.",
    existingCloudLinkGcpError:
      "Specified Gcp project ID is already associated with another Multicloud link resource in this tenancy.",
    existingCloudLinkOciError:
      "An {cloudType} Multicloud link resource already exists in this tenancy.",
    failedCreation: "Oracle Cloud account could not be created",
    failedCreationSubtitle:
      "We could not create your Oracle Cloud account. Please contact our Oracle Customer Service Team.",
    failedLinking: "{cloudType} and Oracle Cloud accounts linking failed",
    failedLinkingSubtitle:
      "We could not link your {cloudType} and Oracle Cloud accounts. Please contact our Oracle Customer Service Team.",
    fullScreenPopupTitle: "Error in full-screen mode",
    fullScreenSubtitle:
      "Your browser might be in full-screen mode. If so, please turn off full-screen and try again.",
    genericErrorSubtitle:
      "It looks like something went wrong while processing your request. Please contact Oracle support or wait a few minutes and try again.",
    genericErrorTitle: "Oops! We're sorry...",
    learnMore: "Learn more",
    missingAzurePrerequisitesSubtitle:
      "Your Azure account or Azure user does not meet the required prerequisites for ODSA setup. See <a href='{link}' target='_blank' rel='noopener noreferrer'>Prerequisites for Oracle Database Service for Azure</a> for more information.",
    nestedPopupSubtitle:
      "Please close this pop-up tab and go back to ODSA signup to continue.",
    nestedPopupTitle: "User authorized",
    noRegionSubscription:
      "Your OCI tenancy is not subscribed to a region that offers Oracle Database for {cloudType}. Log into your tenancy and subscribe to a supported region, then try the sign up again.",
    nonHenosisTroubleshootingSubtitle:
      "To sign up for {providerType}, your OCI tenancy must support identity domains. See <a href='{link}' target='_blank' rel='noopener noreferrer'>Troubleshooting</a> for information on making identity domains available to your Oracle Cloud account.",
    nonRootCompFailedLinkingSubtitle:
      "Multicloud link resources must be created in the root compartment of the OCI tenancy.",
    plannedMaintenanceActivity:
      "{providerType} account creation not available at this time",
    plannedMaintenanceActivityActivation:
      "{providerType} account activation not available at this time",
    plannedMaintenanceActivityDetail:
      "Oracle cannot create your Oracle Database Service for {cloudType} account at this time because of planned system maintenance. Please try again later.",
    plannedMaintenanceActivityDetailActivation:
      "Oracle cannot activate your Oracle Database Service for {cloudType} account at this time because of planned system maintenance. Please try again later.",
    plannedMaintenanceActivityDetailExisting:
      "Oracle cannot link your OCI and {cloudType} cloud accounts at this time because of planned system maintenance. Please try again later.",
    plannedMaintenanceActivityExisting:
      "{providerType} account linking not available at this time",
    proceedAnyway: "Proceed anyway",
    refreshButton: "Refresh",
    unexpectedError: "Unexpected error occurred"
  },
  footer: {
    adChoices: "Ad Choices",
    cookiePreferenceUnavailable: "Cookie preferences unavailable",
    copyright: "© 2022 Oracle",
    info: "Do Not Sell My Info",
    support: "Support",
    terms: "Privacy"
  },
  gcpFooter: {
    cookiePreferencesUnavailable: "Cookie preferences unavailable",
    copyright:
      "Copyright © {year}, Oracle and/or its affiliates. All rights reserved.",
    privacy: "Privacy",
    terms: "Terms"
  },
  gcpHome: {
    beforeYouBegin: "Before you begin",
    body1:
      "This site will guide you through the steps to onboard with Oracle Database for Google Cloud.",
    body2:
      "The Oracle Database for Google Cloud onboarding configuration steps are:",
    createOciAccount: "Create an OCI cloud account",
    getStarted: "Get Started",
    optional:
      "Optionally, you can set up identity federation for your OCI account using Google Cloud as your identity provider. ",
    requiredHeader: "You must have the following to begin your onboarding:",
    requiredGcpCloudAccount: "A Google Cloud account with at least one project",
    requiredOciCloudAccount:
      "An Oracle Cloud Infrastructure (OCI) cloud account. ",
    steps: {
      step1: "Sign in to your OCI account.",
      step2:
        "Grant permissions for the signup application to link your OCI account to your Google Cloud account and configure Oracle Database for Google Cloud Service.",
      step3: "Select the Google Cloud organization and service account name.",
      step4:
        "Select the Google Cloud projects you want to configure for Oracle Database for Google Cloud.",
      step5:
        "Select the home region for your Oracle Database for Google Cloud Service."
    },
    welcome: "Welcome to Oracle Database for Google Cloud"
  },
  gcpSignup: {
    button: {
      cancel: "CANCEL",
      continue: "CONTINUE",
      back: "GO BACK"
    },
    cloudlinkErrorPage: {
      cancelCloudLinkingBody: "Cancels the Multicloud linking process.",
      cancelCloudLinkingHeader: "Cancel Multicloud linking",
      contactOracleSupport: "Please contact Oracle Support.",
      defaultErrorMessage: "Multicloud link failed for unknown reason. ",
      errorHeader:
        "There was an error linking your Oracle Cloud account with your GCP account for the following reason:",
      projectSelectionBody: "Projects:",
      projectSelectionHeader: "Edit project selections",
      regionSelectionBody: "Region:",
      regionSelectionHeader: "Edit region selection",
      retryBody:
        "Go back to the previous screen and retry the Multicloud linking.",
      retryHeader: "Retry",
      selectOptionPrompt: "Please select an option below:",
      serviceAccountBody: "Service account:",
      serviceAccountHeader: "Edit service account name selection",
      organizationBody: "Organization:",
      organizationHeader: "Edit organization selection"
    },
    error: {
      serviceAccountNameLength:
        "Service account name must between {min} to {max} characters.",
      serviceAccountNameRegex:
        "Service account name format must be like SA_NAME@PROJECT_ID.iam.gserviceaccount.com. SA_NAME can only contain alphanumeric characters and dashes.",
      projectsToLinkErr: "Please select at least one project to link.",
      noServiceAccount: "No service account",
      noSupportedRegionsError:
        "The OCI tenancy is not subscribed to any of the supported multicloud regions."
    },
    organizationTitle: "Please select the GCP organization",
    organizationName: "GCP organization",
    serviceNameTitle: "Please select the service account name",
    serviceNameInput: "Service account name",
    serviceNameDefault: "Select a service account",
    gcpProject: "GCP Project",
    gcpProjectDefault: "Select a project",
    noProject: "No Project",
    projectsToLink: "Select the project(s) to link",
    regionsForCouldLink: "Select the home region for the Multicloud link",
    waitLinkODSGCP:
      "Please wait while we link your Oracle Cloud account with your GCP account...",
    waitLinkODSGCPSub:
      "Once your accounts are linked, we will take you to the Oracle Database Service for GCP portal.",
    homeRegionTitle: "Select the home region for the Multicloud link",
    steps: {
      step1: "Select organization",
      step2: "Select service account name",
      step3: "Select projects to link",
      step4: "Select the region",
      step5: "Create Multicloud link"
    }
  },
  hints: {
    addressSubtitle:
      "This should be your current street address. We use this information to verify your identity. Please ensure that you enter the correct address information.",
    addressTitle: "Address",
    companySubtitle: "Enter your company or organization name.",
    companyTitle: "Company Name",
    confirmPasswordSubtitle:
      "Confirm your password by entering it again. This should match the password entered above.",
    confirmPasswordTitle: "Confirm password",
    emailSubtitle:
      "Enter the email address you want to use for creating this account.",
    emailTitle: "Email address",
    homeRegionSubtitle:
      "Your home region contains your account information and cloud identity (IAM) resources. It cannot be changed after your account is created. If you are unsure which region to select as your home region, <a href='{link}' target='_blank' rel='noopener noreferrer'>contact our support team</a> before you create your account.",
    homeRegiontitle: "Home Region",
    tenancySubtitle:
      "The cloud account name will be assigned to your company’s or organization’s environment and used when signing into the OCI console. You can always rename your account later using the console.",
    tenancyTitle: "Cloud Account Name"
  },
  infoBlock: {
    contentO4A:
      "Run cost-optimized, high-performance, and autonomous versions of Oracle Database in the cloud. Oracle Database is the world’s leading converged, multi-model database management system.",
    contentOne:
      "When you sign up for Oracle Cloud, you get a cloud account and an Oracle Cloud Infrastructure tenancy. Oracle assigns the same name to the cloud account and the tenancy.",
    contentTwo:
      "A region is a localized geographic area. A region is composed of one or more availability domains, which represent the physical data centers within the region. Oracle Cloud Infrastructure resources are either region-specific, such as a virtual cloud network, or availability domain-specific, such as a compute instance.",
    titleO4A: "Oracle Database Service for {cloudType} Portal",
    titleOne: "What is a Cloud Account?",
    titleTwo: "About Regions"
  },
  keepAlive: {
    closeButton: "Refresh page",
    dialogContent1: "Your session will expire in '{displayTimer}' minutes",
    dialogContent2:
      "Your session timed out because of inactivity. Please reload the page to continue.",
    dialogTitle1: "Session Is About to Expire",
    dialogTitle2: "Session expired"
  },
  landingPage: {
    fullyAutomatedButton: "Start fully automated configuration",
    fullyAutomatedDescription:
      "When you choose this option, you will grant ODSA the Azure permissions needed to automate the three configuration steps listed above. During the fully automated configuration, we will ask for the following Azure permissions:",
    fullyAutomatedFooter:
      "When the account linking and automated ODSA configuration is complete, the browser will redirect you to the ODSA portal, and you can begin provisioning Oracle Databases.",
    fullyAutomatedPermission1:
      "Access Azure Service Management as you (preview)",
    fullyAutomatedPermission2:
      "Manage permission grants and app role assignments",
    fullyAutomatedPermission3: "Read and write all groups",
    fullyAutomatedPermission4: "Read and write all user’s full profiles",
    fullyAutomatedPermission5: "Read and write applications",
    fullyAutomatedPermission6:
      "Read and write your organization’s application configuration policies",
    fullyAutomatedPermission7: "Read directory RBAC settings",
    fullyAutomatedPermission8: "Read your organization's policies",
    fullyAutomatedPermission9: "Sign you in and read your profile",
    fullyAutomatedTitle: "Fully Automated Configuration Details",
    guidedLinking: "Guided Account Linking Details",
    guidedLinkingButton: "Start guided account linking",
    guidedLinkingDescription:
      "This option links your Azure and Oracle accounts automatically, then redirects you to the ODSA portal. There, you’ll see instructions for how to manually link your Azure subscriptions to ODSA. You will also see instructions for identity federation, which is optional with guided account linking.",
    landingTitle: "Welcome to Oracle Database Service for Azure",
    optionDetail:
      "You can choose either <strong>fully automated configuration</strong> or <strong>guided account linking</strong>. Fully automated configuration is faster and more convenient, but requires granting the ODSA application more Azure permissions to set up the service. Note that fully automated configuration performs all three configuration steps listed above.",
    optionDifference:
      "If you prefer to grant ODSA only the minimum set of permissions required to get started, and do not want automated identity federation, use the guided account linking option. Guided account linking requires you to do a few manual configuration steps in your Azure account after the initial account linking, and will take more time to complete. The choice you make usually depends on your organization's size and security requirements.",
    optionValidation:
      "Start by validating that your Azure environment and your Azure account meet the following prerequisites. Then, choose a configuration option by clicking either <strong>Fully automated configuration</strong> or <strong>Guided account linking</strong> below.",
    options: "Sign Up Options",
    step1:
      "<strong>Account Linking</strong> – Connects your Azure and OCI accounts. This is required to use ODSA.",
    step2:
      "<strong>Subscription Linking</strong> – Links ODSA to one or more Azure subscriptions so ODSA can provision resources in those subscriptions. This is required to use ODSA.",
    step3:
      "<strong>Identity Federation</strong> – Enables Azure users to authenticate to the OCI Console using their Azure Active Directory credentials. To access the ODSA portal, you will always use your Azure credentials. This step is included in the fully automated configuration option discussed below. This step is optional if you use the guided account linking option discussed below.",
    stepsFooter:
      "The account linking step links your Azure and OCI accounts. It is automated for your safety and convenience in both of the sign up options. If you don't have an Oracle Cloud account, you can create one during this step. The subscription linking and identity federation steps that follow can be automated or performed manually, depending on the sign up option you choose.",
    stepsHeader:
      "Before you can use the Oracle Database Service for Azure (ODSA) with your Azure applications, you must first complete several configuration steps that connect your Azure environment to Oracle Cloud Infrastructure (OCI). The configuration steps are:"
  },
  linkingO4AModal: {
    closeModal: "Close",
    defaultTerms1:
      "THIS FEATURE IS PROVIDED ON A PRE-GENERALLY AVAILABLE BASIS FOR EVALUATION AND INFORMATION SHARING PURPOSES ONLY, AND IS NOT A COMMITMENT BY ORACLE TO DELIVER ANY MATERIAL, CODE, FUNCTIONALITY, OR SERVICES. THE DEVELOPMENT, RELEASE, AND TIMING OF ANY FEATURES OR FUNCTIONALITY FOR ORACLE’S PROGRAMS AND SERVICES REMAINS AT THE SOLE DISCRETION OF ORACLE. ALL RELEASE DATES OR OTHER PREDICTIONS OF FUTURE EVENTS PROVIDED TO YOU ARE SUBJECT TO CHANGE. YOU SHOULD NOT RELY ON THE FUTURE AVAILABILITY OF ANY FUTURE ORACLE PROGRAM OR SERVICE IN ENTERING INTO ANY LICENSE OR SERVICE AGREEMENT WITH ORACLE. ORACLE RESERVES THE RIGHT TO MAKE CHANGES OR UPDATES TO ORACLE PROGRAMS AND SERVICES AT ANY TIME WITHOUT NOTICE.",
    defaultTerms2:
      "This Oracle Cloud Service may enable You to link to, transfer Your Content or Third Party Content to, or otherwise access third party’s websites, platforms, content, products, and information (“Third Party Services”). Oracle does not control and is not responsible for Third Party Services, the performance or availability of Third Party Services, or any content received or sent. If you transfer or cause the transfer of your content from this Oracle Cloud Service to a Third Party Service or other location, that transfer constitutes a distribution by you and not by Oracle.",
    defaultTerms3:
      "EXCEPT FOR ORACLE CLOUD SERVICES LISTED IN ORACLE CLOUD SERVICES SERVICE DESCRIPTIONS (located at <a target='_blank' rel='noopener noreferrer' href='{link}'>http://oracle.com/contracts</a>), ORACLE EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND FOR ANY PRODUCTS PROVIDED THROUGH THIS SITE; THIS DISCLAIMER COVERS ALL WARRANTIES WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, COMPATIBILITY WITH OTHER PRODUCTS, AND NONINFRINGEMENT, AS FURTHER DESCRIBED IN THE TERMS OF USE FOR THIS SITE.",
    defaultTerms4:
      "ORACLE CLOUD SERVICES LISTED IN ORACLE CLOUD SERVICES SERVICE DESCRIPTIONS (located at <a target='_blank' rel='noopener noreferrer' href='{link}'>http://oracle.com/contracts</a>) ARE WARRANTED SUBJECT TO THE TERMS OF THE APPLICABLE ORACLE CLOUD SERVICES AGREEMENT UNDER WHICH THEY ARE LICENSED. PRODUCTS AND SERVICES PROVIDED BY THIRD PARTIES MAY OR MAY NOT BE WARRANTED UNDER TERMS PROVIDED BY THE THIRD PARTY LICENSOR AND ORACLE SHALL BEAR NO RESPONSIBILITY FOR THE CONTENT, FORMAT, OR ENFORCEABILITY OF THIRD PARTY LICENSES OR DISCLAIMERS. YOU ARE RESPONSIBLE FOR COMPLYING WITH THE TERMS OF THIRD PARTY LICENSE AGREEMENTS AND FOR THE INSTALLATION, OPERATION, AND MAINTENANCE OF THIRD PARTY PRODUCTS PROVIDED THROUGH THIS SITE.",
    defaultTerms5:
      "BY SELECTING THE \"I ACCEPT\" BUTTON OR BOX (OR THE EQUIVALENT), YOU ACKNOWLEDGE ORACLE'S DISCLAIMER OF WARRANTIES AND THE ORACLE.COM TERMS OF USE (located at: <a target='_blank' rel='noopener noreferrer' href='{link}'>http://www.oracle.com/us/legal/terms/index.html</a>). IN ADDITION, YOU ACKNOWLEDGE THAT PRODUCTS PROVIDED THROUGH THIS SITE MAY BE HOSTED IN AN ORACLE ENVIRONMENT ON ORACLE-OWNED HARDWARE, OR IN A THIRD PARTY ENVIRONMENT ON HARDWARE OWNED BY A THIRD PARTY. THIS MEANS THAT YOUR DATA MAY BE STORED WITHIN AN ORACLE OPERATED ENVIRONMENT OR WITHIN A THIRD PARTY OPERATED ENVIRONMENT.",
    linkingProcess1:
      "Set up user federation between your OCI tenancy and your Azure tenancy. Federation enables OCI to use Azure Active Directory (the user identity provider) to manage OCI user logins and passwords, and to authenticate users for access to OCI services and resources.",
    linkingProcess2:
      "Create an OCI Multicloud principal in Azure Active Directory with the permissions needed by OCI to make calls or push data to your Azure tenancy.",
    linkingProcess3:
      "Create a new compartment in the OCI tenancy corresponding to the specified Azure subscription.",
    linkingProcess4:
      "Create a new identity domain in the OCI tenancy that is federated with Azure Active Directory.",
    linkingProcess5:
      'Create a "Multicloud link" resource in OCI to store metadata for links between Azure and OCI. This resource also stores Azure Resource Manager (ARM) roles for Oracle features. Oracle will also create a set of policies in the OCI tenancy that allow the Multicloud link resource principal to execute OCI API requests from Azure users.',
    linkingProcess6:
      "Create a resource principal in OCI for the Multicloud link resource. A resource principal allows you to authorize Azure services to make API calls to OCI services without needing to provide credentials for every call.",
    linkingProcessHeader:
      "As a part of the account-linking process, we will create:",
    linkingTitle: "Linking {cloudType} and Oracle Cloud accounts",
    permissionsHeader:
      "The permission below is needed to link your {cloudType} account to this Oracle Cloud account.",
    termsOfUse: "Terms of Service"
  },
  loadingroom: {
    login: "Please wait while we redirect you to {cloudType} login..."
  },
  marketing: {
    heatwaveSubtitle:
      "HeatWave is a massively parallel, high performance, in-memory query accelerator for the Oracle MySQL Database Service that accelerates MySQL performance by orders of magnitude for analytics and mixed workloads.",
    heatwaveTitle: "Run high performance queries with Heat Wave",
    oracleDBSubtitle:
      "Deliver an integrated platform that allows you to deploy and manage Oracle Cloud databases with the {cloudType} experience.",
    oracleDBTitle: "Manage Oracle Cloud Databases with {cloudType}"
  },
  mobileVerify: {
    codeRequestOpenDialogOnly:
      "If you already have a verification code for the above mobile number and based on your email: '{username}' ,<a href='{link}'>click here</a> to verify code.",
    contactPhoneLabel: "Contact phone number",
    customerPhoneLabel: "Customer phone number",
    maxAttemptsTitle: "You have reached the maximum number of attempts",
    mobileMaxAttemptsError:
      "If you wish to try again, please return to signup and provide a valid contact number.",
    mobileNumberDescription:
      "Oracle does not accept text-only mobile numbers, as we may need to speak to you if there are questions about your account. This verification code will be sent in a text message to your mobile phone. Standard text messaging rates apply.",
    mobileNumberInfo:
      "Please provide a valid phone number. Oracle does not accept text only mobile numbers as we may need to speak to you to assist you with your account.",
    mobileTrunkMessage:
      "Do not enter trunk prefix codes when entering your mobile number. For example, enter 123... instead of *0*123... or *1*123. Enter numbers only, and do not include spaces or special characters.",
    mobileVerificationCode: "Mobile verification code",
    mobileVerificationLabel: "Mobile verification",
    mobileVerifyTimer:
      "If you don't receive the mobile verification code in '{displayMobileTimer}' minutes , then you may request another code.",
    phone: "Phone Number",
    pleaseVerify: "Please verify the code we sent to your mobile number.",
    smsValidationCodeErrorDetail:
      "You have entered an invalid code. Please check your text message and try the code again, or request another code.",
    smsValidationCodeErrorFormat: "Invalid code format.",
    smsValidationCodeErrorSummary: "Invalid Code.",
    verificationCode: "Verification code",
    verificationCodeInfo: "Enter the code that was sent to your mobile device",
    verifymycode: "Verify my code"
  },
  nonAuth: {
    btnTxt: "Learn more",
    creating:
      "The order is no longer valid and cannot be activated. Please contact Oracle Support for assistance with account creation.",
    linkTxt: "Start for free.",
    message: "There was a problem activating your order",
    read: "You can read more about Oracle Cloud Infrastructure.",
    signup: "Looking to try Oracle Cloud Free Tier?",
    title: "Cloud Infrastructure"
  },
  nonPending: {
    btnTxt: "Contact Customer Support",
    message: "There was a problem activating your order.",
    messageActivating:
      "The order is currently being activated. You will receive an email once activation is complete.",
    messageActive:
      "The order has already been activated. Please contact support for further information.",
    messageDeleted:
      "The order is no longer valid and cannot be activated. Please contact support to sort this problem.",
    messageExpired:
      "The order you are trying to complete has expired. To process the order, contact Oracle Sales and ask for help with Oracle Database Service for {cloudType}.",
    messageHold:
      "The order cannot be activated at this time because it is still being processed. Please wait a few minutes, then try again.",
    title: "Cloud Infrastructure"
  },
  o4aActivate: {
    leftTitle: "Welcome {name}"
  },
  o4aSignIn: {
    leftDescription:
      "Run cost-optimized, high-performance, and autonomous versions of Oracle Database in the cloud. Oracle Database is the world’s leading converged, multi-model database management system.",
    leftTitle: "Oracle Database Service for {cloudType} Portal"
  },
  ociLandingPage: {
    cloudAccountName: "Oracle Cloud account name",
    continue: "Continue",
    cookiePreferences: "Cookie Preferences",
    enterTenancy:
      "Enter your Oracle Cloud account name to sign in to your account.",
    existingOciSigninTitle:
      "Continue as the currently signed in OCI user, or select <strong>Use another OCI account.</strong>",
    existingUserStep1: "Select Azure subscriptions",
    existingUserStep2: "Sign in to your Oracle Cloud account",
    existingUserStep3: "Select a primary region for {providerType}",
    existingUserStep4: "Link your Oracle Cloud and {cloudType} accounts",
    existingUserTitle: "Already have an Oracle Cloud account?",
    leftDescription:
      "Thanks for signing into your {cloudType} account. Let’s set up your Oracle Cloud account and then link it to your {cloudType} account.",
    leftTitle: "Welcome {name}",
    logout: "Use another {cloudType} account",
    newUserStep1: "Select Azure subscriptions",
    newUserStep2: "Sign up for a new Oracle Cloud account",
    newUserStep3: "Link your Oracle Cloud and {cloudType} accounts",
    newUserTitle: "Not an Oracle Cloud customer yet?",
    ociLogoutLink: "Use another OCI account",
    privacy: "Privacy",
    region: "Region",
    selectRegionSubtitle:
      "Oracle Database for {cloudType} ({providerType}) is available in OCI regions that offer a high-performance cross-cloud connection between OCI and {cloudType} data centers. Select the primary region where you will deploy {providerType} resources.",
    selectRegionTitle: "Select Region",
    signInTitle: "Sign in to your Oracle Cloud account",
    signUp: "Sign Up",
    termsOfUse: "Terms of Use",
    yourOracleCloudAccount: "Your Oracle Cloud Account"
  },
  password: {
    confirmPass: "Confirm Password",
    confirmedError: "Passwords do not match",
    pass: "Password"
  },
  passwordRules: {
    lessThan40Chars: "Less than 40 characters",
    moreThan8Chars: "More than 8 characters",
    noEmail: "No email address",
    noFirstName: "No first name",
    noLastName: "No last name",
    noSpaces: "No spaces",
    noSpecialChars: "No ` ~ > \\\\ characters",
    oneLowercase: "One lowercase character",
    oneNumeric: "One numeric character",
    oneSpecialChar: "One special character",
    oneUppercase: "One uppercase character",
    passwordRules: "Password Rules"
  },
  personalInfo: {
    address1: "Address Line 1",
    address2: "Address Line 2",
    address3: "Address Line 3",
    address4: "Address Line 4",
    addressInfo: "Address information",
    addressPlaceholder: "Enter your address",
    city: "City",
    cityPlaceholder: "Enter your city",
    cnpjPlaceholder: "Enter the CNPJ number formatted as xxxxxxxx/xxxx-xx",
    county: "County",
    countyPlaceholder: "Enter your county",
    cpfPlaceholder: "Enter the CPF number formatted as xxxxxxxx-xx",
    dept: "Department",
    district: "District",
    externalNum: "External Number",
    intNum: "Internal Number",
    neighborPlaceholder: "Enter your neighborhood",
    neighborhood: "Neighborhood",
    optional: "Optional",
    postalcode: "Postal Code",
    province: "Province",
    provincePlaceholder: "Enter your province",
    requiredMsg: "A value for {translatedName} is required.",
    state: "State",
    statePlaceholder: "Enter state",
    street: "Street",
    streetPlaceholder: "Enter street",
    zip: "Zip or Postal Code",
    zipPlaceholder: "Enter Zip or Postal Code"
  },
  regExpr: {
    accountName:
      "Use only lowercase English letters and numbers, and start with a letter.",
    address:
      "Use only letters, numbers, apostrophes, hyphens, commas, spaces, parenthesis, ampersands, slashes, hashes, and periods.",
    altEnglishCompanyName:
      "Use only English letters, numbers, apostrophes, hyphens, commas, spaces, parenthesis, ampersands, and periods. Company name can only start with a letter or number and can be 1 to 200 characters long.",
    arZipCodeMsg:
      "Enter 4 to 8 letters and numbers in the format 9999 or X9999XXX, where 9 is any number and X is any letter. Example: 1234 or A1234BSX",
    auZipCodeMsg: "Enter 4 numbers. Example: 9406",
    brBillingCnpjMsg:
      "Enter billing CNPJ in the format 99999999/9999-99, where 9 is any number. Example: 12345678/0001-18",
    brBillingCpfMsg:
      "Enter billing CPF in the format 999999999-99, where 9 is any number. Example: 655156112-18",
    brCnpjMsg:
      "Enter CNPJ in the format 99999999/9999-99, where 9 is any number. Example: 12345678/0001-18",
    brCpfMsg:
      "Enter CPF in the format 999999999-99, where 9 is any number. Example: 655156112-18",
    brZipCodeMsg:
      "Enter 8 numbers in the format 99999-999, where 9 is any number. Example: 12345-678",
    caZipCodeMsg:
      "Enter 6 numbers or letters in the format X9X 9X9, where X is any letter and 9 is any number. Example: K7P 1K4",
    chZipCodeMsg: "Enter 4 numbers. Example: 8010",
    clZipCodeMsg:
      "Enter 7 numbers in the format 9999999 or 999-9999, where 9 is any number. Example: 1234567 123-4567",
    crZipCodeMsg:
      "Enter 4 or 5 numbers in the format 9999 or 99999, where 9 is any number. Example: 1234 or 12345",
    departmentName: "Department Name cannot be more than 60 characters",
    ecZipCodeMsg:
      "Enter 6 numbers and letters in the format EC999999, where 9 is any number. Example: EC123456",
    email:
      "Enter an email address in the format username@example.com, with a maximum length of 50 characters.",
    firstName:
      "Use only letters, apostrophes, hyphens, commas, spaces, and periods. The value must start with a letter, and must be between 1 and 25 characters long.",
    gbZipCodeMsg:
      "Enter 5 to 8 numbers or letters with space. Example: X9X 9XX, X9 9XX, X99 9XX, X9X X99, XX9 9XX, XX99 9XX, XX9X 9XX, where 9 is any number and X is any letter.",
    ggZipCodeMsg:
      "Enter 6 numbers or letters with space in the format XX9 9XX, where 9 is any number and X is any letter. Example: AB5 8WR",
    ilZipCodeMsg: "Enter 5 numbers or 7 numbers. Example: 94065 or 9406534",
    inServiceTaxMsg: "Enter 15 alphanumeric characters.",
    inZipCodeMsg:
      "Enter 6 numbers in the format 999999 or 999 999, where 9 is any number. Example: 400021 or 400 021",
    itZipCodeMsg:
      "Enter 6 numbers in the format 999 999, where 9 is any number. Example: 823 456",
    jobTitle:
      "Use only letters, numbers and spaces. The value must be between 1 and 40 characters long.",
    jpZipCodeMsg:
      "Enter 7 numbers in the format 999-9999, where 9 is any number.",
    krZipCodeMsg:
      "Enter 6 numbers in the format 999-999, where 9 is any number. Example: 823-456",
    lastName:
      "Use only letters, apostrophes, hyphens, commas, spaces, and periods. The value can only start with a letter, and must be between 1 and 25 characters long.",
    letters: "Use only letters.",
    lvZipCodeMsg:
      "Enter 7 letters and numbers in the format LV-9999, where 9 is any number. Example: LV-1234",
    mobileNumber:
      "Use only numbers. The phone number can only contain numbers and must be 10 digits long, including area code.",
    mobileNumberInt:
      "Use only numbers. The phone number can only contain numbers, and can be from 5 to 15 characters long, with a minimum of 5 digits.",
    nlZipCodeMsg:
      "Enter 4 numbers and two letters in the format 9999 XX, where 9 is any number and X is any letter. Example: 1234 AB",
    noPoBoxMsg: "Do not enter a PO Box number.",
    number: "Use only numbers.",
    omZipCodeMsg:
      "Enter 3 numbers in the format 999, where 9 is any number. Example: 432",
    organization:
      "Use only letters, numbers, apostrophes, hyphens, commas, spaces, parenthesis, ampersands, and periods. The company name can only start with a letter or number and can be up to 200 characters long.",
    otherReason:
      "Use only letters, numbers, apostrophes, hyphens, commas, spaces, parenthesis, ampersands, and periods. Other Reason can only start with a letter or number and can be up to 200 characters long.",
    phoneNumber:
      "Use only numbers, parentheses, hyphens, periods, and spaces. The phone number must start with a number or open parenthesis character and can be from 5 to 22 characters long, with a minimum of 5 digits.",
    plZipCodeMsg:
      "Enter 5 numbers in the format 99999 or 99-999, where 9 is any number. Example: 82345 or 34-345",
    poBoxMsg: "Enter PO Box number. Example: PO Box 1234",
    ptZipCodeMsg:
      "Enter 7 numbers in the format 9999-999 or 9999 999, where 9 is any number. Example: 1234-567 1234 567",
    seZipCodeMsg:
      "Enter 5 numbers in the format 99999 or 999 99, where 9 is any number. Example: 77010 or 770 10",
    sgZipCodeMsg: "Enter 6 numbers. Example: 159088",
    siZipCodeMsg:
      "Enter 4 numbers in the format 9999 or SI-9999, where 9 is any number. Example: 7701 or SI-7710",
    smsValidationCodeErrorDetail:
      "You have entered an invalid code. Check your text message and try the code again, or request another code.",
    smsValidationCodeErrorFormat: "Invalid code format.",
    smsValidationCodeErrorSummary: "Invalid Code.",
    twZipCodeMsg:
      "Enter 3 or 5 numbers in the format 999 or 99999, where 9 is any number. Example: 123 or 12345",
    usZipCodeMsg: "Enter 5 numbers. Example: 94065",
    validProvinceData: "Province not found. Please enter a valid province.",
    validStateData: "State not found. Please enter a valid state."
  },
  securityVerification: {
    addPayment: "Add payment verification method",
    addVerifyMethod: "Add verification method",
    expireson: "Expires on ",
    maxAttemptsInfo:
      "If you wish to try again, please return to <a href='{link}'>signup</a> and provide a valid credit card.",
    maxAttemptsTitle: "You have reached the maximum number of attempts",
    nocharge: "You won't be charged unless you elect to upgrade the account",
    paymentVerifyLabel: "Payment verification",
    thankyou: "Thank you for verifying your details",
    txtCreditCard:
      "You may see a small, temporary charge on your payment method. This is a verification hold that will be removed automatically. See the <a href='{link}' target='_blank' rel='noopener noreferrer'>FAQ</a> for more information",
    txtCreditCard4:
      "Oracle uses third-party payment processor CyberSource for Oracle Store payment processing. CyberSource will request and collect certain information as part of the payment processing. Please refer to CyberSource's privacy statement at <a href='{link}' target='_blank' rel='noopener noreferrer'>http://www.cybersource.com/privacy</a> for the terms applicable to the data collected."
  },
  signUpPage: {
    accountName: "Account Name",
    country: "Country or territory",
    email: "Email",
    emailIDPlaceholder: "Enter an email address to be used with your account",
    firstName: "First Name",
    homeRegion: "Home Region",
    lastName: "Last Name",
    paymentInformation: "Payment Information",
    signUp: "Sign-up Information",
    taggedEmailInfo:
      "We trimmed the tag you added to your email. To sign in to your account, please use your email address as shown.",
    verifyEmail: "Verify my email"
  },
  signup: {
    accountInformation: "Account information",
    accountName: "Cloud Account Name",
    accountNameLengthError: "Account Name cannot be more than 25 characters",
    accountNamePlaceholder: "Account Name",
    altEngCompanyName: "Alternate English Company Name",
    altEngCompanyNamePlaceholder: "Enter alternate English company name",
    api403Error: "Oops, something went wrong",
    api403ErrorDetails:
      "The number of requests has been exceeded. Reload the page or retry the operation. If the problem persists, <a href='{link}' target='_blank' rel='noopener noreferrer'>contact Oracle Support</a>",
    apiError: "Data unavailable",
    apiErrorDetails:
      "Some information in the Oracle Cloud Account Signup form could not be found or processed. Try resubmitting, or reload the page and reenter your information. If the problem persists,  <a href='{link}' target='_blank' rel='noopener noreferrer'>contact Oracle Support</a>",
    armOCIInfo:
      "Because of high demand for Arm Ampere A1 Compute in the South Korea Central (Seoul) and Japan East (Tokyo) regions, A1 instance availability in these regions is limited. If you plan to create A1 instances, we recommend choosing another region as your home region.",
    captchaExpiration: "Verification expired. Check the Captcha checkbox again",
    confirmEmail: "Confirm your email address",
    confirmPassword: "Confirm Password",
    confirmPasswordMatch: "Passwords Matched",
    confirmPasswordPlaceholder: "Re-enter your password",
    confirmPasswordValidation: "Passwords do not match",
    country: "Country or Territory",
    countryError:
      "Sales Self-service for your country is currently unavailable. Please contact <a href='{link}' target='_blank' rel='noreferrer'>Oracle Sales</a> to assist you.",
    departmentName: "Department Name",
    departmentNamePlaceholder: "Enter your Department",
    email: "Email",
    emailExists: "It looks like you are already registered.",
    emailPlaceholder:
      "Enter the email address you want to set as the user name for signing into the OCI Console",
    emailSendFailure:
      "Your verification link could not be delivered to the email provided.",
    emailVerifyLinkSent: "Email verification link sent",
    firstName: "First Name",
    firstNamePlaceholder: "Enter your first name",
    forgotAcctEmail2:
      "<link id='sendEmail'>Click here</link>, and we'll send you an email with the account details",
    forgotAcctEmail3:
      "This email address is already assigned to an Oracle Cloud Account.",
    forgotAcctEmail4:
      "Oracle Cloud access details for all accounts associated with the email have been sent.",
    homeRegion: "Home Region",
    homeRegionInfo:
      "Your <a href='{link1}' target='_blank' rel='noopener noreferrer'>home region</a> is the geographic location where your account and identity (IAM) resources will be created. It cannot be changed after sign-up. <a href='{link2}' target='_blank' rel='noopener noreferrer'>See Regions</a> for service availability.",
    homeRegionPlaceholder: "Select your home region",
    invalidEmail: "Invalid email entered",
    jobTitle: "Job Title",
    jobTitlePlaceholder: "Enter your job title",
    lastName: "Last Name",
    lastNamePlaceholder: "Enter your last name",
    note: "Information",
    oracleEmail:
      "Oracle employee emails are not allowed. Please use another non-oracle email.",
    organization: "Company Name",
    organizationPlaceholder: "Enter your company or organizations name",
    password: "Password",
    passwordCondition1:
      "Password must contain a minimum of 8 characters, with 1 lowercase, 1 uppercase, 1 numeric, and 1 special character",
    passwordCondition2:
      "Password cannot exceed 40 characters, and cannot contain the user's first name, last name, email address, spaces, or {escChars} characters",
    passwordPlaceholder: "Enter a valid password",
    requestSent: "Request sent",
    resendEmail: "Resend Email",
    retry: "Retry",
    sendEmail: "Send Email",
    signupNotAvailable:
      "Self-service sign up for your country is currently unavailable. Please contact Oracle Sales for assistance with you with your sign up.",
    taggedEmailInfo:
      "We trimmed the tag you added to your email. To sign in to your account, please use your email address as shown.",
    username: "Username",
    verifyMyEmail: "Verify my email"
  },
  subscriptionBlock: {
    SearchSubscriptions: "Search subscription",
    noResults: "No results found",
    orderNum: "Order Number",
    subId: "Subscription ID",
    subName: "Subscription Name",
    title: "Select Azure Subscriptions"
  },
  termsOfUse: {
    defaultTerms:
      "I have read and agreed to the <link id='terms'>Terms of Service</link>, <link id='privacy'>Privacy Policy</link> and <link id='linking'>Linking {cloudType} and Oracle Cloud accounts</link>",
    linkingTerms:
      "I have read and agreed to the terms of <link id='linking'>Linking {cloudType} and Oracle Cloud accounts</link>"
  },
  troubleShootingModal: {
    errorMessage: "Message:",
    requestId: "Request Id:",
    timestamp: "Timestamp:",
    troubleShootingTitle:
      "Troubleshooting details (Only for Dev and Preprod testing)"
  },
  uberLandingPage: {
    awsContent:
      "The Oracle Database Service for AWS (ODSAWS) is an Oracle managed service delivering Oracle Cloud Infrastructure (OCI) database services directly to your AWS environment. This service is available in AWS regions offering Oracle's interconnect for AWS, an Oracle managed tunnel connection between OCI and AWS.",
    awsHeader: "Oracle Database Service for AWS",
    azureContent:
      "The Oracle Database Service for Microsoft Azure (ODSA) delivers Oracle Cloud Infrastructure (OCI) database services directly to your Microsoft Azure environment. This service is available in Azure regions offering the Oracle Interconnect for Microsoft Azure, an Oracle managed tunnel connection between OCI and Azure.",
    azureHeader: "Oracle Database Service for Azure",
    continueAWS: "Continue to Oracle Database Service for AWS",
    continueAzure: "Continue to Oracle Database Service for Azure",
    continueGCP: "Continue to Oracle Database Service for GCP",
    gcpContent:
      "The Oracle Database Service for GCP (ODSGCP) is an Oracle managed service delivering Oracle Cloud Infrastructure (OCI) database services directly to your GCP environment. This service is available in GCP regions offering Oracle's interconnect for GCP, an Oracle managed tunnel connection between OCI and GCP.",
    gcpHeader: "Oracle Database Service for GCP",
    uberContentAzureGcp:
      "Oracle Cloud Infrastructure (OCI) offers Multicloud database services for Azure and GCP that allow you to easily integrate OCI database systems into your cloud environment. OCI uses a service-based approach to service setup and resource provisioning. This provides an alternative to manually creating complex cross-cloud deployments for your application stacks.",
    uberContentAzureGcpAws:
      "Oracle Cloud Infrastructure (OCI) offers Multicloud database services for Azure, AWS, and GCP that allow you to easily integrate OCI database systems into your cloud environment. OCI uses a service-based approach to service setup and resource provisioning. This provides an alternative to manually creating complex cross-cloud deployments for your application stacks.",
    uberTitleAzureGcp: "Welcome to Oracle Database Service for Azure and GCP",
    uberTitleAzureGcpAws:
      "Welcome to Oracle Database Service for Azure, AWS, and GCP"
  },
  validationFailures: {
    azureConsentErrorSubtitle1:
      "We cannot activate your Oracle Database Service for Azure subscription because the required Azure permissions were not provided.",
    azureConsentErrorSubtitle2:
      "Questions? Please contact the Oracle Customer Service Team.",
    azureConsentErrorTitle: "Permissions not provided",
    existingLinkHeader: "Your {cloudType} account is already linked!",
    existingLinkSubtitle:
      "{azureAccount} is already linked to an Oracle Cloud account and Oracle Database Service for {cloudType} is active. If you are trying to activate Oracle Database Service for {cloudType} for a different {cloudType} account, please sign in to that {cloudType} account.",
    gcpExistingLinkSubtitle:
      "{gcpAccount} is already linked to an Oracle Cloud account and Oracle Database Service for {cloudType} is active. If you are trying to activate Oracle Database Service for {cloudType} for a different OCI account, please sign in to that OCI account.",
    genericError: "There was a problem validating your account!",
    genericErrorSubtitle:
      "Something went wrong when validating your account permissions. Please contact your {cloudType} account administrator, or try again in a few minutes.",
    indexDBErrorSubtitle:
      "Your web browser is missing IndexedDB feature required by the Oracle Database Service for {cloudType} Sign-up.",
    indexDBErrorTitle: "Missing Browser Requirements",
    logout: "Sign into a different {cloudType} account",
    ociLogout: "Sign into a different OCI account",
    noSubscriptionHeader: "Azure account subscription not found!",
    noSubscriptionSubtitle:
      "Oracle Database Service for Azure requires a valid Azure account subscription. Please get valid account details from your Azure account administrator and try again.",
    redirectConsole: "Go to Oracle Database Service for {cloudType} Console",
    supportedBrowserList: "List of supported browsers",
    troubleshootingGuide: "Troubleshooting guide"
  },
  waitingRoom: {
    contactSupport: " Contact Chat Support",
    createAccount: "Creating your account. This can take up to 5 minutes.",
    longerWaitSubtitle:
      "You will receive an email once your account creation is finalized.",
    longerWaitTitle: "We're still finalizing a few details...",
    message:
      "Please wait while we create your Oracle Cloud account and link it to your {cloudType} account. This can take up to 5 minutes.",
    messageLinking:
      "Please wait while we link your accounts. This can take up to 5 minutes.",
    messageSubtitle:
      "Once the linking is complete, we will take you to Oracle Database Service for {cloudType} portal.",
    needHelp: "Need Help?",
    slide1Content:
      "Oracle Cloud Infrastructure supports traditional workloads and delivers modern cloud development tools. It is architected to detect and defend against security threats, so you can innovate more. Oracle delivers low cost and high performance to lower your total cost of ownership.",
    slide1Header: "Cloud Infrastructure",
    slide1Title: "Innovate with Oracle Cloud Infrastructure",
    timeout:
      "The polling request timed out. You can either retry creating the Multicloud link, or you can cancel."
  }
};

export const i18n = new LoomI18n();

/**
 * Initialize the instance. If no {@code defaultLanguage} are provided, tries to automatically detect the language
 * @param defaultLanguage The language to use initially
 */
export function init(
  defaultLanguage?: string,
  localeOptions?: {
    defaultLocale?: string;
    defaultCurrency?: Currency.Code;
    defaultTimezone?: string;
  }
): Promise<void> {
  return i18n.init({
    remoteResourcesPatterns: [
      "https://locales.plugins.oci.oraclecloud.com/multi-cloud-signup/0.361.0/{{lng}}.json",
      "https://locales.plugins.oci.oraclecloud.com/multi-cloud-signup/{{lng}}.json"
    ],
    defaultLanguage: defaultLanguage,
    resources: bundled,
    localesData: {
      en: enLocaleData
    },
    defaultCurrency: localeOptions && localeOptions.defaultCurrency,
    defaultTimezone: localeOptions && localeOptions.defaultTimezone
  });
}

/**
 * Add a notification callback on initialization time. Please set this before you call @method init
 * @param callback
 */
export function onInit(
  callback: (initLang: string, error?: any) => void
): void {
  i18n.onInit(callback);
}

/**
 * Helper functions for i18n.
 */
export const i18nUtils = {
  /**
   * Function to format currency.
   * @param value
   * @deprecated We will be removing "Code" and only have optional "Options" object where you can specify the currency
   * @param codeOrOptions Currency code to use, defaults to USD. Use getCurrencyCode to map string to Currency.Code enum
   * or Options object to set currencyCode(defaults to USD), displayFormat(Defaults to Symbol) and precision
   * @deprecated We will be removing "displayFormat" in the future. You can set this in the Options object
   * @param displayFormat Display as symbol or name. Eg. $5.00 or 5 US Dollars, Defaults to Symbol.
   * This is ignored if "Options" object is provided
   */
  formatCurrency: function formatCurrency(
    value: number,
    codeOrOptions?: Currency.Code | Currency.Options,
    displayFormat?: Currency.DisplayFormat
  ): string {
    return i18n.formatCurrency(value, codeOrOptions, displayFormat);
  },

  /**
   * Get Currency.Code enum from the specified string. If not found, returns the string value cast as enum.
   * @param code Currency code string to look up.
   */
  getCurrencyCode: function getCurrencyCode(code: string): Currency.Code {
    return (<any>Currency.Code).code || code;
  }
};

/**
 * Add a notification callback on missing translation key. Useful for applications that may need to do
 * work once the translation key is missing in translation files.
 * @param callback
 */
export function onMissingKey(callback: (key: string) => void): void {
  i18n.onMissingKey(callback);
}

/**
 * Function to check if the instance is initialized.
 */
export function isInit(): boolean {
  return i18n.isInitialized;
}

/**
 * Change the language of the current instance.
 * @param language New language to use. Can be either language code (e.g. "en"), or locale code (e.g. "en-US"))
 */
export function changeLanguage(language: string): Promise<void> {
  return i18n.changeLanguage(language);
}

/**
 * Add a notification callback on change of language. Useful for applications that may need
 * to do work once the language is changed (e.g. forceUpdate of the root component in react)
 * @param callback
 */
export function onLanguageChanged(
  callback: (language: string, error?: any) => void
): void {
  i18n.onLanguageChanged(callback);
}

function shouldReturnKey(
  key: string,
  returnKey: boolean | undefined,
  props: { [key: string]: any }
) {
  if (returnKey) {
    return key;
  } else {
    return i18n.t(key, props);
  }
}

export const activate = {
  createErrorDetails: function activate_createErrorDetails(
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("activate.createErrorDetails", returnKey, { link });
  }
};

export const activationForm = {
  action: {
    submit: function activationForm_action_submit(returnKey?: boolean): string {
      return shouldReturnKey("activationForm.action.submit", returnKey, {});
    }
  },
  activateAccountHeader: function activationForm_activateAccountHeader(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "activationForm.activateAccountHeader",
      returnKey,
      {}
    );
  },
  email: function activationForm_email(returnKey?: boolean): string {
    return shouldReturnKey("activationForm.email", returnKey, {});
  },
  error: {
    gen: function activationForm_error_gen(returnKey?: boolean): string {
      return shouldReturnKey("activationForm.error.gen", returnKey, {});
    },
    length: function activationForm_error_length(returnKey?: boolean): string {
      return shouldReturnKey("activationForm.error.length", returnKey, {});
    },
    regExpr: function activationForm_error_regExpr(
      insert: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey("activationForm.error.regExpr", returnKey, {
        insert
      });
    },
    required: function activationForm_error_required(
      insert: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey("activationForm.error.required", returnKey, {
        insert
      });
    },
    tenancyReq: function activationForm_error_tenancyReq(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("activationForm.error.tenancyReq", returnKey, {});
    },
    unavailable: function activationForm_error_unavailable(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("activationForm.error.unavailable", returnKey, {});
    }
  },
  firstName: function activationForm_firstName(returnKey?: boolean): string {
    return shouldReturnKey("activationForm.firstName", returnKey, {});
  },
  homeRegion: function activationForm_homeRegion(returnKey?: boolean): string {
    return shouldReturnKey("activationForm.homeRegion", returnKey, {});
  },
  homeRegionInfo: function activationForm_homeRegionInfo(
    link1: string,
    link2: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("activationForm.homeRegionInfo", returnKey, {
      link1,
      link2
    });
  },
  label: function activationForm_label(returnKey?: boolean): string {
    return shouldReturnKey("activationForm.label", returnKey, {});
  },
  lastName: function activationForm_lastName(returnKey?: boolean): string {
    return shouldReturnKey("activationForm.lastName", returnKey, {});
  },
  tenancyName: function activationForm_tenancyName(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("activationForm.tenancyName", returnKey, {});
  },
  tenancyNameInfo: function activationForm_tenancyNameInfo(
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("activationForm.tenancyNameInfo", returnKey, {
      link
    });
  }
};

export const agreement = {
  errorDialog1: function agreement_errorDialog1(returnKey?: boolean): string {
    return shouldReturnKey("agreement.errorDialog1", returnKey, {});
  },
  errorDialog2: function agreement_errorDialog2(
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("agreement.errorDialog2", returnKey, { link });
  },
  errorDialog3: function agreement_errorDialog3(
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("agreement.errorDialog3", returnKey, { link });
  },
  errorTitle: function agreement_errorTitle(returnKey?: boolean): string {
    return shouldReturnKey("agreement.errorTitle", returnKey, {});
  },
  tryMeButton: function agreement_tryMeButton(returnKey?: boolean): string {
    return shouldReturnKey("agreement.tryMeButton", returnKey, {});
  }
};

export const awsAccountIDPage = {
  accountIDField: function awsAccountIDPage_accountIDField(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("awsAccountIDPage.accountIDField", returnKey, {});
  },
  accountIDPageTitle: function awsAccountIDPage_accountIDPageTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "awsAccountIDPage.accountIDPageTitle",
      returnKey,
      {}
    );
  },
  accountIDPageTitleInfo: function awsAccountIDPage_accountIDPageTitleInfo(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "awsAccountIDPage.accountIDPageTitleInfo",
      returnKey,
      {}
    );
  },
  userpoolIDField: function awsAccountIDPage_userpoolIDField(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("awsAccountIDPage.userpoolIDField", returnKey, {});
  },
  validateAccount: function awsAccountIDPage_validateAccount(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("awsAccountIDPage.validateAccount", returnKey, {});
  }
};

export const awsCloudformationPage = {
  agreementCheckbox: function awsCloudformationPage_agreementCheckbox(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "awsCloudformationPage.agreementCheckbox",
      returnKey,
      {}
    );
  },
  cloudFormationFormTitle: function awsCloudformationPage_cloudFormationFormTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "awsCloudformationPage.cloudFormationFormTitle",
      returnKey,
      {}
    );
  },
  cloudFormationPrerequisites: function awsCloudformationPage_cloudFormationPrerequisites(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "awsCloudformationPage.cloudFormationPrerequisites",
      returnKey,
      {}
    );
  },
  cloudFormationSubmit: function awsCloudformationPage_cloudFormationSubmit(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "awsCloudformationPage.cloudFormationSubmit",
      returnKey,
      {}
    );
  },
  cloudFormationTerms: function awsCloudformationPage_cloudFormationTerms(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "awsCloudformationPage.cloudFormationTerms",
      returnKey,
      {}
    );
  },
  confirmEmailLabel: function awsCloudformationPage_confirmEmailLabel(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "awsCloudformationPage.confirmEmailLabel",
      returnKey,
      {}
    );
  },
  documentationLink: function awsCloudformationPage_documentationLink(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "awsCloudformationPage.documentationLink",
      returnKey,
      {}
    );
  },
  emailLabel: function awsCloudformationPage_emailLabel(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("awsCloudformationPage.emailLabel", returnKey, {});
  },
  error: {
    confirmEmailError: function awsCloudformationPage_error_confirmEmailError(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "awsCloudformationPage.error.confirmEmailError",
        returnKey,
        {}
      );
    },
    regExpr: function awsCloudformationPage_error_regExpr(
      insert: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey("awsCloudformationPage.error.regExpr", returnKey, {
        insert
      });
    },
    required: function awsCloudformationPage_error_required(
      insert: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "awsCloudformationPage.error.required",
        returnKey,
        { insert }
      );
    },
    userPoolError: function awsCloudformationPage_error_userPoolError(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "awsCloudformationPage.error.userPoolError",
        returnKey,
        {}
      );
    }
  },
  info: function awsCloudformationPage_info(returnKey?: boolean): string {
    return shouldReturnKey("awsCloudformationPage.info", returnKey, {});
  },
  learnMore: function awsCloudformationPage_learnMore(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("awsCloudformationPage.learnMore", returnKey, {});
  },
  optional: function awsCloudformationPage_optional(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("awsCloudformationPage.optional", returnKey, {});
  },
  userPoolLabel: function awsCloudformationPage_userPoolLabel(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "awsCloudformationPage.userPoolLabel",
      returnKey,
      {}
    );
  }
};

export const awsFooter = {
  privacy: function awsFooter_privacy(returnKey?: boolean): string {
    return shouldReturnKey("awsFooter.privacy", returnKey, {});
  },
  terms: function awsFooter_terms(returnKey?: boolean): string {
    return shouldReturnKey("awsFooter.terms", returnKey, {});
  }
};

export const awsHome = {
  cloudFormationPollMaxRetriesMsg: function awsHome_cloudFormationPollMaxRetriesMsg(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "awsHome.cloudFormationPollMaxRetriesMsg",
      returnKey,
      {}
    );
  },
  cloudFormationPollingErrorMsg: function awsHome_cloudFormationPollingErrorMsg(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "awsHome.cloudFormationPollingErrorMsg",
      returnKey,
      {}
    );
  },
  cloudFormationPollingMsg: function awsHome_cloudFormationPollingMsg(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("awsHome.cloudFormationPollingMsg", returnKey, {});
  },
  collapsedStepsLabel: function awsHome_collapsedStepsLabel(
    stepNumber: string,
    stepsCount: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("awsHome.collapsedStepsLabel", returnKey, {
      stepNumber,
      stepsCount
    });
  },
  documentationLink: function awsHome_documentationLink(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("awsHome.documentationLink", returnKey, {});
  },
  error: {
    preReqValidation403: function awsHome_error_preReqValidation403(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "awsHome.error.preReqValidation403",
        returnKey,
        {}
      );
    },
    preReqValidationGenericError: function awsHome_error_preReqValidationGenericError(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "awsHome.error.preReqValidationGenericError",
        returnKey,
        {}
      );
    },
    preReqValidationWhitelistError: function awsHome_error_preReqValidationWhitelistError(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "awsHome.error.preReqValidationWhitelistError",
        returnKey,
        {}
      );
    }
  },
  preReqValidationInvalidError: function awsHome_preReqValidationInvalidError(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "awsHome.preReqValidationInvalidError",
      returnKey,
      {}
    );
  },
  preReqValidationLoadingFailure: function awsHome_preReqValidationLoadingFailure(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "awsHome.preReqValidationLoadingFailure",
      returnKey,
      {}
    );
  },
  preReqValidationLoadingTitle: function awsHome_preReqValidationLoadingTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "awsHome.preReqValidationLoadingTitle",
      returnKey,
      {}
    );
  },
  signIn: function awsHome_signIn(returnKey?: boolean): string {
    return shouldReturnKey("awsHome.signIn", returnKey, {});
  },
  signInPageRedirectMsg: function awsHome_signInPageRedirectMsg(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("awsHome.signInPageRedirectMsg", returnKey, {});
  },
  stepNumberLabel: function awsHome_stepNumberLabel(
    step: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("awsHome.stepNumberLabel", returnKey, { step });
  },
  supportLink: function awsHome_supportLink(returnKey?: boolean): string {
    return shouldReturnKey("awsHome.supportLink", returnKey, {});
  },
  wizardAccountIDTitle: function awsHome_wizardAccountIDTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("awsHome.wizardAccountIDTitle", returnKey, {});
  },
  wizardAccountValidationTitle: function awsHome_wizardAccountValidationTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "awsHome.wizardAccountValidationTitle",
      returnKey,
      {}
    );
  },
  wizardBannerContent1: function awsHome_wizardBannerContent1(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("awsHome.wizardBannerContent1", returnKey, {});
  },
  wizardBannerStep1: function awsHome_wizardBannerStep1(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("awsHome.wizardBannerStep1", returnKey, {});
  },
  wizardBannerStep2: function awsHome_wizardBannerStep2(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("awsHome.wizardBannerStep2", returnKey, {});
  },
  wizardBannerStep3: function awsHome_wizardBannerStep3(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("awsHome.wizardBannerStep3", returnKey, {});
  },
  wizardCFValidationTitle: function awsHome_wizardCFValidationTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("awsHome.wizardCFValidationTitle", returnKey, {});
  },
  wizardLaunchCFTitle: function awsHome_wizardLaunchCFTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("awsHome.wizardLaunchCFTitle", returnKey, {});
  }
};

export const cloudSignInCard = {
  cloudAccountName: function cloudSignInCard_cloudAccountName(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("cloudSignInCard.cloudAccountName", returnKey, {});
  },
  forgotYourCloudAccountName: function cloudSignInCard_forgotYourCloudAccountName(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "cloudSignInCard.forgotYourCloudAccountName",
      returnKey,
      {}
    );
  },
  getHelp: function cloudSignInCard_getHelp(returnKey?: boolean): string {
    return shouldReturnKey("cloudSignInCard.getHelp", returnKey, {});
  },
  next: function cloudSignInCard_next(returnKey?: boolean): string {
    return shouldReturnKey("cloudSignInCard.next", returnKey, {});
  },
  notAnOracleCloudCustomer: function cloudSignInCard_notAnOracleCloudCustomer(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "cloudSignInCard.notAnOracleCloudCustomer",
      returnKey,
      {}
    );
  },
  signUp: function cloudSignInCard_signUp(returnKey?: boolean): string {
    return shouldReturnKey("cloudSignInCard.signUp", returnKey, {});
  }
};

export const common = {
  activate: function common_activate(returnKey?: boolean): string {
    return shouldReturnKey("common.activate", returnKey, {});
  },
  cancel: function common_cancel(returnKey?: boolean): string {
    return shouldReturnKey("common.cancel", returnKey, {});
  },
  close: function common_close(returnKey?: boolean): string {
    return shouldReturnKey("common.close", returnKey, {});
  },
  contactSupport: function common_contactSupport(returnKey?: boolean): string {
    return shouldReturnKey("common.contactSupport", returnKey, {});
  },
  continue: function common_continue(returnKey?: boolean): string {
    return shouldReturnKey("common.continue", returnKey, {});
  },
  edit: function common_edit(returnKey?: boolean): string {
    return shouldReturnKey("common.edit", returnKey, {});
  },
  learnMore: function common_learnMore(returnKey?: boolean): string {
    return shouldReturnKey("common.learnMore", returnKey, {});
  },
  o4ATitle: function common_o4ATitle(returnKey?: boolean): string {
    return shouldReturnKey("common.o4ATitle", returnKey, {});
  },
  retry: function common_retry(returnKey?: boolean): string {
    return shouldReturnKey("common.retry", returnKey, {});
  },
  tryAgain: function common_tryAgain(returnKey?: boolean): string {
    return shouldReturnKey("common.tryAgain", returnKey, {});
  }
};

export const countries = {
  AD: function countries_AD(returnKey?: boolean): string {
    return shouldReturnKey("countries.AD", returnKey, {});
  },
  AE: function countries_AE(returnKey?: boolean): string {
    return shouldReturnKey("countries.AE", returnKey, {});
  },
  AF: function countries_AF(returnKey?: boolean): string {
    return shouldReturnKey("countries.AF", returnKey, {});
  },
  AG: function countries_AG(returnKey?: boolean): string {
    return shouldReturnKey("countries.AG", returnKey, {});
  },
  AI: function countries_AI(returnKey?: boolean): string {
    return shouldReturnKey("countries.AI", returnKey, {});
  },
  AL: function countries_AL(returnKey?: boolean): string {
    return shouldReturnKey("countries.AL", returnKey, {});
  },
  AM: function countries_AM(returnKey?: boolean): string {
    return shouldReturnKey("countries.AM", returnKey, {});
  },
  AO: function countries_AO(returnKey?: boolean): string {
    return shouldReturnKey("countries.AO", returnKey, {});
  },
  AQ: function countries_AQ(returnKey?: boolean): string {
    return shouldReturnKey("countries.AQ", returnKey, {});
  },
  AR: function countries_AR(returnKey?: boolean): string {
    return shouldReturnKey("countries.AR", returnKey, {});
  },
  AS: function countries_AS(returnKey?: boolean): string {
    return shouldReturnKey("countries.AS", returnKey, {});
  },
  AT: function countries_AT(returnKey?: boolean): string {
    return shouldReturnKey("countries.AT", returnKey, {});
  },
  AU: function countries_AU(returnKey?: boolean): string {
    return shouldReturnKey("countries.AU", returnKey, {});
  },
  AW: function countries_AW(returnKey?: boolean): string {
    return shouldReturnKey("countries.AW", returnKey, {});
  },
  AX: function countries_AX(returnKey?: boolean): string {
    return shouldReturnKey("countries.AX", returnKey, {});
  },
  AZ: function countries_AZ(returnKey?: boolean): string {
    return shouldReturnKey("countries.AZ", returnKey, {});
  },
  BA: function countries_BA(returnKey?: boolean): string {
    return shouldReturnKey("countries.BA", returnKey, {});
  },
  BB: function countries_BB(returnKey?: boolean): string {
    return shouldReturnKey("countries.BB", returnKey, {});
  },
  BD: function countries_BD(returnKey?: boolean): string {
    return shouldReturnKey("countries.BD", returnKey, {});
  },
  BE: function countries_BE(returnKey?: boolean): string {
    return shouldReturnKey("countries.BE", returnKey, {});
  },
  BF: function countries_BF(returnKey?: boolean): string {
    return shouldReturnKey("countries.BF", returnKey, {});
  },
  BG: function countries_BG(returnKey?: boolean): string {
    return shouldReturnKey("countries.BG", returnKey, {});
  },
  BH: function countries_BH(returnKey?: boolean): string {
    return shouldReturnKey("countries.BH", returnKey, {});
  },
  BI: function countries_BI(returnKey?: boolean): string {
    return shouldReturnKey("countries.BI", returnKey, {});
  },
  BJ: function countries_BJ(returnKey?: boolean): string {
    return shouldReturnKey("countries.BJ", returnKey, {});
  },
  BL: function countries_BL(returnKey?: boolean): string {
    return shouldReturnKey("countries.BL", returnKey, {});
  },
  BM: function countries_BM(returnKey?: boolean): string {
    return shouldReturnKey("countries.BM", returnKey, {});
  },
  BN: function countries_BN(returnKey?: boolean): string {
    return shouldReturnKey("countries.BN", returnKey, {});
  },
  BO: function countries_BO(returnKey?: boolean): string {
    return shouldReturnKey("countries.BO", returnKey, {});
  },
  BQ: function countries_BQ(returnKey?: boolean): string {
    return shouldReturnKey("countries.BQ", returnKey, {});
  },
  BR: function countries_BR(returnKey?: boolean): string {
    return shouldReturnKey("countries.BR", returnKey, {});
  },
  BS: function countries_BS(returnKey?: boolean): string {
    return shouldReturnKey("countries.BS", returnKey, {});
  },
  BT: function countries_BT(returnKey?: boolean): string {
    return shouldReturnKey("countries.BT", returnKey, {});
  },
  BV: function countries_BV(returnKey?: boolean): string {
    return shouldReturnKey("countries.BV", returnKey, {});
  },
  BW: function countries_BW(returnKey?: boolean): string {
    return shouldReturnKey("countries.BW", returnKey, {});
  },
  BY: function countries_BY(returnKey?: boolean): string {
    return shouldReturnKey("countries.BY", returnKey, {});
  },
  BZ: function countries_BZ(returnKey?: boolean): string {
    return shouldReturnKey("countries.BZ", returnKey, {});
  },
  CA: function countries_CA(returnKey?: boolean): string {
    return shouldReturnKey("countries.CA", returnKey, {});
  },
  CC: function countries_CC(returnKey?: boolean): string {
    return shouldReturnKey("countries.CC", returnKey, {});
  },
  CD: function countries_CD(returnKey?: boolean): string {
    return shouldReturnKey("countries.CD", returnKey, {});
  },
  CF: function countries_CF(returnKey?: boolean): string {
    return shouldReturnKey("countries.CF", returnKey, {});
  },
  CG: function countries_CG(returnKey?: boolean): string {
    return shouldReturnKey("countries.CG", returnKey, {});
  },
  CH: function countries_CH(returnKey?: boolean): string {
    return shouldReturnKey("countries.CH", returnKey, {});
  },
  CI: function countries_CI(returnKey?: boolean): string {
    return shouldReturnKey("countries.CI", returnKey, {});
  },
  CK: function countries_CK(returnKey?: boolean): string {
    return shouldReturnKey("countries.CK", returnKey, {});
  },
  CL: function countries_CL(returnKey?: boolean): string {
    return shouldReturnKey("countries.CL", returnKey, {});
  },
  CM: function countries_CM(returnKey?: boolean): string {
    return shouldReturnKey("countries.CM", returnKey, {});
  },
  CN: function countries_CN(returnKey?: boolean): string {
    return shouldReturnKey("countries.CN", returnKey, {});
  },
  CO: function countries_CO(returnKey?: boolean): string {
    return shouldReturnKey("countries.CO", returnKey, {});
  },
  CR: function countries_CR(returnKey?: boolean): string {
    return shouldReturnKey("countries.CR", returnKey, {});
  },
  CV: function countries_CV(returnKey?: boolean): string {
    return shouldReturnKey("countries.CV", returnKey, {});
  },
  CW: function countries_CW(returnKey?: boolean): string {
    return shouldReturnKey("countries.CW", returnKey, {});
  },
  CX: function countries_CX(returnKey?: boolean): string {
    return shouldReturnKey("countries.CX", returnKey, {});
  },
  CY: function countries_CY(returnKey?: boolean): string {
    return shouldReturnKey("countries.CY", returnKey, {});
  },
  CZ: function countries_CZ(returnKey?: boolean): string {
    return shouldReturnKey("countries.CZ", returnKey, {});
  },
  DE: function countries_DE(returnKey?: boolean): string {
    return shouldReturnKey("countries.DE", returnKey, {});
  },
  DJ: function countries_DJ(returnKey?: boolean): string {
    return shouldReturnKey("countries.DJ", returnKey, {});
  },
  DK: function countries_DK(returnKey?: boolean): string {
    return shouldReturnKey("countries.DK", returnKey, {});
  },
  DM: function countries_DM(returnKey?: boolean): string {
    return shouldReturnKey("countries.DM", returnKey, {});
  },
  DO: function countries_DO(returnKey?: boolean): string {
    return shouldReturnKey("countries.DO", returnKey, {});
  },
  DZ: function countries_DZ(returnKey?: boolean): string {
    return shouldReturnKey("countries.DZ", returnKey, {});
  },
  EC: function countries_EC(returnKey?: boolean): string {
    return shouldReturnKey("countries.EC", returnKey, {});
  },
  EE: function countries_EE(returnKey?: boolean): string {
    return shouldReturnKey("countries.EE", returnKey, {});
  },
  EG: function countries_EG(returnKey?: boolean): string {
    return shouldReturnKey("countries.EG", returnKey, {});
  },
  EH: function countries_EH(returnKey?: boolean): string {
    return shouldReturnKey("countries.EH", returnKey, {});
  },
  ER: function countries_ER(returnKey?: boolean): string {
    return shouldReturnKey("countries.ER", returnKey, {});
  },
  ES: function countries_ES(returnKey?: boolean): string {
    return shouldReturnKey("countries.ES", returnKey, {});
  },
  ET: function countries_ET(returnKey?: boolean): string {
    return shouldReturnKey("countries.ET", returnKey, {});
  },
  FI: function countries_FI(returnKey?: boolean): string {
    return shouldReturnKey("countries.FI", returnKey, {});
  },
  FJ: function countries_FJ(returnKey?: boolean): string {
    return shouldReturnKey("countries.FJ", returnKey, {});
  },
  FK: function countries_FK(returnKey?: boolean): string {
    return shouldReturnKey("countries.FK", returnKey, {});
  },
  FM: function countries_FM(returnKey?: boolean): string {
    return shouldReturnKey("countries.FM", returnKey, {});
  },
  FO: function countries_FO(returnKey?: boolean): string {
    return shouldReturnKey("countries.FO", returnKey, {});
  },
  FR: function countries_FR(returnKey?: boolean): string {
    return shouldReturnKey("countries.FR", returnKey, {});
  },
  GA: function countries_GA(returnKey?: boolean): string {
    return shouldReturnKey("countries.GA", returnKey, {});
  },
  GB: function countries_GB(returnKey?: boolean): string {
    return shouldReturnKey("countries.GB", returnKey, {});
  },
  GD: function countries_GD(returnKey?: boolean): string {
    return shouldReturnKey("countries.GD", returnKey, {});
  },
  GE: function countries_GE(returnKey?: boolean): string {
    return shouldReturnKey("countries.GE", returnKey, {});
  },
  GF: function countries_GF(returnKey?: boolean): string {
    return shouldReturnKey("countries.GF", returnKey, {});
  },
  GG: function countries_GG(returnKey?: boolean): string {
    return shouldReturnKey("countries.GG", returnKey, {});
  },
  GH: function countries_GH(returnKey?: boolean): string {
    return shouldReturnKey("countries.GH", returnKey, {});
  },
  GI: function countries_GI(returnKey?: boolean): string {
    return shouldReturnKey("countries.GI", returnKey, {});
  },
  GL: function countries_GL(returnKey?: boolean): string {
    return shouldReturnKey("countries.GL", returnKey, {});
  },
  GM: function countries_GM(returnKey?: boolean): string {
    return shouldReturnKey("countries.GM", returnKey, {});
  },
  GN: function countries_GN(returnKey?: boolean): string {
    return shouldReturnKey("countries.GN", returnKey, {});
  },
  GP: function countries_GP(returnKey?: boolean): string {
    return shouldReturnKey("countries.GP", returnKey, {});
  },
  GQ: function countries_GQ(returnKey?: boolean): string {
    return shouldReturnKey("countries.GQ", returnKey, {});
  },
  GR: function countries_GR(returnKey?: boolean): string {
    return shouldReturnKey("countries.GR", returnKey, {});
  },
  GS: function countries_GS(returnKey?: boolean): string {
    return shouldReturnKey("countries.GS", returnKey, {});
  },
  GT: function countries_GT(returnKey?: boolean): string {
    return shouldReturnKey("countries.GT", returnKey, {});
  },
  GU: function countries_GU(returnKey?: boolean): string {
    return shouldReturnKey("countries.GU", returnKey, {});
  },
  GW: function countries_GW(returnKey?: boolean): string {
    return shouldReturnKey("countries.GW", returnKey, {});
  },
  GY: function countries_GY(returnKey?: boolean): string {
    return shouldReturnKey("countries.GY", returnKey, {});
  },
  HK: function countries_HK(returnKey?: boolean): string {
    return shouldReturnKey("countries.HK", returnKey, {});
  },
  HM: function countries_HM(returnKey?: boolean): string {
    return shouldReturnKey("countries.HM", returnKey, {});
  },
  HN: function countries_HN(returnKey?: boolean): string {
    return shouldReturnKey("countries.HN", returnKey, {});
  },
  HR: function countries_HR(returnKey?: boolean): string {
    return shouldReturnKey("countries.HR", returnKey, {});
  },
  HT: function countries_HT(returnKey?: boolean): string {
    return shouldReturnKey("countries.HT", returnKey, {});
  },
  HU: function countries_HU(returnKey?: boolean): string {
    return shouldReturnKey("countries.HU", returnKey, {});
  },
  ID: function countries_ID(returnKey?: boolean): string {
    return shouldReturnKey("countries.ID", returnKey, {});
  },
  IE: function countries_IE(returnKey?: boolean): string {
    return shouldReturnKey("countries.IE", returnKey, {});
  },
  IL: function countries_IL(returnKey?: boolean): string {
    return shouldReturnKey("countries.IL", returnKey, {});
  },
  IM: function countries_IM(returnKey?: boolean): string {
    return shouldReturnKey("countries.IM", returnKey, {});
  },
  IN: function countries_IN(returnKey?: boolean): string {
    return shouldReturnKey("countries.IN", returnKey, {});
  },
  IO: function countries_IO(returnKey?: boolean): string {
    return shouldReturnKey("countries.IO", returnKey, {});
  },
  IQ: function countries_IQ(returnKey?: boolean): string {
    return shouldReturnKey("countries.IQ", returnKey, {});
  },
  IS: function countries_IS(returnKey?: boolean): string {
    return shouldReturnKey("countries.IS", returnKey, {});
  },
  IT: function countries_IT(returnKey?: boolean): string {
    return shouldReturnKey("countries.IT", returnKey, {});
  },
  JE: function countries_JE(returnKey?: boolean): string {
    return shouldReturnKey("countries.JE", returnKey, {});
  },
  JM: function countries_JM(returnKey?: boolean): string {
    return shouldReturnKey("countries.JM", returnKey, {});
  },
  JO: function countries_JO(returnKey?: boolean): string {
    return shouldReturnKey("countries.JO", returnKey, {});
  },
  JP: function countries_JP(returnKey?: boolean): string {
    return shouldReturnKey("countries.JP", returnKey, {});
  },
  KE: function countries_KE(returnKey?: boolean): string {
    return shouldReturnKey("countries.KE", returnKey, {});
  },
  KG: function countries_KG(returnKey?: boolean): string {
    return shouldReturnKey("countries.KG", returnKey, {});
  },
  KH: function countries_KH(returnKey?: boolean): string {
    return shouldReturnKey("countries.KH", returnKey, {});
  },
  KI: function countries_KI(returnKey?: boolean): string {
    return shouldReturnKey("countries.KI", returnKey, {});
  },
  KM: function countries_KM(returnKey?: boolean): string {
    return shouldReturnKey("countries.KM", returnKey, {});
  },
  KN: function countries_KN(returnKey?: boolean): string {
    return shouldReturnKey("countries.KN", returnKey, {});
  },
  KR: function countries_KR(returnKey?: boolean): string {
    return shouldReturnKey("countries.KR", returnKey, {});
  },
  KW: function countries_KW(returnKey?: boolean): string {
    return shouldReturnKey("countries.KW", returnKey, {});
  },
  KY: function countries_KY(returnKey?: boolean): string {
    return shouldReturnKey("countries.KY", returnKey, {});
  },
  KZ: function countries_KZ(returnKey?: boolean): string {
    return shouldReturnKey("countries.KZ", returnKey, {});
  },
  LA: function countries_LA(returnKey?: boolean): string {
    return shouldReturnKey("countries.LA", returnKey, {});
  },
  LB: function countries_LB(returnKey?: boolean): string {
    return shouldReturnKey("countries.LB", returnKey, {});
  },
  LC: function countries_LC(returnKey?: boolean): string {
    return shouldReturnKey("countries.LC", returnKey, {});
  },
  LI: function countries_LI(returnKey?: boolean): string {
    return shouldReturnKey("countries.LI", returnKey, {});
  },
  LK: function countries_LK(returnKey?: boolean): string {
    return shouldReturnKey("countries.LK", returnKey, {});
  },
  LR: function countries_LR(returnKey?: boolean): string {
    return shouldReturnKey("countries.LR", returnKey, {});
  },
  LS: function countries_LS(returnKey?: boolean): string {
    return shouldReturnKey("countries.LS", returnKey, {});
  },
  LT: function countries_LT(returnKey?: boolean): string {
    return shouldReturnKey("countries.LT", returnKey, {});
  },
  LU: function countries_LU(returnKey?: boolean): string {
    return shouldReturnKey("countries.LU", returnKey, {});
  },
  LV: function countries_LV(returnKey?: boolean): string {
    return shouldReturnKey("countries.LV", returnKey, {});
  },
  LY: function countries_LY(returnKey?: boolean): string {
    return shouldReturnKey("countries.LY", returnKey, {});
  },
  MA: function countries_MA(returnKey?: boolean): string {
    return shouldReturnKey("countries.MA", returnKey, {});
  },
  MC: function countries_MC(returnKey?: boolean): string {
    return shouldReturnKey("countries.MC", returnKey, {});
  },
  MD: function countries_MD(returnKey?: boolean): string {
    return shouldReturnKey("countries.MD", returnKey, {});
  },
  ME: function countries_ME(returnKey?: boolean): string {
    return shouldReturnKey("countries.ME", returnKey, {});
  },
  MF: function countries_MF(returnKey?: boolean): string {
    return shouldReturnKey("countries.MF", returnKey, {});
  },
  MG: function countries_MG(returnKey?: boolean): string {
    return shouldReturnKey("countries.MG", returnKey, {});
  },
  MH: function countries_MH(returnKey?: boolean): string {
    return shouldReturnKey("countries.MH", returnKey, {});
  },
  MK: function countries_MK(returnKey?: boolean): string {
    return shouldReturnKey("countries.MK", returnKey, {});
  },
  ML: function countries_ML(returnKey?: boolean): string {
    return shouldReturnKey("countries.ML", returnKey, {});
  },
  MM: function countries_MM(returnKey?: boolean): string {
    return shouldReturnKey("countries.MM", returnKey, {});
  },
  MN: function countries_MN(returnKey?: boolean): string {
    return shouldReturnKey("countries.MN", returnKey, {});
  },
  MO: function countries_MO(returnKey?: boolean): string {
    return shouldReturnKey("countries.MO", returnKey, {});
  },
  MP: function countries_MP(returnKey?: boolean): string {
    return shouldReturnKey("countries.MP", returnKey, {});
  },
  MQ: function countries_MQ(returnKey?: boolean): string {
    return shouldReturnKey("countries.MQ", returnKey, {});
  },
  MR: function countries_MR(returnKey?: boolean): string {
    return shouldReturnKey("countries.MR", returnKey, {});
  },
  MS: function countries_MS(returnKey?: boolean): string {
    return shouldReturnKey("countries.MS", returnKey, {});
  },
  MT: function countries_MT(returnKey?: boolean): string {
    return shouldReturnKey("countries.MT", returnKey, {});
  },
  MU: function countries_MU(returnKey?: boolean): string {
    return shouldReturnKey("countries.MU", returnKey, {});
  },
  MV: function countries_MV(returnKey?: boolean): string {
    return shouldReturnKey("countries.MV", returnKey, {});
  },
  MW: function countries_MW(returnKey?: boolean): string {
    return shouldReturnKey("countries.MW", returnKey, {});
  },
  MX: function countries_MX(returnKey?: boolean): string {
    return shouldReturnKey("countries.MX", returnKey, {});
  },
  MY: function countries_MY(returnKey?: boolean): string {
    return shouldReturnKey("countries.MY", returnKey, {});
  },
  MZ: function countries_MZ(returnKey?: boolean): string {
    return shouldReturnKey("countries.MZ", returnKey, {});
  },
  NA: function countries_NA(returnKey?: boolean): string {
    return shouldReturnKey("countries.NA", returnKey, {});
  },
  NC: function countries_NC(returnKey?: boolean): string {
    return shouldReturnKey("countries.NC", returnKey, {});
  },
  NE: function countries_NE(returnKey?: boolean): string {
    return shouldReturnKey("countries.NE", returnKey, {});
  },
  NF: function countries_NF(returnKey?: boolean): string {
    return shouldReturnKey("countries.NF", returnKey, {});
  },
  NG: function countries_NG(returnKey?: boolean): string {
    return shouldReturnKey("countries.NG", returnKey, {});
  },
  NI: function countries_NI(returnKey?: boolean): string {
    return shouldReturnKey("countries.NI", returnKey, {});
  },
  NL: function countries_NL(returnKey?: boolean): string {
    return shouldReturnKey("countries.NL", returnKey, {});
  },
  NO: function countries_NO(returnKey?: boolean): string {
    return shouldReturnKey("countries.NO", returnKey, {});
  },
  NP: function countries_NP(returnKey?: boolean): string {
    return shouldReturnKey("countries.NP", returnKey, {});
  },
  NR: function countries_NR(returnKey?: boolean): string {
    return shouldReturnKey("countries.NR", returnKey, {});
  },
  NU: function countries_NU(returnKey?: boolean): string {
    return shouldReturnKey("countries.NU", returnKey, {});
  },
  NZ: function countries_NZ(returnKey?: boolean): string {
    return shouldReturnKey("countries.NZ", returnKey, {});
  },
  OM: function countries_OM(returnKey?: boolean): string {
    return shouldReturnKey("countries.OM", returnKey, {});
  },
  PA: function countries_PA(returnKey?: boolean): string {
    return shouldReturnKey("countries.PA", returnKey, {});
  },
  PE: function countries_PE(returnKey?: boolean): string {
    return shouldReturnKey("countries.PE", returnKey, {});
  },
  PF: function countries_PF(returnKey?: boolean): string {
    return shouldReturnKey("countries.PF", returnKey, {});
  },
  PG: function countries_PG(returnKey?: boolean): string {
    return shouldReturnKey("countries.PG", returnKey, {});
  },
  PH: function countries_PH(returnKey?: boolean): string {
    return shouldReturnKey("countries.PH", returnKey, {});
  },
  PK: function countries_PK(returnKey?: boolean): string {
    return shouldReturnKey("countries.PK", returnKey, {});
  },
  PL: function countries_PL(returnKey?: boolean): string {
    return shouldReturnKey("countries.PL", returnKey, {});
  },
  PM: function countries_PM(returnKey?: boolean): string {
    return shouldReturnKey("countries.PM", returnKey, {});
  },
  PN: function countries_PN(returnKey?: boolean): string {
    return shouldReturnKey("countries.PN", returnKey, {});
  },
  PR: function countries_PR(returnKey?: boolean): string {
    return shouldReturnKey("countries.PR", returnKey, {});
  },
  PS: function countries_PS(returnKey?: boolean): string {
    return shouldReturnKey("countries.PS", returnKey, {});
  },
  PT: function countries_PT(returnKey?: boolean): string {
    return shouldReturnKey("countries.PT", returnKey, {});
  },
  PW: function countries_PW(returnKey?: boolean): string {
    return shouldReturnKey("countries.PW", returnKey, {});
  },
  PY: function countries_PY(returnKey?: boolean): string {
    return shouldReturnKey("countries.PY", returnKey, {});
  },
  QA: function countries_QA(returnKey?: boolean): string {
    return shouldReturnKey("countries.QA", returnKey, {});
  },
  RE: function countries_RE(returnKey?: boolean): string {
    return shouldReturnKey("countries.RE", returnKey, {});
  },
  RO: function countries_RO(returnKey?: boolean): string {
    return shouldReturnKey("countries.RO", returnKey, {});
  },
  RS: function countries_RS(returnKey?: boolean): string {
    return shouldReturnKey("countries.RS", returnKey, {});
  },
  RU: function countries_RU(returnKey?: boolean): string {
    return shouldReturnKey("countries.RU", returnKey, {});
  },
  RW: function countries_RW(returnKey?: boolean): string {
    return shouldReturnKey("countries.RW", returnKey, {});
  },
  SA: function countries_SA(returnKey?: boolean): string {
    return shouldReturnKey("countries.SA", returnKey, {});
  },
  SB: function countries_SB(returnKey?: boolean): string {
    return shouldReturnKey("countries.SB", returnKey, {});
  },
  SC: function countries_SC(returnKey?: boolean): string {
    return shouldReturnKey("countries.SC", returnKey, {});
  },
  SE: function countries_SE(returnKey?: boolean): string {
    return shouldReturnKey("countries.SE", returnKey, {});
  },
  SG: function countries_SG(returnKey?: boolean): string {
    return shouldReturnKey("countries.SG", returnKey, {});
  },
  SH: function countries_SH(returnKey?: boolean): string {
    return shouldReturnKey("countries.SH", returnKey, {});
  },
  SI: function countries_SI(returnKey?: boolean): string {
    return shouldReturnKey("countries.SI", returnKey, {});
  },
  SJ: function countries_SJ(returnKey?: boolean): string {
    return shouldReturnKey("countries.SJ", returnKey, {});
  },
  SK: function countries_SK(returnKey?: boolean): string {
    return shouldReturnKey("countries.SK", returnKey, {});
  },
  SL: function countries_SL(returnKey?: boolean): string {
    return shouldReturnKey("countries.SL", returnKey, {});
  },
  SM: function countries_SM(returnKey?: boolean): string {
    return shouldReturnKey("countries.SM", returnKey, {});
  },
  SN: function countries_SN(returnKey?: boolean): string {
    return shouldReturnKey("countries.SN", returnKey, {});
  },
  SO: function countries_SO(returnKey?: boolean): string {
    return shouldReturnKey("countries.SO", returnKey, {});
  },
  SR: function countries_SR(returnKey?: boolean): string {
    return shouldReturnKey("countries.SR", returnKey, {});
  },
  SS: function countries_SS(returnKey?: boolean): string {
    return shouldReturnKey("countries.SS", returnKey, {});
  },
  ST: function countries_ST(returnKey?: boolean): string {
    return shouldReturnKey("countries.ST", returnKey, {});
  },
  SV: function countries_SV(returnKey?: boolean): string {
    return shouldReturnKey("countries.SV", returnKey, {});
  },
  SX: function countries_SX(returnKey?: boolean): string {
    return shouldReturnKey("countries.SX", returnKey, {});
  },
  SZ: function countries_SZ(returnKey?: boolean): string {
    return shouldReturnKey("countries.SZ", returnKey, {});
  },
  TC: function countries_TC(returnKey?: boolean): string {
    return shouldReturnKey("countries.TC", returnKey, {});
  },
  TD: function countries_TD(returnKey?: boolean): string {
    return shouldReturnKey("countries.TD", returnKey, {});
  },
  TF: function countries_TF(returnKey?: boolean): string {
    return shouldReturnKey("countries.TF", returnKey, {});
  },
  TG: function countries_TG(returnKey?: boolean): string {
    return shouldReturnKey("countries.TG", returnKey, {});
  },
  TH: function countries_TH(returnKey?: boolean): string {
    return shouldReturnKey("countries.TH", returnKey, {});
  },
  TJ: function countries_TJ(returnKey?: boolean): string {
    return shouldReturnKey("countries.TJ", returnKey, {});
  },
  TK: function countries_TK(returnKey?: boolean): string {
    return shouldReturnKey("countries.TK", returnKey, {});
  },
  TL: function countries_TL(returnKey?: boolean): string {
    return shouldReturnKey("countries.TL", returnKey, {});
  },
  TM: function countries_TM(returnKey?: boolean): string {
    return shouldReturnKey("countries.TM", returnKey, {});
  },
  TN: function countries_TN(returnKey?: boolean): string {
    return shouldReturnKey("countries.TN", returnKey, {});
  },
  TO: function countries_TO(returnKey?: boolean): string {
    return shouldReturnKey("countries.TO", returnKey, {});
  },
  TR: function countries_TR(returnKey?: boolean): string {
    return shouldReturnKey("countries.TR", returnKey, {});
  },
  TT: function countries_TT(returnKey?: boolean): string {
    return shouldReturnKey("countries.TT", returnKey, {});
  },
  TV: function countries_TV(returnKey?: boolean): string {
    return shouldReturnKey("countries.TV", returnKey, {});
  },
  TW: function countries_TW(returnKey?: boolean): string {
    return shouldReturnKey("countries.TW", returnKey, {});
  },
  TZ: function countries_TZ(returnKey?: boolean): string {
    return shouldReturnKey("countries.TZ", returnKey, {});
  },
  UA: function countries_UA(returnKey?: boolean): string {
    return shouldReturnKey("countries.UA", returnKey, {});
  },
  UG: function countries_UG(returnKey?: boolean): string {
    return shouldReturnKey("countries.UG", returnKey, {});
  },
  UM: function countries_UM(returnKey?: boolean): string {
    return shouldReturnKey("countries.UM", returnKey, {});
  },
  US: function countries_US(returnKey?: boolean): string {
    return shouldReturnKey("countries.US", returnKey, {});
  },
  UY: function countries_UY(returnKey?: boolean): string {
    return shouldReturnKey("countries.UY", returnKey, {});
  },
  UZ: function countries_UZ(returnKey?: boolean): string {
    return shouldReturnKey("countries.UZ", returnKey, {});
  },
  VA: function countries_VA(returnKey?: boolean): string {
    return shouldReturnKey("countries.VA", returnKey, {});
  },
  VC: function countries_VC(returnKey?: boolean): string {
    return shouldReturnKey("countries.VC", returnKey, {});
  },
  VE: function countries_VE(returnKey?: boolean): string {
    return shouldReturnKey("countries.VE", returnKey, {});
  },
  VG: function countries_VG(returnKey?: boolean): string {
    return shouldReturnKey("countries.VG", returnKey, {});
  },
  VI: function countries_VI(returnKey?: boolean): string {
    return shouldReturnKey("countries.VI", returnKey, {});
  },
  VN: function countries_VN(returnKey?: boolean): string {
    return shouldReturnKey("countries.VN", returnKey, {});
  },
  VU: function countries_VU(returnKey?: boolean): string {
    return shouldReturnKey("countries.VU", returnKey, {});
  },
  WF: function countries_WF(returnKey?: boolean): string {
    return shouldReturnKey("countries.WF", returnKey, {});
  },
  WS: function countries_WS(returnKey?: boolean): string {
    return shouldReturnKey("countries.WS", returnKey, {});
  },
  YE: function countries_YE(returnKey?: boolean): string {
    return shouldReturnKey("countries.YE", returnKey, {});
  },
  YT: function countries_YT(returnKey?: boolean): string {
    return shouldReturnKey("countries.YT", returnKey, {});
  },
  ZA: function countries_ZA(returnKey?: boolean): string {
    return shouldReturnKey("countries.ZA", returnKey, {});
  },
  ZM: function countries_ZM(returnKey?: boolean): string {
    return shouldReturnKey("countries.ZM", returnKey, {});
  },
  ZW: function countries_ZW(returnKey?: boolean): string {
    return shouldReturnKey("countries.ZW", returnKey, {});
  }
};

export const cyberSourceErrMsg = {
  avsHeader: function cyberSourceErrMsg_avsHeader(returnKey?: boolean): string {
    return shouldReturnKey("cyberSourceErrMsg.avsHeader", returnKey, {});
  },
  avsMessage: function cyberSourceErrMsg_avsMessage(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("cyberSourceErrMsg.avsMessage", returnKey, {});
  },
  editPaymentMethod: function cyberSourceErrMsg_editPaymentMethod(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "cyberSourceErrMsg.editPaymentMethod",
      returnKey,
      {}
    );
  },
  footer: function cyberSourceErrMsg_footer(returnKey?: boolean): string {
    return shouldReturnKey("cyberSourceErrMsg.footer", returnKey, {});
  },
  header202: function cyberSourceErrMsg_header202(returnKey?: boolean): string {
    return shouldReturnKey("cyberSourceErrMsg.header202", returnKey, {});
  },
  header231: function cyberSourceErrMsg_header231(returnKey?: boolean): string {
    return shouldReturnKey("cyberSourceErrMsg.header231", returnKey, {});
  },
  header2xx: function cyberSourceErrMsg_header2xx(returnKey?: boolean): string {
    return shouldReturnKey("cyberSourceErrMsg.header2xx", returnKey, {});
  },
  header481: function cyberSourceErrMsg_header481(returnKey?: boolean): string {
    return shouldReturnKey("cyberSourceErrMsg.header481", returnKey, {});
  },
  message202: function cyberSourceErrMsg_message202(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("cyberSourceErrMsg.message202", returnKey, {});
  },
  message231: function cyberSourceErrMsg_message231(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("cyberSourceErrMsg.message231", returnKey, {});
  },
  message2xx: function cyberSourceErrMsg_message2xx(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("cyberSourceErrMsg.message2xx", returnKey, {});
  },
  message481: function cyberSourceErrMsg_message481(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("cyberSourceErrMsg.message481", returnKey, {});
  },
  psd2Header: function cyberSourceErrMsg_psd2Header(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("cyberSourceErrMsg.psd2Header", returnKey, {});
  },
  psd2Message: function cyberSourceErrMsg_psd2Message(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("cyberSourceErrMsg.psd2Message", returnKey, {});
  },
  title: function cyberSourceErrMsg_title(returnKey?: boolean): string {
    return shouldReturnKey("cyberSourceErrMsg.title", returnKey, {});
  }
};

export const errorPage = {
  blockPopupSubtitle: function errorPage_blockPopupSubtitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("errorPage.blockPopupSubtitle", returnKey, {});
  },
  blockPopupTitle: function errorPage_blockPopupTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("errorPage.blockPopupTitle", returnKey, {});
  },
  couldNotLink: function errorPage_couldNotLink(returnKey?: boolean): string {
    return shouldReturnKey("errorPage.couldNotLink", returnKey, {});
  },
  existingCloudLinkAwsError: function errorPage_existingCloudLinkAwsError(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "errorPage.existingCloudLinkAwsError",
      returnKey,
      {}
    );
  },
  existingCloudLinkAzureError: function errorPage_existingCloudLinkAzureError(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "errorPage.existingCloudLinkAzureError",
      returnKey,
      {}
    );
  },
  existingCloudLinkGcpError: function errorPage_existingCloudLinkGcpError(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "errorPage.existingCloudLinkGcpError",
      returnKey,
      {}
    );
  },
  existingCloudLinkOciError: function errorPage_existingCloudLinkOciError(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("errorPage.existingCloudLinkOciError", returnKey, {
      cloudType
    });
  },
  failedCreation: function errorPage_failedCreation(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("errorPage.failedCreation", returnKey, {});
  },
  failedCreationSubtitle: function errorPage_failedCreationSubtitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("errorPage.failedCreationSubtitle", returnKey, {});
  },
  failedLinking: function errorPage_failedLinking(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("errorPage.failedLinking", returnKey, { cloudType });
  },
  failedLinkingSubtitle: function errorPage_failedLinkingSubtitle(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("errorPage.failedLinkingSubtitle", returnKey, {
      cloudType
    });
  },
  fullScreenPopupTitle: function errorPage_fullScreenPopupTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("errorPage.fullScreenPopupTitle", returnKey, {});
  },
  fullScreenSubtitle: function errorPage_fullScreenSubtitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("errorPage.fullScreenSubtitle", returnKey, {});
  },
  genericErrorSubtitle: function errorPage_genericErrorSubtitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("errorPage.genericErrorSubtitle", returnKey, {});
  },
  genericErrorTitle: function errorPage_genericErrorTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("errorPage.genericErrorTitle", returnKey, {});
  },
  learnMore: function errorPage_learnMore(returnKey?: boolean): string {
    return shouldReturnKey("errorPage.learnMore", returnKey, {});
  },
  missingAzurePrerequisitesSubtitle: function errorPage_missingAzurePrerequisitesSubtitle(
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "errorPage.missingAzurePrerequisitesSubtitle",
      returnKey,
      { link }
    );
  },
  nestedPopupSubtitle: function errorPage_nestedPopupSubtitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("errorPage.nestedPopupSubtitle", returnKey, {});
  },
  nestedPopupTitle: function errorPage_nestedPopupTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("errorPage.nestedPopupTitle", returnKey, {});
  },
  noRegionSubscription: function errorPage_noRegionSubscription(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("errorPage.noRegionSubscription", returnKey, {
      cloudType
    });
  },
  nonHenosisTroubleshootingSubtitle: function errorPage_nonHenosisTroubleshootingSubtitle(
    providerType: string,
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "errorPage.nonHenosisTroubleshootingSubtitle",
      returnKey,
      { providerType, link }
    );
  },
  nonRootCompFailedLinkingSubtitle: function errorPage_nonRootCompFailedLinkingSubtitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "errorPage.nonRootCompFailedLinkingSubtitle",
      returnKey,
      {}
    );
  },
  plannedMaintenanceActivity: function errorPage_plannedMaintenanceActivity(
    providerType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("errorPage.plannedMaintenanceActivity", returnKey, {
      providerType
    });
  },
  plannedMaintenanceActivityActivation: function errorPage_plannedMaintenanceActivityActivation(
    providerType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "errorPage.plannedMaintenanceActivityActivation",
      returnKey,
      { providerType }
    );
  },
  plannedMaintenanceActivityDetail: function errorPage_plannedMaintenanceActivityDetail(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "errorPage.plannedMaintenanceActivityDetail",
      returnKey,
      { cloudType }
    );
  },
  plannedMaintenanceActivityDetailActivation: function errorPage_plannedMaintenanceActivityDetailActivation(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "errorPage.plannedMaintenanceActivityDetailActivation",
      returnKey,
      { cloudType }
    );
  },
  plannedMaintenanceActivityDetailExisting: function errorPage_plannedMaintenanceActivityDetailExisting(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "errorPage.plannedMaintenanceActivityDetailExisting",
      returnKey,
      { cloudType }
    );
  },
  plannedMaintenanceActivityExisting: function errorPage_plannedMaintenanceActivityExisting(
    providerType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "errorPage.plannedMaintenanceActivityExisting",
      returnKey,
      { providerType }
    );
  },
  proceedAnyway: function errorPage_proceedAnyway(returnKey?: boolean): string {
    return shouldReturnKey("errorPage.proceedAnyway", returnKey, {});
  },
  refreshButton: function errorPage_refreshButton(returnKey?: boolean): string {
    return shouldReturnKey("errorPage.refreshButton", returnKey, {});
  },
  unexpectedError: function errorPage_unexpectedError(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("errorPage.unexpectedError", returnKey, {});
  }
};

export const footer = {
  adChoices: function footer_adChoices(returnKey?: boolean): string {
    return shouldReturnKey("footer.adChoices", returnKey, {});
  },
  cookiePreferenceUnavailable: function footer_cookiePreferenceUnavailable(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("footer.cookiePreferenceUnavailable", returnKey, {});
  },
  copyright: function footer_copyright(returnKey?: boolean): string {
    return shouldReturnKey("footer.copyright", returnKey, {});
  },
  info: function footer_info(returnKey?: boolean): string {
    return shouldReturnKey("footer.info", returnKey, {});
  },
  support: function footer_support(returnKey?: boolean): string {
    return shouldReturnKey("footer.support", returnKey, {});
  },
  terms: function footer_terms(returnKey?: boolean): string {
    return shouldReturnKey("footer.terms", returnKey, {});
  }
};

export const gcpFooter = {
  cookiePreferencesUnavailable: function gcpFooter_cookiePreferencesUnavailable(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "gcpFooter.cookiePreferencesUnavailable",
      returnKey,
      {}
    );
  },
  copyright: function gcpFooter_copyright(
    year: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("gcpFooter.copyright", returnKey, { year });
  },
  privacy: function gcpFooter_privacy(returnKey?: boolean): string {
    return shouldReturnKey("gcpFooter.privacy", returnKey, {});
  },
  terms: function gcpFooter_terms(returnKey?: boolean): string {
    return shouldReturnKey("gcpFooter.terms", returnKey, {});
  }
};

export const gcpHome = {
  beforeYouBegin: function gcpHome_beforeYouBegin(returnKey?: boolean): string {
    return shouldReturnKey("gcpHome.beforeYouBegin", returnKey, {});
  },
  body1: function gcpHome_body1(returnKey?: boolean): string {
    return shouldReturnKey("gcpHome.body1", returnKey, {});
  },
  body2: function gcpHome_body2(returnKey?: boolean): string {
    return shouldReturnKey("gcpHome.body2", returnKey, {});
  },
  createOciAccount: function gcpHome_createOciAccount(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("gcpHome.createOciAccount", returnKey, {});
  },
  getStarted: function gcpHome_getStarted(returnKey?: boolean): string {
    return shouldReturnKey("gcpHome.getStarted", returnKey, {});
  },
  optional: function gcpHome_optional(returnKey?: boolean): string {
    return shouldReturnKey("gcpHome.optional", returnKey, {});
  },
  requiredHeader: function gcpHome_requiredHeader(returnKey?: boolean): string {
    return shouldReturnKey("gcpHome.requiredHeader", returnKey, {});
  },
  requiredGcpCloudAccount: function gcpHome_requiredGcpCloudAccount(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("gcpHome.requiredGcpCloudAccount", returnKey, {});
  },
  requiredOciCloudAccount: function gcpHome_requiredOciCloudAccount(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("gcpHome.requiredOciCloudAccount", returnKey, {});
  },
  steps: {
    step1: function gcpHome_steps_step1(returnKey?: boolean): string {
      return shouldReturnKey("gcpHome.steps.step1", returnKey, {});
    },
    step2: function gcpHome_steps_step2(returnKey?: boolean): string {
      return shouldReturnKey("gcpHome.steps.step2", returnKey, {});
    },
    step3: function gcpHome_steps_step3(returnKey?: boolean): string {
      return shouldReturnKey("gcpHome.steps.step3", returnKey, {});
    },
    step4: function gcpHome_steps_step4(returnKey?: boolean): string {
      return shouldReturnKey("gcpHome.steps.step4", returnKey, {});
    },
    step5: function gcpHome_steps_step5(returnKey?: boolean): string {
      return shouldReturnKey("gcpHome.steps.step5", returnKey, {});
    }
  },
  welcome: function gcpHome_welcome(returnKey?: boolean): string {
    return shouldReturnKey("gcpHome.welcome", returnKey, {});
  }
};

export const gcpSignup = {
  button: {
    cancel: function gcpSignup_button_cancel(returnKey?: boolean): string {
      return shouldReturnKey("gcpSignup.button.cancel", returnKey, {});
    },
    continue: function gcpSignup_button_continue(returnKey?: boolean): string {
      return shouldReturnKey("gcpSignup.button.continue", returnKey, {});
    },
    back: function gcpSignup_button_back(returnKey?: boolean): string {
      return shouldReturnKey("gcpSignup.button.back", returnKey, {});
    }
  },
  cloudlinkErrorPage: {
    cancelCloudLinkingBody: function gcpSignup_cloudlinkErrorPage_cancelCloudLinkingBody(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "gcpSignup.cloudlinkErrorPage.cancelCloudLinkingBody",
        returnKey,
        {}
      );
    },
    cancelCloudLinkingHeader: function gcpSignup_cloudlinkErrorPage_cancelCloudLinkingHeader(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "gcpSignup.cloudlinkErrorPage.cancelCloudLinkingHeader",
        returnKey,
        {}
      );
    },
    contactOracleSupport: function gcpSignup_cloudlinkErrorPage_contactOracleSupport(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "gcpSignup.cloudlinkErrorPage.contactOracleSupport",
        returnKey,
        {}
      );
    },
    defaultErrorMessage: function gcpSignup_cloudlinkErrorPage_defaultErrorMessage(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "gcpSignup.cloudlinkErrorPage.defaultErrorMessage",
        returnKey,
        {}
      );
    },
    errorHeader: function gcpSignup_cloudlinkErrorPage_errorHeader(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "gcpSignup.cloudlinkErrorPage.errorHeader",
        returnKey,
        {}
      );
    },
    projectSelectionBody: function gcpSignup_cloudlinkErrorPage_projectSelectionBody(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "gcpSignup.cloudlinkErrorPage.projectSelectionBody",
        returnKey,
        {}
      );
    },
    projectSelectionHeader: function gcpSignup_cloudlinkErrorPage_projectSelectionHeader(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "gcpSignup.cloudlinkErrorPage.projectSelectionHeader",
        returnKey,
        {}
      );
    },
    regionSelectionBody: function gcpSignup_cloudlinkErrorPage_regionSelectionBody(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "gcpSignup.cloudlinkErrorPage.regionSelectionBody",
        returnKey,
        {}
      );
    },
    regionSelectionHeader: function gcpSignup_cloudlinkErrorPage_regionSelectionHeader(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "gcpSignup.cloudlinkErrorPage.regionSelectionHeader",
        returnKey,
        {}
      );
    },
    retryBody: function gcpSignup_cloudlinkErrorPage_retryBody(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "gcpSignup.cloudlinkErrorPage.retryBody",
        returnKey,
        {}
      );
    },
    retryHeader: function gcpSignup_cloudlinkErrorPage_retryHeader(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "gcpSignup.cloudlinkErrorPage.retryHeader",
        returnKey,
        {}
      );
    },
    selectOptionPrompt: function gcpSignup_cloudlinkErrorPage_selectOptionPrompt(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "gcpSignup.cloudlinkErrorPage.selectOptionPrompt",
        returnKey,
        {}
      );
    },
    serviceAccountBody: function gcpSignup_cloudlinkErrorPage_serviceAccountBody(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "gcpSignup.cloudlinkErrorPage.serviceAccountBody",
        returnKey,
        {}
      );
    },
    serviceAccountHeader: function gcpSignup_cloudlinkErrorPage_serviceAccountHeader(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "gcpSignup.cloudlinkErrorPage.serviceAccountHeader",
        returnKey,
        {}
      );
    },
    organizationBody: function gcpSignup_cloudlinkErrorPage_organizationBody(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "gcpSignup.cloudlinkErrorPage.organizationBody",
        returnKey,
        {}
      );
    },
    organizationHeader: function gcpSignup_cloudlinkErrorPage_organizationHeader(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "gcpSignup.cloudlinkErrorPage.organizationHeader",
        returnKey,
        {}
      );
    }
  },
  error: {
    serviceAccountNameLength: function gcpSignup_error_serviceAccountNameLength(
      min: string,
      max: string,
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "gcpSignup.error.serviceAccountNameLength",
        returnKey,
        { min, max }
      );
    },
    serviceAccountNameRegex: function gcpSignup_error_serviceAccountNameRegex(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "gcpSignup.error.serviceAccountNameRegex",
        returnKey,
        {}
      );
    },
    projectsToLinkErr: function gcpSignup_error_projectsToLinkErr(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "gcpSignup.error.projectsToLinkErr",
        returnKey,
        {}
      );
    },
    noServiceAccount: function gcpSignup_error_noServiceAccount(
      returnKey?: boolean
    ): string {
      return shouldReturnKey("gcpSignup.error.noServiceAccount", returnKey, {});
    },
    noSupportedRegionsError: function gcpSignup_error_noSupportedRegionsError(
      returnKey?: boolean
    ): string {
      return shouldReturnKey(
        "gcpSignup.error.noSupportedRegionsError",
        returnKey,
        {}
      );
    }
  },
  organizationTitle: function gcpSignup_organizationTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("gcpSignup.organizationTitle", returnKey, {});
  },
  organizationName: function gcpSignup_organizationName(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("gcpSignup.organizationName", returnKey, {});
  },
  serviceNameTitle: function gcpSignup_serviceNameTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("gcpSignup.serviceNameTitle", returnKey, {});
  },
  serviceNameInput: function gcpSignup_serviceNameInput(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("gcpSignup.serviceNameInput", returnKey, {});
  },
  serviceNameDefault: function gcpSignup_serviceNameDefault(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("gcpSignup.serviceNameDefault", returnKey, {});
  },
  gcpProject: function gcpSignup_gcpProject(returnKey?: boolean): string {
    return shouldReturnKey("gcpSignup.gcpProject", returnKey, {});
  },
  gcpProjectDefault: function gcpSignup_gcpProjectDefault(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("gcpSignup.gcpProjectDefault", returnKey, {});
  },
  noProject: function gcpSignup_noProject(returnKey?: boolean): string {
    return shouldReturnKey("gcpSignup.noProject", returnKey, {});
  },
  projectsToLink: function gcpSignup_projectsToLink(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("gcpSignup.projectsToLink", returnKey, {});
  },
  regionsForCouldLink: function gcpSignup_regionsForCouldLink(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("gcpSignup.regionsForCouldLink", returnKey, {});
  },
  waitLinkODSGCP: function gcpSignup_waitLinkODSGCP(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("gcpSignup.waitLinkODSGCP", returnKey, {});
  },
  waitLinkODSGCPSub: function gcpSignup_waitLinkODSGCPSub(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("gcpSignup.waitLinkODSGCPSub", returnKey, {});
  },
  homeRegionTitle: function gcpSignup_homeRegionTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("gcpSignup.homeRegionTitle", returnKey, {});
  },
  steps: {
    step1: function gcpSignup_steps_step1(returnKey?: boolean): string {
      return shouldReturnKey("gcpSignup.steps.step1", returnKey, {});
    },
    step2: function gcpSignup_steps_step2(returnKey?: boolean): string {
      return shouldReturnKey("gcpSignup.steps.step2", returnKey, {});
    },
    step3: function gcpSignup_steps_step3(returnKey?: boolean): string {
      return shouldReturnKey("gcpSignup.steps.step3", returnKey, {});
    },
    step4: function gcpSignup_steps_step4(returnKey?: boolean): string {
      return shouldReturnKey("gcpSignup.steps.step4", returnKey, {});
    },
    step5: function gcpSignup_steps_step5(returnKey?: boolean): string {
      return shouldReturnKey("gcpSignup.steps.step5", returnKey, {});
    }
  }
};

export const hints = {
  addressSubtitle: function hints_addressSubtitle(returnKey?: boolean): string {
    return shouldReturnKey("hints.addressSubtitle", returnKey, {});
  },
  addressTitle: function hints_addressTitle(returnKey?: boolean): string {
    return shouldReturnKey("hints.addressTitle", returnKey, {});
  },
  companySubtitle: function hints_companySubtitle(returnKey?: boolean): string {
    return shouldReturnKey("hints.companySubtitle", returnKey, {});
  },
  companyTitle: function hints_companyTitle(returnKey?: boolean): string {
    return shouldReturnKey("hints.companyTitle", returnKey, {});
  },
  confirmPasswordSubtitle: function hints_confirmPasswordSubtitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.confirmPasswordSubtitle", returnKey, {});
  },
  confirmPasswordTitle: function hints_confirmPasswordTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.confirmPasswordTitle", returnKey, {});
  },
  emailSubtitle: function hints_emailSubtitle(returnKey?: boolean): string {
    return shouldReturnKey("hints.emailSubtitle", returnKey, {});
  },
  emailTitle: function hints_emailTitle(returnKey?: boolean): string {
    return shouldReturnKey("hints.emailTitle", returnKey, {});
  },
  homeRegionSubtitle: function hints_homeRegionSubtitle(
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("hints.homeRegionSubtitle", returnKey, { link });
  },
  homeRegiontitle: function hints_homeRegiontitle(returnKey?: boolean): string {
    return shouldReturnKey("hints.homeRegiontitle", returnKey, {});
  },
  tenancySubtitle: function hints_tenancySubtitle(returnKey?: boolean): string {
    return shouldReturnKey("hints.tenancySubtitle", returnKey, {});
  },
  tenancyTitle: function hints_tenancyTitle(returnKey?: boolean): string {
    return shouldReturnKey("hints.tenancyTitle", returnKey, {});
  }
};

export const infoBlock = {
  contentO4A: function infoBlock_contentO4A(returnKey?: boolean): string {
    return shouldReturnKey("infoBlock.contentO4A", returnKey, {});
  },
  contentOne: function infoBlock_contentOne(returnKey?: boolean): string {
    return shouldReturnKey("infoBlock.contentOne", returnKey, {});
  },
  contentTwo: function infoBlock_contentTwo(returnKey?: boolean): string {
    return shouldReturnKey("infoBlock.contentTwo", returnKey, {});
  },
  titleO4A: function infoBlock_titleO4A(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("infoBlock.titleO4A", returnKey, { cloudType });
  },
  titleOne: function infoBlock_titleOne(returnKey?: boolean): string {
    return shouldReturnKey("infoBlock.titleOne", returnKey, {});
  },
  titleTwo: function infoBlock_titleTwo(returnKey?: boolean): string {
    return shouldReturnKey("infoBlock.titleTwo", returnKey, {});
  }
};

export const keepAlive = {
  closeButton: function keepAlive_closeButton(returnKey?: boolean): string {
    return shouldReturnKey("keepAlive.closeButton", returnKey, {});
  },
  dialogContent1: function keepAlive_dialogContent1(
    displayTimer: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("keepAlive.dialogContent1", returnKey, {
      displayTimer
    });
  },
  dialogContent2: function keepAlive_dialogContent2(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("keepAlive.dialogContent2", returnKey, {});
  },
  dialogTitle1: function keepAlive_dialogTitle1(returnKey?: boolean): string {
    return shouldReturnKey("keepAlive.dialogTitle1", returnKey, {});
  },
  dialogTitle2: function keepAlive_dialogTitle2(returnKey?: boolean): string {
    return shouldReturnKey("keepAlive.dialogTitle2", returnKey, {});
  }
};

export const landingPage = {
  fullyAutomatedButton: function landingPage_fullyAutomatedButton(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("landingPage.fullyAutomatedButton", returnKey, {});
  },
  fullyAutomatedDescription: function landingPage_fullyAutomatedDescription(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "landingPage.fullyAutomatedDescription",
      returnKey,
      {}
    );
  },
  fullyAutomatedFooter: function landingPage_fullyAutomatedFooter(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("landingPage.fullyAutomatedFooter", returnKey, {});
  },
  fullyAutomatedPermission1: function landingPage_fullyAutomatedPermission1(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "landingPage.fullyAutomatedPermission1",
      returnKey,
      {}
    );
  },
  fullyAutomatedPermission2: function landingPage_fullyAutomatedPermission2(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "landingPage.fullyAutomatedPermission2",
      returnKey,
      {}
    );
  },
  fullyAutomatedPermission3: function landingPage_fullyAutomatedPermission3(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "landingPage.fullyAutomatedPermission3",
      returnKey,
      {}
    );
  },
  fullyAutomatedPermission4: function landingPage_fullyAutomatedPermission4(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "landingPage.fullyAutomatedPermission4",
      returnKey,
      {}
    );
  },
  fullyAutomatedPermission5: function landingPage_fullyAutomatedPermission5(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "landingPage.fullyAutomatedPermission5",
      returnKey,
      {}
    );
  },
  fullyAutomatedPermission6: function landingPage_fullyAutomatedPermission6(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "landingPage.fullyAutomatedPermission6",
      returnKey,
      {}
    );
  },
  fullyAutomatedPermission7: function landingPage_fullyAutomatedPermission7(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "landingPage.fullyAutomatedPermission7",
      returnKey,
      {}
    );
  },
  fullyAutomatedPermission8: function landingPage_fullyAutomatedPermission8(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "landingPage.fullyAutomatedPermission8",
      returnKey,
      {}
    );
  },
  fullyAutomatedPermission9: function landingPage_fullyAutomatedPermission9(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "landingPage.fullyAutomatedPermission9",
      returnKey,
      {}
    );
  },
  fullyAutomatedTitle: function landingPage_fullyAutomatedTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("landingPage.fullyAutomatedTitle", returnKey, {});
  },
  guidedLinking: function landingPage_guidedLinking(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("landingPage.guidedLinking", returnKey, {});
  },
  guidedLinkingButton: function landingPage_guidedLinkingButton(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("landingPage.guidedLinkingButton", returnKey, {});
  },
  guidedLinkingDescription: function landingPage_guidedLinkingDescription(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "landingPage.guidedLinkingDescription",
      returnKey,
      {}
    );
  },
  landingTitle: function landingPage_landingTitle(returnKey?: boolean): string {
    return shouldReturnKey("landingPage.landingTitle", returnKey, {});
  },
  optionDetail: function landingPage_optionDetail(returnKey?: boolean): string {
    return shouldReturnKey("landingPage.optionDetail", returnKey, {});
  },
  optionDifference: function landingPage_optionDifference(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("landingPage.optionDifference", returnKey, {});
  },
  optionValidation: function landingPage_optionValidation(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("landingPage.optionValidation", returnKey, {});
  },
  options: function landingPage_options(returnKey?: boolean): string {
    return shouldReturnKey("landingPage.options", returnKey, {});
  },
  step1: function landingPage_step1(returnKey?: boolean): string {
    return shouldReturnKey("landingPage.step1", returnKey, {});
  },
  step2: function landingPage_step2(returnKey?: boolean): string {
    return shouldReturnKey("landingPage.step2", returnKey, {});
  },
  step3: function landingPage_step3(returnKey?: boolean): string {
    return shouldReturnKey("landingPage.step3", returnKey, {});
  },
  stepsFooter: function landingPage_stepsFooter(returnKey?: boolean): string {
    return shouldReturnKey("landingPage.stepsFooter", returnKey, {});
  },
  stepsHeader: function landingPage_stepsHeader(returnKey?: boolean): string {
    return shouldReturnKey("landingPage.stepsHeader", returnKey, {});
  }
};

export const linkingO4AModal = {
  closeModal: function linkingO4AModal_closeModal(returnKey?: boolean): string {
    return shouldReturnKey("linkingO4AModal.closeModal", returnKey, {});
  },
  defaultTerms1: function linkingO4AModal_defaultTerms1(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("linkingO4AModal.defaultTerms1", returnKey, {});
  },
  defaultTerms2: function linkingO4AModal_defaultTerms2(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("linkingO4AModal.defaultTerms2", returnKey, {});
  },
  defaultTerms3: function linkingO4AModal_defaultTerms3(
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("linkingO4AModal.defaultTerms3", returnKey, {
      link
    });
  },
  defaultTerms4: function linkingO4AModal_defaultTerms4(
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("linkingO4AModal.defaultTerms4", returnKey, {
      link
    });
  },
  defaultTerms5: function linkingO4AModal_defaultTerms5(
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("linkingO4AModal.defaultTerms5", returnKey, {
      link
    });
  },
  linkingProcess1: function linkingO4AModal_linkingProcess1(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("linkingO4AModal.linkingProcess1", returnKey, {});
  },
  linkingProcess2: function linkingO4AModal_linkingProcess2(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("linkingO4AModal.linkingProcess2", returnKey, {});
  },
  linkingProcess3: function linkingO4AModal_linkingProcess3(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("linkingO4AModal.linkingProcess3", returnKey, {});
  },
  linkingProcess4: function linkingO4AModal_linkingProcess4(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("linkingO4AModal.linkingProcess4", returnKey, {});
  },
  linkingProcess5: function linkingO4AModal_linkingProcess5(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("linkingO4AModal.linkingProcess5", returnKey, {});
  },
  linkingProcess6: function linkingO4AModal_linkingProcess6(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("linkingO4AModal.linkingProcess6", returnKey, {});
  },
  linkingProcessHeader: function linkingO4AModal_linkingProcessHeader(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "linkingO4AModal.linkingProcessHeader",
      returnKey,
      {}
    );
  },
  linkingTitle: function linkingO4AModal_linkingTitle(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("linkingO4AModal.linkingTitle", returnKey, {
      cloudType
    });
  },
  permissionsHeader: function linkingO4AModal_permissionsHeader(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("linkingO4AModal.permissionsHeader", returnKey, {
      cloudType
    });
  },
  termsOfUse: function linkingO4AModal_termsOfUse(returnKey?: boolean): string {
    return shouldReturnKey("linkingO4AModal.termsOfUse", returnKey, {});
  }
};

export const loadingroom = {
  login: function loadingroom_login(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("loadingroom.login", returnKey, { cloudType });
  }
};

export const marketing = {
  heatwaveSubtitle: function marketing_heatwaveSubtitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("marketing.heatwaveSubtitle", returnKey, {});
  },
  heatwaveTitle: function marketing_heatwaveTitle(returnKey?: boolean): string {
    return shouldReturnKey("marketing.heatwaveTitle", returnKey, {});
  },
  oracleDBSubtitle: function marketing_oracleDBSubtitle(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("marketing.oracleDBSubtitle", returnKey, {
      cloudType
    });
  },
  oracleDBTitle: function marketing_oracleDBTitle(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("marketing.oracleDBTitle", returnKey, { cloudType });
  }
};

export const mobileVerify = {
  codeRequestOpenDialogOnly: function mobileVerify_codeRequestOpenDialogOnly(
    username: string,
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "mobileVerify.codeRequestOpenDialogOnly",
      returnKey,
      { username, link }
    );
  },
  contactPhoneLabel: function mobileVerify_contactPhoneLabel(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("mobileVerify.contactPhoneLabel", returnKey, {});
  },
  customerPhoneLabel: function mobileVerify_customerPhoneLabel(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("mobileVerify.customerPhoneLabel", returnKey, {});
  },
  maxAttemptsTitle: function mobileVerify_maxAttemptsTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("mobileVerify.maxAttemptsTitle", returnKey, {});
  },
  mobileMaxAttemptsError: function mobileVerify_mobileMaxAttemptsError(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "mobileVerify.mobileMaxAttemptsError",
      returnKey,
      {}
    );
  },
  mobileNumberDescription: function mobileVerify_mobileNumberDescription(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "mobileVerify.mobileNumberDescription",
      returnKey,
      {}
    );
  },
  mobileNumberInfo: function mobileVerify_mobileNumberInfo(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("mobileVerify.mobileNumberInfo", returnKey, {});
  },
  mobileTrunkMessage: function mobileVerify_mobileTrunkMessage(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("mobileVerify.mobileTrunkMessage", returnKey, {});
  },
  mobileVerificationCode: function mobileVerify_mobileVerificationCode(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "mobileVerify.mobileVerificationCode",
      returnKey,
      {}
    );
  },
  mobileVerificationLabel: function mobileVerify_mobileVerificationLabel(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "mobileVerify.mobileVerificationLabel",
      returnKey,
      {}
    );
  },
  mobileVerifyTimer: function mobileVerify_mobileVerifyTimer(
    displayMobileTimer: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("mobileVerify.mobileVerifyTimer", returnKey, {
      displayMobileTimer
    });
  },
  phone: function mobileVerify_phone(returnKey?: boolean): string {
    return shouldReturnKey("mobileVerify.phone", returnKey, {});
  },
  pleaseVerify: function mobileVerify_pleaseVerify(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("mobileVerify.pleaseVerify", returnKey, {});
  },
  smsValidationCodeErrorDetail: function mobileVerify_smsValidationCodeErrorDetail(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "mobileVerify.smsValidationCodeErrorDetail",
      returnKey,
      {}
    );
  },
  smsValidationCodeErrorFormat: function mobileVerify_smsValidationCodeErrorFormat(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "mobileVerify.smsValidationCodeErrorFormat",
      returnKey,
      {}
    );
  },
  smsValidationCodeErrorSummary: function mobileVerify_smsValidationCodeErrorSummary(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "mobileVerify.smsValidationCodeErrorSummary",
      returnKey,
      {}
    );
  },
  verificationCode: function mobileVerify_verificationCode(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("mobileVerify.verificationCode", returnKey, {});
  },
  verificationCodeInfo: function mobileVerify_verificationCodeInfo(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("mobileVerify.verificationCodeInfo", returnKey, {});
  },
  verifymycode: function mobileVerify_verifymycode(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("mobileVerify.verifymycode", returnKey, {});
  }
};

export const nonAuth = {
  btnTxt: function nonAuth_btnTxt(returnKey?: boolean): string {
    return shouldReturnKey("nonAuth.btnTxt", returnKey, {});
  },
  creating: function nonAuth_creating(returnKey?: boolean): string {
    return shouldReturnKey("nonAuth.creating", returnKey, {});
  },
  linkTxt: function nonAuth_linkTxt(returnKey?: boolean): string {
    return shouldReturnKey("nonAuth.linkTxt", returnKey, {});
  },
  message: function nonAuth_message(returnKey?: boolean): string {
    return shouldReturnKey("nonAuth.message", returnKey, {});
  },
  read: function nonAuth_read(returnKey?: boolean): string {
    return shouldReturnKey("nonAuth.read", returnKey, {});
  },
  signup: function nonAuth_signup(returnKey?: boolean): string {
    return shouldReturnKey("nonAuth.signup", returnKey, {});
  },
  title: function nonAuth_title(returnKey?: boolean): string {
    return shouldReturnKey("nonAuth.title", returnKey, {});
  }
};

export const nonPending = {
  btnTxt: function nonPending_btnTxt(returnKey?: boolean): string {
    return shouldReturnKey("nonPending.btnTxt", returnKey, {});
  },
  message: function nonPending_message(returnKey?: boolean): string {
    return shouldReturnKey("nonPending.message", returnKey, {});
  },
  messageActivating: function nonPending_messageActivating(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("nonPending.messageActivating", returnKey, {});
  },
  messageActive: function nonPending_messageActive(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("nonPending.messageActive", returnKey, {});
  },
  messageDeleted: function nonPending_messageDeleted(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("nonPending.messageDeleted", returnKey, {});
  },
  messageExpired: function nonPending_messageExpired(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("nonPending.messageExpired", returnKey, {
      cloudType
    });
  },
  messageHold: function nonPending_messageHold(returnKey?: boolean): string {
    return shouldReturnKey("nonPending.messageHold", returnKey, {});
  },
  title: function nonPending_title(returnKey?: boolean): string {
    return shouldReturnKey("nonPending.title", returnKey, {});
  }
};

export const o4aActivate = {
  leftTitle: function o4aActivate_leftTitle(
    name: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("o4aActivate.leftTitle", returnKey, { name });
  }
};

export const o4aSignIn = {
  leftDescription: function o4aSignIn_leftDescription(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("o4aSignIn.leftDescription", returnKey, {});
  },
  leftTitle: function o4aSignIn_leftTitle(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("o4aSignIn.leftTitle", returnKey, { cloudType });
  }
};

export const ociLandingPage = {
  cloudAccountName: function ociLandingPage_cloudAccountName(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ociLandingPage.cloudAccountName", returnKey, {});
  },
  continue: function ociLandingPage_continue(returnKey?: boolean): string {
    return shouldReturnKey("ociLandingPage.continue", returnKey, {});
  },
  cookiePreferences: function ociLandingPage_cookiePreferences(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ociLandingPage.cookiePreferences", returnKey, {});
  },
  enterTenancy: function ociLandingPage_enterTenancy(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ociLandingPage.enterTenancy", returnKey, {});
  },
  existingOciSigninTitle: function ociLandingPage_existingOciSigninTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "ociLandingPage.existingOciSigninTitle",
      returnKey,
      {}
    );
  },
  existingUserStep1: function ociLandingPage_existingUserStep1(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ociLandingPage.existingUserStep1", returnKey, {});
  },
  existingUserStep2: function ociLandingPage_existingUserStep2(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ociLandingPage.existingUserStep2", returnKey, {});
  },
  existingUserStep3: function ociLandingPage_existingUserStep3(
    providerType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ociLandingPage.existingUserStep3", returnKey, {
      providerType
    });
  },
  existingUserStep4: function ociLandingPage_existingUserStep4(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ociLandingPage.existingUserStep4", returnKey, {
      cloudType
    });
  },
  existingUserTitle: function ociLandingPage_existingUserTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ociLandingPage.existingUserTitle", returnKey, {});
  },
  leftDescription: function ociLandingPage_leftDescription(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ociLandingPage.leftDescription", returnKey, {
      cloudType
    });
  },
  leftTitle: function ociLandingPage_leftTitle(
    name: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ociLandingPage.leftTitle", returnKey, { name });
  },
  logout: function ociLandingPage_logout(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ociLandingPage.logout", returnKey, { cloudType });
  },
  newUserStep1: function ociLandingPage_newUserStep1(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ociLandingPage.newUserStep1", returnKey, {});
  },
  newUserStep2: function ociLandingPage_newUserStep2(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ociLandingPage.newUserStep2", returnKey, {});
  },
  newUserStep3: function ociLandingPage_newUserStep3(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ociLandingPage.newUserStep3", returnKey, {
      cloudType
    });
  },
  newUserTitle: function ociLandingPage_newUserTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ociLandingPage.newUserTitle", returnKey, {});
  },
  ociLogoutLink: function ociLandingPage_ociLogoutLink(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ociLandingPage.ociLogoutLink", returnKey, {});
  },
  privacy: function ociLandingPage_privacy(returnKey?: boolean): string {
    return shouldReturnKey("ociLandingPage.privacy", returnKey, {});
  },
  region: function ociLandingPage_region(returnKey?: boolean): string {
    return shouldReturnKey("ociLandingPage.region", returnKey, {});
  },
  selectRegionSubtitle: function ociLandingPage_selectRegionSubtitle(
    cloudType: string,
    providerType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ociLandingPage.selectRegionSubtitle", returnKey, {
      cloudType,
      providerType
    });
  },
  selectRegionTitle: function ociLandingPage_selectRegionTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ociLandingPage.selectRegionTitle", returnKey, {});
  },
  signInTitle: function ociLandingPage_signInTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("ociLandingPage.signInTitle", returnKey, {});
  },
  signUp: function ociLandingPage_signUp(returnKey?: boolean): string {
    return shouldReturnKey("ociLandingPage.signUp", returnKey, {});
  },
  termsOfUse: function ociLandingPage_termsOfUse(returnKey?: boolean): string {
    return shouldReturnKey("ociLandingPage.termsOfUse", returnKey, {});
  },
  yourOracleCloudAccount: function ociLandingPage_yourOracleCloudAccount(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "ociLandingPage.yourOracleCloudAccount",
      returnKey,
      {}
    );
  }
};

export const password = {
  confirmPass: function password_confirmPass(returnKey?: boolean): string {
    return shouldReturnKey("password.confirmPass", returnKey, {});
  },
  confirmedError: function password_confirmedError(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("password.confirmedError", returnKey, {});
  },
  pass: function password_pass(returnKey?: boolean): string {
    return shouldReturnKey("password.pass", returnKey, {});
  }
};

export const passwordRules = {
  lessThan40Chars: function passwordRules_lessThan40Chars(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("passwordRules.lessThan40Chars", returnKey, {});
  },
  moreThan8Chars: function passwordRules_moreThan8Chars(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("passwordRules.moreThan8Chars", returnKey, {});
  },
  noEmail: function passwordRules_noEmail(returnKey?: boolean): string {
    return shouldReturnKey("passwordRules.noEmail", returnKey, {});
  },
  noFirstName: function passwordRules_noFirstName(returnKey?: boolean): string {
    return shouldReturnKey("passwordRules.noFirstName", returnKey, {});
  },
  noLastName: function passwordRules_noLastName(returnKey?: boolean): string {
    return shouldReturnKey("passwordRules.noLastName", returnKey, {});
  },
  noSpaces: function passwordRules_noSpaces(returnKey?: boolean): string {
    return shouldReturnKey("passwordRules.noSpaces", returnKey, {});
  },
  noSpecialChars: function passwordRules_noSpecialChars(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("passwordRules.noSpecialChars", returnKey, {});
  },
  oneLowercase: function passwordRules_oneLowercase(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("passwordRules.oneLowercase", returnKey, {});
  },
  oneNumeric: function passwordRules_oneNumeric(returnKey?: boolean): string {
    return shouldReturnKey("passwordRules.oneNumeric", returnKey, {});
  },
  oneSpecialChar: function passwordRules_oneSpecialChar(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("passwordRules.oneSpecialChar", returnKey, {});
  },
  oneUppercase: function passwordRules_oneUppercase(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("passwordRules.oneUppercase", returnKey, {});
  },
  passwordRules: function passwordRules_passwordRules(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("passwordRules.passwordRules", returnKey, {});
  }
};

export const personalInfo = {
  address1: function personalInfo_address1(returnKey?: boolean): string {
    return shouldReturnKey("personalInfo.address1", returnKey, {});
  },
  address2: function personalInfo_address2(returnKey?: boolean): string {
    return shouldReturnKey("personalInfo.address2", returnKey, {});
  },
  address3: function personalInfo_address3(returnKey?: boolean): string {
    return shouldReturnKey("personalInfo.address3", returnKey, {});
  },
  address4: function personalInfo_address4(returnKey?: boolean): string {
    return shouldReturnKey("personalInfo.address4", returnKey, {});
  },
  addressInfo: function personalInfo_addressInfo(returnKey?: boolean): string {
    return shouldReturnKey("personalInfo.addressInfo", returnKey, {});
  },
  addressPlaceholder: function personalInfo_addressPlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("personalInfo.addressPlaceholder", returnKey, {});
  },
  city: function personalInfo_city(returnKey?: boolean): string {
    return shouldReturnKey("personalInfo.city", returnKey, {});
  },
  cityPlaceholder: function personalInfo_cityPlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("personalInfo.cityPlaceholder", returnKey, {});
  },
  cnpjPlaceholder: function personalInfo_cnpjPlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("personalInfo.cnpjPlaceholder", returnKey, {});
  },
  county: function personalInfo_county(returnKey?: boolean): string {
    return shouldReturnKey("personalInfo.county", returnKey, {});
  },
  countyPlaceholder: function personalInfo_countyPlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("personalInfo.countyPlaceholder", returnKey, {});
  },
  cpfPlaceholder: function personalInfo_cpfPlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("personalInfo.cpfPlaceholder", returnKey, {});
  },
  dept: function personalInfo_dept(returnKey?: boolean): string {
    return shouldReturnKey("personalInfo.dept", returnKey, {});
  },
  district: function personalInfo_district(returnKey?: boolean): string {
    return shouldReturnKey("personalInfo.district", returnKey, {});
  },
  externalNum: function personalInfo_externalNum(returnKey?: boolean): string {
    return shouldReturnKey("personalInfo.externalNum", returnKey, {});
  },
  intNum: function personalInfo_intNum(returnKey?: boolean): string {
    return shouldReturnKey("personalInfo.intNum", returnKey, {});
  },
  neighborPlaceholder: function personalInfo_neighborPlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("personalInfo.neighborPlaceholder", returnKey, {});
  },
  neighborhood: function personalInfo_neighborhood(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("personalInfo.neighborhood", returnKey, {});
  },
  optional: function personalInfo_optional(returnKey?: boolean): string {
    return shouldReturnKey("personalInfo.optional", returnKey, {});
  },
  postalcode: function personalInfo_postalcode(returnKey?: boolean): string {
    return shouldReturnKey("personalInfo.postalcode", returnKey, {});
  },
  province: function personalInfo_province(returnKey?: boolean): string {
    return shouldReturnKey("personalInfo.province", returnKey, {});
  },
  provincePlaceholder: function personalInfo_provincePlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("personalInfo.provincePlaceholder", returnKey, {});
  },
  requiredMsg: function personalInfo_requiredMsg(
    translatedName: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("personalInfo.requiredMsg", returnKey, {
      translatedName
    });
  },
  state: function personalInfo_state(returnKey?: boolean): string {
    return shouldReturnKey("personalInfo.state", returnKey, {});
  },
  statePlaceholder: function personalInfo_statePlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("personalInfo.statePlaceholder", returnKey, {});
  },
  street: function personalInfo_street(returnKey?: boolean): string {
    return shouldReturnKey("personalInfo.street", returnKey, {});
  },
  streetPlaceholder: function personalInfo_streetPlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("personalInfo.streetPlaceholder", returnKey, {});
  },
  zip: function personalInfo_zip(returnKey?: boolean): string {
    return shouldReturnKey("personalInfo.zip", returnKey, {});
  },
  zipPlaceholder: function personalInfo_zipPlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("personalInfo.zipPlaceholder", returnKey, {});
  }
};

export const regExpr = {
  accountName: function regExpr_accountName(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.accountName", returnKey, {});
  },
  address: function regExpr_address(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.address", returnKey, {});
  },
  altEnglishCompanyName: function regExpr_altEnglishCompanyName(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("regExpr.altEnglishCompanyName", returnKey, {});
  },
  arZipCodeMsg: function regExpr_arZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.arZipCodeMsg", returnKey, {});
  },
  auZipCodeMsg: function regExpr_auZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.auZipCodeMsg", returnKey, {});
  },
  brBillingCnpjMsg: function regExpr_brBillingCnpjMsg(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("regExpr.brBillingCnpjMsg", returnKey, {});
  },
  brBillingCpfMsg: function regExpr_brBillingCpfMsg(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("regExpr.brBillingCpfMsg", returnKey, {});
  },
  brCnpjMsg: function regExpr_brCnpjMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.brCnpjMsg", returnKey, {});
  },
  brCpfMsg: function regExpr_brCpfMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.brCpfMsg", returnKey, {});
  },
  brZipCodeMsg: function regExpr_brZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.brZipCodeMsg", returnKey, {});
  },
  caZipCodeMsg: function regExpr_caZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.caZipCodeMsg", returnKey, {});
  },
  chZipCodeMsg: function regExpr_chZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.chZipCodeMsg", returnKey, {});
  },
  clZipCodeMsg: function regExpr_clZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.clZipCodeMsg", returnKey, {});
  },
  crZipCodeMsg: function regExpr_crZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.crZipCodeMsg", returnKey, {});
  },
  departmentName: function regExpr_departmentName(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.departmentName", returnKey, {});
  },
  ecZipCodeMsg: function regExpr_ecZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.ecZipCodeMsg", returnKey, {});
  },
  email: function regExpr_email(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.email", returnKey, {});
  },
  firstName: function regExpr_firstName(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.firstName", returnKey, {});
  },
  gbZipCodeMsg: function regExpr_gbZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.gbZipCodeMsg", returnKey, {});
  },
  ggZipCodeMsg: function regExpr_ggZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.ggZipCodeMsg", returnKey, {});
  },
  ilZipCodeMsg: function regExpr_ilZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.ilZipCodeMsg", returnKey, {});
  },
  inServiceTaxMsg: function regExpr_inServiceTaxMsg(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("regExpr.inServiceTaxMsg", returnKey, {});
  },
  inZipCodeMsg: function regExpr_inZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.inZipCodeMsg", returnKey, {});
  },
  itZipCodeMsg: function regExpr_itZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.itZipCodeMsg", returnKey, {});
  },
  jobTitle: function regExpr_jobTitle(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.jobTitle", returnKey, {});
  },
  jpZipCodeMsg: function regExpr_jpZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.jpZipCodeMsg", returnKey, {});
  },
  krZipCodeMsg: function regExpr_krZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.krZipCodeMsg", returnKey, {});
  },
  lastName: function regExpr_lastName(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.lastName", returnKey, {});
  },
  letters: function regExpr_letters(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.letters", returnKey, {});
  },
  lvZipCodeMsg: function regExpr_lvZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.lvZipCodeMsg", returnKey, {});
  },
  mobileNumber: function regExpr_mobileNumber(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.mobileNumber", returnKey, {});
  },
  mobileNumberInt: function regExpr_mobileNumberInt(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("regExpr.mobileNumberInt", returnKey, {});
  },
  nlZipCodeMsg: function regExpr_nlZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.nlZipCodeMsg", returnKey, {});
  },
  noPoBoxMsg: function regExpr_noPoBoxMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.noPoBoxMsg", returnKey, {});
  },
  number: function regExpr_number(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.number", returnKey, {});
  },
  omZipCodeMsg: function regExpr_omZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.omZipCodeMsg", returnKey, {});
  },
  organization: function regExpr_organization(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.organization", returnKey, {});
  },
  otherReason: function regExpr_otherReason(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.otherReason", returnKey, {});
  },
  phoneNumber: function regExpr_phoneNumber(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.phoneNumber", returnKey, {});
  },
  plZipCodeMsg: function regExpr_plZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.plZipCodeMsg", returnKey, {});
  },
  poBoxMsg: function regExpr_poBoxMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.poBoxMsg", returnKey, {});
  },
  ptZipCodeMsg: function regExpr_ptZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.ptZipCodeMsg", returnKey, {});
  },
  seZipCodeMsg: function regExpr_seZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.seZipCodeMsg", returnKey, {});
  },
  sgZipCodeMsg: function regExpr_sgZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.sgZipCodeMsg", returnKey, {});
  },
  siZipCodeMsg: function regExpr_siZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.siZipCodeMsg", returnKey, {});
  },
  smsValidationCodeErrorDetail: function regExpr_smsValidationCodeErrorDetail(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "regExpr.smsValidationCodeErrorDetail",
      returnKey,
      {}
    );
  },
  smsValidationCodeErrorFormat: function regExpr_smsValidationCodeErrorFormat(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "regExpr.smsValidationCodeErrorFormat",
      returnKey,
      {}
    );
  },
  smsValidationCodeErrorSummary: function regExpr_smsValidationCodeErrorSummary(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "regExpr.smsValidationCodeErrorSummary",
      returnKey,
      {}
    );
  },
  twZipCodeMsg: function regExpr_twZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.twZipCodeMsg", returnKey, {});
  },
  usZipCodeMsg: function regExpr_usZipCodeMsg(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.usZipCodeMsg", returnKey, {});
  },
  validProvinceData: function regExpr_validProvinceData(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("regExpr.validProvinceData", returnKey, {});
  },
  validStateData: function regExpr_validStateData(returnKey?: boolean): string {
    return shouldReturnKey("regExpr.validStateData", returnKey, {});
  }
};

export const securityVerification = {
  addPayment: function securityVerification_addPayment(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("securityVerification.addPayment", returnKey, {});
  },
  addVerifyMethod: function securityVerification_addVerifyMethod(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "securityVerification.addVerifyMethod",
      returnKey,
      {}
    );
  },
  expireson: function securityVerification_expireson(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("securityVerification.expireson", returnKey, {});
  },
  maxAttemptsInfo: function securityVerification_maxAttemptsInfo(
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("securityVerification.maxAttemptsInfo", returnKey, {
      link
    });
  },
  maxAttemptsTitle: function securityVerification_maxAttemptsTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "securityVerification.maxAttemptsTitle",
      returnKey,
      {}
    );
  },
  nocharge: function securityVerification_nocharge(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("securityVerification.nocharge", returnKey, {});
  },
  paymentVerifyLabel: function securityVerification_paymentVerifyLabel(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "securityVerification.paymentVerifyLabel",
      returnKey,
      {}
    );
  },
  thankyou: function securityVerification_thankyou(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("securityVerification.thankyou", returnKey, {});
  },
  txtCreditCard: function securityVerification_txtCreditCard(
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("securityVerification.txtCreditCard", returnKey, {
      link
    });
  },
  txtCreditCard4: function securityVerification_txtCreditCard4(
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("securityVerification.txtCreditCard4", returnKey, {
      link
    });
  }
};

export const signUpPage = {
  accountName: function signUpPage_accountName(returnKey?: boolean): string {
    return shouldReturnKey("signUpPage.accountName", returnKey, {});
  },
  country: function signUpPage_country(returnKey?: boolean): string {
    return shouldReturnKey("signUpPage.country", returnKey, {});
  },
  email: function signUpPage_email(returnKey?: boolean): string {
    return shouldReturnKey("signUpPage.email", returnKey, {});
  },
  emailIDPlaceholder: function signUpPage_emailIDPlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signUpPage.emailIDPlaceholder", returnKey, {});
  },
  firstName: function signUpPage_firstName(returnKey?: boolean): string {
    return shouldReturnKey("signUpPage.firstName", returnKey, {});
  },
  homeRegion: function signUpPage_homeRegion(returnKey?: boolean): string {
    return shouldReturnKey("signUpPage.homeRegion", returnKey, {});
  },
  lastName: function signUpPage_lastName(returnKey?: boolean): string {
    return shouldReturnKey("signUpPage.lastName", returnKey, {});
  },
  paymentInformation: function signUpPage_paymentInformation(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signUpPage.paymentInformation", returnKey, {});
  },
  signUp: function signUpPage_signUp(returnKey?: boolean): string {
    return shouldReturnKey("signUpPage.signUp", returnKey, {});
  },
  taggedEmailInfo: function signUpPage_taggedEmailInfo(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signUpPage.taggedEmailInfo", returnKey, {});
  },
  verifyEmail: function signUpPage_verifyEmail(returnKey?: boolean): string {
    return shouldReturnKey("signUpPage.verifyEmail", returnKey, {});
  }
};

export const signup = {
  accountInformation: function signup_accountInformation(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.accountInformation", returnKey, {});
  },
  accountName: function signup_accountName(returnKey?: boolean): string {
    return shouldReturnKey("signup.accountName", returnKey, {});
  },
  accountNameLengthError: function signup_accountNameLengthError(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.accountNameLengthError", returnKey, {});
  },
  accountNamePlaceholder: function signup_accountNamePlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.accountNamePlaceholder", returnKey, {});
  },
  altEngCompanyName: function signup_altEngCompanyName(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.altEngCompanyName", returnKey, {});
  },
  altEngCompanyNamePlaceholder: function signup_altEngCompanyNamePlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "signup.altEngCompanyNamePlaceholder",
      returnKey,
      {}
    );
  },
  api403Error: function signup_api403Error(returnKey?: boolean): string {
    return shouldReturnKey("signup.api403Error", returnKey, {});
  },
  api403ErrorDetails: function signup_api403ErrorDetails(
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.api403ErrorDetails", returnKey, { link });
  },
  apiError: function signup_apiError(returnKey?: boolean): string {
    return shouldReturnKey("signup.apiError", returnKey, {});
  },
  apiErrorDetails: function signup_apiErrorDetails(
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.apiErrorDetails", returnKey, { link });
  },
  armOCIInfo: function signup_armOCIInfo(returnKey?: boolean): string {
    return shouldReturnKey("signup.armOCIInfo", returnKey, {});
  },
  captchaExpiration: function signup_captchaExpiration(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.captchaExpiration", returnKey, {});
  },
  confirmEmail: function signup_confirmEmail(returnKey?: boolean): string {
    return shouldReturnKey("signup.confirmEmail", returnKey, {});
  },
  confirmPassword: function signup_confirmPassword(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.confirmPassword", returnKey, {});
  },
  confirmPasswordMatch: function signup_confirmPasswordMatch(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.confirmPasswordMatch", returnKey, {});
  },
  confirmPasswordPlaceholder: function signup_confirmPasswordPlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.confirmPasswordPlaceholder", returnKey, {});
  },
  confirmPasswordValidation: function signup_confirmPasswordValidation(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.confirmPasswordValidation", returnKey, {});
  },
  country: function signup_country(returnKey?: boolean): string {
    return shouldReturnKey("signup.country", returnKey, {});
  },
  countryError: function signup_countryError(
    link: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.countryError", returnKey, { link });
  },
  departmentName: function signup_departmentName(returnKey?: boolean): string {
    return shouldReturnKey("signup.departmentName", returnKey, {});
  },
  departmentNamePlaceholder: function signup_departmentNamePlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.departmentNamePlaceholder", returnKey, {});
  },
  email: function signup_email(returnKey?: boolean): string {
    return shouldReturnKey("signup.email", returnKey, {});
  },
  emailExists: function signup_emailExists(returnKey?: boolean): string {
    return shouldReturnKey("signup.emailExists", returnKey, {});
  },
  emailPlaceholder: function signup_emailPlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.emailPlaceholder", returnKey, {});
  },
  emailSendFailure: function signup_emailSendFailure(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.emailSendFailure", returnKey, {});
  },
  emailVerifyLinkSent: function signup_emailVerifyLinkSent(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.emailVerifyLinkSent", returnKey, {});
  },
  firstName: function signup_firstName(returnKey?: boolean): string {
    return shouldReturnKey("signup.firstName", returnKey, {});
  },
  firstNamePlaceholder: function signup_firstNamePlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.firstNamePlaceholder", returnKey, {});
  },
  forgotAcctEmail2: function signup_forgotAcctEmail2(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.forgotAcctEmail2", returnKey, {});
  },
  forgotAcctEmail3: function signup_forgotAcctEmail3(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.forgotAcctEmail3", returnKey, {});
  },
  forgotAcctEmail4: function signup_forgotAcctEmail4(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.forgotAcctEmail4", returnKey, {});
  },
  homeRegion: function signup_homeRegion(returnKey?: boolean): string {
    return shouldReturnKey("signup.homeRegion", returnKey, {});
  },
  homeRegionInfo: function signup_homeRegionInfo(
    link1: string,
    link2: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.homeRegionInfo", returnKey, {
      link1,
      link2
    });
  },
  homeRegionPlaceholder: function signup_homeRegionPlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.homeRegionPlaceholder", returnKey, {});
  },
  invalidEmail: function signup_invalidEmail(returnKey?: boolean): string {
    return shouldReturnKey("signup.invalidEmail", returnKey, {});
  },
  jobTitle: function signup_jobTitle(returnKey?: boolean): string {
    return shouldReturnKey("signup.jobTitle", returnKey, {});
  },
  jobTitlePlaceholder: function signup_jobTitlePlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.jobTitlePlaceholder", returnKey, {});
  },
  lastName: function signup_lastName(returnKey?: boolean): string {
    return shouldReturnKey("signup.lastName", returnKey, {});
  },
  lastNamePlaceholder: function signup_lastNamePlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.lastNamePlaceholder", returnKey, {});
  },
  note: function signup_note(returnKey?: boolean): string {
    return shouldReturnKey("signup.note", returnKey, {});
  },
  oracleEmail: function signup_oracleEmail(returnKey?: boolean): string {
    return shouldReturnKey("signup.oracleEmail", returnKey, {});
  },
  organization: function signup_organization(returnKey?: boolean): string {
    return shouldReturnKey("signup.organization", returnKey, {});
  },
  organizationPlaceholder: function signup_organizationPlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.organizationPlaceholder", returnKey, {});
  },
  password: function signup_password(returnKey?: boolean): string {
    return shouldReturnKey("signup.password", returnKey, {});
  },
  passwordCondition1: function signup_passwordCondition1(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.passwordCondition1", returnKey, {});
  },
  passwordCondition2: function signup_passwordCondition2(
    escChars: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.passwordCondition2", returnKey, {
      escChars
    });
  },
  passwordPlaceholder: function signup_passwordPlaceholder(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.passwordPlaceholder", returnKey, {});
  },
  requestSent: function signup_requestSent(returnKey?: boolean): string {
    return shouldReturnKey("signup.requestSent", returnKey, {});
  },
  resendEmail: function signup_resendEmail(returnKey?: boolean): string {
    return shouldReturnKey("signup.resendEmail", returnKey, {});
  },
  retry: function signup_retry(returnKey?: boolean): string {
    return shouldReturnKey("signup.retry", returnKey, {});
  },
  sendEmail: function signup_sendEmail(returnKey?: boolean): string {
    return shouldReturnKey("signup.sendEmail", returnKey, {});
  },
  signupNotAvailable: function signup_signupNotAvailable(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.signupNotAvailable", returnKey, {});
  },
  taggedEmailInfo: function signup_taggedEmailInfo(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("signup.taggedEmailInfo", returnKey, {});
  },
  username: function signup_username(returnKey?: boolean): string {
    return shouldReturnKey("signup.username", returnKey, {});
  },
  verifyMyEmail: function signup_verifyMyEmail(returnKey?: boolean): string {
    return shouldReturnKey("signup.verifyMyEmail", returnKey, {});
  }
};

export const subscriptionBlock = {
  SearchSubscriptions: function subscriptionBlock_SearchSubscriptions(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "subscriptionBlock.SearchSubscriptions",
      returnKey,
      {}
    );
  },
  noResults: function subscriptionBlock_noResults(returnKey?: boolean): string {
    return shouldReturnKey("subscriptionBlock.noResults", returnKey, {});
  },
  orderNum: function subscriptionBlock_orderNum(returnKey?: boolean): string {
    return shouldReturnKey("subscriptionBlock.orderNum", returnKey, {});
  },
  subId: function subscriptionBlock_subId(returnKey?: boolean): string {
    return shouldReturnKey("subscriptionBlock.subId", returnKey, {});
  },
  subName: function subscriptionBlock_subName(returnKey?: boolean): string {
    return shouldReturnKey("subscriptionBlock.subName", returnKey, {});
  },
  title: function subscriptionBlock_title(returnKey?: boolean): string {
    return shouldReturnKey("subscriptionBlock.title", returnKey, {});
  }
};

export const termsOfUse = {
  defaultTerms: function termsOfUse_defaultTerms(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("termsOfUse.defaultTerms", returnKey, { cloudType });
  },
  linkingTerms: function termsOfUse_linkingTerms(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("termsOfUse.linkingTerms", returnKey, { cloudType });
  }
};

export const troubleShootingModal = {
  errorMessage: function troubleShootingModal_errorMessage(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("troubleShootingModal.errorMessage", returnKey, {});
  },
  requestId: function troubleShootingModal_requestId(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("troubleShootingModal.requestId", returnKey, {});
  },
  timestamp: function troubleShootingModal_timestamp(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("troubleShootingModal.timestamp", returnKey, {});
  },
  troubleShootingTitle: function troubleShootingModal_troubleShootingTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "troubleShootingModal.troubleShootingTitle",
      returnKey,
      {}
    );
  }
};

export const uberLandingPage = {
  awsContent: function uberLandingPage_awsContent(returnKey?: boolean): string {
    return shouldReturnKey("uberLandingPage.awsContent", returnKey, {});
  },
  awsHeader: function uberLandingPage_awsHeader(returnKey?: boolean): string {
    return shouldReturnKey("uberLandingPage.awsHeader", returnKey, {});
  },
  azureContent: function uberLandingPage_azureContent(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("uberLandingPage.azureContent", returnKey, {});
  },
  azureHeader: function uberLandingPage_azureHeader(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("uberLandingPage.azureHeader", returnKey, {});
  },
  continueAWS: function uberLandingPage_continueAWS(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("uberLandingPage.continueAWS", returnKey, {});
  },
  continueAzure: function uberLandingPage_continueAzure(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("uberLandingPage.continueAzure", returnKey, {});
  },
  continueGCP: function uberLandingPage_continueGCP(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("uberLandingPage.continueGCP", returnKey, {});
  },
  gcpContent: function uberLandingPage_gcpContent(returnKey?: boolean): string {
    return shouldReturnKey("uberLandingPage.gcpContent", returnKey, {});
  },
  gcpHeader: function uberLandingPage_gcpHeader(returnKey?: boolean): string {
    return shouldReturnKey("uberLandingPage.gcpHeader", returnKey, {});
  },
  uberContentAzureGcp: function uberLandingPage_uberContentAzureGcp(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "uberLandingPage.uberContentAzureGcp",
      returnKey,
      {}
    );
  },
  uberContentAzureGcpAws: function uberLandingPage_uberContentAzureGcpAws(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "uberLandingPage.uberContentAzureGcpAws",
      returnKey,
      {}
    );
  },
  uberTitleAzureGcp: function uberLandingPage_uberTitleAzureGcp(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("uberLandingPage.uberTitleAzureGcp", returnKey, {});
  },
  uberTitleAzureGcpAws: function uberLandingPage_uberTitleAzureGcpAws(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "uberLandingPage.uberTitleAzureGcpAws",
      returnKey,
      {}
    );
  }
};

export const validationFailures = {
  azureConsentErrorSubtitle1: function validationFailures_azureConsentErrorSubtitle1(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "validationFailures.azureConsentErrorSubtitle1",
      returnKey,
      {}
    );
  },
  azureConsentErrorSubtitle2: function validationFailures_azureConsentErrorSubtitle2(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "validationFailures.azureConsentErrorSubtitle2",
      returnKey,
      {}
    );
  },
  azureConsentErrorTitle: function validationFailures_azureConsentErrorTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "validationFailures.azureConsentErrorTitle",
      returnKey,
      {}
    );
  },
  existingLinkHeader: function validationFailures_existingLinkHeader(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validationFailures.existingLinkHeader", returnKey, {
      cloudType
    });
  },
  existingLinkSubtitle: function validationFailures_existingLinkSubtitle(
    azureAccount: string,
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "validationFailures.existingLinkSubtitle",
      returnKey,
      { azureAccount, cloudType }
    );
  },
  gcpExistingLinkSubtitle: function validationFailures_gcpExistingLinkSubtitle(
    gcpAccount: string,
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "validationFailures.gcpExistingLinkSubtitle",
      returnKey,
      { gcpAccount, cloudType }
    );
  },
  genericError: function validationFailures_genericError(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validationFailures.genericError", returnKey, {});
  },
  genericErrorSubtitle: function validationFailures_genericErrorSubtitle(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "validationFailures.genericErrorSubtitle",
      returnKey,
      { cloudType }
    );
  },
  indexDBErrorSubtitle: function validationFailures_indexDBErrorSubtitle(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "validationFailures.indexDBErrorSubtitle",
      returnKey,
      { cloudType }
    );
  },
  indexDBErrorTitle: function validationFailures_indexDBErrorTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "validationFailures.indexDBErrorTitle",
      returnKey,
      {}
    );
  },
  logout: function validationFailures_logout(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validationFailures.logout", returnKey, {
      cloudType
    });
  },
  ociLogout: function validationFailures_ociLogout(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validationFailures.ociLogout", returnKey, {});
  },
  noSubscriptionHeader: function validationFailures_noSubscriptionHeader(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "validationFailures.noSubscriptionHeader",
      returnKey,
      {}
    );
  },
  noSubscriptionSubtitle: function validationFailures_noSubscriptionSubtitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "validationFailures.noSubscriptionSubtitle",
      returnKey,
      {}
    );
  },
  redirectConsole: function validationFailures_redirectConsole(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("validationFailures.redirectConsole", returnKey, {
      cloudType
    });
  },
  supportedBrowserList: function validationFailures_supportedBrowserList(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "validationFailures.supportedBrowserList",
      returnKey,
      {}
    );
  },
  troubleshootingGuide: function validationFailures_troubleshootingGuide(
    returnKey?: boolean
  ): string {
    return shouldReturnKey(
      "validationFailures.troubleshootingGuide",
      returnKey,
      {}
    );
  }
};

export const waitingRoom = {
  contactSupport: function waitingRoom_contactSupport(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("waitingRoom.contactSupport", returnKey, {});
  },
  createAccount: function waitingRoom_createAccount(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("waitingRoom.createAccount", returnKey, {});
  },
  longerWaitSubtitle: function waitingRoom_longerWaitSubtitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("waitingRoom.longerWaitSubtitle", returnKey, {});
  },
  longerWaitTitle: function waitingRoom_longerWaitTitle(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("waitingRoom.longerWaitTitle", returnKey, {});
  },
  message: function waitingRoom_message(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("waitingRoom.message", returnKey, { cloudType });
  },
  messageLinking: function waitingRoom_messageLinking(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("waitingRoom.messageLinking", returnKey, {});
  },
  messageSubtitle: function waitingRoom_messageSubtitle(
    cloudType: string,
    returnKey?: boolean
  ): string {
    return shouldReturnKey("waitingRoom.messageSubtitle", returnKey, {
      cloudType
    });
  },
  needHelp: function waitingRoom_needHelp(returnKey?: boolean): string {
    return shouldReturnKey("waitingRoom.needHelp", returnKey, {});
  },
  slide1Content: function waitingRoom_slide1Content(
    returnKey?: boolean
  ): string {
    return shouldReturnKey("waitingRoom.slide1Content", returnKey, {});
  },
  slide1Header: function waitingRoom_slide1Header(returnKey?: boolean): string {
    return shouldReturnKey("waitingRoom.slide1Header", returnKey, {});
  },
  slide1Title: function waitingRoom_slide1Title(returnKey?: boolean): string {
    return shouldReturnKey("waitingRoom.slide1Title", returnKey, {});
  },
  timeout: function waitingRoom_timeout(returnKey?: boolean): string {
    return shouldReturnKey("waitingRoom.timeout", returnKey, {});
  }
};
