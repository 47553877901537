import React from "react";
import { genShortUid } from "../../utils/guid";
import * as stringUtils from "../../utils/string";

class HighlightChunks {
  value!: string;
  Highlighted!: boolean;
}

export type HighlightProps = {
  inputValue: string;
  search: string;
  className?: string;
  caseSensitive?: boolean;
};

function Highlight({ inputValue, search, className, caseSensitive }: HighlightProps): JSX.Element {
  if (!inputValue || !search) {
    return <span>{inputValue}</span>;
  }

  const value = stringUtils.removeTags(inputValue.toString());

  let words = search.split(" ");
  words = words.filter(w => !!w).sort((w1, w2) => w2.length - w1.length);

  const chunks: HighlightChunks[] = [{ Highlighted: false, value: value.toString() }];
  for (let i = 0, { length } = words; i < length; ++i) {
    const lookFor = words[i];

    for (let j = 0, valLength = chunks.length; j < valLength; ++j) {
      const chunk = chunks[j];
      if (chunk.Highlighted) {
        continue;
      }
      const searchIn = !caseSensitive ? chunk.value.toLowerCase() : chunk.value;
      const lookForLower = !caseSensitive ? lookFor.toLowerCase() : lookFor;
      const lookForLength = lookFor.length;
      const indexes = stringUtils.allIndexOf(searchIn, lookForLower);

      if (indexes.length) {
        const tempChunks: HighlightChunks[] = [];
        tempChunks.push({
          value: chunk.value.substring(0, indexes[0]),
          Highlighted: false,
        });
        for (let k = 0, indexesLength = indexes.length; k < indexesLength; ++k) {
          const from = indexes[k];
          const to = from + lookForLength;
          const str = chunk.value.substring(from, from + lookForLength);

          tempChunks.push({
            value: str,
            Highlighted: true,
          });

          const length2 = k + 1 !== indexes.length ? indexes[k + 1] - to : chunk.value.length - to;

          tempChunks.push({
            value: chunk.value.substring(to, to + length2),
            Highlighted: false,
          });
        }

        chunks.splice(j, 1, ...tempChunks);
        valLength = chunks.length;
        j += tempChunks.length - 1;
      }
    }
  }

  return (
    <>
      {chunks.map((v: HighlightChunks) =>
        !v.Highlighted ? (
          v.value
        ) : (
          <span key={genShortUid()} className={className ?? "highlighted"}>
            {v.value}
          </span>
        ),
      )}
    </>
  );
}

export default Highlight;
