import { getWindow } from "../../fluorine/WindowHelper";

/**
 * A whitelist object used to determine if a specific url matches a regular expression.
 *
 * @interface AuthEndpoint
 */
interface AuthEndpoint {
  regex: RegExp;
  authenticate: boolean;
}

/**
 * An array of AuthEndpoint objects used to determine if the application should authenticate
 * the specific url.
 */
const authEndpoints: AuthEndpoint[] = [
  // for localhost 8181 and 8282
  {
    regex: new RegExp("^(http|https)://localhost:(8181|8282)"),
    authenticate: true,
  },
  // Placeholder for the DNS API
  {
    regex: new RegExp("apis/coredns"),
    authenticate: true,
  },
  // OCI Status API (https://ocistatus.oraclecloud.com/)
  // e.g. https://ocistatus.oraclecloud.com/api/v2/status.json
  {
    regex: new RegExp("^https://ocistatus.oraclecloud.com/api/.+$"),
    authenticate: false,
  },
  // cdn
  {
    regex: new RegExp("^https?://.*plugins.oci.oraclecloud.com([/?]|$)"),
    authenticate: false,
  },
  // R0/next
  {
    regex: new RegExp("^https?://.*.aka.r0.grungy.us([/?]|$)"),
    authenticate: true,
  },
  // R1/Stable
  {
    regex: new RegExp("^https?://.*.r1.oracleiaas.com([/?]|$)"),
    authenticate: true,
  },
  // Prod
  {
    regex: new RegExp("^https?://.*.oraclecloud.com([/?]|$)"),
    authenticate: true,
  },
  // Gov Cloud
  {
    regex: new RegExp("^https?://.*.oraclegovcloud.(com|uk)([/?]|$)"),
    authenticate: true,
  },
  // ONS Cloud
  {
    regex: new RegExp("^https?://.*.oracleo?nsrcloud.com([/?]|$)"),
    authenticate: true,
  },
  {
    regex: new RegExp("^https?://.*.oraclecloud5.com([/?]|$)"),
    authenticate: true,
  },
  // https://jira.oci.oraclecorp.com/browse/DUPLO-790
  // for hops endpoint, should remove once DUPLO-790 is done
  {
    regex: new RegExp("^(http|https)://hops(.|-).*(svc|aka)"),
    authenticate: true,
  },
  // v2 hops endpoint
  {
    regex: new RegExp("^(http|https)://provisioning-api(.|-).*(svc|next)"),
    authenticate: true,
  },
  // SPECTRE
  {
    regex: new RegExp("^(http|https)://spectre.*"),
    authenticate: true,
  },
  // Development tenancies
  {
    regex: new RegExp("^https?://([a-zA-Z0-9-_]+\\.)*oc-test.com([/?]|$)"),
    authenticate: true,
  },
  // oc6
  {
    regex: new RegExp("^https?://([a-zA-Z0-9-_]+\\.)*(oraclecloud|oraclerealm).ic.gov([/?]|$)"),
    authenticate: true,
  },
  // oc7
  {
    regex: new RegExp("^https?://([a-zA-Z0-9-_]+\\.)*oci?.ic.gov([/?]|$)"),
    authenticate: true,
  },
  // oc8
  {
    regex: /^https?:\/\/(?:[a-zA-Z0-9-_]+\.)*oraclecloud8\.com(?:[\/?]|$)/,
    authenticate: true,
  },
  // oc9
  {
    regex: /^https?:\/\/(?:[a-zA-Z0-9-_]+\.)*oraclecloud9\.com(?:[\/?]|$)/,
    authenticate: true,
  },
  // oc10
  {
    regex: /^https?:\/\/(?:[a-zA-Z0-9-_]+\.)*oraclecloud10\.com(?:[\/?]|$)/,
    authenticate: true,
  },
];

/**
 * Check a specific url against the whitelist to determine if the provided url should be authorized
 * or not. It will step through the whitelist array in the defined order and stop on the FIRST
 * match. Make sure you define the most specific routes first.
 *
 * @export
 * @param url - The specific url to match against.
 *
 * @returns {boolean} true if the url should be authorized; false otherwise.
 */
export function shouldAuthorize(url: string): boolean {
  // if url doesn't come with host, we use the host from url
  let urlObj;
  try {
    urlObj = new URL(url);
  } catch (_) {
    urlObj = new URL(getWindow().location.href);
    url = `${urlObj.protocol}//${urlObj.host}${url}`;
  }

  for (let index in authEndpoints) {
    const match = authEndpoints[index].regex.test(url);
    if (match) {
      return authEndpoints[index].authenticate;
    }
  }

  return false;
}
