/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export interface HookError {
  status: number;
  message: string;
}

export enum CloudType {
  AZURE = "AZURE",
  AWS = "AWS",
  GCP = "GCP",
}

export enum Environment {
  LOCALHOST = "LOCALHOST",
  PINTLAB = "PINTLAB",
  DEV = "DEV",
  STAGING = "STAGING",
  PROD = "PROD",
}
