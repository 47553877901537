import { Alert, SpaceBetween, Spinner, TextContent } from "o4aws-react";
import AwsFooter from "../Footer/AwsFooter";
import HeaderContainer from "../Headers/HeaderContainer";
import "./AwsLoadingRoom.scss";

type AwsLoadingRoomProps = {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  showHeader?: boolean;
  hideSpinner?: boolean;
  errorPayload?: Error;
  showFooter?: boolean;
  controlHeight?: boolean;
  enableAwsBanner?: boolean;
};

const AwsLoadingRoom = ({
  showHeader,
  title,
  subtitle,
  hideSpinner,
  errorPayload,
  showFooter = true,
  controlHeight = false,
  enableAwsBanner = false,
}: AwsLoadingRoomProps) => {
  let loadingRoomClassName = "";
  if (controlHeight && enableAwsBanner) loadingRoomClassName = "loadingRoomRestrictHeight40";
  else if (controlHeight) loadingRoomClassName = "loadingRoomRestrictHeight50";
  return (
    <div className={loadingRoomClassName} data-testid={loadingRoomClassName}>
      {showHeader && <HeaderContainer />}
      <div className="odsawsLoadingContainer" data-testid="aws-loading-room">
        <div className="odsawsLoadingGraphic" />
        {!hideSpinner && (
          <div data-testid="loadingRoomSpinner">
            <Spinner size="large" />
          </div>
        )}
        <TextContent>
          <SpaceBetween size="xs">
            <div className="odsawsLoadingTitle" data-testid="aws-loading-room-title">
              {title}
            </div>
            {subtitle && <p data-testid="aws-loading-room-subtitle">{subtitle}</p>}
          </SpaceBetween>
          {errorPayload && (
            <div
              className="xs-block-margin odsawsLoading-contain-width-65"
              data-testid="aws-loading-error-alert"
            >
              <Alert type="error">{errorPayload.message}</Alert>
            </div>
          )}
        </TextContent>
      </div>
      {showFooter && <AwsFooter />}
    </div>
  );
};

export default AwsLoadingRoom;
