import * as React from "react";
import { ReactNode, useMemo, useState } from "react";
import {
  Box,
  Button,
  ButtonDropdown,
  ButtonDropdownProps,
  ButtonProps,
  Header,
  HeaderProps,
  SpaceBetween,
  Table,
  TableProps,
  Toggle,
} from "../../index";
import "./ResourceListTable.scss";

export const REFRESH_BTN_TEST_ID = "rlt-refresh-btn";

export type ResourceListTableDropDownProps = Omit<ButtonDropdownProps, "variant"> & {
  variant: "normal";
};

type ActionsName = "customActions" | "refresh" | "groupedToggle" | "dropdown" | "primaryAction";

export type ResourceListTableProps<TEntity = any> = Omit<TableProps<TEntity>, "header"> & {
  actionButtonProps?: ButtonProps;
  actionButtonTitle?: string;
  buttonDropdown?: ResourceListTableDropDownProps;
  buttonDropdownTitle?: string;
  actionsOrder?: ActionsName[];
  customActions?: ReactNode;
  groupedTitle?: string;
  header?: Pick<HeaderProps, "counter" | "description" | "info" | "variant">;
  onGrouped?(_toggled: boolean): void;
  onRefresh?(): void;
  subHeader?: ReactNode;
  title: string;
};

export function ResourceListTableHeader({
  actionButtonProps,
  actionButtonTitle,
  actionsOrder,
  buttonDropdown,
  buttonDropdownTitle,
  customActions,
  groupedTitle,
  header,
  onGrouped,
  onRefresh,
  subHeader,
  title,
}: ResourceListTableProps): JSX.Element {
  const [checked, setChecked] = useState<boolean>(true);
  const toggleChanged = (toggled: boolean) => {
    setChecked(toggled);
    onGrouped(toggled);
  };
  const actions = useMemo(
    () => getActions(),
    [
      actionButtonProps,
      actionButtonTitle,
      actionsOrder,
      buttonDropdown,
      buttonDropdownTitle,
      customActions,
      groupedTitle,
      onGrouped,
      onRefresh,
    ],
  );

  function getActions(): (ReactNode | JSX.Element)[] {
    const actionsDict: { [key in ActionsName]: ReactNode | JSX.Element | false | undefined } = {
      refresh: onRefresh && (
        <Button key={1} data-testid={REFRESH_BTN_TEST_ID} iconName="refresh" onClick={onRefresh} />
      ),
      groupedToggle: onGrouped && (
        <Box key={2} textAlign="center" padding="xxs">
          <Toggle checked={checked} onChange={({ detail }) => toggleChanged(detail.checked)}>
            {groupedTitle}
          </Toggle>
        </Box>
      ),
      dropdown: buttonDropdown && buttonDropdownTitle && (
        <ButtonDropdown key={3} {...buttonDropdown}>
          {buttonDropdownTitle}
        </ButtonDropdown>
      ),
      primaryAction: actionButtonTitle && actionButtonProps && (
        <Button key={4} variant="primary" {...actionButtonProps}>
          {actionButtonTitle}
        </Button>
      ),
      customActions,
    };

    const enabledActionNames = Object.keys(actionsDict).filter(
      (actionName: ActionsName) => !!actionsDict[actionName],
    ) as ActionsName[];

    if (actionsOrder?.length) {
      const actionNameIndexOfOrder = actionsOrder.reduce((prev, curr, i) => {
        prev[curr] = i;
        return prev;
      }, {} as { [key: string]: number });
      enabledActionNames.sort((a, b) => {
        const indexA = actionNameIndexOfOrder[a];
        const indexB = actionNameIndexOfOrder[b];
        if (indexA === undefined) return 1;
        if (indexB === undefined) return -1;
        return indexA - indexB;
      });
    }

    return enabledActionNames.map((actionName: ActionsName) => actionsDict[actionName]);
  }

  return (
    <>
      <Header
        {...header}
        actions={
          <SpaceBetween direction="horizontal" size="s">
            {actions}
          </SpaceBetween>
        }
      >
        {title}
      </Header>
      {subHeader}
    </>
  );
}

function ResourceListTable(props: ResourceListTableProps) {
  return <Table {...props} header={<ResourceListTableHeader {...props} />} />;
}

export default ResourceListTable;
