import { SessionStorage } from "./Type";
import Log from "./CourierService/Logger";
import { AUTH_CODE_QUERY_PARAM, AWS_ACCOUNT_ID_PARAM } from "./constants";

export const checkCanary = () => {
  if (window.location.search.includes(SessionStorage.CANARY)) {
    const search = window.location.search;
    const queryParameters = search.split("&");
    // eslint-disable-next-line array-callback-return
    queryParameters.map(param => {
      param = param.replace("?", "");
      const paramContents = param.split("=");
      try {
        sessionStorage.setItem(paramContents[0], paramContents[1]);
      } catch (error) {
        Log.error("There was an error parsing queryString:", error);
      }
    });
  }
};

export const removeSearchParam = (searchParam: string[]) => {
  const currentURL = new URL(window.location.href);
  let shouldResetLocation = false;
  searchParam.forEach(parameter => {
    if (currentURL.searchParams.has(parameter)) {
      currentURL.searchParams.delete(parameter);
      shouldResetLocation = true;
    }
  });
  shouldResetLocation && window.location.assign(currentURL);
};

export const isCanaryRun = () => {
  return sessionStorage.getItem(SessionStorage.CANARY) === "true";
};

export const localhostHelper = () => {
  if (window.location.hostname === "localhost") {
    const url = new URL(
      window.location.href.replace("https://localhost:3000", "https://localhost.oracle.com:3000"),
    );
    window.location.replace(url);
  }
};

export const handleAWSAuthCode = () => {
  const authRedirectURL = new URLSearchParams(window.location.search);
  if (authRedirectURL.has(AUTH_CODE_QUERY_PARAM)) {
    const authCodeValue = authRedirectURL.get(AUTH_CODE_QUERY_PARAM);
    sessionStorage.setItem(SessionStorage.AUTH_CODE, authCodeValue || "");
  }
};

export const handleAWSAccountId = () => {
  const searchURL = new URLSearchParams(window.location.search);
  if (searchURL.has(AWS_ACCOUNT_ID_PARAM)) {
    const accountID = searchURL.get(AWS_ACCOUNT_ID_PARAM);
    accountID && sessionStorage.setItem(SessionStorage.ACCOUNT_ID, accountID);
  }
};

export const handleExternalId = () => {
  let externalID: string = Date.now().toString();
  const characters: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz";
  for (let i: number = 0; i < 23; i++) {
    externalID += characters.charAt(Math.floor(Math.random() * 62));
  }
  sessionStorage.setItem(SessionStorage.EXTERNAL_ID, externalID);
  return externalID;
};
