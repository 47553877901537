import * as React from "react";
import { Button, FormField, FormFieldProps, Textarea } from "../../index";
import "./FileUpload.scss";

export interface FileUploadProps extends FormFieldProps {
  /**
   * Label for FileUpload
   */
  ariaLabel: string;

  /**
   * placeholder text for FileUpload
   */
  placeholder?: string;

  /**
   * Button text
   */
  buttonText?: string;

  /**
   * Callback when a file is provided or the existing input is changed
   */
  onChange?(value: File): void;

  /**
   * Callback to validate the text input
   */
  validator?(file: File): string[] | undefined;

  /**
   * Form error messages
   */
  formErrors?: string;
}

export const SELECT_FILE_TEST_ID = "select-file-test-id";
export const FILE_UPLOADER_TEST_ID = "file-uploader";

export function FileUpload({
  ariaLabel,
  description,
  placeholder,
  buttonText,
  formErrors,
  validator,
  onChange,
}: FileUploadProps): JSX.Element {
  const [fileName, setName] = React.useState<string>("");
  const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>();
  const selectedFile = React.useRef<File | undefined>();
  const [errorMessage, setErrorMessage] = React.useState<string>(formErrors);
  const clickHandler = (): void => {
    inputRef && inputRef.click();
  };

  const fieldValidation = (): void => {
    const errorMsg = validator && validator(selectedFile.current);

    const errors = [...(errorMsg || [])];
    setErrorMessage(errors.join("\n"));
  };

  const internalOnChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    evt.preventDefault();

    selectedFile.current = evt.target.files?.length > 0 ? evt.target.files[0] : undefined;

    const uploadedFileName: string = selectedFile.current?.name || "";
    setName(uploadedFileName);
    if (validator) {
      fieldValidation();
    }

    onChange?.(selectedFile.current);
  };

  return (
    <div className="o4aws-react-file-upload">
      <FormField label={ariaLabel} description={description} errorText={errorMessage}>
        <input
          ref={input => setInputRef(input)}
          className="input-file"
          data-testid="file-uploader"
          type="file"
          id="File"
          name="File"
          onChange={internalOnChange}
        />
      </FormField>
      <div className="file-upload-action">
        <div className="file-textarea">
          <Textarea placeholder={placeholder} value={fileName} rows={1} readOnly />
        </div>
        <div className="upload-button">
          {!buttonText ? (
            <Button
              data-testid={SELECT_FILE_TEST_ID}
              iconName="folder"
              onClick={clickHandler}
              variant="inline-icon"
            />
          ) : (
            <Button data-testid={SELECT_FILE_TEST_ID} onClick={clickHandler} variant="link">
              {buttonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default FileUpload;
