/* eslint-disable no-restricted-properties */
/* eslint-disable no-bitwise */

import random from "../utils/random";

export function genGuid(): string {
  return ((1e7).toString() + -1e3 + -4e3 + -8e3 + -1e11).replace(/[0123456789]/g, (c: any) =>
    (c ^ (random.getInt(1, 256) & (15 >> (c / 4)))).toString(16),
  );
}

export function genShortUid(): string {
  return Math.random().toString(36).slice(2);
}
