import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { initializeDuploAuth } from "./utils/authHelper";
import { checkCanary, localhostHelper, removeSearchParam } from "./bootstrapUtils";
import IndexedDBMissingErrorPage from "./common/Errors/IndexedDBMissingErrorPage";
import { FormProvider } from "./components/Context/ApplicationContext";
import { INDEXED_DB_MISSING_ERROR } from "./constants";
import { AuthMetrics, emitEvent } from "./CourierService/metrics";
import { initializeInfinity } from "./CourierService/postAnalytics";
import * as Messages from "./codegen/Messages";
import FeatureFlags from "./utils/FeatureFlags";
import { getDocument } from "./utils/BrowserHelpers";
import {
  TelemetryClientConfig,
  TelemetryClient,
  MetricsClient as LoomMetricsClient,
} from "loom-telemetry-client";
import { Environment, MulticloudTelemetryHelper } from "multi-cloud-shared-components";
import { isLocalhostEnv, isPintlabEnv, isStagingEnv } from "./utils/ApiConfigUtil";
import { isCanaryRun } from "./bootstrapUtils";
import Log from "./CourierService/Logger";
import { postAnalytics } from "./CourierService/postAnalytics";
import "./styles/main.scss";

const version = require("../package.json").version;
console.log(`App version: ${version}`);

const TELEMETRY_SERVICE_BATCH_SIZE = 25;
const TELEMETRY_SERVICE_MAX_RETRIES = 3;

async function initializeTelemetryClient(): Promise<void> {
  const telemetryClientConfig: TelemetryClientConfig = {
    serviceLimit: TELEMETRY_SERVICE_BATCH_SIZE,
    maxRetries: TELEMETRY_SERVICE_MAX_RETRIES,
    logToBrowsersConsole: true,
    postToTelemetryService: true,
    namespace: "multicloud_signup",
  };

  // const timeToInitializeTelemetryClient = window.performance.now();
  await TelemetryClient.initialize(telemetryClientConfig);
  TelemetryClient.setRealm("oc1");
  TelemetryClient.setTelemetryURLs(["https://console-telemetry.oci.oraclecloud.com"]);

  /*
  Metrics.emitOnce(
    Metrics.Areas.TimeToInitializeTelemetryClient,
    Metrics.EventModifiers.Duration,
    Math.floor(window.performance.now() - timeToInitializeTelemetryClient),
  );
  */
}

async function initialize(): Promise<void> {
  const doc = getDocument();
  checkCanary();
  localhostHelper();
  removeSearchParam(["tenancyId"]);
  initializeInfinity();
  await initializeTelemetryClient();

  MulticloudTelemetryHelper.init({
    emitMetricInterface: LoomMetricsClient.getMetricsClient().sendMetric.bind(
      LoomMetricsClient.getMetricsClient(),
    ),
    postAnalyticsInterface: postAnalytics,
    csp: undefined!,
    cspIdentifier: undefined!,
    ociTenantName: undefined!,
    app: "multicloud_signup",
    defaultRegion: undefined!,
    env: isLocalhostEnv()
      ? Environment.LOCALHOST
      : isPintlabEnv()
      ? Environment.DEV
      : isStagingEnv()
      ? Environment.STAGING
      : Environment.PROD,
    isCanary: isCanaryRun(),
  });

  try {
    await initializeDuploAuth();
    await Promise.all([FeatureFlags.initialize(), Messages.init()]);

    ReactDOM.render(
      <React.StrictMode>
        <FormProvider>
          <App />
        </FormProvider>
      </React.StrictMode>,
      doc.getElementById("root"),
    );
  } catch (e: any) {
    Messages.init();
    let errorPage: JSX.Element | null = null;
    if (e.message === INDEXED_DB_MISSING_ERROR) {
      errorPage = <IndexedDBMissingErrorPage />;
    } else {
      emitEvent(AuthMetrics.OCIAuthInitError);
      Log.error(`Index page init failure - ${JSON.stringify(e)}`);
    }

    ReactDOM.render(
      <React.StrictMode>
        <FormProvider>{errorPage ? errorPage : <App />}</FormProvider>
      </React.StrictMode>,
      doc.getElementById("root"),
    );
  }
}

initialize();
