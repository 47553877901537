/**
 * Helper function for determining if an error has JSON content
 */
export const hasJsonContent = (res: Response): boolean => {
  const contentType = res.headers?.get("Content-Type");
  return !!(contentType && contentType.includes("application/json"));
};

export const defaultPostRequestSupplier = (payload: BodyInit | null, endpoint: string) =>
  new Request(endpoint, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: payload,
  });
