import PSD2OrgIDList from "../data/PSD2OrgID.json";
import * as Messages from "../codegen/Messages";
import { FormattedString } from "loom-formatted-string-react";

export function getErrorTypeFromPaymentErrorCodes(orgID: string, errorCode?: string, avs?: string) {
  let errorType = "";
  if (errorCode === "481" && (avs === "C" || avs === "N")) {
    errorType = "avs";
  } else if (errorCode === "481" && !(avs === "C" || avs === "N")) {
    errorType = "481";
  } else if (errorCode === "231") {
    errorType = "231";
  } else if (errorCode === "202") {
    errorType = "202";
  } else if ((errorCode === "203" || errorCode === "233") && PSD2OrgIDList.hasOwnProperty(orgID)) {
    errorType = "psd2";
  } else {
    errorType = "2xx";
  }
  return errorType;
}

export function getPaymentErrorContent(errorType: string) {
  if (errorType === "avs") {
    return (
      <>
        <p>{Messages.cyberSourceErrMsg.avsHeader()}</p>
        <p>{Messages.cyberSourceErrMsg.avsMessage()}</p>
        <p>{Messages.cyberSourceErrMsg.footer()}</p>
      </>
    );
  } else if (errorType === "481") {
    return (
      <>
        <p>{Messages.cyberSourceErrMsg.header481()}</p>
        <p>
          <FormattedString inputText={Messages.cyberSourceErrMsg.message481()} />
        </p>
        <p>
          <FormattedString inputText={Messages.cyberSourceErrMsg.footer()} />
        </p>
      </>
    );
  } else if (errorType === "231") {
    return (
      <>
        <p>
          <FormattedString inputText={Messages.cyberSourceErrMsg.header231()} />
        </p>
        <p>
          <FormattedString inputText={Messages.cyberSourceErrMsg.message231()} />
        </p>
        <p>
          <FormattedString inputText={Messages.cyberSourceErrMsg.footer()} />
        </p>
      </>
    );
  } else if (errorType === "202") {
    return (
      <>
        <p>
          <FormattedString inputText={Messages.cyberSourceErrMsg.header202()} />
        </p>
        <p>
          <FormattedString inputText={Messages.cyberSourceErrMsg.message202()} />
        </p>
        <p>
          <FormattedString inputText={Messages.cyberSourceErrMsg.footer()} />
        </p>
      </>
    );
  } else if (errorType === "psd2") {
    return (
      <>
        <p>
          <FormattedString inputText={Messages.cyberSourceErrMsg.psd2Header()} />
        </p>
        <p>
          <FormattedString inputText={Messages.cyberSourceErrMsg.psd2Message()} />
        </p>
        <p>
          <FormattedString inputText={Messages.cyberSourceErrMsg.footer()} />
        </p>
      </>
    );
  } else {
    return (
      <>
        <p>
          <FormattedString inputText={Messages.cyberSourceErrMsg.header2xx()} />
        </p>
        <p>
          <FormattedString inputText={Messages.cyberSourceErrMsg.message2xx()} />
        </p>
        <p>
          <FormattedString inputText={Messages.cyberSourceErrMsg.footer()} />
        </p>
      </>
    );
  }
}
