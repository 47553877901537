import {
  IAuthorization,
  IProvidesAuthorization,
  IProvidesAuthorizationContext,
  IAuthorizationContext,
} from "../authorization";

import * as signing from "../../signingString";
import { IKeyPairEnvelope, INativeCrypto, str2ab } from "../../crypto";
import { fromByteArray } from "base64-js";
import { getHgCompatibleDbData, isHgCompatibleDbDataPresent } from "../../indexedDb";
import { HgIndexedDBData } from "../../HgIndexedDBData";

const AUTHORIZATION = "Authorization";

export interface IBrowserMaterialEnvelope extends IKeyPairEnvelope {
  securityToken: string;
}

// Tagging interface
export interface ISignatureAuthStrategy {}

export interface ISignatureAuthorization extends IAuthorization<ISignatureAuthStrategy> {
  orderedHeaders: Array<string>;
  signingString: string;
  signature: string;
}

export interface ICryptoDelegate {
  (): INativeCrypto;
}

export class BrowserSignedRequestAuthorizer
  implements IProvidesAuthorization<ISignatureAuthStrategy, IBrowserMaterialEnvelope, ISignatureAuthorization>
{
  readonly contextFactory: IProvidesAuthorizationContext<ISignatureAuthStrategy, IBrowserMaterialEnvelope>;
  readonly cryptoDelegate: ICryptoDelegate;
  constructor(
    cryptoDelegate: ICryptoDelegate,
    contextFactory: IProvidesAuthorizationContext<ISignatureAuthStrategy, IBrowserMaterialEnvelope>,
  ) {
    this.cryptoDelegate = cryptoDelegate;
    this.contextFactory = contextFactory;
  }

  async createAuthorization(
    context: IAuthorizationContext<ISignatureAuthStrategy, IBrowserMaterialEnvelope>,
  ): Promise<ISignatureAuthorization> {
    const request = context.request;

    const packet = await signing.createSigningString(request);
    const envelope = context.materials;
    const crypto = this.cryptoDelegate();
    const signatureBuffer = await crypto.sign(envelope.materials.privateKey, str2ab(packet.signingString));

    return {
      orderedHeaders: packet.orderedHeaders,
      signingString: packet.signingString,
      signature: fromByteArray(new Uint8Array(signatureBuffer)),
    };
  }

  async createSignature(realm: string, content: string): Promise<string> {
    const hgCompatibleDbData: HgIndexedDBData = await getHgCompatibleDbData(realm);
    if (isHgCompatibleDbDataPresent(hgCompatibleDbData)) {
      const crypto = this.cryptoDelegate();
      const signatureBuffer = await crypto.sign(hgCompatibleDbData.private, str2ab(content));

      return fromByteArray(new Uint8Array(signatureBuffer));
    } else {
      return Promise.reject("No authentication material present.");
    }
  }

  attachAuthorization(
    context: IAuthorizationContext<ISignatureAuthStrategy, IBrowserMaterialEnvelope>,
    authorization: ISignatureAuthorization,
  ): Promise<Request> {
    const newReq = context.request.clone();

    const authParts = [
      ["keyId", "ST$" + context.materials.securityToken],
      ["version", "1"],
      ["algorithm", "rsa-sha256"],
      ["headers", authorization.orderedHeaders.join(" ")],
      ["signature", authorization.signature],
    ];
    const authorizationString = authParts
      .map(kv => {
        let [k, v] = kv;
        return `${k}=\"${v}\"`;
      })
      .join(",");
    const authHeader = `Signature ${authorizationString}`;

    newReq.headers.set(AUTHORIZATION, authHeader);
    return Promise.resolve(newReq);
  }
}
