/**
 * Gets random int between min and max included.
 *
 * @param min minium number included
 * @param max maximum number included
 * @returns random int
 */
function getInt(min: number, max: number): number {
  return min + Math.floor(Math.random() * (max - min + 1));
}

function getItemFromArray<T>(items: T[]): T | null {
  if (!items.length) {
    return null;
  }
  return items[getInt(0, items.length - 1)];
}

export default { getInt, getItemFromArray };
