import React from "react";
import { Truste } from "../../Type";
import AwsFooter from "./AwsFooter";
import { OciFooter } from "./OciFooter";
import GcpFooter from "./GcpFooter";

declare global {
  interface Window {
    truste: Truste;
  }
}

type FooterType = "oci" | "aws" | "gcp";

type FooterContainerProps = {
  footerType?: FooterType;
};

export const FooterContainer = ({ footerType = "oci" }: FooterContainerProps) => {
  const [trusteAvailable, setTrusteAvailable] = React.useState<boolean>(false);

  React.useEffect(() => {
    /**
     * Since the footer in the app is not static, the "Cookie Preferences" link is reset after the footer is hidden for the first time.
     * This fix manually calls the internal icon initialization function to re-render this link.
     */
    const trusteLink = document.getElementById("teconsent");
    if (window.truste?.eu && !trusteLink?.hasChildNodes()) {
      window.truste.eu?.icon?.initialize();
    }
    setTrusteAvailable(!!window.truste?.eu);
  }, []);

  switch (footerType) {
    case "aws":
      return <AwsFooter isTrusteAvailable={trusteAvailable} />;
    case "gcp":
      return <GcpFooter isTrusteAvailable={trusteAvailable} />;
    case "oci":
      return <OciFooter isTrusteAvailable={trusteAvailable} />;
  }
};
