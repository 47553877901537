import { RealmConfig } from "./RealmConfig";
import { OracleCloudAuthenticatorConfig } from "./OracleCloudAuthenticatorConfig";
import { SoupAuthenticator } from "./SoupAuthenticator";
import { TokenAuthenticator, RestoreRouteFunction, ReportErrorFunction, GetAuthorizationUrl } from "./interfaces";
import { checkSoupLanding, getCurrentUrlParameters, checkForSLO } from "./urlHelpers";
import { clearAuthenticatingRealm, getAuthenticatingRealm } from "../indexedDb";
import Logger from "../../telemetry/Logger";
import MetricsClient from "../../telemetry/MetricsClient";
import { getXmlRequestWrapper } from "../xmlHttpRequestWrapper";
import { getXmlRequestWrapper as localGetXmlRequestWrapper } from "../localXmlHttpRequestWrapper";

export class OraclePublicCloudAuthenticatorSingleton {
  private static reportErrorCallback: ReportErrorFunction;
  private static authenticators = new Map<string, TokenAuthenticator>();
  private static readonly defaultRealm = "default";
  private static readonly realmHeader = "x-orcl-realm";
  private static config: OracleCloudAuthenticatorConfig;
  // added to support a public property for signed XMLHttpRequests - assigned at initialization
  private static defaultAuthUrl: GetAuthorizationUrl;

  /**
   * Init OraclePublicCloudAuthenticator.
   *
   * Before this library redirects to SOUP login page, it gets router path and computes a state from it.
   * The state is passed to SOUP. When SOUP redirects back to console, the state is passed back. This library extracts the original
   * router path, and calls restoreRouteCallback, so app status can be restored
   * @param {RestoreRouteFunction} restoreRouteCallback The callback to pass back router path
   * @param {ReportErrorFunction} reportError The callback to report error happened inside this library
   * @param realmConfigs List of objects which hold various endpoints/urls for each realm
   * @param reusedTokenExpiration
   */
  public static async initialize(
    restoreRouteCallback: RestoreRouteFunction,
    reportError: ReportErrorFunction,
    realmConfigs?: RealmConfig[],
    reusedTokenExpiration?: number,
    optOutXmlHttpOverride?: boolean,
  ): Promise<Error | undefined> {
    const oracleConfig: OracleCloudAuthenticatorConfig = {
      restoreRouteCallback,
      reportError,
      realmConfigs: [],
    };

    oracleConfig.realmConfigs = [...realmConfigs];
    oracleConfig.realmConfigs.reduce((authenticators, realmConfig) => {
      authenticators.set(
        realmConfig.realm,
        new SoupAuthenticator(oracleConfig.restoreRouteCallback, oracleConfig.reportError, realmConfig),
      );
      return authenticators;
    }, this.authenticators);
    await this.addTelemetryAndSloSupport(oracleConfig, optOutXmlHttpOverride);
    this.config = oracleConfig;
    this.reportError = oracleConfig.reportError;
    return this.authenticate(reusedTokenExpiration);
  }

  private static async addTelemetryAndSloSupport(
    oracleConfig: OracleCloudAuthenticatorConfig,
    optOutXmlHttpOverride: boolean,
  ) {
    oracleConfig.realmConfigs.forEach(async (realmConfig: RealmConfig) => {
      this.authenticators.get(realmConfig.realm).setLoggerClient(new Logger(realmConfig.realm));
      this.authenticators.get(realmConfig.realm).setMetricsClient(new MetricsClient(realmConfig.realm));
      if (realmConfig.realm === this.defaultRealm) {
        !optOutXmlHttpOverride &&
          OraclePublicCloudAuthenticatorSingleton.installXMLHttpRequestWrapper(realmConfig.authorizationServiceUrl);
        this.defaultAuthUrl = realmConfig.authorizationServiceUrl;
        const sloLogout = OraclePublicCloudAuthenticatorSingleton.singleLogout();
        if (sloLogout) {
          try {
            await this.authenticators.get(realmConfig.realm).logout();
          } catch (error) {
            OraclePublicCloudAuthenticatorSingleton.reportError(
              `OraclePublicCloudAuthenticator::singleLogout: ${error}`,
            );
          }
        }
      }
    });
  }

  private static singleLogout(): boolean {
    const currentUrlParameters = getCurrentUrlParameters();
    return checkForSLO(currentUrlParameters);
  }

  private static async authenticate(reusedTokenExpiration?: number) {
    const currentUrlParameters = getCurrentUrlParameters();
    const isSoupLanding = checkSoupLanding(currentUrlParameters, reusedTokenExpiration);
    let authenticateError;
    if (isSoupLanding) {
      authenticateError = await (
        this.getInstance(await getAuthenticatingRealm()) as SoupAuthenticator
      ).handleLogInFromSoupLandingPage(currentUrlParameters, !this.config.restoreRouteCallback);
    } else {
      localStorage.setItem("ConsoleLandingTimestamp", `${new Date().getTime()}`);
    }
    await clearAuthenticatingRealm();
    return authenticateError;
  }

  public static get Config(): OracleCloudAuthenticatorConfig {
    return this.config;
  }

  public static async crossRealmFetch(url: string, request: Request): Promise<Response> {
    if (!request.headers.has(this.realmHeader)) {
      throw new Error(`Cross realm header ${this.realmHeader} not present `);
    }

    const realm = request.headers.get(this.realmHeader);
    request.headers.delete(this.realmHeader);
    const authenticator = this.getInstance(realm);
    if (!(await authenticator.isSessionActive())) {
      await authenticator.authenticateUser();
      return;
    }

    return authenticator.getFetchApi()(url, request);
  }

  public static async updateAuthConfigUrls(configs: {
    loginServiceUrl: string;
    identityServiceUrl: string;
    authorizationServiceUrl: GetAuthorizationUrl;
  }) {
    const authenticator = this.getInstance(await getAuthenticatingRealm()) as SoupAuthenticator;
    authenticator.updateConfigUrls(configs);
  }

  /**
   * Get authenticator instance
   */
  public static getInstance(realm?: string) {
    let currentRealm = realm || this.defaultRealm;
    if (!this.authenticators.has(currentRealm)) {
      const errorMessage =
        this.defaultRealm === currentRealm
          ? "Please call initialize before using authenticator"
          : `Please initialize realm ${currentRealm} before using authenticator`;
      throw new Error(errorMessage);
    }
    return this.authenticators.get(currentRealm);
  }

  public static reportError(error: string): void {
    this.reportErrorCallback(error);
  }

  /**
   * Installs the XMLHttpRequest wrapper in order to add authentication to legacy style requests.
   *
   * @private
   * @memberof BMCClient
   */
  private static installXMLHttpRequestWrapper(getAuthorizationUrl: GetAuthorizationUrl) {
    // Without specifying "any" as the type you will get an error yelling about "new".
    // Any should be ok because we override XMLHttpRequest post-init with xmlHttpRequestWrapper
    // and the extended functions added by xmlHttpRequestWrapper are for internal use only.
    (<any>window).XMLHttpRequest = getXmlRequestWrapper(getAuthorizationUrl, XMLHttpRequest as any) as any;
    console.warn("WARNING! XMLHttpRequest signing for OCI endpoints is being deprecated");
  }

  public static getOciXmlHttpRequest(): typeof window.XMLHttpRequest {
    return localGetXmlRequestWrapper(this.defaultAuthUrl, XMLHttpRequest as any) as any;
  }

  /**
   * Set authenticator instance. This function is design for unit-test.
   * Prefix with "_" to avoid compile complain it is declared but never used.
   *
   * @param realm
   * @param obj An instance of TokenAuthenticator
   * @param reportError Callback to report error
   */
  // tslint:disable-next-line:function-name
  public static _setInstance(realm: string, obj: TokenAuthenticator, reportError: ReportErrorFunction) {
    this.authenticators.set(realm, obj);
    this.reportErrorCallback = reportError;
  }
}
