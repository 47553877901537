import { AwsAuth } from "aws-auth-core";
import { capitalize, findIndex, isEmpty, trim } from "lodash";
import { AzAuth } from "loom-az-auth-service";
import { FetchApiSignature } from "loom-az-auth-service/build/src/FetchApiSignature";
import { Region } from "oci-console-regions";
import { FormDispatch } from "../components/Context/ApplicationContext";
import { CourierServiceMetadata } from "../CourierService/CourierServiceTransport";
import { fetchBanner } from "../CourierService/FetchService";
import Log from "../CourierService/Logger";
import {
  ApiMetricType,
  emitCloudSpecificEvent,
  emitEvent,
  emitLatencyEvent,
  getApiMetricName,
  MetricPrefixes,
} from "../CourierService/metrics";
import { postCloudSpecificAnalytics } from "../CourierService/postAnalytics";
import OrgIdList from "../data/OrgIdName.json";
import { GcpStoreActions } from "../models/gcpState";
import { store } from "../redux/store";
import { CloudLinkType, Dispatch, HomeRegionInternal, ProviderType, SessionStorage } from "../Type";
import FeatureFlags from "../utils/FeatureFlags";
import { retryPromiseWithDelay } from "../utils/PromiseUtil";
import { getConsoleEndpoint } from "./EnvironmentConfig";
import { hasJsonContent } from "./PromiseUtil";

export interface BannerResults {
  bannerDetails: string;
  bannerTitle: string;
  endTime: string;
  isOpayOutage: boolean;
  level: string;
  startTime: string;
  shouldDisableFlow?: boolean;
}

export function reloadOnBackButton(e: PopStateEvent) {
  e.preventDefault();
  window.location.reload();
}

export const addReloadOnBackButtonHandler = () => {
  window.addEventListener("popstate", reloadOnBackButton);
  window.history.pushState(null, "", window.location.href);
  return () => {
    window.removeEventListener("popstate", reloadOnBackButton);
  };
};

/**
 * Converts inputField name to human readable text
 * @param  {string} inputFieldName The name of the inputField
 * @return {string} string output with lowercase inputFieldName split at capital letters separated by spaces in between.
 */
export const getFormattedInputFieldName = (inputFieldName: string) => {
  return inputFieldName
    .replace(/([A-Z])/g, " $1")
    .trim()
    .toLowerCase();
};

export const getOpcRequestId = (response: Response): string | null => {
  return response?.headers?.get("opc-request-id") ?? "";
};

export const getOpcRequestIdMetadata = (response: Response): CourierServiceMetadata | undefined => {
  const opcRequestId = getOpcRequestId(response);
  if (opcRequestId) {
    return [{ key: "opcRequestId", value: opcRequestId, type: "string" }];
  }
  return;
};

/**
 * Converts API reponse into metadata format suitable for logging
 * @param  {Response} response The response from the API
 * @return {CourierServiceMetadata} Metadata output with response body, response status code and opcRequestID
 */
export const getAPIResponseMetadata = async (
  response: Response,
): Promise<CourierServiceMetadata> => {
  const opcRequestIdMetadata = getOpcRequestIdMetadata(response);
  const responseMetadata: CourierServiceMetadata = [
    {
      key: "statusCode",
      value: response.status.toString(),
      type: "number",
    },
  ];
  if (opcRequestIdMetadata) {
    responseMetadata.push(...opcRequestIdMetadata);
  }
  const clonedResponse = response.clone();
  if (hasJsonContent(clonedResponse)) {
    const body = await clonedResponse.json();
    if (body?.message) {
      responseMetadata.push({
        key: "responseBody",
        value: body.message,
        type: "string",
      });
    }
  }
  return responseMetadata;
};

export const checkBanner = (bannerResults: BannerResults, indexValue: number): BannerResults => {
  const currentTime = new Date().getTime();
  const shouldDisableFlow = FeatureFlags.isEnabled(
    indexValue === 0 ? "disable-signup-azure" : "disable-activation-azure",
  );
  const bannerStartTime = Date.parse(bannerResults.startTime);
  const bannerEndTime = Date.parse(bannerResults.endTime);
  if ((currentTime >= bannerStartTime && currentTime <= bannerEndTime) || shouldDisableFlow) {
    return { ...bannerResults, shouldDisableFlow: true };
  }
  return { ...bannerResults, shouldDisableFlow: false };
};

/**
 * Fetches banner based on Activation or Signup scenario and returns API results.
 * @param  {number} indexValue Identifier to distinguish scenario(0: SignUp, 1: Activation)
 * @return {BannerResults} Results of fetchBanner API if successful.
 */
export const getBannerResults = async (indexValue: number): Promise<BannerResults | undefined> => {
  const fetchBannerStartTime = new Date().getTime();
  const bannerApiMetricPrefix =
    indexValue === 0 ? MetricPrefixes.Banner : MetricPrefixes.ActivationBanner;
  return fetchBanner()
    .then(async res => {
      const responseMetadata = await getAPIResponseMetadata(res);
      emitLatencyEvent(
        getApiMetricName(bannerApiMetricPrefix, ApiMetricType.Latency),
        fetchBannerStartTime,
      );
      if (res.status === 200) {
        const result = hasJsonContent(res) ? await res.json() : res;
        const banner = result?.items[indexValue];
        Log.info("GET banner list successful", responseMetadata);
        emitEvent(getApiMetricName(bannerApiMetricPrefix, ApiMetricType._2xx));
        return checkBanner(banner, indexValue);
      } else {
        Log.error("Banner API Call failed", res.status, responseMetadata);
        if (res.status === 403) {
          emitEvent(getApiMetricName(bannerApiMetricPrefix, ApiMetricType._403));
        } else if (res.status >= 400 && res.status <= 499) {
          emitEvent(getApiMetricName(bannerApiMetricPrefix, ApiMetricType._4xx));
        } else if (res.status >= 500) {
          emitEvent(getApiMetricName(bannerApiMetricPrefix, ApiMetricType._5xx));
        }
      }
    })
    .catch((error: { message: any }) => {
      emitLatencyEvent(
        getApiMetricName(bannerApiMetricPrefix, ApiMetricType.Latency),
        fetchBannerStartTime,
      );
      Log.error(`GET banner list exception: ${error.message}`);
      emitEvent(getApiMetricName(bannerApiMetricPrefix, ApiMetricType.Exception));
      return undefined;
    });
};

export const pushIntoNavHistory = () => {
  window.history.pushState(null, "", window.location.href);
};

export const generateRandomNumber = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const generateRandomLetter = () => {
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  return alphabet[Math.floor(Math.random() * alphabet.length)];
};

export const isNumericChar = (c: string) => {
  const re = /^\d$/;
  return re.test(c);
};

export const getOrgId = (orgName: string) => {
  let orgId = "";
  // eslint-disable-next-line array-callback-return
  OrgIdList.OrgMapping.map(item => {
    if (item.orgName === orgName) {
      orgId = item.orgId;
    }
  });
  return orgId;
};

export const randomPaddedIntString = (
  min: number,
  max: number,
  padString: string,
  length: number,
) => {
  let str = Math.floor(Math.random() * (max - min + 1) + min).toString();

  while (str.length < length) {
    str = padString + str;
  }
  return str;
};

export const removeSpace = (output: any) => {
  let value;
  if (typeof output === "string") {
    return trim(output);
  }
  /* eslint-disable guard-for-in,no-restricted-syntax,no-param-reassign */
  for (const key in output) {
    value = output[key];
    if (typeof value === "string") {
      output[key] = trim(value);
    } else if (typeof value === "object" && !Array.isArray(value)) {
      output[key] = removeSpace(value);
    }
  }
  return output;
};

export const getFeatureFlag = (featureName: string, featureFlags: any[]) => {
  if (!isEmpty(featureFlags)) {
    const index = findIndex(featureFlags, o => o.featureName === featureName);
    return featureFlags[index].isActive;
  }
  return false;
};

export const objectWithoutKey = (object: any, key: any) => {
  const { [key]: deletedKey, ...otherKeys } = object;
  return otherKeys;
};

export const updateApiErrorDetails = async (
  requestURL: string,
  dispatch: FormDispatch,
  res?: Response,
) => {
  try {
    let errorDetails;
    if (res) {
      const response = await res.json();
      errorDetails = {
        opcRequestId: res.headers.get("opc-request-id"),
        errorTimestamp:
          res.headers.get("date") || res.headers.get("Date") || new Date().toUTCString(),
        errorMessage: `The URI '${requestURL}' returned a ${res.status} error : ${JSON.stringify(
          response,
        )}`,
      };
    } else {
      errorDetails = { errorMessage: `The URI '${requestURL}' returned an error.` };
    }
    errorDetails &&
      dispatch({
        type: Dispatch.UPDATE_API_ERROR_DETAILS,
        payload: errorDetails,
      });
  } catch (e) {
    Log.error(`Updating Troubleshooting details failed for ${requestURL}.`);
  }
};

export function getDictionary<T>(arr: T[], keySelector: (item: T) => string): { [key: string]: T } {
  return arr.reduce((prev: { [key: string]: T }, curr: T) => {
    const key = keySelector(curr);
    if (prev[key]) {
      throw new Error(`The key "${key}" is duplicated.`);
    }
    prev[key] = curr;
    return prev;
  }, {} as { [key: string]: T });
}

export function getCloudType(): CloudLinkType {
  try {
    const cloudName = window.location.pathname.split("/")[1].toUpperCase() as CloudLinkType;
    if (Object.values(CloudLinkType).includes(cloudName)) {
      return cloudName;
    }
  } catch (e) {
    Log.error("Error in getCloudType() ", e);
  }
  return CloudLinkType.AZURE; //Defaulting to Azure
}

export function getCloudTypeName(cloudTypeName?: string) {
  const cloudType = cloudTypeName || getCloudType();
  if (cloudType === CloudLinkType.AZURE) {
    return capitalize(cloudType);
  }
  return cloudType;
}

export function getProviderType(cloudType?: string): ProviderType {
  const cloudString = cloudType || getCloudType();
  if (cloudString === CloudLinkType.AWS) {
    return "ODSAWS";
  } else if (cloudString === CloudLinkType.AZURE) {
    return "ODSA";
  } else if (cloudString === CloudLinkType.GCP) {
    return "ODSGCP";
  }
  return "";
}

export async function logOut(cloudType: string) {
  if (cloudType === CloudLinkType.AZURE) {
    await AzAuth.instance.logOut();
  } else if (cloudType === CloudLinkType.AWS) {
    await AwsAuth.instance.logOut();
  } else if (cloudType === CloudLinkType.GCP) {
    store.dispatch({ type: GcpStoreActions.CANCEL });
  }
}

export function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );

  return JSON.parse(jsonPayload);
}

export function getAwsConsoleEndpoint(cloudLinkRegion?: string) {
  const awsAccountId = sessionStorage.getItem(SessionStorage.ACCOUNT_ID);
  const awsUserPoolId = sessionStorage.getItem(SessionStorage.USERPOOL_ID);
  return `${getConsoleEndpoint(
    CloudLinkType.AWS,
  )}${`?accountId=${awsAccountId}&userPoolId=${awsUserPoolId}${
    cloudLinkRegion && `&region=${cloudLinkRegion}`
  }`}`;
}

export function getGcpConsoleEndpoint(tenant?: string) {
  return `${getConsoleEndpoint(CloudLinkType.GCP)}${tenant && `?tenant=${tenant}`}`;
}

/**
 * Retries all regions specified in homeRegions using the fetchApi provided starting from regionIndex
 * @param  {homeRegions} An array of regions in  to try the fetch API call in
 * @param {fetchApi} The fetch api (of the CSP) to be used to make the API call
 * @param {regionalEndpointSupplier} A supplier that takes in a Region and returns the endpoint URL
 * @param {getRequestSupplier} A supplier that takes in the URL and returns a Request object
 * @param {dispatch} The dispatch to send any updates to
 * @param {regionIndex} Optional index to start from
 * @return {Promise<Response>} A promise containing the response (after potential) regional failover
 */
export async function fetchWithRegionFailover(
  homeRegions: HomeRegionInternal[],
  fetchApi: FetchApiSignature,
  regionalEndpointSupplier: (region: Region) => string,
  getRequestSupplier: (endpoint: string) => Request,
  dispatch: FormDispatch,
  regionIndex: number = 0,
): Promise<Response> {
  const homeRegionsList = ([...homeRegions] as any[]).map(region => region.value.id);

  let regionToTry: Region;
  if (regionIndex >= homeRegionsList.length) {
    return Promise.reject({});
  } else {
    regionToTry = homeRegionsList[regionIndex];
  }

  try {
    const response = await retryPromiseWithDelay(() => {
      return fetchApi(getRequestSupplier(regionalEndpointSupplier(regionToTry)));
    }, dispatch);

    if (response.ok) {
      return response;
    }
    throw new Error();
  } catch (error: any) {
    if (error.status >= 500) {
      // retry next region or if exhausted rejected with last error
      if (regionIndex < homeRegionsList.length - 1) {
        Log.warn(`Fetch API returned 5xx in region ${regionToTry}. Attempting another region`);
        return fetchWithRegionFailover(
          homeRegions,
          fetchApi,
          regionalEndpointSupplier,
          getRequestSupplier,
          dispatch,
          regionIndex + 1,
        );
      }
      Log.error("Fetch API returned 5xx. Exhausted all regions.");
    }
    return Promise.reject(error);
  }
}

export function errorHandle(
  error: any,
  metricPrefix: MetricPrefixes,
  cloudType: CloudLinkType,
  fetchStartTime: number,
  eventName: string,
) {
  if (error.status === 404) {
    return;
  } else if (error.status === 403) {
    emitCloudSpecificEvent([
      {
        name: getApiMetricName(metricPrefix, ApiMetricType._403),
        cloudType,
      },
    ]);
  } else if (error.status >= 400 && error.status <= 499) {
    emitCloudSpecificEvent([
      {
        name: getApiMetricName(metricPrefix, ApiMetricType._4xx),
        cloudType,
      },
    ]);
  } else if (error.status >= 500) {
    emitCloudSpecificEvent([
      {
        name: getApiMetricName(metricPrefix, ApiMetricType._5xx),

        cloudType,
      },
    ]);
  } else {
    emitCloudSpecificEvent([
      {
        name: getApiMetricName(metricPrefix, ApiMetricType.Exception),
        cloudType,
      },
    ]);
  }

  postCloudSpecificAnalytics(eventName, cloudType, { error });
  emitCloudSpecificEvent([
    {
      name: getApiMetricName(metricPrefix, ApiMetricType.Latency),
      cloudType: cloudType,
      startTime: fetchStartTime,
    },
  ]);
}
