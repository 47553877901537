import { fromByteArray } from "base64-js";
import { GcpIndexedDBData } from "../interfaces/gcpIndexedDBData";
import { fetchNativeSubtleCrypto, str2ab } from "./crypto";
import ErrorUtils from "./errorUtils";
import { getGcpIndexedDBData } from "./indexedDb";

const EMPTY_STRING = "";

/**
 * State payload
 */
interface PayLoad {
  destination_uri: string;
}

interface StateParam {
  payload: PayLoad;
  signature: string;
}

class StateObject {
  /**
   * Payload to be signed/encoded
   */
  private readonly payload = {} as PayLoad;

  /**
   * Signature is signed payload
   */
  public signature = EMPTY_STRING;

  /**
   * Constructor
   * @param destinationUri The url as payload of state
   */
  constructor(destinationUri: string) {
    this.payload.destination_uri = destinationUri;
  }

  /**
   * @returns Head and payload as ArrayBuffer
   */
  public getPayloadForSigning(): ArrayBuffer {
    return str2ab(JSON.stringify(this.payload));
  }

  /**
   * Serialize this object as string
   */
  public serialize(): string {
    const stateParam: StateParam = {
      payload: this.payload,
      signature: this.signature,
    };

    return JSON.stringify(stateParam);
  }

  public static async extractPayload(state: string): Promise<PayLoad> {
    try {
      const incomingStatePayload = JSON.parse(b64DecodeUnicode(state)) as StateParam;

      // verification
      const stateObject = new StateObject(incomingStatePayload.payload.destination_uri);
      const signatureVerification = await createSignature(stateObject.getPayloadForSigning());

      if (incomingStatePayload.signature === signatureVerification) {
        return incomingStatePayload.payload;
      }
      throw ErrorUtils.invalidStateSignatureError();
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      throw error;
    }
  }
}

/**
 * Signing algorithm
 */
const stateSigningAlgorithm = {
  name: "HMAC",
  hash: { name: "SHA-256" },
};

/**
 * Generate key for signing state
 * @returns The key promise
 */
export function generateStateSigningKey(): PromiseLike<CryptoKey> {
  const browserCryptoObject = fetchNativeSubtleCrypto();
  return browserCryptoObject.generateKey(stateSigningAlgorithm, false, [
    "sign",
    "verify",
  ]) as PromiseLike<CryptoKey>;
}

/**
 * Create signature
 * @param content The content to be signed
 * @returns The signature promise
 */
async function createSignature(content: ArrayBuffer): Promise<string> {
  try {
    const browserCryptoObject: SubtleCrypto = fetchNativeSubtleCrypto();
    const gcpIndexedDBData: GcpIndexedDBData = await getGcpIndexedDBData();
    const signature = await browserCryptoObject.sign(
      stateSigningAlgorithm,
      gcpIndexedDBData.stateSignKey,
      content,
    );
    return fromByteArray(new Uint8Array(signature));
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
    throw e;
  }
}

/**
 * Construct state string from current Url
 */
export async function constructState(destinationUri: string): Promise<string> {
  try {
    const stateObject = new StateObject(destinationUri);
    stateObject.signature = await createSignature(stateObject.getPayloadForSigning());
    return b64EncodeUnicode(stateObject.serialize());
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    throw error;
  }
}

/**
 * Get payload from state
 * @param state The encoded state string
 */
export async function decodeState(state: string): Promise<string> {
  try {
    const payload: PayLoad = await StateObject.extractPayload(state);
    return payload.destination_uri;
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
    throw error;
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
export function b64EncodeUnicode(str: string) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) =>
      String.fromCharCode.apply(null, [`0x${p1}` as any]),
    ),
  );
}

// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
export function b64DecodeUnicode(str: string) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(c => {
        const uriComponent = `00${c.charCodeAt(0).toString(16)}`;
        return `%${uriComponent.slice(-2)}`;
      })
      .join(""),
  );
}
