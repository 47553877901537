import { Experiment } from "./experimentTypes";

/**
 * Campaigns name to be use to defined the experiments and type restriction to avoid typos
 * (remove null)
 *
 * ```typescript
 * // Example of campaign names registry
 * export type CampaignNames = "Experiment" | "Other-Experiment";
 * ```
 */
export type CampaignNames = null;

/**
 * Experiment registry
 *
 * ```typescript
 * // Example of registry of experiments
 * export const Experiments: Experiment[] = [
 *   {
 *     name: "Experiment",
 *   } as Experiment<TestExperiment>,
 *   {
 *     name: "Other-Experiment",
 *     actions: {
 *       linkClick: "link-clicked",
 *     },
 *     variants: {
 *       default: "default",
 *       variant: "variantValue",
 *     },
 *   } as Experiment<{
 *       default: "default" | "variant1_2" | "Default";
 *       variant: "variantValue" | "Default";
 *   }>
 * ];
 * ```
 */
export const Experiments: Experiment[] = [];
