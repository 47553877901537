import * as React from "react";
import { useMemo } from "react";
import * as ReactDOM from "react-dom";
import { FlashbarProps } from "../../index";
import { getDocument, getWindow } from "../../utils/browser";
import { genGuid } from "../../utils/guid";
import NotificationsHub from "./NotificationsHub";

export const NOT_ROOT_ID = "o4aws-react-notification-root";
const CLEANUP_INTERVAL_FREQUENCY = 15000;

export type Notification = {
  timeoutInMs?: number;
} & FlashbarProps.MessageDefinition;

export type NotificationInstance = {
  element: HTMLElement;
  initialized: boolean;
  setNotification?(_notification: Notification): void;
  clearAll?(): void;
};

type Options = Omit<Notification, "content" | "type">;

class NotificationsManagerInternal {
  private document = getDocument();
  private window = getWindow();
  private notificationInstances: {
    [elementId: string]: NotificationInstance;
  } = {};
  private intervalId?: number;

  add = (notification: Notification, elementRef?: HTMLElement): void => {
    elementRef = elementRef ?? this.getRootElement();
    elementRef.id = elementRef.id || genGuid();

    this.initInstance(elementRef, () => {
      this.getByElementId(elementRef?.id).initialized = true;
      this.getByElementId(elementRef?.id).setNotification(notification);
    });
  };

  clearAllByElementId = (elementId: string): void => {
    this.getByElementId(elementId).clearAll();
  };

  clearAll = (): void => {
    Object.keys(this.notificationInstances).forEach((elementId: string) =>
      this.getByElementId(elementId).clearAll(),
    );
  };

  private getByElementId(elementId: string): NotificationInstance {
    return this.notificationInstances[elementId];
  }

  private initInstance(elementRef: HTMLElement, readyCb: () => void): void {
    const instance = this.getByElementId(elementRef.id) ?? this.addInstance(elementRef);

    if (instance.initialized) {
      readyCb();
      return;
    }

    ReactDOM.render(
      <NotificationsHub notificationInstance={instance} onInitialize={readyCb} />,
      elementRef,
    );
  }

  private initCleanUp(): void {
    if (this.intervalId) return;

    this.intervalId = this.window.setInterval(() => {
      const elementIds = Object.keys(this.notificationInstances).filter(
        (elementId: string) => elementId !== NOT_ROOT_ID,
      );

      if (!elementIds.length) {
        this.window.clearInterval(this.intervalId);
        this.intervalId = undefined;
        return;
      }

      let i = elementIds.length;
      while (--i >= 0) {
        !this.document.getElementById(elementIds[i]) && this.removeByElementId(elementIds[i]);
      }
    }, CLEANUP_INTERVAL_FREQUENCY);
  }

  private removeByElementId(elementId: string): void {
    ReactDOM.unmountComponentAtNode(this.getByElementId(elementId).element);
    delete this.notificationInstances[elementId];
  }

  private addInstance(element: HTMLElement): NotificationInstance {
    this.notificationInstances[element.id] = {
      element,
      initialized: false,
    };

    this.initCleanUp();

    return this.getByElementId(element.id);
  }

  private getRootElement(): HTMLElement {
    if (this.getByElementId(NOT_ROOT_ID)) {
      return this.getByElementId(NOT_ROOT_ID).element;
    }

    const rootElement = this.document.createElement("div");
    rootElement.id = NOT_ROOT_ID;
    this.document.body.prepend(rootElement);
    return rootElement;
  }
}

type NotificationAliasFn = (
  _message: string,
  _options?: Options | null,
  _elementRef?: HTMLElement | null,
) => void;

export type NotificationsManager = Pick<
  NotificationsManagerInternal,
  "add" | "clearAll" | "clearAllByElementId"
> & {
  info: NotificationAliasFn;
  error: NotificationAliasFn;
  success: NotificationAliasFn;
  warning: NotificationAliasFn;
};

const NotificationsManagerInstance = new NotificationsManagerInternal();

const NotificationsService: NotificationsManager = {
  add: NotificationsManagerInstance.add,
  clearAll: NotificationsManagerInstance.clearAll,
  clearAllByElementId: NotificationsManagerInstance.clearAllByElementId,
  info: (message: string, options?: Options | null, elementRef?: HTMLElement | null) =>
    NotificationsManagerInstance.add({ content: message, type: "info", ...options }, elementRef),
  error: (message: string, options?: Options | null, elementRef?: HTMLElement | null) =>
    NotificationsManagerInstance.add({ content: message, type: "error", ...options }, elementRef),
  success: (message: string, options?: Options | null, elementRef?: HTMLElement | null) =>
    NotificationsManagerInstance.add({ content: message, type: "success", ...options }, elementRef),
  warning: (message: string, options?: Options | null, elementRef?: HTMLElement | null) =>
    NotificationsManagerInstance.add({ content: message, type: "warning", ...options }, elementRef),
};

export function useNotifications(): NotificationsManager {
  return useMemo(() => NotificationsService, [NotificationsManagerInstance]);
}

export default NotificationsService;
