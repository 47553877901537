import { CloudType } from "models/types";
import * as React from "react";
import { TelemetryHelperConfig } from "./TelemetryHelperConfig";
import {
  API_CALL_METRIC_NAME,
  API_LATENCY_METRIC_NAME,
  buildAnalyticsName,
  determineStatus,
  EMPTY_DIMENSION_VALUE,
  EventType,
  RENDER_COMPONENT_LATENCY_MS_METRIC_NAME,
  RENDER_COMPONENT_METRIC_NAME,
  Status,
} from "./TelemetryHelperTypes";

type ApiCallType<Resp extends Response> = (...args: any[]) => Promise<Resp>;

class TelemetryHelper {
  private static config: TelemetryHelperConfig;

  private static assertConfigInitialized(): void {
    if (!this.config) {
      throw new Error("Please initialize TelemetryHelper before use");
    }
  }

  public static isConfigInitialized(): boolean {
    return !!this.config;
  }

  public static init(config: TelemetryHelperConfig) {
    if (!this.config) {
      this.config = { ...config };
    }
  }

  public static getConfig(): TelemetryHelperConfig {
    this.assertConfigInitialized();
    return { ...this.config };
  }

  public static setCloudServiceProvider(csp: CloudType): void {
    this.assertConfigInitialized();
    this.config.csp = csp;
  }

  public static setCloudServiceProviderIdentifier(cspIdentifier: string): void {
    this.assertConfigInitialized();
    this.config.cspIdentifier = cspIdentifier;
  }

  public static setOciTenantName(ociTenantName: string): void {
    this.assertConfigInitialized();
    this.config.ociTenantName = ociTenantName;
  }

  public static setDefaultRegion(defaultRegion: string): void {
    this.assertConfigInitialized();
    this.config.defaultRegion = defaultRegion;
  }

  private static sanitizeDimensions(dimensions: Record<string, string>): Record<string, string> {
    Object.keys(dimensions).forEach(key => {
      if (!dimensions[key]) {
        dimensions[key] = EMPTY_DIMENSION_VALUE;
      }
    });
    return dimensions;
  }

  // Metrics

  public static withMcRenderMetrics<T>(
    WrappedComponent: React.ComponentType<T>,
  ): React.ComponentType<T> {
    return (props: T) => {
      const startTime = performance.now();
      const componentName = WrappedComponent.displayName || WrappedComponent.name || "Component";

      React.useEffect(() => {
        try {
          if (startTime) {
            const renderTime = performance.now() - startTime;
            // Only emit if initialized
            if (TelemetryHelper.isConfigInitialized) {
              // Emit count metric
              TelemetryHelper.getConfig().emitMetricInterface(
                RENDER_COMPONENT_METRIC_NAME,
                1,
                TelemetryHelper.sanitizeDimensions({
                  componentName,
                  csp: TelemetryHelper.getConfig().csp,
                  cspIdentifier: TelemetryHelper.getConfig().cspIdentifier,
                  ociTenantName: TelemetryHelper.getConfig().ociTenantName,
                  app: TelemetryHelper.getConfig().app,
                  isCanary: String(TelemetryHelper.getConfig().isCanary),
                  env: TelemetryHelper.getConfig().env,
                  status: Status.Success,
                  region: TelemetryHelper.getConfig().defaultRegion,
                }),
              );

              // Emit latency metric
              TelemetryHelper.getConfig().emitMetricInterface(
                RENDER_COMPONENT_LATENCY_MS_METRIC_NAME,
                renderTime,
                TelemetryHelper.sanitizeDimensions({
                  componentName,
                  csp: TelemetryHelper.getConfig().csp,
                  cspIdentifier: TelemetryHelper.getConfig().cspIdentifier,
                  ociTenantName: TelemetryHelper.getConfig().ociTenantName,
                  app: TelemetryHelper.getConfig().app,
                  isCanary: String(TelemetryHelper.getConfig().isCanary),
                  env: TelemetryHelper.getConfig().env,
                  status: Status.Success,
                  region: TelemetryHelper.getConfig().defaultRegion,
                }),
              );
            }
          }
        } catch {}
      }, []);

      return <WrappedComponent {...props} />;
    };
  }

  public static withApiMetrics = <Resp extends Response, T extends ApiCallType<Resp>>(
    apiCall: T,
    apiName: string,
    region?: string,
    timeout?: number,
  ): T => {
    this.assertConfigInitialized();

    if (!apiCall) {
      throw new Error("Please provide an API call");
    }

    return (async (...args: Parameters<T>) => {
      let response: Resp = null;
      let status: Status = null;
      let startTime: number = null;
      try {
        startTime = performance.now();
        if (timeout) {
          response = (await Promise.race([
            apiCall(...args),
            new Promise((_resolve, reject) => {
              setTimeout(() => {
                reject({});
                status = Status.Timeout;
              }, timeout);
            }),
          ])) as Resp;
        } else {
          response = await apiCall(...args);
        }
      } catch (e: any) {
        response = e as Resp;
        throw e;
      } finally {
        this.emitApiMetrics(response, apiName, performance.now() - startTime, region, status);
      }

      return response;
    }) as T;
  };

  public static emitApiMetrics(
    response: Response,
    apiName: string,
    latency: number,
    region?: string,
    status?: Status,
  ): void {
    this.assertConfigInitialized();

    try {
      const statusCode = response?.status ? String(response.status) : null;
      const { statusFamily } = determineStatus(Number(statusCode));
      status = status || determineStatus(Number(statusCode)).status;

      // Emit api call metric
      this.config.emitMetricInterface(
        API_CALL_METRIC_NAME,
        1,
        this.sanitizeDimensions({
          apiName,
          csp: this.config.csp,
          cspIdentifier: this.config.cspIdentifier,
          ociTenantName: this.config.ociTenantName,
          app: this.config.app,
          isCanary: String(this.config.isCanary),
          region: region || this.config.defaultRegion,
          env: this.config.env,
          statusCode,
          statusFamily,
          status,
        }),
      );

      // Emit latency metric
      this.config.emitMetricInterface(
        API_LATENCY_METRIC_NAME,
        latency,
        this.sanitizeDimensions({
          apiName,
          csp: this.config.csp,
          cspIdentifier: this.config.cspIdentifier,
          ociTenantName: this.config.ociTenantName,
          app: this.config.app,
          isCanary: String(this.config.isCanary),
          region: region || this.config.defaultRegion,
          env: this.config.env,
          statusCode,
          statusFamily,
          status,
        }),
      );
    } catch {}
  }

  public static emitMetric(name: string, value: number, dimensions?: Record<string, string>): void {
    this.assertConfigInitialized();

    try {
      // Emit metric
      this.config.emitMetricInterface(
        name,
        value,
        this.sanitizeDimensions({
          csp: this.config.csp,
          cspIdentifier: this.config.cspIdentifier,
          ociTenantName: this.config.ociTenantName,
          app: this.config.app,
          isCanary: String(this.config.isCanary),
          region: this.config.defaultRegion,
          env: this.config.env,
          ...dimensions,
        }),
      );
    } catch {}
  }

  // Analytics

  public static postAnalytics(eventName: string, eventType: EventType): void {
    this.assertConfigInitialized();
    this.config.postAnalyticsInterface(buildAnalyticsName(eventName, this.config), eventType);
  }
}

export default TelemetryHelper;
