import StorageKeys from "../constants/storageKeys";
import { FetchTokenProps } from "../interfaces/fetchTokenProps";
import { IdentityToken } from "../interfaces/identityToken";
import { getBootstrapRegionList, getMchubPlatformGetTokenEndpoint } from "./envUtils";
import ErrorUtils from "./errorUtils";
import { Log } from "./logUtils";
import { defaultPostRequestSupplier } from "./promiseUtils";
import { fetchWithRegionFailover } from "./retryUtils";
import { getItemFromLocalStore } from "./storageUtils";

const TIME_BEFORE_EXPIRATION = 10000 * 60; // 10 minutes in milliseconds
const CLOCK_OFFSET_IN_SECONDS = 60000; // 1 minute in milliseconds

export function isTokenAboutToExpire(exp: number): boolean {
  const now = Date.now();
  return now < exp && now + TIME_BEFORE_EXPIRATION >= exp;
}

export function isTokenDead(exp: number): boolean {
  const now = Date.now();
  return now >= exp - CLOCK_OFFSET_IN_SECONDS;
}

// Takes a date string and returns a timestamp in seconds
export function dateStringToTimestamp(dateString: string): number {
  return new Date(dateString).getTime() / 1000;
}

/**
 * Get a value from the identity token
 * @param field The field of the IdentityToken that you need the value from.
 * @returns identity token field value.
 */
export function getIdentityTokenValue<TField extends keyof IdentityToken>(
  field: TField,
): IdentityToken[TField] | never {
  const tokens = getItemFromLocalStore(StorageKeys.UserTokensKey);
  if (tokens?.decoded_id_token) {
    return tokens.decoded_id_token[field];
  }
  throw ErrorUtils.userNotLoggedInError();
}

export const fetchTokenFromMccp = async (props: FetchTokenProps): Promise<Response> => {
  // Init logger if present
  if (props?.logger) {
    Log(props.logger);
  }

  return fetchWithRegionFailover(
    props.ociFetchApi,
    getMchubPlatformGetTokenEndpoint,
    defaultPostRequestSupplier.bind(null, props.payload),
    getBootstrapRegionList(),
  );
};
