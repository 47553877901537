import { ExpandableSection as AwsuiExpandableSection } from "@awsui/components-react";
import "./index.scss";
import initCssVariables from "./styles/variablesOverride";
import withCustomElementWrap from "./utils/withCustomElementWrap";

export {
  Alert,
  AlertProps,
  AnnotationContext,
  AnnotationContextProps,
  AppLayout,
  AppLayoutProps,
  AreaChart,
  AreaChartProps,
  AttributeEditor,
  AttributeEditorProps,
  Autosuggest,
  AutosuggestProps,
  Badge,
  BadgeProps,
  BarChart,
  BarChartProps,
  Box,
  BoxProps,
  BreadcrumbGroup,
  BreadcrumbGroupProps,
  Button,
  ButtonDropdown,
  ButtonDropdownProps,
  ButtonProps,
  Cards,
  CardsProps,
  Checkbox,
  CheckboxProps,
  CodeEditor,
  CodeEditorProps,
  CollectionPreferences,
  CollectionPreferencesProps,
  ColumnLayout,
  ColumnLayoutProps,
  Container,
  ContainerProps,
  DatePicker,
  DatePickerProps,
  DateRangePicker,
  DateRangePickerProps,
  ExpandableSectionProps,
  Flashbar,
  FlashbarProps,
  Form,
  FormField,
  FormFieldProps,
  FormProps,
  Grid,
  GridProps,
  Header,
  HeaderProps,
  HelpPanel,
  HelpPanelProps,
  Hotspot,
  HotspotProps,
  Icon,
  IconProps,
  Input,
  InputProps,
  LineChart,
  LineChartProps,
  Link,
  LinkProps,
  MixedLineBarChart,
  MixedLineBarChartProps,
  Modal,
  ModalProps,
  Multiselect,
  MultiselectProps,
  Pagination,
  PaginationProps,
  PieChart,
  PieChartProps,
  ProgressBar,
  ProgressBarProps,
  PropertyFilter,
  PropertyFilterProps,
  RadioGroup,
  RadioGroupProps,
  S3ResourceSelector,
  S3ResourceSelectorProps,
  SegmentedControl,
  SegmentedControlProps,
  Select,
  SelectProps,
  SideNavigation,
  SideNavigationProps,
  SpaceBetween,
  SpaceBetweenProps,
  Spinner,
  SpinnerProps,
  SplitPanel,
  SplitPanelProps,
  StatusIndicator,
  StatusIndicatorProps,
  Table,
  TableProps,
  Tabs,
  TabsProps,
  TagEditor,
  TagEditorProps,
  Textarea,
  TextareaProps,
  TextContent,
  TextContentProps,
  TextFilter,
  TextFilterProps,
  Tiles,
  TilesProps,
  TimeInput,
  TimeInputProps,
  Toggle,
  ToggleProps,
  TokenGroup,
  TokenGroupProps,
  TopNavigation,
  TopNavigationProps,
  TutorialPanel,
  TutorialPanelProps,
  Wizard,
  WizardProps,
} from "@awsui/components-react";
export * from "@awsui/components-react/interfaces";
export { applyDensity, applyMode, Density, disableMotion, Mode } from "@awsui/global-styles";
export {
  default as ClipboardButton,
  Props as ClipboardProps,
} from "./components/ClipboardButton/ClipboardButton";
export {
  CustomListContainerProps,
  default as CustomListContainer,
} from "./components/CustomContainer/CustomContainer";
export {
  CustomLinkProps,
  default as CustomLink,
  LinkList,
} from "./components/CustomLinks/CustomLinks";
export { default as Highlight, HighlightProps } from "./components/Highlight/Highlight";
export {
  default as LabelValuePair,
  LabelValuePairProps,
} from "./components/LabelValuePair/LabelValuePair";
export {
  default as Notifications,
  NotificationsManager,
  useNotifications,
} from "./components/Notifications/Notifications";
export {
  default as NotificationsContainer,
  NotificationsContainerProps,
} from "./components/NotificationsContainer/NotificationsContainer";
export { default as Popover, PopoverProps } from "./components/Popover/Popover";
export {
  default as ResourceListTable,
  ResourceListTableDropDownProps,
  ResourceListTableProps,
} from "./components/ResourceListTable/ResourceListTable";
export {
  default as ResourceListTableDynamic,
  ResourceListTableDynamicProps,
} from "./components/ResourceListTableDynamic/ResourceListTableDynamic";
export {
  ShowCopyDomainName,
  ShowCopyDomainProps,
  ShowCopyText,
  ShowCopyTextProps,
} from "./components/ShowCopyText/ShowCopyText";

export { default as FileUpload, FileUploadProps } from "./components/FileUpload/FileUpload";

export const ExpandableSection = withCustomElementWrap(
  AwsuiExpandableSection,
  "expandable-section",
);

initCssVariables();
