import jwtDecode from "jwt-decode";
import StorageKeys from "../constants/storageKeys";
import { AccessToken, IdentityToken } from "../interfaces";
import ErrorUtils from "./errorUtils";
import { getItemFromLocalStore } from "./storageUtils";

const TIME_BEFORE_EXPIRATION = 10 * 60; // 10 minutes in seconds
const CLOCK_OFFSET_IN_SECONDS = 60; // 1 minute in seconds

export function isAccessTokenAboutToExpire(tokenString: string): boolean {
  const accessToken = jwtDecode(tokenString) as AccessToken;
  const now = Date.now() / 1000;
  return now < accessToken.exp && now + TIME_BEFORE_EXPIRATION >= accessToken.exp;
}

export function isAccessTokenDead(tokenString: string): boolean {
  const accessToken = jwtDecode(tokenString) as AccessToken;
  const now = Date.now() / 1000;
  return now >= accessToken.exp - CLOCK_OFFSET_IN_SECONDS;
}

export function isIdentityTokenDead(tokenString: string): boolean {
  const accessToken = jwtDecode(tokenString) as IdentityToken;
  const now = Date.now() / 1000;
  return now >= accessToken.exp - CLOCK_OFFSET_IN_SECONDS;
}

// Takes a date string and returns a timestamp in seconds
export function dateStringToTimestamp(dateString: string): number {
  return new Date(dateString).getTime() / 1000;
}

/**
 * Get a value from the access token
 * @param field The field of the AccessToken that you need the value from.
 * @returns access token field value.
 */
export function getAccessTokenValue<TField extends keyof AccessToken>(
  field: TField,
): AccessToken[TField] | never {
  const tokens = getItemFromLocalStore(StorageKeys.UserTokensKey);
  if (tokens && tokens.access_token) {
    const accessToken = jwtDecode(tokens.access_token) as AccessToken;
    return accessToken[field];
  }
  throw ErrorUtils.userNotLoggedInError();
}
