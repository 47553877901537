import Log from "../CourierService/Logger";
import { emitEvent, Metrics } from "../CourierService/metrics";
import FeatureFlags from "./FeatureFlags";
import { Features } from "./FeatureFlagsRegistry";

export function sendFeatureFlagErrorLogs(userSession: string, error: string): void {
  emitEvent(Metrics.FetchFeaturesManifestFail);
  Log.error(error);
}

export function useFeatureFlagEnabled(features: Features[], userSession: string): boolean {
  if (!features?.length) {
    return false;
  }

  return features.length === 1
    ? FeatureFlags.isEnabled(features[0], sendFeatureFlagErrorLogs.bind(null, userSession))
    : FeatureFlags.areEnabled(features, sendFeatureFlagErrorLogs.bind(null, userSession));
}
