enum MetricNames {
  // Aws Metrics

  // Azure Metrics

  // Gcp Api Metrics
  GcpCreateMulticloudLink = "GcpCreateMulticloudLink",
  GcpFetchAccessToken = "GcpFetchAccessToken",
  GcpGetMulticloudLinkWorkRequest = "GcpGetMulticloudLinkWorkRequest",
  GcpListMchubSupportedRegions = "GcpListMchubSupportedRegions",
  GcpListMulticloudLinks = "GcpListMulticloudLinks",
  GcpListOciSubscribedRegions = "GcpListOciSubscribedRegions",
  GcpListOrganizations = "GcpListOrganizations",
  GcpListProjects = "GcpListProjects",
  GcpListServiceAccounts = "GcpListServiceAccounts",

  // Gcp General Metrics
  ErrorBoundary = "ErrorBoundary",
}

export default MetricNames;
