import { useMemo } from "react";

/**
 * Get window through a function so we can mock this function in unit test and return a stub window
 */
export function getWindow(): Window {
  return window;
}

/**
 * Returns memoized version of getWindow
 */
export function useWindow(): Window {
  return useMemo(() => getWindow(), []);
}

/**
 * Get document through a function so we can mock this function in unit test and return a stub document
 */
export function getDocument(): Document {
  return document;
}

/**
 * Returns memoized version of getDocument
 */
export function useDocument(): Document {
  return useMemo(() => getDocument(), []);
}
