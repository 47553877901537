import { IProvidesAuthorization, IAuthorization, IAuthorizationContext } from "./client/authorization";
import { IIOKernel } from "./io/kernel";
import { TokenRefreshHandler } from "./TokenRefreshHandler";
import { OPC_REQUEST_ID } from "./signingString";
import { createSessionRequestId } from "../utils/headersUtils";
import Base64Url from "base64url";
import { GetAuthorizationUrl } from "./authenticator";

export type DeliverRequestSignature = (request: Request) => Promise<Response>;

export interface JwtClaims {
  [key: string]: any;
}
export class BMCClient<TStrategy, TMaterial, TAuthorizer extends IAuthorization<TStrategy>> {
  private tokenRefreshHandler: TokenRefreshHandler;

  constructor(
    private realm: string,
    getAuthorizationUrl: GetAuthorizationUrl,
    readonly ioKernel: IIOKernel,
    private authorizer: IProvidesAuthorization<TStrategy, TMaterial, TAuthorizer>,
  ) {
    this.tokenRefreshHandler = new TokenRefreshHandler(getAuthorizationUrl);
  }

  /**
   * Refresh security token.
   * @param force - Whether or not to refresh token if the session time left is outside the "about to expire" window.
   */
  tryRefresh(force?: boolean) {
    return this.tokenRefreshHandler.tryRefresh(this.realm, this.deliverAuthenticatedHttp.bind(this), force);
  }

  /**
   * A fetch wrapper for making authenticated http requests to Oracle Public Cloud API
   * @param input - The url or Request object
   * @param init - If url is provided, init allows for additional options
   * @return The http response promise.
   */
  async fetch(input: string | Request, init?: RequestInit): Promise<Response> {
    try {
      await this.tokenRefreshHandler.tryRefresh(this.realm, this.deliverAuthenticatedHttp.bind(this));

      if (input) {
        let request = input instanceof Request ? input : new Request(input, init);
        return this.deliverAuthenticatedHttp(request);
      }
    } catch (error) {
      let errorMessage = error;
      if (error && error.message) {
        errorMessage = error.message;
      }
      throw new Error(`Failed to perform fetch ${errorMessage}`);
    }
  }

  /**
   * @param claims - JSON object whose members are the desired Jwt claims
   * @return - Json Web Token
   */
  async createJwt(claims: JwtClaims): Promise<string> {
    try {
      await this.tokenRefreshHandler.tryRefresh(this.realm, this.deliverAuthenticatedHttp.bind(this));

      if (claims) {
        // create header
        const headerContent = Base64Url.encode(
          JSON.stringify({
            typ: "JWT",
            alg: "RS256",
          }),
        );

        // create payload
        const bodyContent = Base64Url.encode(JSON.stringify(claims));

        // sign
        const contentToSign = `${headerContent}.${bodyContent}`;
        const signature = await this.authorizer.createSignature(this.realm, contentToSign);
        const base64UrlSig = Base64Url.fromBase64(signature);
        return `${contentToSign}.${base64UrlSig}`;
      }
    } catch (error) {
      let errorMessage = error;
      if (error && error.message) {
        errorMessage = error.message;
      }
      throw new Error(`Failed to perform sign jwt ${errorMessage}`);
    }
  }

  /**
   * Returns a copy of the request with authentication headers after ensuring
   * security token is refreshed.
   * @param request the http request
   */
  public async createAuthorizedRequest(request: Request): Promise<Request> {
    await this.tokenRefreshHandler.tryRefresh(this.realm, this.deliverAuthenticatedHttp.bind(this));
    return this.attachAuthorization(request);
  }

  /**
   * Makes an http request with added authentication headers.
   * @param request the http request
   */
  private async deliverAuthenticatedHttp(request: Request): Promise<Response> {
    request = await this.attachAuthorization(request);
    if (!request.headers.has(OPC_REQUEST_ID)) {
      const sessionRequestId = await createSessionRequestId(this.realm);
      request.headers.set(OPC_REQUEST_ID, sessionRequestId);
    }

    return this.ioKernel.networkServices.deliverHttp(request);
  }

  /**
   * Returns a copy of the request with authentication headers.
   * @param request the http request
   */
  private async attachAuthorization(request: Request): Promise<Request> {
    const context: IAuthorizationContext<TStrategy, TMaterial> =
      await this.authorizer.contextFactory.createAuthorizationContext(request);
    const authorization: TAuthorizer = await this.authorizer.createAuthorization(context);
    return await this.authorizer.attachAuthorization(context, authorization);
  }
}
