/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
enum CustomErrorStatus {
  AuthNotInitialized = "GcpAuth has not been initialized",
  NotloggedIn = "User not logged in",
  MissingInfo = "Missing info required for sign in",
  InvalidStateSignature = "State signature invalid",
}

export default CustomErrorStatus;
