/**
 * @class ShowCopyText
 * @description Component for displaying a truncated (by default) field the user can click to
 * see if they want to see the entire thing, as well as copy it to the clipboard.
 */

import * as React from "react";
import { useState } from "react";
import { Button, SpaceBetween, TextContent } from "../../index";
import { BaseProps } from "../../models/baseProps";
import { truncate } from "../../utils/string";
import ClipboardButton, { Props as ClipboardProps } from "../ClipboardButton/ClipboardButton";
import "./ShowCopyText.scss";

export const SHOW_COPY_BTN_TESTID = "show-copy-internal-toggle-text";

/**
 * ShowCopyText props.
 */
export interface ShowCopyTextProps
  extends Pick<ClipboardProps, "copiedLabel" | "copyLabel">,
    BaseProps {
  /**
   * Number of characters to display
   */
  charactersToShow: number;
  /**
   * Full text string
   */
  fullText: string;
  /**
   * Optional parameter to display when collapsed
   */
  shortText?: string;
  /**
   * text for the button used to show the full text
   */
  showLabel: string;
  /**
   * text for the button to hide the full text
   */
  hideLabel: string;
}

export function ShowCopyText({
  charactersToShow,
  fullText,
  shortText,
  copiedLabel,
  copyLabel,
  showLabel,
  hideLabel,
  testId,
}: ShowCopyTextProps) {
  const shortTextFallback = truncate(fullText, charactersToShow);

  return (
    <ShowCopyInternal
      {...{
        showLabel,
        hideLabel,
        fullText,
        copiedLabel,
        copyLabel,
        shortText: shortText || shortTextFallback,
        testId,
      }}
    />
  );
}

export interface ShowCopyDomainProps
  extends Pick<ClipboardProps, "copiedLabel" | "copyLabel">,
    BaseProps {
  /**
   * Fully qualified domain name for the URL
   */
  fqdn: string;
  /**
   * text for the button used to show the full text
   */
  showLabel: string;
  /**
   * text for the button to hide the full text
   */
  hideLabel: string;
}

function truncateDomainName(fqdn: string): string {
  const portions = fqdn.split(".");
  const firstPortion = portions && portions[0];

  return firstPortion ? `${firstPortion}...` : fqdn;
}

export function ShowCopyDomainName({
  fqdn,
  copiedLabel,
  copyLabel,
  showLabel,
  hideLabel,
  testId,
}: ShowCopyDomainProps): JSX.Element {
  return (
    <ShowCopyInternal
      {...{
        copiedLabel,
        copyLabel,
        showLabel,
        hideLabel,
        fullText: fqdn,
        shortText: truncateDomainName(fqdn),
        testId,
      }}
    />
  );
}

interface ShowCopyInternalProps
  extends Pick<ClipboardProps, "copiedLabel" | "copyLabel">,
    BaseProps {
  shortText: string;
  fullText: string;
  showLabel: string;
  hideLabel: string;
}

function ShowCopyInternal({
  fullText,
  shortText,
  copyLabel,
  copiedLabel,
  hideLabel,
  showLabel,
  testId,
}: ShowCopyInternalProps): JSX.Element {
  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const hideText = hideLabel;
  const showText = showLabel;
  const toggleLinkText = expanded ? hideText : showText;

  return (
    <span className="show-copy" aria-expanded={expanded}>
      <SpaceBetween size="xxs" direction="horizontal">
        <TextContent>{expanded ? fullText : shortText}</TextContent>

        <Button variant="link" onClick={toggleExpanded} data-testid={SHOW_COPY_BTN_TESTID}>
          {toggleLinkText}
        </Button>

        <ClipboardButton
          text={fullText}
          copiedLabel={copiedLabel}
          copyLabel={copyLabel}
          testId={testId}
        />
      </SpaceBetween>
    </span>
  );
}
