import UrlPlaceholders from "./urlPlaceholders";

class UrlConstants {
  public static readonly CognitoLoginURLTemplate = `https://${UrlPlaceholders.DomainPlaceholder}.auth.${UrlPlaceholders.RegionPlaceholder}.amazoncognito.com/oauth2/authorize?client_id=${UrlPlaceholders.ClientIdPlaceholder}&response_type=code&scope=${UrlPlaceholders.ScopePlaceholder}&redirect_uri=${UrlPlaceholders.RedirectUriPlaceholder}&code_challenge=${UrlPlaceholders.CodeChallengePlaceholder}&code_challenge_method=S256`;

  public static readonly CognitoLogoutURLTemplate = `https://${UrlPlaceholders.DomainPlaceholder}.auth.${UrlPlaceholders.RegionPlaceholder}.amazoncognito.com/logout?client_id=${UrlPlaceholders.ClientIdPlaceholder}&logout_uri=${UrlPlaceholders.LogoutUriPlaceholder}`;

  public static readonly CognitoTokenURLTemplate = `https://${UrlPlaceholders.DomainPlaceholder}.auth.${UrlPlaceholders.RegionPlaceholder}.amazoncognito.com/oauth2/token`;
}

export default UrlConstants;
