import * as React from "react";
import Log from "./Logger";

/**
 * The RenderMetrics space allows you to capture metrics for a larger component & its sub-components.
 * Example: A page component with multiple section components & sections containing widget components.
 *
 * Usage:
 *  - Wrap the top-level component with a RenderMetricsProvider which makes this functionality
 *    available to all nested components via the React Context API. You need to specify the names
 *    of the nested components you want to track.
 *  - Wrap each individual component in a withRenderMetrics HOC & combine the RenderMetricsProps to
 *    the component props. You can do that any level of nesting.
 *  - Signal when the component is fully rendered by calling "this.props.renderMetrics.rendered(...)".
 *    Where you invoke this function will vary based on the component and how you consider it fully
 *    rendered, but componentDidUpdate is a safe bet in most cases.
 *  - The RenderMetricsProvider will notify you when all tracked components are fully rendered, failed
 *    to render, or rendering timed out.
 */

const renderTimeout = 10000;
const TIMEOUT_ERROR = "TIMEOUT";
const DEFAULT_TIMEOUT = 5000;

type Subtract<All extends Sub, Sub extends object> = Pick<All, Exclude<keyof All, keyof Sub>>;

function timeoutPromise<T>(
  promise: Promise<T>,
  timeout = DEFAULT_TIMEOUT,
  error?: string,
): Promise<T> {
  return Promise.race([
    promise,
    new Promise((_, reject) => setTimeout(() => reject(error || TIMEOUT_ERROR), timeout)),
  ]) as Promise<T>;
}

export type PromiseResult = { name: string; success: boolean };
export type PromiseCallback = (result: PromiseResult) => void;

/**
 * Props for components wrapped in "withRenderMetrics".
 */
export interface RenderMetricsProps {
  /**
   * Access the render metrics helpers.
   */
  renderMetrics: {
    /**
     * Invoke this method when rendering has completed successfully.
     * If rendering fails ungracefully, you should be throwing an error.
     *
     * @param name The name of the component that completed rendering.
     */
    rendered(name: string): void;
  };
}

/**
 * Props for the RenderMetricsProvider component.
 */
interface RenderMetricsProviderProps {
  /**
   * The name of the top-level component that is tracking the rendering metric.
   */
  name: string;

  /**
   * The names of the child components that the rendering metric will track and wait for them to
   * complete rendering.
   */
  track: string[];

  /**
   * A callback that is invoked when rendering all child components is complete, regardless of whether
   * rendering was successful or not.
   * @param success Whether the rendering of all child components was successful.
   */
  onRenderComplete(success: boolean): void;
  azTenant?: string;
}

const Context = React.createContext<RenderMetricsProps>({
  renderMetrics: {
    rendered: () => {},
  },
});

/**
 * HOC that adds the render metrics capabilities to the wrapped component. The props for the wrapped
 * component must extend RenderMetricsProps.
 *
 * @param Component The component to be wrapped.
 */
export const withRenderMetrics = <P extends RenderMetricsProps>(
  Component: React.FunctionComponent<P>,
) => {
  class Wrapped extends React.Component<Subtract<any, RenderMetricsProps>> {
    public render(): React.ReactNode {
      // The assertion (as {} as P) is intentional to bypass a limitation in typing HOCs in TypeScript.
      return (
        <Context.Consumer>
          {contexts => <Component {...(this.props as {} as P)} {...contexts} />}
        </Context.Consumer>
      );
    }
  }

  function getDisplayName(Component: React.ComponentType<P>): string {
    return Component.displayName || Component.name || "Component";
  }

  (Wrapped as unknown as React.ComponentType).displayName = `WithRenderMetrics(${getDisplayName(
    Component,
  )})`;
  return Wrapped;
};

/**
 * HOC that adds the render metrics capabilities to the wrapped non-typed component.
 *
 * @param Component The component to be wrapped.
 */
export const withRenderMetricsNonTyped = <P extends any>(Component: React.FunctionComponent<P>) => {
  class Wrapped extends React.Component<any> {
    public render(): React.ReactNode {
      // The assertion (as {} as P) is intentional to bypass a limitation in typing HOCs in TypeScript.
      return (
        <Context.Consumer>
          {contexts => <Component {...(this.props as {} as P)} {...contexts} />}
        </Context.Consumer>
      );
    }
  }

  function getDisplayName(Component: React.ComponentType<P>): string {
    return Component.displayName || Component.name || "Component";
  }

  (
    Wrapped as unknown as React.ComponentType
  ).displayName = `withRenderMetricsNonTyped(${getDisplayName(Component)})`;
  return Wrapped;
};

/**
 * Provides the render metrics context to its child components via the React Context API.
 * Wrap the top-level component with a RenderMetricsProvider.
 */
export class RenderMetricsProvider extends React.Component<
  RenderMetricsProviderProps,
  RenderMetricsProps
> {
  protected contextProps!: RenderMetricsProps;
  protected errorReject!: PromiseCallback;
  protected promises: Record<string, Promise<PromiseResult>> = {};
  protected resolveFns: Record<string, PromiseCallback> = {};
  protected mounted = false;

  constructor(props: RenderMetricsProviderProps) {
    super(props);
    this.setupPromises();
  }

  public componentDidCatch(): void {
    this.errorReject({ name: "_provider", success: false });
  }

  public componentDidMount(): void {
    this.mounted = true;
  }

  public componentWillUnmount(): void {
    this.mounted = false;
  }

  public render(): React.ReactNode {
    return (
      <Context.Provider value={{ ...this.contextProps }}>{this.props.children}</Context.Provider>
    );
  }

  private setupPromises(): void {
    const promises = this.promises;
    const resolveFns = this.resolveFns;

    // Create a promise that can be rejected if "componentDidCatch" is triggered for cases when
    // errors in child components are thrown.
    const errorPromise = new Promise((_, reject) => {
      this.errorReject = reject;
    });

    // For each child component we need to wait for, ...
    this.props.track.forEach(name => {
      // ... make sure its rendering doesn't time out...
      promises[name] = timeoutPromise(
        // ... and capture the resolve callback for it...
        new Promise<PromiseResult>(resolve => {
          resolveFns[name] = resolve;
        }),
        renderTimeout,
        TIMEOUT_ERROR,
      ).catch(error => {
        // ... then make sure to resolve with the right success value in case of a timeout.
        if (error === TIMEOUT_ERROR) {
          if (this.mounted) {
            Log.error(`RenderMetrics (${this.props.name}): "${name}" timed out.`);
            //Metrics.emitTimedOut(this.props.name, name);
          }
          return { name, success: false };
        }
        throw error;
      });
    });

    // Halt as soon as an error is thrown or the wait-for-children promise is done.
    Promise.race([errorPromise, Promise.all(Object.keys(promises).map(key => promises[key]))])
      .catch(error => [error])
      .then((results: any) => {
        if (!this.mounted) {
          //console.info(`RenderMetrics (${this.props.name}): Unmounted before rendering completed.`);
          return;
        }

        if (results.some((result: { success: any }) => !result.success)) {
          Log.error(
            `Failing metric: ${this.props.name}\n` +
              "Failing components: \n" +
              results
                .filter((result: { success: any }) => !result.success)
                .map((result: { name: any }) => result.name)
                .join(" \n"),
          );
          this.props.onRenderComplete(false);
        } else {
          this.props.onRenderComplete(true);
        }
      });

    this.contextProps = {
      renderMetrics: {
        // This is what a child component calls when they're done rendering.
        rendered: name => {
          if (name in resolveFns) {
            resolveFns[name]({ name, success: true });
          } else {
            //console.warn(`RenderMetrics (${this.props.name}): "${name}" is not tracked.`);
          }
        },
      },
    };
  }
}
