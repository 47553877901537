/* eslint-disable no-unused-vars */

import { ComponentType } from "react";

export function getWrappedComponentDisplayName(
  baseComponentName?: string,
): (WrappedComponent: ComponentType) => string {
  return (WrappedComponent: ComponentType): string =>
    (baseComponentName ?? "") +
    (WrappedComponent.displayName ?? WrappedComponent.name ?? "Component");
}
