import { FetchApiSignature } from "../interfaces/fetchApiSignature";
import { Log } from "./logUtils";
import { hasJsonContent } from "./promiseUtils";

const DEFAULT_RETRY_NUMBER = 3;
const DEFAULT_RETRY_DELAY = 1000;

/**
 * Helper function to set delay
 */
const setDelay = (delayMs: number) =>
  new Promise<void>(resolve => {
    setTimeout(() => {
      resolve();
    }, delayMs);
  });

const retryPromiseWithDelayAsync = async (
  promise: () => Promise<Response>,
  retries: number,
  delayMs: number,
  initialRetries: number = retries,
): Promise<Response> => {
  let response: Response | undefined;
  try {
    response = await promise();
    if (response.ok) {
      return response;
    }
    throw new Error();
  } catch (e: any) {
    if (retries === 1) {
      if (response) {
        return Promise.reject({
          status: response.status,
          msg: hasJsonContent(response) ? (await response.json())?.message : response.statusText,
          retries: initialRetries,
        });
      }
      return Promise.reject({
        status: undefined,
        msg: (e as Error)?.message,
        retries: initialRetries,
      });
    }

    return setDelay(delayMs).then(() =>
      retryPromiseWithDelayAsync(promise, retries - 1, delayMs, initialRetries),
    );
  }
};

export const retryPromiseWithDelay = (
  promise: () => Promise<Response>,
  retries = DEFAULT_RETRY_NUMBER,
  delay = DEFAULT_RETRY_DELAY,
): Promise<Response> => retryPromiseWithDelayAsync(promise, retries, delay, retries);

export async function fetchWithRegionFailover(
  fetchApi: FetchApiSignature,
  regionalEndpointSupplier: (region: string) => string,
  getRequestSupplier: (endpoint: string) => Request,
  regionsToTry: string[],
  regionIndex: number = 0,
): Promise<Response> {
  if (regionIndex >= regionsToTry.length) {
    return Promise.reject({});
  }
  const regionToTry = regionsToTry[regionIndex];
  const url = regionalEndpointSupplier(regionToTry);

  try {
    const response = await retryPromiseWithDelay(() => fetchApi(getRequestSupplier(url)));

    if (response.ok) {
      Log().info(`Successfully fetched from url: ${url} from region ${regionToTry}`);
      return response;
    }
    throw new Error(`"Unexpected response when attempting region ${regionToTry}`);
  } catch (error: any) {
    if (error?.status >= 500) {
      // retry next region or if exhausted rejected with last error
      if (regionIndex < regionsToTry.length - 1) {
        Log().warn(
          `Received an error status ${error?.status} from region ${regionToTry}. Retrying in another region.`,
        );
        return fetchWithRegionFailover(
          fetchApi,
          regionalEndpointSupplier,
          getRequestSupplier,
          regionsToTry,
          regionIndex + 1,
        );
      }
      Log().error(
        `Received error ${error?.status} from region ${regionToTry}. Exhausted all regions`,
      );
    }
    return Promise.reject({
      ...error,
      msg: "Exhausted retries for all regions. ".concat(error?.msg ? error?.msg : ""),
    });
  }
}
