import { courier_service_url, metrics_environment } from "../config/apiConfig";
import { COURIER_SERVICE_NAMESPACE } from "../constants";
import { v4 as uuidv4 } from "uuid";
import { isCanaryRun } from "../bootstrapUtils";

export enum Severity {
  INFO = 20000,
  WARN = 3000,
  ERROR = 40000,
}

export type CourierServiceMetadata = {
  key: string;
  value: string;
  type: "string" | "number";
}[];

class CourierTransport {
  private readonly metricsUrl: string;
  private readonly logsUrl: string;
  private readonly actionsUrl: string;
  public userSession: string;

  constructor() {
    this.metricsUrl = `${courier_service_url}/sendMetrics`;
    this.logsUrl = `${courier_service_url}/sendLogging`;
    this.actionsUrl = `${courier_service_url}/sendAction`;
    this.userSession = "signup_" + uuidv4();
  }

  private reportError(errorMessage: string, error?: unknown) {
    console.group("Courier error");
    console.error(errorMessage);
    if (error) {
      console.error(error);
    }
    console.groupEnd();
  }

  getUserSession(): string {
    return this.userSession;
  }

  processTelemetry(url: string, body: BodyInit, type: string): void {
    fetch(url, {
      body,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (!response.ok) {
          this.reportError(`Error sending ${type}`, `response code ${response.status}`);
        }
      })
      .catch(exception => {
        this.reportError(`Error sending ${type}`, exception);
      });
  }

  processLog(logLevel: Severity, message: string, logMetadata?: CourierServiceMetadata): void {
    if (process.env.NODE_ENV !== "production" || isCanaryRun()) {
      console.log(
        `Logging at ${Date.now()} - ${JSON.stringify({ logLevel, message, logMetadata })}`,
      );
    } else {
      const type = "logging";
      const body = JSON.stringify({
        source: COURIER_SERVICE_NAMESPACE,
        severity: logLevel,
        type,
        timestamp: Date.now(),
        message,
        metadata: logMetadata ?? [],
        userSession: this.userSession,
      });
      this.processTelemetry(this.logsUrl, body, type);
    }
  }

  processMetric(name: string, value: number, metadata?: CourierServiceMetadata): void {
    const type = "metrics";
    const metricPrefix = "mcs_";
    const canarySeparator = isCanaryRun() ? "_canary" : "";

    const body = JSON.stringify({
      source: COURIER_SERVICE_NAMESPACE,
      type,
      timestamp: Date.now(),
      sampling: 1,
      name: metricPrefix + name + "_" + metrics_environment + canarySeparator,
      metadata: metadata ?? [],
      value: value,
      userSession: this.userSession,
    });
    this.processTelemetry(this.metricsUrl, body, type);
  }

  processAction(action: string, value: string, logMetadata?: CourierServiceMetadata): void {
    const type = "action";
    const body = JSON.stringify({
      source: COURIER_SERVICE_NAMESPACE,
      type,
      timestamp: Date.now(),
      action,
      value,
      metadata: logMetadata ?? [],
      userSession: this.userSession,
    });
    this.processTelemetry(this.actionsUrl, body, type);
  }
}

export default new CourierTransport();
