import * as React from "react";
import { createElement, FunctionComponent, ComponentType, ReactElement } from "react";
import { getWrappedComponentDisplayName } from "./utils";

const BASE_CUSTOM_ELEMENT_NAME = "o4aws-react";

/**
 * Wraps a component with a custom element. The intention is to use this to
 * target styles for the wrapped component
 *
 * @example
 * ```tsx
 * import Person from "./file/path/to/component/person";
 * const WrappedPerson = withCustomElementWrap<PersonProps>(Person, "person-suffix");
 * <WrappedPerson firstName="John" lastName="Doe">
 *  <div class="age">Age: {22}</div>
 * </WrappedPerson>
 * ```
 *
 * ```css
 * o4aws-react-person-suffix .age {
 *  color: blue;
 * }
 * ```
 *
 * @template TProps
 * @param WrappedComponent
 * @param elementSuffix
 * @returns wrapped component with custom element
 */
function withCustomElementWrap<TProps>(
  WrappedComponent: ComponentType<TProps>,
  elementSuffix: string,
): FunctionComponent<TProps> {
  function CustomElementWrap(props: TProps): ReactElement<TProps> {
    return createElement<TProps>(
      `${BASE_CUSTOM_ELEMENT_NAME}-${elementSuffix}`,
      {} as TProps,
      <WrappedComponent {...props} />,
    );
  }

  (CustomElementWrap as React.ComponentType).displayName =
    getWrappedComponentDisplayName()(WrappedComponent);

  return CustomElementWrap;
}

export default withCustomElementWrap;
