import { Region } from "oci-console-regions";

export const hostname = window.location.hostname;
export const CLOUD_LINKS = "/cloudLinks";

export enum SignUpURLs {
  MIGLAB = "signup-miglab.multicloud.oracle.com",
  PINTLAB = "signup-pintlab.multicloud.oracle.com",
  STAGE = "signup-staging.multicloud.oracle.com",
  PROD = "signup.multicloud.oracle.com",
}

export enum ODSAConsoleUrls {
  DEV = "https://multicloud.oraclecorp.com/azure",
  PREPROD = "https://console-staging.multicloud.oracle.com/azure",
  PROD = "https://console.multicloud.oracle.com/azure",
}
export enum ODSAWSConsoleURLs {
  DEV = "https://aws-dev.multicloud.oracle.com",
  PREPROD = "https://aws-staging.multicloud.oracle.com",
  PROD = "https://aws.multicloud.oracle.com",
}
export enum ODSGCPConsoleURLs {
  DEV = "https://gcp-dev.multicloud.oracle.com",
  PREPROD = "https://gcp-staging.multicloud.oracle.com",
  PROD = "https://gcp.multicloud.oracle.com",
}
export class MchubProxyURLs {
  static setRegion(region?: Region) {
    if (region) {
      this.DEV = `https://mchub-azure-proxy-dev.${region}.oci.oraclecloud.com`;
      this.PREPROD = `https://mchub-azure-proxy-preprod.${region}.oci.oraclecloud.com`;
      this.PROD = `https://mchub-azure-proxy.${region}.oci.oraclecloud.com`;
    }
    return MchubProxyURLs;
  }
  toString() {
    return MchubProxyURLs;
  }
  static DEV = "https://mchub-azure-proxy-dev.us-ashburn-1.oci.oraclecloud.com";
  static PREPROD = "https://mchub-azure-proxy-preprod.us-ashburn-1.oci.oraclecloud.com";
  static PROD = "https://mchub-azure-proxy.us-ashburn-1.oci.oraclecloud.com";
}

export class AWSGetCloudLinkURLs {
  static setRegion(accountId: string, region?: Region) {
    if (region && accountId) {
      this.DEV = `https://mchub-cloudlink-aws-dev.${region}.oci.oraclecloud.com/accounts/${accountId}/cloudLinks?lifecycleState=ACTIVE`;
      this.PREPROD = `https://mchub-cloudlink-aws-preprod.${region}.oci.oraclecloud.com/accounts/${accountId}/cloudLinks?lifecycleState=ACTIVE`;
      this.PROD = `https://mchub-cloudlink-aws.${region}.oci.oraclecloud.com/accounts/${accountId}/cloudLinks?lifecycleState=ACTIVE`;
    } else if (accountId) {
      this.DEV = `https://mchub-cloudlink-aws-dev.us-ashburn-1.oci.oraclecloud.com/accounts/${accountId}/cloudLinks?lifecycleState=ACTIVE`;
      this.PREPROD = `https://mchub-cloudlink-aws-preprod.us-ashburn-1.oci.oraclecloud.com/accounts/${accountId}/cloudLinks?lifecycleState=ACTIVE`;
      this.PROD = `https://mchub-cloudlink-aws.us-ashburn-1.oci.oraclecloud.com/accounts/${accountId}/cloudLinks?lifecycleState=ACTIVE`;
    }
    return AWSGetCloudLinkURLs;
  }
  toString() {
    return AWSGetCloudLinkURLs;
  }
  static DEV = "";
  static PREPROD = "";
  static PROD = "";
}

export enum AwsCfnTemplateURLs {
  DEV = "https://odsaws-cloudformation-templates-dev.s3.amazonaws.com/oci-aws.yaml",
  PREPROD = "https://odsaws-cloudformation-templates-preprod.s3.amazonaws.com/oci-aws.yaml",
  PROD = "https://odsaws-cloudformation-templates.s3.amazonaws.com/oci-aws.yaml",
}

export class CloudLinkUrls {
  static setRegion(region?: Region) {
    if (region) {
      this.DEV = `https://mchub-cloud-link-dev.${region}.oci.oraclecloud.com/20220401`;
      this.PREPROD = `https://mchub-cloud-link-preprod.${region}.oci.oraclecloud.com/20220401`;
      this.PROD = `https://mchub-cloud-link.${region}.oci.oraclecloud.com/20220401`;
    }
    return CloudLinkUrls;
  }
  toString() {
    return CloudLinkUrls;
  }
  static DEV = "https://mchub-cloud-link-dev.us-ashburn-1.oci.oraclecloud.com/20220401";
  static PREPROD = "https://mchub-cloud-link-preprod.us-ashburn-1.oci.oraclecloud.com/20220401";
  static PROD = "https://mchub-cloud-link.us-ashburn-1.oci.oraclecloud.com/20220401";
}

export class MchubPlatformURLs {
  static setRegion(region?: Region) {
    if (region) {
      this.DEV = `https://mchub-platform-dev.${region}.oci.oraclecloud.com/20220401`;
      this.PREPROD = `https://mchub-platform-preprod.${region}.oci.oraclecloud.com/20220401`;
      this.PROD = `https://mchub-platform.${region}.oci.oraclecloud.com/20220401`;
    }
    return MchubPlatformURLs;
  }
  toString() {
    return MchubPlatformURLs;
  }
  static DEV = "https://mchub-platform-dev.us-ashburn-1.oci.oraclecloud.com/20220401";
  static PREPROD = "https://mchub-platform-preprod.us-ashburn-1.oci.oraclecloud.com/20220401";
  static PROD = "https://mchub-platform.us-ashburn-1.oci.oraclecloud.com/20220401";
}

export class MchubSignupURLs {
  static setRegion(region?: Region) {
    if (region) {
      this.DEV = `https://mchub-signup-dev.${region}.oci.oraclecloud.com/20220401`;
      this.PREPROD = `https://mchub-signup-preprod.${region}.oci.oraclecloud.com/20220401`;
      this.PROD = `https://mchub-signup.${region}.oci.oraclecloud.com/20220401`;
    }
    return MchubSignupURLs;
  }
  toString() {
    return MchubSignupURLs;
  }
  static DEV = "https://mchub-signup-dev.us-ashburn-1.oci.oraclecloud.com/20220401";
  static PREPROD = "https://mchub-signup-preprod.us-ashburn-1.oci.oraclecloud.com/20220401";
  static PROD = "https://mchub-signup.us-ashburn-1.oci.oraclecloud.com/20220401";
}

// Minimal permission applications
export enum AzureAppIds {
  DEV = "daed79ed-832a-435e-97bc-cefeb4c3aa2b",
  PREPROD = "29a1f0c7-a310-4db1-b0bf-6be17b05f4be",
  PROD = "7bd54f49-089a-4967-9549-3c70195cf58c",
}

// Full permission applications
export enum AutomatedAzureAppIds {
  DEV = "c9b880b7-64a6-4a57-aeec-e0ceedd361b9",
  PREPROD = "266d93d1-aaee-4f4d-a9f0-1f4e472881de",
  PROD = "c19dedba-79d3-4665-a522-f614cfd75ac5",
}

export const AzureSubscriptionURL =
  "https://management.azure.com/subscriptions?api-version=2020-01-01";

export enum Environment {
  DEV = "dev",
  LOCALHOST = "localhost",
  LOCALHOST_CERTS = "localhost.oracle.com",
  MIGLAB = "miglab",
  PINTLAB = "pintlab",
  PRD = "prd",
  PROD_ORACLE = "prod-oracle",
  SITOCI = "sitoci",
  STAGE = "stage",
  UAT = "uat",
  UAT3 = "uat3",
}

export const base_url =
  hostname === Environment.LOCALHOST ||
  hostname === Environment.LOCALHOST_CERTS ||
  hostname === SignUpURLs.PINTLAB
    ? `https://signup-api-stage.oraclecloud.com:6443/20200828` // Pintlab2 - dev
    : hostname === SignUpURLs.STAGE
    ? "https://signup-api-stage.oraclecloud.com:443/20200828" // Pintlab - preprod
    : hostname === SignUpURLs.PROD
    ? "https://signup-api.cloud.oracle.com/20200828"
    : "";

export const env =
  hostname === SignUpURLs.STAGE
    ? Environment.UAT
    : hostname === Environment.LOCALHOST_CERTS ||
      hostname === Environment.LOCALHOST ||
      hostname === SignUpURLs.PINTLAB
    ? Environment.SITOCI
    : hostname === SignUpURLs.PROD
    ? Environment.PRD
    : "";

export const signup_environment =
  hostname === Environment.LOCALHOST || hostname === Environment.LOCALHOST_CERTS
    ? Environment.DEV
    : hostname === SignUpURLs.STAGE
    ? Environment.STAGE
    : hostname === SignUpURLs.MIGLAB
    ? Environment.MIGLAB
    : hostname === SignUpURLs.PINTLAB
    ? Environment.PINTLAB
    : hostname === SignUpURLs.PROD
    ? Environment.PROD_ORACLE
    : "";

export const courier_service_url = (
  [
    Environment.LOCALHOST,
    Environment.LOCALHOST_CERTS,
    SignUpURLs.MIGLAB,
    SignUpURLs.STAGE,
    SignUpURLs.PINTLAB,
  ] as string[]
).includes(hostname)
  ? "https://courier-service-stage.oraclecloud.com/20200828"
  : hostname === SignUpURLs.PROD
  ? "https://courier-service.oraclecloud.com/20200828"
  : "";

export const metrics_environment = (
  [
    Environment.LOCALHOST,
    Environment.LOCALHOST_CERTS,
    SignUpURLs.MIGLAB,
    SignUpURLs.PINTLAB,
  ] as string[]
).includes(hostname)
  ? Environment.DEV
  : hostname === SignUpURLs.STAGE
  ? Environment.STAGE
  : hostname === SignUpURLs.PROD
  ? Environment.PRD
  : "";

export enum GcpClientIds {
  DEV = "565548346248-chk3a52llkpg34j10uhpig6lc422lvdk.apps.googleusercontent.com", // MCUIP - Dev
  PREPROD = "104849030461-73oa89d4o4q0ubi23dnr63cjs4imdcij.apps.googleusercontent.com", // MCUIP - PreProd
  PROD = "184629786259-ldukpuskucht5n5d42rtth74b7vffcne.apps.googleusercontent.com", // MCUIP - Prod
}

export class GCPGetCloudLinkURLs {
  static setRegion(tenantId: string, region?: Region) {
    if (region && tenantId) {
      this.DEV = `https://mchub-cloud-link-dev.${region}.oci.oraclecloud.com/20220401/cloudLinks?compartmentId=${tenantId}&cloudLinkType=GCP&lifecycleState=ACTIVE`;
      this.PREPROD = `https://mchub-cloud-link-preprod.${region}.oci.oraclecloud.com/20220401/cloudLinks?compartmentId=${tenantId}&cloudLinkType=GCP&lifecycleState=ACTIVE`;
      this.PROD = `https://mchub-cloud-link.${region}.oci.oraclecloud.com/20220401/cloudLinks?compartmentId=${tenantId}&cloudLinkType=GCP&lifecycleState=ACTIVE`;
    } else if (tenantId) {
      this.DEV = `https://mchub-cloud-link-dev.us-ashburn-1.oci.oraclecloud.com/20220401/cloudLinks?compartmentId=${tenantId}&cloudLinkType=GCP&lifecycleState=ACTIVE`;
      this.PREPROD = `https://mchub-cloud-link-preprod.us-ashburn-1.oci.oraclecloud.com/20220401/cloudLinks?compartmentId=${tenantId}&cloudLinkType=GCP&lifecycleState=ACTIVE`;
      this.PROD = `https://mchub-cloud-link.us-ashburn-1.oci.oraclecloud.com/20220401/cloudLinks?compartmentId=${tenantId}&cloudLinkType=GCP&lifecycleState=ACTIVE`;
    }
    return GCPGetCloudLinkURLs;
  }
  toString() {
    return GCPGetCloudLinkURLs;
  }
  static DEV = "";
  static PREPROD = "";
  static PROD = "";
}
