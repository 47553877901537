/**
 * Unknown sign-in error
 */
export const UNKNOWN_SIGN_IN_ERROR = "UNKNOWN_SIGN_IN_ERROR";

/**
 * Clock skew sign-in error
 */
export const CLOCK_SKEW_SIGN_IN_ERROR = "CLOCK_SKEW_SIGN_IN_ERROR";

/**
 * User login with invalid url
 * e.g bookmarked SOUP login page where skipping browser crypto key creation
 * where callback url is not verifiable
 */
export const INVALID_SIGN_IN_URL = "INVALID_SIGN_IN_URL_ERROR";

/**
 * Notify upstream component user session is no longer valid
 */
export const SESSION_TIMEOUT = "SESSION_TIMEOUT";

/**
 * Unable or unauthorized to resolve requested OCID for tenancy override.
 */
export const TENANCY_OVERRIDE_FAILURE_404 = "TENANCY_OVERRIDE_FAILURE_404";

/**
 * Tenant not found or incorrect region for tenancy override.
 */
export const TENANCY_OVERRIDE_FAILURE_404_TENANT_NOT_FOUND = "TENANCY_OVERRIDE_FAILURE_404_TENANT_NOT_FOUND";

/**
 * This tenancy is unauthorized for cross-tenancy access.
 */
export const TENANCY_OVERRIDE_FAILURE_401 = "TENANCY_OVERRIDE_FAILURE_401";
