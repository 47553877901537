import { Region } from "oci-console-regions";
import { CloudType, Environment } from "../models/types";

export const isPintlabEnv = () =>
  window.location.origin?.toLowerCase().includes(Environment.PINTLAB.toLowerCase());

export const isLocalhostEnv = () =>
  window.location.origin?.toLowerCase().includes(Environment.LOCALHOST.toLowerCase());

export const isStagingEnv = () =>
  window.location.origin?.toLowerCase().includes(Environment.STAGING.toLowerCase());

export const isDevEnv = () =>
  window.location.origin?.toLowerCase().includes(Environment.DEV.toLowerCase());

/**
 * Can be lazily updated when adding regions.
 * Note: This list is only used to break the chicken and egg problem of supported regions.
 * This region list is not the source of truth and mchub `/regions` API will be the one
 */
export const getBootstrapRegionList = (cloudType: CloudType): string[] => {
  // Shuffle to randomize load
  if (cloudType === CloudType.AZURE) {
    if (isPintlabEnv() || isLocalhostEnv() || isDevEnv()) {
      return [Region.IAD, Region.FRA];
    }

    if (isStagingEnv()) {
      return [Region.IAD, Region.FRA];
    }
    return [
      Region.IAD,
      Region.LHR,
      Region.FRA,
      Region.PHX,
      Region.NRT,
      Region.SJC,
      Region.YYZ,
      Region.AMS,
      Region.VCP,
      Region.ICN,
      Region.SIN,
      Region.JNB,
    ];
  }

  if (cloudType === CloudType.AWS) {
    if (isPintlabEnv() || isLocalhostEnv() || isDevEnv()) {
      return [Region.IAD, Region.FRA];
    }
    if (isStagingEnv()) {
      return [Region.IAD, Region.FRA];
    }
    return [Region.IAD, Region.LHR, Region.FRA, Region.NRT, Region.SJC, Region.VCP, Region.SIN];
  }
  if (cloudType === CloudType.GCP) {
    if (isPintlabEnv() || isLocalhostEnv() || isDevEnv()) {
      return [Region.IAD, Region.FRA];
    }
    if (isStagingEnv()) {
      return [Region.IAD, Region.FRA];
    }
    return [Region.IAD, Region.LHR, Region.FRA, Region.NRT, Region.YYZ];
  }
  return [];
};

export class CloudLinkUrls {
  static setRegion(region: string) {
    this.DEV = `https://mchub-cloud-link-dev.${region}.oci.oraclecloud.com/20220401`;
    this.PREPROD = `https://mchub-cloud-link-preprod.${region}.oci.oraclecloud.com/20220401`;
    this.PROD = `https://mchub-cloud-link.${region}.oci.oraclecloud.com/20220401`;

    return CloudLinkUrls;
  }
  toString() {
    return CloudLinkUrls;
  }

  static DEV = "https://mchub-cloud-link-dev.us-ashburn-1.oci.oraclecloud.com/20220401";
  static PREPROD = "https://mchub-cloud-link-preprod.us-ashburn-1.oci.oraclecloud.com/20220401";
  static PROD = "https://mchub-cloud-link.us-ashburn-1.oci.oraclecloud.com/20220401";
}

export const getMchubEndpoint = (region: string) => {
  if (isPintlabEnv() || isLocalhostEnv() || isDevEnv()) {
    return CloudLinkUrls.setRegion(region).DEV;
  }
  if (isStagingEnv()) {
    return CloudLinkUrls.setRegion(region).PREPROD;
  }
  return CloudLinkUrls.setRegion(region).PROD;
};

export const getMchubRegionsEndpoint = (cloudType: string, region: string) =>
  getMchubEndpoint(region).concat(`/regions?cloudLinkType=${cloudType}`);
export const getMchubCloudLinksEndpoint = (cloudType: string, tenantId: string, region: string) =>
  getMchubEndpoint(region).concat(
    `/cloudLinks?compartmentId=${tenantId}&cloudLinkType=${cloudType}&lifecycleState=ACTIVE`,
  );

export const getMchubGetCloudLinksEndpoint = (cloudLinkId: string, region: string) =>
  getMchubEndpoint(region).concat(`/cloudLinks/${cloudLinkId}`);

export const getMchubListProjectsEndpoint = (cloudLinkId: string, region: string) =>
  getMchubEndpoint(region).concat(`/clouds/gcp/projects?cloudLinkId=${cloudLinkId}`);

export const getIdentityRegionSubscriptionsEndpoint = (region: string, tenantId: string) =>
  `https://identity.${region}.oci.oraclecloud.com/20160918/tenancies/${tenantId}/regionSubscriptions`;

export const SESSION_STORAGE_CANARY_KEY = "canary";
export const isCanary = () => sessionStorage.getItem(SESSION_STORAGE_CANARY_KEY) === "true";
