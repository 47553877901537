/* eslint-disable no-redeclare */

import {
  Popover as AwsuiPopover,
  PopoverProps as AwsuiPopoverProps,
} from "@awsui/components-react";
import * as React from "react";
import { useEffect, useRef } from "react";
import { BaseProps } from "../../models/baseProps";
import { getDocument } from "../../utils/browser";

export interface PopoverProps extends Omit<AwsuiPopoverProps, "triggerType">, BaseProps {
  triggerType?: AwsuiPopoverProps.TriggerType | "hover";
}

function Popover(props: PopoverProps) {
  const { triggerType, children, dismissButton, testId } = props;
  const wrapperRef = useRef<HTMLElement>();

  function handleMouseOver(): void {
    // The parentElement of the wrapper is the trigger
    wrapperRef.current.parentElement.click();
  }

  function handleMouseLeave(): void {
    // An event is registered to close it on document mousedown leveraging it since there's no other way
    getDocument().dispatchEvent(new MouseEvent("mousedown"));
  }

  useEffect(() => {
    if (triggerType !== "hover") {
      return undefined;
    }

    wrapperRef.current.addEventListener("mouseover", handleMouseOver);
    wrapperRef.current.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      wrapperRef.current.removeEventListener("mouseover", handleMouseOver);
      wrapperRef.current.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [triggerType]);

  return (
    <AwsuiPopover
      {...props}
      triggerType={triggerType === "hover" ? "custom" : triggerType}
      dismissButton={triggerType === "hover" ? false : dismissButton}
    >
      <span data-testid={testId} ref={wrapperRef}>
        {children}
      </span>
    </AwsuiPopover>
  );
}

export default Popover;
