import IndexDB from "localforage";
import { GcpIndexedDBData } from "../interfaces/gcpIndexedDBData";
import { generateStateSigningKey } from "./urlStateUtils";

/**
 * The IndexDB key to store key pair
 */
const KEY_NAME = "key-gcp-auth";

/**
 * IndexDB name to persist information between http redirects.
 */
const DB_NAME = "gcp-key-store";

/**
 * IndexDB version. This must be in sync with Hg
 */
const DB_VERSION = 2;

/**
 * Store name in above IndexDB
 */
const STORE_NAME = "keys";

let indexDB: typeof IndexDB;

export function createDbInstance() {
  indexDB = IndexDB.createInstance({
    driver: IndexDB.INDEXEDDB,
    name: DB_NAME,
    storeName: STORE_NAME,
    version: DB_VERSION,
  });
}

/**
 * Initializes GcpIndexedDBData and saves it to IndexDb
 * @returns Promise of GcpIndexedDBData
 */
export async function initGcpIndexedDBData(): Promise<GcpIndexedDBData> {
  const stateSigningKey = await generateStateSigningKey();
  const gcpIndexedDBData = {} as GcpIndexedDBData;
  gcpIndexedDBData.createdAt = Math.ceil(new Date(Date.now()).getTime());
  gcpIndexedDBData.id = KEY_NAME;
  gcpIndexedDBData.stateSignKey = stateSigningKey;
  await setItem(gcpIndexedDBData);

  return gcpIndexedDBData;
}

/**
 * Retrieve GcpIndexedDBData from IndexedDB
 * @returns Promise of GcpIndexedDBData
 */
export async function getGcpIndexedDBData(): Promise<GcpIndexedDBData> {
  return indexDB?.getItem(KEY_NAME) as Promise<GcpIndexedDBData>;
}

/**
 * Save GcpIndexedDBData to Db
 * @param gcpIndexedDBData data to be saved
 */
export function setItem(gcpIndexedDBData: GcpIndexedDBData): Promise<GcpIndexedDBData> {
  return indexDB?.setItem(KEY_NAME, gcpIndexedDBData);
}

/**
 * Clear Db
 */
export function clearDb(keySuffix?: string): Promise<void> {
  return keySuffix ? indexDB?.removeItem(keySuffix) : indexDB?.clear();
}
