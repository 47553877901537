import { AzAuthConfig } from "./AzAuthConfig";
import {
  AccountInfo,
  AuthenticationResult,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { FetchApiSignature } from "./FetchApiSignature";
import { SilentRequest } from "@azure/msal-browser/dist/request/SilentRequest";
import {PopupRequest} from "@azure/msal-browser/dist/request/PopupRequest";

export class AzAuth {
  private msalObj: PublicClientApplication;
  private readonly maxRetries = 10;
  private readonly defaultRetryTime = 1000;

  private constructor(private config: AzAuthConfig) {
    this.msalObj = new PublicClientApplication(config.msalConfig);
  }
  private static singleton: AzAuth;

  public static async init(config: AzAuthConfig): Promise<AzAuth> {
    AzAuth.singleton = new AzAuth(config);
    const response = await AzAuth.singleton.msalObj.handleRedirectPromise();
    if (response !== null) {
      AzAuth.singleton.account = response.account || undefined;
      if (response?.account?.tenantId) {
        AzAuth.setTenancy(response.account.tenantId);
      }
    }
    return AzAuth.singleton;
  }

  public static get instance(): AzAuth {
    if (!AzAuth.singleton) {
      throw new Error("AzAuth has not been initialized");
    }
    return AzAuth.singleton;
  }

  private static readonly tenancyIdKey = "AzAuth.TenancyId"
  private static getTenancy() {
    return localStorage.getItem(AzAuth.tenancyIdKey);
  }

  private static setTenancy(tenancyId: string) {
    localStorage.setItem(AzAuth.tenancyIdKey, tenancyId);
  }

  private static deleteTenancy() {
    localStorage.removeItem(AzAuth.tenancyIdKey);
  }

  private account: AccountInfo | undefined;
  private async login(popup: boolean, force: boolean, tenancyId?: string) {
    const tenancyIdLocal = tenancyId || AzAuth.getTenancy();
    const currentAccounts = this.msalObj.getAllAccounts();
    if (currentAccounts.length === 0 || force) {
      const { scopes } = this.config;
      const request: PopupRequest = { scopes };
      if (tenancyIdLocal) {
        request.authority = `https://login.microsoftonline.com/${tenancyIdLocal}/`
      }
      if (popup) {
        await this.msalObj.loginPopup(request);
      } else {
        await this.msalObj.loginRedirect(request);
      }
    } else if (currentAccounts.length > 1) {
      // TODO: Add choose account code here
    } else if (currentAccounts.length === 1) {
      this.account = currentAccounts[0];
      AzAuth.setTenancy(this.account.tenantId);
    }
  }

  public async authenticate(force: boolean = false, tenancyId?: string) {
    return this.login(false, force, tenancyId);
  }

  public async authenticatePopup(force: boolean = false, tenancyId?: string) {
    return this.login(true, force, tenancyId);
  }

  public async logOut(): Promise<void> {
    const account = this.account;
    if (!account) {
      throw new Error("User not logged in");
    }
    AzAuth.deleteTenancy();
    return this.msalObj.logoutRedirect({ account });
  }

  public async getTokenPopup(
    request: SilentRequest
  ): Promise<AuthenticationResult> {
    request.account = this.account;
    const tenant = this.account?.tenantId;
    // Using Tenant to fix issue with guest accounts.
    if (tenant) {
      request.authority = request.authority || `https://login.microsoftonline.com/${tenant}/`
    }

    try {
      return await this.msalObj.acquireTokenSilent(request);
    } catch (error) {
      console.warn(
        "silent token acquisition fails. acquiring token using popup "
      );
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return this.msalObj.acquireTokenPopup(request).then((tokenResponse) => {
          console.log(tokenResponse);
          return tokenResponse;
        });
      }
      console.warn(error);
      throw error;
    }
  }

  private async sleep(time: number): Promise<void> {
    return new Promise((resolve => {
      setTimeout(() => { resolve()}, time);
    }))
  }

  public async getTokenSilent(request: SilentRequest, retries?: number, retryTime?: number): Promise<AuthenticationResult> {
    request.account = this.account;
    const tenant = this.account?.tenantId;
    // Using Tenant to fix issue with guest accounts.
    if (tenant) {
      request.authority = request.authority || `https://login.microsoftonline.com/${tenant}/`
    }
    let localRetries = retries || 0;
    if (localRetries > this.maxRetries) {
      localRetries = this.maxRetries;
    }
    let localRetryTime = retryTime || this.defaultRetryTime;
    if (localRetryTime < 0) {
      localRetryTime = this.defaultRetryTime;
    }
    try {
      return await this.msalObj.acquireTokenSilent(request);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        console.warn(`silent token acquisition fails. retrying in ${localRetryTime} milliseconds`);
        if (localRetries > 0) {
          await this.sleep(localRetryTime);
          return await this.getTokenSilent(request, localRetries - 1, localRetryTime);
        }
      }
      console.error(error);
      throw error;
    }
  }

  public getFetchApi(silentRequest: SilentRequest): FetchApiSignature {
    return async (input: string | Request, init?: RequestInit) => {
      const { accessToken } = await this.getTokenPopup(silentRequest);
      const request: Request = new Request(input, init);
      const bearer = `Bearer ${accessToken}`;
      request.headers.append("Authorization", bearer);
      return fetch(request);
    };
  }

  public getAccount(): AccountInfo {
    if (!this.account) {
      throw new Error("User not logged in");
    }
    return this.account;
  }
}
