/**
 * Get window through a function so we can mock this function in unit test and return a stub window
 */
export function getWindow(): Window {
  return window;
}

/**
 * Get document through a function so we can mock this function in unit test and return a stub document
 */
export function getDocument(): Document {
  return document;
}

export const hostname = getWindow().location.hostname;

export const getWindowHost = () => window.location.host;

export const redirectTo = (url: string | URL) => {
  getWindow().location.assign(url);
};

export const getUriDestination = () => {
  let result = null;
  const windowURL = new URLSearchParams(window.location.search);
  const uriDestination = windowURL.get("uriDestination");
  if (uriDestination === null) return result;
  try {
    result = decodeURIComponent(uriDestination);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("URI Destination is not encoded: ", e);
    result = uriDestination;
  }
  return new URL(result);
};
