import { getWindow } from "./BrowserHelpers";
import { getParamFromQueryString } from "./urlUtils";
import { ConfigValue, Features, FeaturesManifest } from "./FeatureFlagsRegistry";
import { Environment, signup_environment } from "../config/apiConfig";
import { AvailabilityMetrics, emitEvent } from "../CourierService/metrics";
import Log from "../CourierService/Logger";

const FETCH_FEATURES_ERROR = "Failed to fetch the Feature Manifest from public URL";

export const CONFIG_OVERRIDE_KEY = "configoverride";
export type LogCallbackType = (error: string) => void;
export const INVALID_FEATURE_FLAG_NAME = "Invalid feature flag name";

class FeatureFlags {
  private env: Environment | "";
  protected config: FeaturesManifest = {}; // Original static config from the manifest.
  protected featureFlags: FeaturesManifest = {}; // Computed result after overrides.
  protected error: string = "";

  constructor() {
    this.env = signup_environment;
  }

  public async initialize(): Promise<void> {
    this.error = "";
    this.config = await this.getFeaturesManifest();
    this.featureFlags = { ...this.config }; // Clone.
    this.applyOverrides();
  }

  /**
   * Returns the value of a feature flag.
   * If no matching feature flag is found, returns undefined.
   *
   * @param feature The name of the feature flag.
   * @param logCallback error log function.
   * @returns The value of the feature flag, or undefined if not found.
   */
  public getValue(feature: Features, logCallback?: LogCallbackType): ConfigValue {
    if (this.validateFeature(feature, logCallback)) {
      const val = this.featureFlags[feature]!;
      if (isPrimitiveConfigValue(val)) {
        return val;
      }

      return val[this.env] ?? val["*"] ?? val;
    }

    return "";
  }

  /**
   * Checks if a feature flag is enabled. If no matching feature flag is found, returns false.
   *
   * @param feature The name of the feature flag.
   * @param logCallback error log function.
   * @returns A boolean that represents whether the feature flag is enabled, or false if not found.
   */
  public isEnabled(feature: Features, logCallback?: LogCallbackType): boolean {
    return (this.getValue(feature, logCallback) || "").toString().toLowerCase() === "true";
  }

  /**
   * Checks if each value in a collection of feature flags is enabled.
   * If any of the features has no match, returns false. Returns true for
   * undefined or empty list.
   *
   * @param features A collection of features.
   * @param logCallback error log function.
   * @returns A boolean that represents whether all features are enabled, or false if any of them
   *  isn't found.
   */
  public areEnabled(features: Features[], logCallback?: LogCallbackType): boolean {
    return (features || []).every(feature => this.isEnabled(feature, logCallback));
  }

  /**
   * Returns the value of a feature flag as a string value, or undefined if not possible.
   *
   * @param feature The name of the feature flag.
   * @param logCallback error log function.
   * @returns A string value for the feature flag, or undefined if not possible.
   */
  public asString(feature: Features, logCallback?: LogCallbackType): string | undefined {
    const val = this.getValue(feature, logCallback);
    return feature in this.featureFlags && isPrimitiveConfigValue(val) ? val.toString() : undefined;
  }

  /**
   * Returns the value of a feature flag as a numeric value, or undefined if not possible.
   *
   * @param feature The name of the feature flag.
   * @param logCallback error log function.
   * @returns A numeric value for the feature flag, or undefined if not possible.
   */
  public asNumber(feature: Features, logCallback?: LogCallbackType): number | undefined {
    const val = this.getValue(feature, logCallback);
    return feature in this.featureFlags && isPrimitiveConfigValue(val)
      ? parseFloat(val.toString())
      : undefined;
  }

  /**
   * Returns the value of a feature flag as a Date value, or undefined if not possible.
   *
   * @param feature The name of the feature flag.
   * @param logCallback error log function.
   * @returns A Date value for the feature flag, or undefined if not possible.
   */
  public asDate(feature: Features, logCallback?: LogCallbackType): Date | undefined {
    const val = this.getValue(feature, logCallback);
    try {
      return new Date(val as string);
    } catch (error) {
      return undefined;
    }
  }

  /**
   * Applies config overrides from the URL the working set of feature flags.
   */
  protected applyOverrides(): void {
    const configOverrideQueryStr = getParamFromQueryString(CONFIG_OVERRIDE_KEY);
    if (configOverrideQueryStr) {
      this.saveOverridesToFeatureFlags(getParamFromQueryString(CONFIG_OVERRIDE_KEY));
      trySaveConfigOverrideRules();
    } else {
      // TODO: Restore query string param
      this.saveOverridesToFeatureFlags(getSavedConfigOverrideFromStorage());
    }
  }

  protected saveOverridesToFeatureFlags(override: string | null): void {
    if (!override) {
      return;
    }

    try {
      const { features } = JSON.parse(override);
      const keys = Object.keys(features || {}) as Features[];

      if (keys.length) {
        keys.forEach(key => {
          this.featureFlags[key] = features[key];
        });
      }
    } catch {
      // Malformed override. Ignore.
    }
  }

  private async getFeaturesManifest(): Promise<FeaturesManifest> {
    return window.__features_manifest
      .then(async response => {
        if (response.status >= 200 && response.status <= 299) {
          const featureManifestResponseJson = await response.json();
          Log.info("Feature flags fetched successfully");
          emitEvent(AvailabilityMetrics.App);
          return featureManifestResponseJson;
        } else {
          throw new Error(`Response Code: ${response.status}`);
        }
      })
      .catch(err => {
        Log.error("Feature flags fetch had an exception", err);
        this.error = `${FETCH_FEATURES_ERROR} ${err}`;
        emitEvent(AvailabilityMetrics.App, 0);
        return {} as FeaturesManifest;
      });
  }

  private validateFeature(feature: Features, logCallback?: LogCallbackType): boolean {
    const invalidFeature = !feature || this.featureFlags[feature] === undefined;
    const initializeError = this.error !== "";
    const hasError = initializeError || invalidFeature;

    if (hasError && logCallback) {
      logCallback(initializeError ? this.error : INVALID_FEATURE_FLAG_NAME);
    }

    // should only check the input feature and whether that feature exists in the featureFlags object,
    // other errors we only log
    return !invalidFeature;
  }
}

export function getSavedConfigOverrideFromStorage(): string | null {
  return getWindow().sessionStorage.getItem(CONFIG_OVERRIDE_KEY);
}

export function removeConfigOverrideFromStorage(): void {
  getWindow().sessionStorage.removeItem(CONFIG_OVERRIDE_KEY);
}

export function trySaveConfigOverrideRules(): string | null {
  let configOverride = getParamFromQueryString(CONFIG_OVERRIDE_KEY);
  if (!configOverride) {
    return null;
  }
  if (configOverride === "") {
    removeConfigOverrideFromStorage();
  } else {
    sessionStorage.setItem(CONFIG_OVERRIDE_KEY, configOverride);
  }
  return configOverride;
}

function isPrimitiveConfigValue(val: any): val is ConfigValue {
  return !!~["boolean", "string", "number"].indexOf(typeof val);
}

export default new FeatureFlags();
