import { MulticloudTelemetryHelper } from "multi-cloud-shared-components";
import { Action } from "../redux/store";

export enum GcpWelcomeState {
  WELCOME = "WELCOME",
  SIGN_IN = "SIGN_IN",
}

export enum GcpSignInState {
  OCI_SIGN_IN = "OCI_SIGN_IN",
  MULTICLOUD_LINK_PRECHECK = "MULTICLOUD_LINK_PRECHECK",
  GCP_SIGN_IN = "GCP_SIGN_IN",
  MULTICLOUD_LINK_ALREADY_EXISTS = "MULTICLOUD_LINK_ALREADY_EXISTS",
  MULTICLOUD_LINK_PRECHECK_ERROR = "MULTICLOUD_LINK_PRECHECK_ERROR",
  OCI_SIGN_OUT = "OCI_SIGN_OUT",
  GCP_SIGN_OUT = "GCP_SIGN_OUT",
  SIGN_UP = "SIGN_UP",
}

export enum GcpSignUpState {
  MULTICLOUD_LINK_USER_INPUT = "MULTICLOUD_LINK_USER_INPUT",
  MULTICLOUD_LINK_CREATE_IN_PROGRESS = "MULTICLOUD_LINK_CREATE_IN_PROGRESS",
  MULTICLOUD_LINK_CREATE_SUCCESS = "MULTICLOUD_LINK_CREATE_SUCCESS",
  MULTICLOUD_LINK_CREATE_FAILURE = "MULTICLOUD_LINK_CREATE_FAILURE",
}

export interface GcpStateDispatch extends Action {
  payload?: GcpStateStore;
}

export const GCP_STORE_NAME = "gcpState";

// Action Types
export namespace GcpStoreActions {
  // Welcome store actions
  export const WELCOME__WELCOME = `${GCP_STORE_NAME}/WELCOME__WELCOME`;
  export const WELCOME__GET_STARTED = `${GCP_STORE_NAME}/WELCOME__GET_STARTED`;

  // Sign in store actions
  export const SIGN_IN__OCI_SIGN_IN = `${GCP_STORE_NAME}/SIGN_IN__OCI_SIGN_IN`;
  export const SIGN_IN__OCI_SIGN_IN_SUCCESS = `${GCP_STORE_NAME}/SIGN_IN__OCI_SIGN_IN_SUCCESS`;
  export const SIGN_IN__PRECHECK_MCL_SUCCESS = `${GCP_STORE_NAME}/SIGN_IN__PRECHECK_MCL_SUCCESS`;
  export const SIGN_IN__GCP_SIGN_IN_SUCCESS = `${GCP_STORE_NAME}/SIGN_IN__GCP_SIGN_IN_SUCCESS`;
  export const SIGN_IN__MCL_EXISTS = `${GCP_STORE_NAME}/SIGN_IN__MCL_EXISTS`;
  export const SIGN_IN__PRECHECK_MCL_FAILURE = `${GCP_STORE_NAME}/SIGN_IN__PRECHECK_MCL_FAILURE`;

  // Sign up store actions
  export const SIGN_UP__CREATE_MCL_REQUEST = `${GCP_STORE_NAME}/SIGN_UP__CREATE_MCL_REQUEST`;
  export const SIGN_UP__CREATE_MCL_FAILURE = `${GCP_STORE_NAME}/SIGN_UP__CREATE_MCL_FAILURE`;
  export const SIGN_UP__CREATE_MCL_SUCCESS = `${GCP_STORE_NAME}/SIGN_UP__CREATE_MCL_SUCCESS`;
  export const SIGN_UP__EDIT = `${GCP_STORE_NAME}/SIGN_UP__EDIT`;

  // Auxiliary actions
  export const CANCEL = `${GCP_STORE_NAME}/CANCEL`;
  export const RESET = `${GCP_STORE_NAME}/RESET`;
  export const OCI_SIGN_OUT = `${GCP_STORE_NAME}/OCI_SIGN_OUT`;
  export const SET_OCI_TENANT = `${GCP_STORE_NAME}/SET_OCI_TENANT`;
  export const SET_GCP_ORG = `${GCP_STORE_NAME}/SET_GCP_ORG`;
  export const NEXT_SIGN_UP_STEP = `${GCP_STORE_NAME}/NEXT_SIGN_UP_STEP`;
  export const PREVIOUS_SIGN_UP_STEP = `${GCP_STORE_NAME}/PREVIOUS_SIGN_UP_STEP`;
  export const SET_ORGANIZATIONS_LIST = `${GCP_STORE_NAME}/SET_ORGANIZATIONS_LIST`;
  export const SET_ORGANIZATION_SELECTION = `${GCP_STORE_NAME}/SET_ORGANIZATION_SELECTION`;
  export const SET_SERVICE_ACCOUNT_PROJECTS_LIST = `${GCP_STORE_NAME}/SET_SERVICE_ACCOUNT_PROJECTS_LIST`;
  export const SET_SERVICE_ACCOUNT_PROJECT_SELECTION = `${GCP_STORE_NAME}/SET_SERVICE_ACCOUNT_PROJECT_SELECTION`;
  export const SET_SERVICE_ACCOUNTS_LIST = `${GCP_STORE_NAME}/SET_SERVICE_ACCOUNTS_LIST`;
  export const SET_SERVICE_ACCOUNT_SELECTION = `${GCP_STORE_NAME}/SET_SERVICE_ACCOUNT_SELECTION`;
  export const SET_PROJECTS_LIST = `${GCP_STORE_NAME}/SET_PROJECTS_LIST`;
  export const SET_PROJECT_SELECTION = `${GCP_STORE_NAME}/SET_PROJECT_SELECTION`;
  export const SET_REGIONS_LIST = `${GCP_STORE_NAME}/SET_REGIONS_LIST`;
  export const SET_REGION_SELECTION = `${GCP_STORE_NAME}/SET_REGION_SELECTION`;
  export const SET_SIGN_UP_USER_INPUT_ERROR = `${GCP_STORE_NAME}/SET_SIGN_UP_USER_INPUT_ERROR`;
}

// GCP types
export type GcpProject = {
  projectNumber: string;
  projectId: string;
  lifecycleState: string;
  name: string;
};

export type GcpOrganization = {
  organizationId: string;
  displayName: string;
  lifecycleState: string;
  name: string;
};

export type GcpServiceAccount = {
  name: string;
  projectId: string;
  uniqueId: string;
  email: string;
  displayName: string;
  oauth2ClientId: string;
  disabled: boolean;
};

export type GcpRegion = {
  value: string;
  label: string;
  endpoint: string;
};

export enum SignUpStep {
  Organization = 0,
  ServiceName = 1,
  Projects = 2,
  Regions = 3,
  CloudLink = 4,
}

// GCP Store
export interface GcpStateStore {
  // Flow states
  welcomeState: GcpWelcomeState;
  signInState: GcpSignInState;
  signUpState: GcpSignUpState;

  // Auxiliary variables
  ociTenant: string;
  gcpOrganizationName: string;
  gcpOrganizationsList: GcpOrganization[];
  gcpOrganizationSelectionId: string;
  serviceAccountProjectsList: GcpProject[];
  serviceAccountProjectSelectionName: string;
  serviceAccountsList: GcpServiceAccount[];
  serviceAccountSelectionName: string;
  projectsList: GcpProject[];
  projectSelections: GcpProject[];
  regionsList: GcpRegion[];
  regionSelection: GcpRegion;
  signUpStep: SignUpStep;
  signUpUserInputErrors: string[];
  createMclWorkRequestId: string;
  createMclErrorMessage: string;
}

export const initialGcpState: GcpStateStore = {
  welcomeState: GcpWelcomeState.WELCOME,
  signInState: GcpSignInState.OCI_SIGN_IN,
  signUpState: GcpSignUpState.MULTICLOUD_LINK_USER_INPUT,

  ociTenant: "",
  gcpOrganizationName: "",
  gcpOrganizationsList: [],
  gcpOrganizationSelectionId: "",
  serviceAccountProjectsList: [],
  serviceAccountProjectSelectionName: "",
  serviceAccountsList: [],
  serviceAccountSelectionName: "",
  projectsList: [],
  projectSelections: [],
  regionsList: [],
  regionSelection: null!,
  signUpStep: SignUpStep.Organization,
  signUpUserInputErrors: ["", "", "", ""],
  createMclWorkRequestId: "",
  createMclErrorMessage: "",
};

export const gcpStateReducer = (
  state: GcpStateStore = initialGcpState,
  action: GcpStateDispatch,
): GcpStateStore => {
  switch (action.type) {
    // Welcome actions
    case GcpStoreActions.WELCOME__WELCOME: {
      return {
        ...state,
        welcomeState: GcpWelcomeState.WELCOME,
      };
    }
    case GcpStoreActions.WELCOME__GET_STARTED: {
      return {
        ...state,
        welcomeState: GcpWelcomeState.SIGN_IN,
      };
    }
    // Sign in actions
    case GcpStoreActions.SIGN_IN__OCI_SIGN_IN: {
      return {
        ...state,
        signInState: GcpSignInState.OCI_SIGN_IN,
        ociTenant: "",
      };
    }
    case GcpStoreActions.SIGN_IN__OCI_SIGN_IN_SUCCESS: {
      return {
        ...state,
        signInState: GcpSignInState.MULTICLOUD_LINK_PRECHECK,
      };
    }
    case GcpStoreActions.SIGN_IN__PRECHECK_MCL_SUCCESS: {
      return {
        ...state,
        signInState: GcpSignInState.GCP_SIGN_IN,
      };
    }
    case GcpStoreActions.SIGN_IN__GCP_SIGN_IN_SUCCESS: {
      return {
        ...state,
        signInState: GcpSignInState.SIGN_UP,
      };
    }
    case GcpStoreActions.SIGN_IN__MCL_EXISTS: {
      return {
        ...state,
        signInState: GcpSignInState.MULTICLOUD_LINK_ALREADY_EXISTS,
      };
    }
    case GcpStoreActions.SIGN_IN__PRECHECK_MCL_FAILURE: {
      return {
        ...state,
        signInState: GcpSignInState.MULTICLOUD_LINK_PRECHECK_ERROR,
      };
    }
    // Sign up actions
    case GcpStoreActions.SIGN_UP__CREATE_MCL_REQUEST: {
      return {
        ...state,
        signUpState: GcpSignUpState.MULTICLOUD_LINK_CREATE_IN_PROGRESS,
        createMclWorkRequestId: action.payload?.createMclWorkRequestId!,
      };
    }
    case GcpStoreActions.SIGN_UP__CREATE_MCL_FAILURE: {
      return {
        ...state,
        signUpState: GcpSignUpState.MULTICLOUD_LINK_CREATE_FAILURE,
        createMclErrorMessage: action.payload?.createMclErrorMessage!,
      };
    }
    case GcpStoreActions.SIGN_UP__CREATE_MCL_SUCCESS: {
      return {
        ...state,
        signUpState: GcpSignUpState.MULTICLOUD_LINK_CREATE_SUCCESS,
      };
    }
    case GcpStoreActions.SIGN_UP__EDIT: {
      return {
        ...state,
        signUpStep: action.payload?.signUpStep!,
        signUpState: GcpSignUpState.MULTICLOUD_LINK_USER_INPUT,
      };
    }
    // Auxiliary actions
    case GcpStoreActions.CANCEL: {
      MulticloudTelemetryHelper.setCloudServiceProviderIdentifier(null!);

      return {
        ...initialGcpState,
        welcomeState: GcpWelcomeState.SIGN_IN,
        signInState: GcpSignInState.GCP_SIGN_OUT,
      };
    }
    case GcpStoreActions.RESET: {
      MulticloudTelemetryHelper.setCloudServiceProviderIdentifier(null!);

      return {
        ...initialGcpState,
        welcomeState: GcpWelcomeState.SIGN_IN,
        signInState: GcpSignInState.GCP_SIGN_OUT,
      };
    }
    case GcpStoreActions.OCI_SIGN_OUT: {
      MulticloudTelemetryHelper.setDefaultRegion(null!);
      MulticloudTelemetryHelper.setOciTenantName(null!);

      return {
        ...initialGcpState,
        welcomeState: GcpWelcomeState.SIGN_IN,
        signInState: GcpSignInState.OCI_SIGN_OUT,
      };
    }
    case GcpStoreActions.SET_OCI_TENANT: {
      MulticloudTelemetryHelper.setOciTenantName(action.payload?.ociTenant!);

      return {
        ...state,
        ociTenant: action.payload?.ociTenant!,
      };
    }
    case GcpStoreActions.SET_GCP_ORG: {
      return {
        ...state,
        gcpOrganizationName: action.payload?.gcpOrganizationName!,
      };
    }
    case GcpStoreActions.NEXT_SIGN_UP_STEP: {
      return {
        ...state,
        signUpStep: state.signUpStep + 1,
      };
    }
    case GcpStoreActions.PREVIOUS_SIGN_UP_STEP: {
      return {
        ...state,
        signUpStep: state.signUpStep - 1,
      };
    }
    case GcpStoreActions.SET_ORGANIZATIONS_LIST: {
      return {
        ...state,
        gcpOrganizationsList: action.payload?.gcpOrganizationsList!,
      };
    }
    case GcpStoreActions.SET_ORGANIZATION_SELECTION: {
      MulticloudTelemetryHelper.setCloudServiceProviderIdentifier(
        action.payload?.gcpOrganizationSelectionId!,
      );

      return {
        ...state,
        gcpOrganizationSelectionId: action.payload?.gcpOrganizationSelectionId!,
      };
    }
    case GcpStoreActions.SET_SERVICE_ACCOUNT_PROJECTS_LIST: {
      return {
        ...state,
        serviceAccountProjectsList: action.payload?.serviceAccountProjectsList!,
      };
    }
    case GcpStoreActions.SET_SERVICE_ACCOUNT_PROJECT_SELECTION: {
      return {
        ...state,
        serviceAccountProjectSelectionName: action.payload?.serviceAccountProjectSelectionName!,
        serviceAccountSelectionName: "",
      };
    }
    case GcpStoreActions.SET_SERVICE_ACCOUNTS_LIST: {
      return {
        ...state,
        serviceAccountsList: action.payload?.serviceAccountsList!,
      };
    }
    case GcpStoreActions.SET_SERVICE_ACCOUNT_SELECTION: {
      return {
        ...state,
        serviceAccountSelectionName: action.payload?.serviceAccountSelectionName!,
      };
    }
    case GcpStoreActions.SET_PROJECTS_LIST: {
      return {
        ...state,
        projectsList: action.payload?.projectsList!,
      };
    }
    case GcpStoreActions.SET_PROJECT_SELECTION: {
      return {
        ...state,
        projectSelections: action.payload?.projectSelections!,
      };
    }
    case GcpStoreActions.SET_REGIONS_LIST: {
      return {
        ...state,
        regionsList: action.payload?.regionsList!,
      };
    }
    case GcpStoreActions.SET_REGION_SELECTION: {
      MulticloudTelemetryHelper.setDefaultRegion(action.payload?.regionSelection?.value!);

      return {
        ...state,
        regionSelection: { ...action.payload?.regionSelection! },
      };
    }
    case GcpStoreActions.SET_SIGN_UP_USER_INPUT_ERROR: {
      return {
        ...state,
        signUpUserInputErrors: [...action.payload?.signUpUserInputErrors!],
      };
    }
    default: {
      return state;
    }
  }
};
