/**
 * Get window through a function so we can mock this function in unit test and return a stub window
 */
export function getWindow(): Window {
  return window;
}

/**
 * Get document through a function so we can mock this function in unit test and return a stub document
 */
export function getDocument(): Document {
  return document;
}
