import { HgIndexedDBData } from "./HgIndexedDBData";
import { IProvidesAuthorizationContext } from "./client/authorization";
import { fetchFluorineCrypto, IKeyPairEnvelope } from "./crypto";
import {
  ISignatureAuthStrategy,
  BrowserSignedRequestAuthorizer,
  IBrowserMaterialEnvelope,
  ISignatureAuthorization,
} from "./client/authorization/BrowserSignedRequestAuthorizer";
import { BrowserIOKernel } from "./io/BrowserIOKernel";
import { BMCClient } from "./BMCClient";
import { getHgCompatibleDbData } from "./indexedDb";
import { OPC_REQUEST_ID, HOST, CONTENT_LENGTH, DIGEST, X_DATE, CONTENT_TYPE } from "./signingString";
import { createSessionRequestId } from "../utils/headersUtils";
import { GetAuthorizationUrl } from "./authenticator";

const AUTHORIZATION = "Authorization";
const REALM = "default";

const AUTH_HEADER_WHITELIST = ["request-target", "date", X_DATE, CONTENT_LENGTH, DIGEST, HOST, CONTENT_TYPE];

/**
 * An interface for XMLHttpRequestHeaders.
 *
 * @interface XMLHttpRequestHeaders
 */
export interface XMLHttpRequestHeaders {
  key: string;
  value: string;
  isSet: boolean;
}

/**
 * An interface for XMLHttpRequestWrapper prototypes.
 *
 * @interface XMLHttpRequestWrapperPrototype
 */
export interface XMLHttpRequestWrapperPrototype extends XMLHttpRequest {
  createFakeRequest: { (data?: any): Request };
  attachHeaders: { (httpRequest: Request, callback: Function): void };
  authenticateRequest: { (fakeRequest: Request, callback: Function): Promise<void> };
  isHeaderInWhitelist: { (header: string): boolean };
  __realSetRequestHeader: { (key: string, value: string): void };
  shouldCalculateAuthHeader: { (): Promise<boolean> };
  recoverWhitelistHeader: { (): void };
  __realXmlHttpRequestSend: { (): void };
}

/**
 * An interface for XMLHttpRequestWrapper.
 *
 * @interface XMLHttpRequestWrapper
 */
export interface XMLHttpRequestWrapper extends XMLHttpRequest {
  wrapperUrl: string;
  wrapperMethod: string;
  wrapperHeaders: XMLHttpRequestHeaders[];
  prototype: XMLHttpRequestWrapperPrototype;
}

export function getXmlRequestWrapper(getAuthorizationUrl: GetAuthorizationUrl, requestWrapper: XMLHttpRequest) {
  /**
   * A wrapper for XMLHttpRequest. Adds authentication on send.
   *
   * Note: The "as any as {real type}" was needed because of a type check error. This is how you
   * "extend" prototype functions so they can be compiled down to es5.
   *
   * @class XMLHttpRequestWrapper
   * @extends {XMLHttpRequest}
   */
  const xmlHttpRequestWrapper = requestWrapper as any as XMLHttpRequestWrapper;

  /**
   * Overload for the open method on XMLHttpRequest. Tracks url and method for future use.
   *
   * @memberof XMLHttpRequestWrapper
   */
  let xmlHttpRequestOpen = xmlHttpRequestWrapper.prototype.open;
  xmlHttpRequestWrapper.prototype.open = function (method: string, url: string, isAsync?: boolean) {
    // The url of the XMLHttpRequest. Used further in the stack where the initial url is no longer
    // available.
    this.wrapperUrl = url;
    // The method of the XMLHttpRequest. Used further in the stack where the initial url is no
    // longer available.
    this.wrapperMethod = method;
    // An array of headers from original request
    this.wrapperHeaders = [] as XMLHttpRequestHeaders[];

    if (isAsync === false) {
      throw new Error("Sending request with 'isAsync' to be 'false' is unsupported in Oracle Console.");
    }

    return xmlHttpRequestOpen.apply(this, arguments);
  };

  /**
   * Overload for the setRequestHeader method on XMLHttpRequest. Tracks headers passed for
   * authorization as there's no interface to get request headers from XMLHttpRequest.
   *
   * @memberof XMLHttpRequestWrapper
   */

  xmlHttpRequestWrapper.prototype.__realSetRequestHeader = xmlHttpRequestWrapper.prototype.setRequestHeader;
  xmlHttpRequestWrapper.prototype.setRequestHeader = function (headerKey: string, value: string) {
    let isSet = false;
    // we ignore all AUTH related headers if target request is in whitelist
    // in the local version, we always assume that it should authorize
    if (!this.isHeaderInWhitelist(headerKey)) {
      this.__realSetRequestHeader.apply(this, arguments);
      isSet = true;
    }

    this.wrapperHeaders.push({ key: headerKey, value: value, isSet });
  };

  /**
   * Overload for the send method on XMLHttpRequest. Generates the necessary authorization headers
   * and appends them to the request.
   *
   * @memberof XMLHttpRequestWrapper
   */
  xmlHttpRequestWrapper.prototype.__realXmlHttpRequestSend = xmlHttpRequestWrapper.prototype.send;
  xmlHttpRequestWrapper.prototype.send = async function (data?: any) {
    try {
      console.warn(
        `the DuploAuthentication client XMLHttpRequest method is deprecated. Use the public getOciXmlHttpRequest property of the singleton to fetch a signing XMLHttpRequest`,
      );
      // Additionally apply a whitelist filter.
      if (!(await this.shouldCalculateAuthHeader())) {
        this.recoverWhitelistHeader();
        this.__realXmlHttpRequestSend.apply(this, [data]);
        return;
      }

      const fakeRequest = this.createFakeRequest(data);

      // If data is a string, text will be identical to data. But when
      // data is a FormData, text will be the serialized form of data
      // using the boundary string present in fakeRequest. In this case,
      // if we call __realXmlHttpRequestSend directly on data, browser
      // creates a new boundary string. This causes a signature
      // mismatch. To solve this, we will call __realXmlHttpRequestSend
      // on text
      const text = await fakeRequest.clone().arrayBuffer();

      // XmlHttpRequest.prototype.send returns NOTHING
      // In order to keep the same signature, we will keep this ".then"

      await refreshSecurityToken();

      await this.authenticateRequest(fakeRequest, () => {
        this.__realXmlHttpRequestSend.apply(this, [text]);
      });
    } catch (error) {
      if (this.onerror && typeof this.onerror === "function") {
        this.onerror(error);
      }
      // print error to console in case caller does not implement onerror callback
      console.error(`Failed to send request ${this.wrapperMethod} : ${this.wrapperUrl}.`, error);
    }
  };

  /**
   * Create a fake Request object to perform the authorization logic with and pass it details
   * about the XMLHttpRequest.
   *
   * @param [data] - The send data to pass to the send function.
   *
   * @returns A fake request object.
   * @memberof XMLHttpRequestWrapper
   */
  xmlHttpRequestWrapper.prototype.createFakeRequest = function (data?: any): Request {
    let bodyValue = data;

    // HEAD or GET requests should never have a body. https://github.com/github/fetch/issues/402
    // Otherwise exception will be thrown.
    // If body is empty string/null/undefined, always set it to undefined to make IE11 happy
    if (this.wrapperMethod.toUpperCase() === "GET" || this.wrapperMethod.toUpperCase() === "HEAD" || !bodyValue) {
      bodyValue = undefined;
    }

    let fakeRequest = new Request(this.wrapperUrl, { method: this.wrapperMethod, body: bodyValue });

    // If the bodyValue is of type FormData, the signature would be
    // calculated based on the boundary string present in fakeRequest.
    // Since the user cannot specify this string anyway, we should
    // retain the Content-Type header.
    if (!(bodyValue instanceof FormData)) {
      // if body is a string, even it is a json payload, Request object will add "text/plain;charset=UTF-8" to "content-type" header
      // clear the content type header and let the caller specify the content type
      fakeRequest.headers.delete(CONTENT_TYPE);
      const contentTypeHeader = this.wrapperHeaders.find((header: any) => {
        return header.key.toLowerCase() === CONTENT_TYPE;
      });

      if (contentTypeHeader) {
        fakeRequest.headers.set(CONTENT_TYPE, contentTypeHeader.value);
      }
    }
    return fakeRequest;
  };

  /**
   * Attach the authorization headers to the initial XMLHttpRequest object before send.
   *
   * @param httpRequest - The fake request object with the attached auth headers.
   * @param callback - A callback function that gets called after auth has completed.
   * @memberof XMLHttpRequestWrapper
   */
  xmlHttpRequestWrapper.prototype.attachHeaders = async function (httpRequest: Request, callback: Function) {
    let hasOpcId = false;

    const authKeysMap: { [key: string]: boolean } = {};
    // Apply the generated authorization headers to the XMLHttpRequest object.
    httpRequest.headers.forEach((value: any, key: any) => {
      xmlHttpRequestWrapper.prototype.__realSetRequestHeader.apply(this, [key, value]);
      // all the keys from httpRequest are lowercase
      authKeysMap[key] = true;
    });

    // Apply original header sets to XMLHttpRequest object
    this.wrapperHeaders.forEach((header: any) => {
      const headerKeyLower = header.key.toLowerCase();
      if (headerKeyLower === OPC_REQUEST_ID) {
        hasOpcId = true;
      } else if (!authKeysMap[headerKeyLower] && !header.isSet) {
        // ignore auth headers from original header set
        xmlHttpRequestWrapper.prototype.__realSetRequestHeader.apply(this, [header.key, header.value]);
      }
    });

    if (!hasOpcId) {
      const sessionRequestId = await createSessionRequestId(REALM);
      xmlHttpRequestWrapper.prototype.__realSetRequestHeader.apply(this, [OPC_REQUEST_ID, sessionRequestId]);
    }

    callback();
  };

  /**
   * Handle authenticating the request on send.
   *
   * @param fakeRequest - A request object containing all the
   * @param callback - A callback function that gets called after auth has completed.
   * @memberof XMLHttpRequestWrapper
   */
  xmlHttpRequestWrapper.prototype.authenticateRequest = async function (
    fakeRequest: Request,
    callback: Function,
  ): Promise<void> {
    const hgCompatibleDbData = await getHgCompatibleDbData(REALM);
    const factory = getAuthenticationFactory(hgCompatibleDbData);
    this.authorizer = new BrowserSignedRequestAuthorizer(fetchFluorineCrypto, factory);
    const context = await this.authorizer.contextFactory.createAuthorizationContext(fakeRequest);
    const authorization = await this.authorizer.createAuthorization(context);
    const httpRequest = await this.authorizer.attachAuthorization(context, authorization);
    await this.attachHeaders(httpRequest, callback);
  };

  /**
   * Check to see if a header is in the whitelist. It will also return true if the header is
   * authorization; We do this so we can later avoid double signing requests.
   *
   * Note: Exported so we can test it.
   *
   * @export
   * @param header The header name to check for.
   * @memberof XMLHttpRequestWrapper
   *
   * @returns True if the header is in the whitelist. False otherwise.
   */
  xmlHttpRequestWrapper.prototype.isHeaderInWhitelist = function (header: string): boolean {
    header = header.toLowerCase();
    const authHeader = AUTHORIZATION.toLowerCase();
    return !!AUTH_HEADER_WHITELIST.find(
      // Prevent a bug where future whitelist headers could be added as not all lowercase by
      // forcing both to be lowercase.
      whitelistHeader => {
        whitelistHeader = whitelistHeader.toLowerCase();
        return whitelistHeader === header || header === authHeader;
      },
    );
  };

  /**
   * We will calculate AUTH header if target Url falls into our list and
   *  - it doesn't come with an Authorization header (old behavior)
   *    or
   *  - it come with an Authorization header that contains a security token which match the one in our database
   */
  xmlHttpRequestWrapper.prototype.shouldCalculateAuthHeader = async function (): Promise<boolean> {
    // this is not applied globally, so any time its used, this statement should be ignored.
    // if (!shouldAuthorize(this.wrapperUrl)) {
    //   return false;
    // }

    const authorizationHeaderLower = AUTHORIZATION.toLocaleLowerCase();
    const authorizationHeader = this.wrapperHeaders.find((header: XMLHttpRequestHeaders) => {
      return header.key.toLowerCase() === authorizationHeaderLower;
    });

    if (!authorizationHeader) {
      return true;
    }

    const hgCompatibleDbData = await getHgCompatibleDbData(REALM);
    return authorizationHeader.value && authorizationHeader.value.indexOf(hgCompatibleDbData.security_token) > 0;
  };

  /**
   * For request that we should not intercept their AUTH signing, we should not touch any of their headers.
   * So there we put back those that we filter out.
   */
  xmlHttpRequestWrapper.prototype.recoverWhitelistHeader = function (): void {
    this.wrapperHeaders.forEach((header: XMLHttpRequestHeaders) => {
      if (!header.isSet) {
        this.__realSetRequestHeader(header.key, header.value);
        header.isSet = true;
      }
    });
  };

  function getAuthenticationFactory(
    hgCompatibleDbData: HgIndexedDBData,
  ): IProvidesAuthorizationContext<ISignatureAuthStrategy, IBrowserMaterialEnvelope> {
    return {
      createAuthorizationContext: request => {
        return Promise.resolve({
          materials: Object.assign(
            {},
            {
              materials: {
                privateKey: hgCompatibleDbData.private,
                publicKey: hgCompatibleDbData.public,
              },
            } as IKeyPairEnvelope,
            { securityToken: hgCompatibleDbData.security_token },
          ),
          request: request,
        });
      },
    };
  }

  /**
   * Function for TokenRefreshHandler.tryRefresh
   *
   * @param request
   * @memberof XMLHttpRequestWrapper
   */
  async function refreshSecurityToken(): Promise<Response> {
    const hgCompatibleDbData = await getHgCompatibleDbData(REALM);
    const factory = getAuthenticationFactory(hgCompatibleDbData);
    const authorizer = new BrowserSignedRequestAuthorizer(fetchFluorineCrypto, factory);
    const client = new BMCClient<ISignatureAuthStrategy, IBrowserMaterialEnvelope, ISignatureAuthorization>(
      REALM,
      getAuthorizationUrl,
      new BrowserIOKernel(),
      authorizer,
    );
    // fetch a empty request to trigger token refresh only
    return client.fetch(undefined);
  }
  return xmlHttpRequestWrapper;
}
