import { FormDispatch } from "../components/Context/ApplicationContext";
import { getAPIResponseMetadata, updateApiErrorDetails } from "./CommonUtil";
import { base_url } from "../config/apiConfig";
import { Dispatch } from "../Type";
import { OrderLifeCycleState } from "../constants";
import { fetchOrders } from "../CourierService/FetchService";
import Log from "../CourierService/Logger";
import {
  ApiMetricType,
  emitEvent,
  emitLatencyEvent,
  getApiMetricName,
  MetricPrefixes,
} from "../CourierService/metrics";
import { hasJsonContent } from "./PromiseUtil";

export const validateActivationToken = async (
  token: string | null,
  loading: boolean,
  dispatch: FormDispatch,
): Promise<any> => {
  let single: any;
  if (!token) {
    dispatch({
      type: Dispatch.GETTING_AUTH,
      payload: false,
    });
    dispatch({
      type: Dispatch.UPDATE_NONAUTH,
      payload: true,
    });
    return [undefined, ""];
  }
  if (loading) {
    const fetchOrdersStartTime = new Date().getTime();
    try {
      const res = await fetchOrders(token);
      emitLatencyEvent(
        getApiMetricName(MetricPrefixes.ActivationValidateToken, ApiMetricType.Latency),
        fetchOrdersStartTime,
      );
      const responseMetadata = await getAPIResponseMetadata(res);
      if (res.status === 200) {
        const result = hasJsonContent(res) ? await res.json() : res;
        single = {
          ...result?.items[0],
        };
        emitEvent(getApiMetricName(MetricPrefixes.ActivationValidateToken, ApiMetricType._2xx));
        Log.info("Activation Validation token successful", responseMetadata);
        //check order status
        if (single) {
          if (!single.orderState || single.orderState !== OrderLifeCycleState.pendingActivation) {
            dispatch({
              type: Dispatch.UPDATE_NONPENDING,
              payload: single.orderState || false,
            });
          }
        }
        dispatch({
          type: Dispatch.GETTING_AUTH,
          payload: false,
        });
      } else {
        Log.error(
          `Activation Token validation error - ${base_url}/orders?token=${token}:`,
          res.status,
          responseMetadata,
        );
        updateApiErrorDetails(`${base_url}/orders?token=${token}`, dispatch, res);
        if (res.status === 403) {
          dispatch({
            type: Dispatch.UPDATE_NONAUTH403,
            payload: true,
          });
          emitEvent(getApiMetricName(MetricPrefixes.ActivationValidateToken, ApiMetricType._403));
        } else if (res.status >= 400 && res.status <= 499) {
          dispatch({
            type: Dispatch.UPDATE_NONAUTH,
            payload: true,
          });
          emitEvent(getApiMetricName(MetricPrefixes.ActivationValidateToken, ApiMetricType._4xx));
        } else if (res.status >= 500) {
          dispatch({
            type: Dispatch.UPDATE_NONAUTH,
            payload: true,
          });
          emitEvent(getApiMetricName(MetricPrefixes.ActivationValidateToken, ApiMetricType._5xx));
        }
      }
    } catch (error) {
      Log.error(`Activation Token validation fail - ${base_url}/orders?token=${token} -`, error);
      dispatch({
        type: Dispatch.UPDATE_NONAUTH,
        payload: true,
      });
      updateApiErrorDetails(`${base_url}/orders?token=${token}`, dispatch);
      emitEvent(getApiMetricName(MetricPrefixes.ActivationValidateToken, ApiMetricType.Exception));
      emitLatencyEvent(
        getApiMetricName(MetricPrefixes.ActivationValidateToken, ApiMetricType.Latency),
        fetchOrdersStartTime,
      );
    }
  }
  return single;
};
