import { CourierServiceMetadata, Severity } from "./CourierServiceTransport";
import CourierTransport from "./CourierServiceTransport";

class CourierServiceClientWrapper {
  azureTenantID: string | undefined;
  awsAccountID: string | undefined;
  awsUserPoolID: string | undefined;
  externalID: string | undefined;
  ociTenantID: string | undefined;

  setAzureTenantID(value: string): void {
    this.azureTenantID = value ?? "";
  }

  setAwsAccountID(value: string): void {
    this.awsAccountID = value ?? "";
  }

  setAwsUserPoolID(value: string): void {
    this.awsUserPoolID = value ?? "";
  }

  setExternalID(value: string): void {
    this.externalID = value ?? "";
  }

  setOciTenantID(value: string): void {
    this.ociTenantID = value ?? "";
  }

  getMetadata(): CourierServiceMetadata {
    const metadata: CourierServiceMetadata = [];
    this.azureTenantID &&
      metadata.push({ key: "azureTenantID", value: this.azureTenantID, type: "string" });
    this.awsAccountID &&
      metadata.push({ key: "awsAccountID", value: this.awsAccountID, type: "string" });
    this.awsUserPoolID &&
      metadata.push({ key: "awsUserPoolID", value: this.awsUserPoolID, type: "string" });
    this.externalID && metadata.push({ key: "externalID", value: this.externalID, type: "string" });
    this.ociTenantID &&
      metadata.push({ key: "ociTenantID", value: this.ociTenantID, type: "string" });
    return metadata;
  }

  log(level: Severity, message: string, metadata?: CourierServiceMetadata): void {
    CourierTransport.processLog(
      level,
      message,
      metadata ? this.getMetadata().concat(metadata) : this.getMetadata(),
    );
  }

  sendAction(action: string, value: string, metadata?: CourierServiceMetadata): void {
    CourierTransport.processAction(
      action,
      value,
      metadata ? this.getMetadata().concat(metadata) : this.getMetadata(),
    );
  }
}

const courierServiceClient = new CourierServiceClientWrapper();

const Log = {
  info: (message: string, metadata?: CourierServiceMetadata) =>
    courierServiceClient.log(Severity.INFO, message, metadata),
  warn: (message: string, metadata?: CourierServiceMetadata) =>
    courierServiceClient.log(Severity.WARN, message, metadata),
  error: (message: string, content?: any, metadata?: CourierServiceMetadata, userError?: boolean) =>
    courierServiceClient.log(
      Severity.ERROR,
      `${message}  ${content ? JSON.stringify(content) : ""}`,
      userError
        ? [...(metadata || []), { key: "userError", value: "true", type: "string" }]
        : metadata,
    ),
  sendAction: (action: string, value: string, metadata?: CourierServiceMetadata) =>
    courierServiceClient.sendAction(action, value, metadata),
  setAzureTenantID: (value: string) => courierServiceClient.setAzureTenantID(value),
  setAwsAccountID: (value: string) => courierServiceClient.setAwsAccountID(value),
  setAwsUserPoolID: (value: string) => courierServiceClient.setAwsUserPoolID(value),
  setExternalID: (value: string) => courierServiceClient.setExternalID(value),
  setOciTenantID: (value: string) => courierServiceClient.setOciTenantID(value),
};

export default Log;
