import { isEqual } from "lodash";
import { SignUpURLs, Environment, base_url } from "../config/apiConfig";
import { CREATE_ACCOUNT_O4A, CREATE_ACCOUNT_O4AWS } from "../constants";
import { CloudLinkType } from "../Type";
import { hostname } from "./BrowserHelpers";
import { getCloudType } from "./CommonUtil";

type SignupBackendCloudRequest = { [key in CloudLinkType]: string };

type SignupBackendCloudRequestValue = {
  [key in SignupBackendRequestType]?: SignupBackendCloudRequest;
};

export const isMiglabEnv = () => isEqual(hostname, SignUpURLs.MIGLAB);

export const isPintlabEnv = () => isEqual(hostname, SignUpURLs.PINTLAB);

export const isLocalhostEnv = () => window.location.origin?.includes(Environment.LOCALHOST);

export const isStagingEnv = () => isEqual(hostname, SignUpURLs.STAGE);

export const getSignupBackendUrl = (type: SignupBackendRequestType): string => {
  const cloudType = getCloudType();
  const cloudRequest = signupBackendRequest[type];
  const requestName = cloudRequest ? cloudRequest[cloudType] : type;
  return `${base_url}/${requestName}`;
};

export type SignupBackendRequestType =
  | "accountStatusO4A"
  | "banner"
  | "createAccount"
  | "orders"
  | "payments"
  | "regions"
  | "states"
  | "sendAssociatedAccountsByEmail"
  | "validateAccountName"
  | "validateEmail"
  | "verifyCaptchaToken";

const signupBackendRequest: SignupBackendCloudRequestValue = {
  createAccount: {
    AZURE: CREATE_ACCOUNT_O4A,
    AWS: CREATE_ACCOUNT_O4AWS,
    GCP: "",
  },
};
