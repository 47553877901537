import * as React from "react";
import InputText from "./InputText/InputText";
import Button from "./Button/Button";
import "./CloudSigninCard.css";

/*
Ideally, the english localization values would be this
{
  cloudAccountName: "Cloud Account Name",
  next: "Next",
  forgotYourCloudAccountName: "Forgot your cloud account name? ",
  getHelp: "Get help",
  notAnOracleCloudCustomer: "Not an Oracle Cloud customer yet?",
  signUp: "Sign Up",
}
*/
export interface LocalizationValues {
  cloudAccountName: string;
  next: string;
  forgotYourCloudAccountName: string;
  getHelp: string;
  notAnOracleCloudCustomer: string;
  signUp: string;
}

export interface CloudSigninCardProps {
  localizationValues: LocalizationValues;
  initialTenant?: string;
}

const DEFAULT_ACCOUNT_HELP_URL = "https://myaccount.cloud.oracle.com/mycloud/faces/getinfo.jspx";
const REDIRECT_URI_QUERY_PARAM_KEY = "redirect_uri";
const TENANT_QUERY_PARAM_KEY = "tenant";
const MAX_TENANT_LENGTH = 64;

const windowLocation = window.location;
function CloudSigninCard({ localizationValues, initialTenant }: CloudSigninCardProps) {
  const [tenant, setTenant] = React.useState<string>(initialTenant || "");

  const setAndCleanTenancy = (value: string): void => {
    const tenantName = value.replace(/[^_a-zA-Z0-9]/g, "").toLowerCase();
    setTenant(tenantName);
  };

  const handleSubmitClick = (): void => {
    const params = new URLSearchParams(windowLocation.search);
    params.delete(TENANT_QUERY_PARAM_KEY);
    params.append(TENANT_QUERY_PARAM_KEY, tenant);

    windowLocation.replace(`${windowLocation.pathname}?${params.toString()}`);
  };

  const handleSignupClick = (): void => {
    const baseUrl = "https://signup.cloud.oracle.com/";

    const currentParams = new URLSearchParams(windowLocation.search);
    const newParams = new URLSearchParams();

    const redirectUri = currentParams.has(REDIRECT_URI_QUERY_PARAM_KEY)
      ? currentParams.get(REDIRECT_URI_QUERY_PARAM_KEY)
      : window.location.href;
    newParams.append(REDIRECT_URI_QUERY_PARAM_KEY, redirectUri);

    windowLocation.replace(`${baseUrl}?${newParams.toString()}`);
  };

  return (
    <div className="container-fluid home-container">
      <div className="signInModal">
        <div className="signInContainer">
          <div className="redwood-icon">
            <span>Oracle</span>
          </div>
          <div className="title">Cloud</div>
          <div className="cloudAccountName">{localizationValues.cloudAccountName}</div>

          <div className="field-wrapper">
            <InputText
              type="text"
              placeholder=" "
              name="tenancyName"
              value={tenant}
              maxLength={MAX_TENANT_LENGTH}
              valid
              required
              onChange={(e: React.FocusEvent<HTMLInputElement>) =>
                setAndCleanTenancy(e?.target?.value)
              }
              testId="tenancy-name-input"
            />
          </div>
          <div className="row logout-row">
            <Button
              className="submit"
              type="solid"
              disabled={tenant?.trim()?.length === 0}
              onClick={handleSubmitClick}
              text={localizationValues.next}
              data-testid="submit-button"
            />
          </div>
          <div className="signInSubtitle">
            {localizationValues.forgotYourCloudAccountName}
            <a href={DEFAULT_ACCOUNT_HELP_URL}>{localizationValues.getHelp}</a>
          </div>
          <hr />
          <div className="signInTitle2">{localizationValues.notAnOracleCloudCustomer}</div>
          <Button
            className="sign-up"
            type="solid"
            onClick={handleSignupClick}
            text={localizationValues.signUp}
            data-testid="signup-button"
          />
        </div>
      </div>
    </div>
  );
}

export default CloudSigninCard;
