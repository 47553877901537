import React, { useState } from "react";
import { CloudLinkType } from "../../Type";
import { getCloudType } from "../../utils/CommonUtil";
import { useFormState } from "../Context/ApplicationContext";
import AwsLoadingRoom from "./AwsLoadingRoom";
import AzureLoadingRoom from "./AzureLoadingRoom";
import GcpLoadingRoom from "./GcpLoadingRoom";

performance.mark("Render.Loading");

type LoadingRoomContainerProps = {
  show?: boolean;
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
  hideSpinner?: boolean;
  delay?: number;
  errorPayload?: Error;
  controlHeight?: boolean;
  enableAwsBanner?: boolean;
};

const LoadingRoomContainer = ({
  show,
  title,
  subtitle,
  showHeader,
  showFooter,
  hideSpinner,
  delay,
  errorPayload,
  controlHeight,
  enableAwsBanner,
}: LoadingRoomContainerProps) => {
  const { loading, nonAuth, nonPending } = useFormState();
  const [lazyLoad, setLazyLoad] = useState(false);

  const cloudType = getCloudType();

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setLazyLoad(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  if (lazyLoad && (show || (loading && !nonAuth && !nonPending))) {
    switch (cloudType) {
      case CloudLinkType.AWS:
        return (
          <AwsLoadingRoom
            title={title}
            subtitle={subtitle}
            showHeader={showHeader}
            showFooter={showFooter}
            hideSpinner={hideSpinner}
            errorPayload={errorPayload}
            controlHeight={controlHeight}
            enableAwsBanner={enableAwsBanner}
          />
        );
      case CloudLinkType.AZURE:
        return (
          <AzureLoadingRoom
            title={title}
            subtitle={subtitle}
            showHeader={showHeader}
            hideSpinner={hideSpinner}
          />
        );
      case CloudLinkType.GCP:
        return (
          <GcpLoadingRoom
            title={title}
            subtitle={subtitle}
            showHeader={showHeader}
            showFooter={showFooter}
            hideSpinner={hideSpinner}
            errorPayload={errorPayload}
          />
        );
      default:
        return <></>;
    }
  } else {
    return <></>;
  }
};

export default LoadingRoomContainer;
