import * as React from "react";
import { CloudType, HookError } from "../models/types";
import {
  getBootstrapRegionList,
  getIdentityRegionSubscriptionsEndpoint,
  getMchubCloudLinksEndpoint,
  getMchubGetCloudLinksEndpoint,
  getMchubListProjectsEndpoint,
  getMchubRegionsEndpoint,
} from "../utils/EnvUtils";
import { Log, SimpleLoggerInterface } from "../utils/Log";
import { defaultGetRequestSupplier, FetchApiSignature } from "../utils/RequestUtils";
import { fetchWithRegionFailover } from "../utils/RetryUtils";

/**
 * Utility method that lets the client call and handle the response directly
 */
export const getOciSubscribedRegionsResponse = async (
  fetchApi: FetchApiSignature,
  ociHomeRegion: string,
  ociTenantId: string,
): Promise<Response> =>
  fetchApi(
    defaultGetRequestSupplier(getIdentityRegionSubscriptionsEndpoint(ociHomeRegion, ociTenantId)),
  );

export const getOciSubscribedRegions = async (
  fetchApi: FetchApiSignature,
  ociHomeRegion: string,
  ociTenantId: string,
): Promise<string[]> => {
  const res = await getOciSubscribedRegionsResponse(fetchApi, ociHomeRegion, ociTenantId);

  if (res.ok) {
    return (await res.json()).map((val: { regionName: string }) => val.regionName);
  }
  throw new Error(`Unexpected response when attempting to fetch ociSubscribedRegions: ${res}`);
};

export const determineUseableRegionsList = (
  ociHomeRegion: string,
  ociSubscribedRegions: string[],
  mchubSupportedRegions: string[],
): string[] => {
  let filteredRegions = [...ociSubscribedRegions];
  filteredRegions = filteredRegions.filter(region => mchubSupportedRegions.includes(region));
  if (filteredRegions.includes(ociHomeRegion)) {
    const homeRegionIndex = filteredRegions.findIndex(r => r === ociHomeRegion);
    filteredRegions.unshift(filteredRegions.splice(homeRegionIndex, 1)[0]);
  }
  Log().info(`Regions that are suitable for retry calls: ${filteredRegions}`);
  return filteredRegions;
};

export const getMchubSupportedRegionsResponse = async (cloudType: CloudType): Promise<Response> => {
  const bootstrapRegionList = getBootstrapRegionList(cloudType);

  return fetchWithRegionFailover(
    fetch,
    getMchubRegionsEndpoint.bind(null, cloudType),
    defaultGetRequestSupplier,
    bootstrapRegionList,
  );
};

export const getMchubSupportedRegions = async (cloudType: CloudType): Promise<string[]> => {
  const res = await getMchubSupportedRegionsResponse(cloudType);
  if (res.status === 200) {
    const homeRegionsFromApi = await res.json();
    const homeRegionList = homeRegionsFromApi
      ? homeRegionsFromApi.items?.map((val: { name: string }) => val.name)
      : [];
    Log().info(`Obtained Mchub supported home region list: ${homeRegionList}`);
    return homeRegionList;
  }
  return [];
};

export interface FetchMulticloudLinksProps {
  ociFetchApi: FetchApiSignature;
  cloudType: CloudType;
  ociHomeRegion: string;
  ociTenantId: string;
  ociSubscribedRegions?: string[];
  logger?: SimpleLoggerInterface;
}

export type GetMulticloudLinkProps = FetchMulticloudLinksProps & { cloudLinkId: string };
export type ListProjectProps = GetMulticloudLinkProps;

export const getMultiCloudLink = async (props: GetMulticloudLinkProps): Promise<Response> => {
  // Init logger if present
  if (props?.logger) {
    Log(props.logger);
  }

  let subscribedRegions = props.ociSubscribedRegions || [];

  if (subscribedRegions.length === 0) {
    subscribedRegions = await getOciSubscribedRegions(
      props.ociFetchApi,
      props.ociHomeRegion,
      props.ociTenantId,
    ); // if not already provided
  }
  const mchubSupportedRegions: string[] = await getMchubSupportedRegions(props.cloudType);

  const regionsList = determineUseableRegionsList(
    props.ociHomeRegion,
    subscribedRegions,
    mchubSupportedRegions,
  );

  return fetchWithRegionFailover(
    props.ociFetchApi,
    getMchubGetCloudLinksEndpoint.bind(null, props.cloudLinkId),
    defaultGetRequestSupplier,
    regionsList,
  );
};

export const listProjectsByCloudLink = async (props: ListProjectProps): Promise<Response> => {
  // Init logger if present
  if (props?.logger) {
    Log(props.logger);
  }

  let subscribedRegions = props.ociSubscribedRegions || [];

  if (subscribedRegions.length === 0) {
    subscribedRegions = await getOciSubscribedRegions(
      props.ociFetchApi,
      props.ociHomeRegion,
      props.ociTenantId,
    ); // if not already provided
  }
  const mchubSupportedRegions: string[] = await getMchubSupportedRegions(props.cloudType);

  const regionsList = determineUseableRegionsList(
    props.ociHomeRegion,
    subscribedRegions,
    mchubSupportedRegions,
  );

  return fetchWithRegionFailover(
    props.ociFetchApi,
    getMchubListProjectsEndpoint.bind(null, props.cloudLinkId),
    defaultGetRequestSupplier,
    regionsList,
  );
};

export const listMultiCloudLinks = async (props: FetchMulticloudLinksProps): Promise<Response> => {
  // Init logger if present
  if (props?.logger) {
    Log(props.logger);
  }

  let subscribedRegions = props.ociSubscribedRegions || [];

  if (subscribedRegions.length === 0) {
    subscribedRegions = await getOciSubscribedRegions(
      props.ociFetchApi,
      props.ociHomeRegion,
      props.ociTenantId,
    ); // if not already provided
  }
  const mchubSupportedRegions: string[] = await getMchubSupportedRegions(props.cloudType);

  const regionsList = determineUseableRegionsList(
    props.ociHomeRegion,
    subscribedRegions,
    mchubSupportedRegions,
  );

  return fetchWithRegionFailover(
    props.ociFetchApi,
    getMchubCloudLinksEndpoint.bind(null, props.cloudType, props.ociTenantId),
    defaultGetRequestSupplier,
    regionsList,
  );
};

// Hook to wrap asynchronous fetch call
export const useMulticloudLinks = (props: FetchMulticloudLinksProps) => {
  const [error, setError] = React.useState<HookError>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [response, setResponse] = React.useState<Response>(undefined);

  const refresh = React.useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const res = await listMultiCloudLinks({ ...props });
      setResponse(res);
    } catch (e: any) {
      setError({ status: e?.status, message: e?.message });
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    error,
    loading,
    refresh,
    response,
  };
};
