import CustomErrorStatus from "../enums/customErrorStatus";

export default class ErrorUtils {
  public static userNotLoggedInError() {
    return new Error(CustomErrorStatus.NotloggedIn);
  }

  public static authNotInitializedError() {
    return new Error(CustomErrorStatus.AuthNotInitialized);
  }

  public static missingInfoError() {
    return new Error(CustomErrorStatus.MissingInfo);
  }
}
