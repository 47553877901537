/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from "react";
import "./Icon.css";

export enum IconType {
  WARNING = "warning",
  INFO = "info",
  ERROR = "error",
  CLOSE = "close",
  GREENCHECK = "greenCheck",
  GRAYCHECK = "grayCheck",
  REDCHECK = "redCheck",
  ACCORDIONARROWUP = "accordionArrowUp",
  ACCORDIONARROWDOWN = "accordionArrowDown",
}

type IconProps = {
  name: IconType;
  onClick?: any;
  testId?: string;
};

// eslint-disable-next-line react/prefer-stateless-function
const Icon: React.FC<IconProps> = (props: IconProps) => (
  <div className={`icon ${props.name}`} onClick={props.onClick} data-testid={props.testId} />
);

export default Icon;
