import { LogLevel } from "./models/LogLevel";
import { OraclePublicCloudAuthenticatorSingleton } from "../fluorine/authenticator/OraclePublicCloudAuthenticatorSingleton";

export default class Logger {
  private logger: (realm: string, logLevel: LogLevel, message: string) => void;

  constructor(private realm: string) {
    this.logger = OraclePublicCloudAuthenticatorSingleton.getInstance(this.realm).Config.log;
  }

  private log(logLevel: LogLevel, message: string) {
    if (this.logger) {
      this.logger(this.realm, logLevel, message);
    } else {
      console.log(message, logLevel);
    }
  }

  public info(message: string) {
    this.log(LogLevel.Info, message);
  }

  public error(message: string) {
    this.log(LogLevel.Error, message);
  }

  public debug(message: string) {
    this.log(LogLevel.Debug, message);
  }

  public warn(message: string) {
    this.log(LogLevel.Warning, message);
  }
}
