import UrlPlaceholders from "./urlPlaceholders";

class UrlConstants {
  /**
   * The base URL for auth endpoints.
   */
  public static readonly GOOGLE_OAUTH2_AUTH_BASE_URL =
    "https://accounts.google.com/o/oauth2/v2/auth";

  /**
   * The base endpoint to revoke tokens.
   */
  public static readonly GOOGLE_OAUTH2_REVOKE_URL = "https://oauth2.googleapis.com/revoke";

  public static readonly GcpAuthURLTemplate = `${UrlConstants.GOOGLE_OAUTH2_AUTH_BASE_URL}?client_id=${UrlPlaceholders.ClientIdPlaceholder}&response_type=code&scope=${UrlPlaceholders.ScopePlaceholder}&redirect_uri=${UrlPlaceholders.RedirectUriPlaceholder}&code_challenge=${UrlPlaceholders.CodeChallengePlaceholder}&code_challenge_method=S256&state=${UrlPlaceholders.StatePlaceholder}&access_type=offline&prompt=consent`;

  public static readonly GcpOAuth2RevokeTokenURLTemplate = `${UrlConstants.GOOGLE_OAUTH2_REVOKE_URL}?token=${UrlPlaceholders.TokenPlaceholder}`;
}

export default UrlConstants;
