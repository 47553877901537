/* eslint-disable */
import _ from "lodash";
import { Region } from "oci-console-regions";
import { base_url } from "../config/apiConfig";
import { CloudLinkType } from "../Type";
import { getSignupBackendUrl } from "../utils/ApiConfigUtil";
import { getMchubEndpoint } from "../utils/EnvironmentConfig";

export const fetchRegions = (cloudLinkType: CloudLinkType, urlRegion?: Region): Promise<Response> =>
  fetch(`${getMchubEndpoint(urlRegion)}/regions?cloudLinkType=${cloudLinkType}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });

export const fetchAssociatedAccounts = (
  email: string,
  controller: AbortController,
): Promise<Response> =>
  fetch(`${base_url}/sendAssociatedAccountsByEmail?email=${email}`, {
    method: "GET",
    signal: controller.signal,
    headers: { "Content-Type": "application/json" },
  });

export const fetchBanner = (): Promise<Response> =>
  fetch(`${base_url}/banner`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });

export const fetchAccountNameValidation = (nameAttempt: string): Promise<Response> => {
  return fetch(`${base_url}/validateAccountName`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ name: nameAttempt }),
  });
};

export const fetchCaptchaToken = (body: string): Promise<Response> =>
  fetch(`${base_url}/verifyCaptchaToken`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body,
  });

export const fetchValidateEmail = (body: string): Promise<Response> =>
  fetch(`${base_url}/validateEmail`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body,
  });

export const fetchStateId = (country: string): Promise<Response> =>
  fetch(`${base_url}/states?countryId=${country}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });

export const fetchPaymentsByEmail = (email: string, body: string): Promise<Response> =>
  fetch(`${base_url}/payments?email=${email}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body,
  });

export const fetchAccountStatusByWorkRequest = (
  requestId: string,
  region: string,
): Promise<Response> =>
  fetch(`${base_url}/accountStatusO4A?workRequestId=${requestId}&region=${region}`, {
    method: "GET",
    credentials: "include",
    headers: { "Content-Type": "text/plain" },
  });

export const fetchOrders = (token: string): Promise<Response> => {
  return fetch(`${base_url}/orders?token=${token}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const postCreateAccountRequest = (email: string, body: string): Request =>
  new Request(`${getSignupBackendUrl("createAccount")}?email=${email}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body,
  });
