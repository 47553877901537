export interface SimpleLoggerInterface {
  info: (value: string) => void;
  warn: (value: string) => void;
  error: (value: string) => void;
}

class Logger {
  private static _logger: SimpleLoggerInterface;

  private constructor(logger: SimpleLoggerInterface) {
    Logger._logger = logger;
  }

  public static instance(logger?: SimpleLoggerInterface): SimpleLoggerInterface {
    if (logger) {
      Logger._logger = logger;
    }

    if (Logger._logger) {
      return Logger._logger;
    }
    if (!logger) {
      Logger._logger = {
        info: _ => {},
        warn: _ => {},
        error: _ => {},
      } as SimpleLoggerInterface;
    }
    return Logger._logger;
  }

  public static info(value: string) {
    Logger._logger.info(value);
  }
}

export const Log = Logger.instance;
