import { ClickDetail } from "@awsui/components-react/internal/events/";
import * as React from "react";
import { useEffect, useState } from "react";
import { Flashbar } from "../../index";
import { useWindow } from "../../utils/browser";
import { genGuid } from "../../utils/guid";
import { Notification, NotificationInstance } from "./Notifications";

type NotificationsHubProps = {
  notificationInstance: NotificationInstance;
  onInitialize(): void;
};

function NotificationsHub({
  notificationInstance: not,
  onInitialize,
}: NotificationsHubProps): JSX.Element {
  const windowRef = useWindow();
  const [notifications, setNotifications] = useState<Notification[]>([]);

  function add(notification: Notification): void {
    setNotifications(currNotifications => [...currNotifications, notification]);

    if (notification.timeoutInMs) {
      windowRef.setTimeout(() => {
        remove(notification.id);
      }, notification.timeoutInMs);
    }
  }

  function remove(notId: string): void {
    setNotifications(currNot => currNot.filter(cn => cn.id !== notId));
  }

  function initManagerSet(): void {
    not.setNotification = (notification: Notification) => {
      const notId = notification.id || genGuid();
      add({
        ...notification,
        id: notId,
        dismissible: !notification.timeoutInMs ? true : notification.dismissible,
        onDismiss: (event: CustomEvent<ClickDetail>) => {
          if (notification.onDismiss) {
            notification.onDismiss(event);
          }
          remove(notId);
        },
      });
    };
    not.clearAll = () => setNotifications([]);
  }

  useEffect(() => {
    initManagerSet();
    onInitialize();
  }, [not]);

  return <Flashbar items={notifications} />;
}

export default NotificationsHub;
