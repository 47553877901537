import * as React from "react";
import { Button, ButtonProps } from "../../index";
import { BaseProps } from "../../models/baseProps";
import { useWindow } from "../../utils/browser";

export interface Props extends BaseProps, Pick<ButtonProps, "variant" | "iconName" | "iconAlign"> {
  /**
   * The text that should be copied into the clipboard
   */
  text?: string;

  /**
   * The initial label
   */
  copyLabel?: string;

  /**
   * The label that conveys that the text has been copied into the clipboard
   */
  copiedLabel?: string;
}

function ClipboardButton({
  text,
  copyLabel,
  copiedLabel,
  variant,
  iconName,
  iconAlign,
  testId,
}: Props): JSX.Element {
  return (
    <GenericClipboard text={text}>
      {(copy, copied) => {
        const alertAndCopy = () => {
          copy();
        };
        return (
          <Button
            variant={variant ?? "link"}
            onClick={alertAndCopy}
            iconName={iconName}
            iconAlign={iconAlign}
            data-testid={testId}
          >
            {copied ? copiedLabel : copyLabel}
          </Button>
        );
      }}
    </GenericClipboard>
  );
}

type GenericClipboardProps = {
  children(_copy: () => void, _copied: boolean): React.ReactNode;
} & Pick<Props, "text">;

function GenericClipboard({ children, text }: GenericClipboardProps): JSX.Element {
  const [copied, setCopied] = React.useState(false);
  const window = useWindow();

  const copy = () => {
    window.navigator.clipboard.writeText(text);
    setCopied(true);
    window.setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return <>{children(copy, copied)}</>;
}

export default ClipboardButton;
