import { Auth, AuthConfig } from "../authentication/Auth";
import { OraclePublicCloudAuthenticatorSingleton } from "duplo-authentication";
import { AzAuth } from "loom-az-auth-service";
import { GcpAuth } from "gcp-auth-core";
import {
  AZURE_AUTHORITY,
  AZURE_ROOT_PATH,
  GcpPaths,
  GCP_SCOPES,
  LOCALHOST_URL,
  OCI,
  SESSION_URL,
} from "../constants";
import { LogLevel, SessionStorage } from "../Type";
import Log from "../CourierService/Logger";
import { emitEvent } from "../CourierService/metrics";
import { AwsAuth } from "aws-auth-core";
import { getAzureAppId, getGcpClientId } from "../utils/EnvironmentConfig";
import {
  buildAuthorizationUrl,
  buildConsoleUrl,
  buildIdentityUrl,
  buildLoginUrl,
  saveQueryStringToSessionStorage,
} from "./urlUtils";
import { isLocalhostEnv } from "../utils";
import { MulticloudTelemetryHelper } from "multi-cloud-shared-components";
import MetricNames from "../telemetry/MetricNames";

export function initializeAuthAzure(automated?: boolean) {
  // TODO: Fix this to handle the pathname and and slash ending
  saveQueryStringToSessionStorage(true);
  let redirectUri = window.location.href;
  const urlParts = redirectUri.split("?");
  if (urlParts.length > 1) {
    redirectUri = urlParts[0];
  }
  const azureAppId = getAzureAppId(automated);
  window.sessionStorage.setItem(SESSION_URL, redirectUri); // Storing the original URL in session storage to help rebuild the search parameters once redirected back.
  return AzAuth.init({
    msalConfig: {
      auth: {
        clientId: azureAppId,
        authority: AZURE_AUTHORITY,
        redirectUri: redirectUri || LOCALHOST_URL,
        postLogoutRedirectUri: window.location.origin + AZURE_ROOT_PATH,
      },
      cache: {
        cacheLocation: "sessionStorage",
      },
    },
    scopes: ["https://management.azure.com//.default openid profile email"],
  });
}

export function initializeAwsAuth(
  clientId: string,
  userPoolId: string,
  accountId: string,
  domain?: string,
  region?: string,
  callbackUrl?: string,
  signoutUrl?: string,
  scopes?: string[],
) {
  const redirectUri = new URL(window.location.href);
  return AwsAuth.init({
    domain: domain || "console-dev",
    region: region || "us-east-2",
    clientId,
    callbackUrl: callbackUrl || [redirectUri.origin, redirectUri.pathname].join(""),
    signoutUrl: signoutUrl || [redirectUri.origin, redirectUri.pathname].join(""),
    scopes: scopes || [],
    userPoolId,
    accountId,
  });
}

function getRegionName(): string {
  let params = new URLSearchParams(document.location.search);
  return params.get("region") || OCI;
}

function restoreRouteCallback() {
  const redirectPath = localStorage.getItem(SessionStorage.URL_PATH);
  console.debug("Redirected Path", redirectPath);
  window.history.pushState(null, "", redirectPath || "/");
}

function log(realm: string, logLevel: LogLevel, message: string) {
  Log.info(`realm ${realm} message ${message}`);
}

function sendMetric(realm: string, name: string, value: number) {
  emitEvent(name, value);
}

export function initializeDuploAuth(tenantName?: string) {
  const redirectUri = window.location.href;
  const regionName = getRegionName();
  const authorizationServiceUrl = () => buildAuthorizationUrl(regionName);
  const landingUrl = buildConsoleUrl(regionName);
  const identityServiceUrl = buildIdentityUrl(regionName);
  const loginServiceUrl = new URL(buildLoginUrl(regionName));
  window.sessionStorage.setItem(SESSION_URL, redirectUri);
  const initConfig: AuthConfig = {
    restoreRouteCallback,
    authorizationServiceUrl,
    landingUrl,
    identityServiceUrl,
    loginServiceUrl: loginServiceUrl.toString(),
    log,
    sendMetric,
    soupParameters: tenantName
      ? {
          tenant: tenantName,
        }
      : {},
  };
  if (isLocalhostEnv()) {
    initConfig.getRedirectUrlOverride = () => {
      return "https://localhost:3000";
    };
  }
  return Auth.init(initConfig);
}

export async function doIdentitySignOut() {
  try {
    localStorage.setItem(SessionStorage.URL_PATH, "/");
    sessionStorage.setItem(SessionStorage.AUTHENTICATED, "false");
    const redirectUri = isLocalhostEnv()
      ? "https://signup-pintlab.multicloud.oracle.com"
      : window.location.origin;
    await Auth.instance.logOut({ consoleHost: redirectUri });
  } catch (e) {
    const redirectUrl = `${window.location.protocol}//${window.location.host}`;
    Log.error(`Failed to successfully sign out due to error: ${e}\n Redirecting to ${redirectUrl}`);
    window.location.replace(redirectUrl);
  }
}

export async function initializeGcpAuth() {
  const gcpClientId = getGcpClientId();
  const ociIdentityToken =
    await OraclePublicCloudAuthenticatorSingleton.getInstance().getIdentityToken();
  saveQueryStringToSessionStorage(true);
  let redirectUri = window.location.href;
  const urlParts = redirectUri.split("?");
  if (urlParts.length > 1) {
    redirectUri = urlParts[0];
  }
  const redirectUrl = new URL(redirectUri);
  window.sessionStorage.setItem(SESSION_URL, redirectUri); // Storing the original URL in session storage to help rebuild the search parameters once redirected back.
  return GcpAuth.init({
    clientId: gcpClientId,
    redirectUri:
      [redirectUrl.origin, redirectUrl.pathname].join("") || LOCALHOST_URL + GcpPaths.ROOT_PATH,
    signoutUrl: window.location.origin + GcpPaths.ROOT_PATH,
    scopes: GCP_SCOPES,
    compartmentId: ociIdentityToken?.tenant,
    ociFetchApi: MulticloudTelemetryHelper.withApiMetrics(
      Auth.instance.getFetchApi(),
      MetricNames.GcpFetchAccessToken,
    ),
  });
}
