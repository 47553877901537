import "./GcpLoadingRoom.scss";
import { Alert, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import blackArrow from "../../images/black-arrow.png";
import gcpLogo from "../../images/gcp-logo.png";
import ociRedLogo from "../../images/oci-red-logo.png";
import { FooterContainer } from "../Footer/FooterContainer";
import HeaderContainer from "../Headers/HeaderContainer";

type GcpLoadingRoomProps = {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  showHeader?: boolean;
  hideSpinner?: boolean;
  errorPayload?: Error;
  showFooter?: boolean;
};

const GcpLoadingRoom = ({
  showHeader,
  title,
  subtitle,
  hideSpinner,
  errorPayload,
  showFooter = true,
}: GcpLoadingRoomProps) => {
  return (
    <div data-testid="gcp-loading-room" className="odsgcpLoadingRoomContainer">
      {showHeader && <HeaderContainer />}
      <Box
        data-testid="gcp-loading-room-logo"
        textAlign="center"
        className="odsgcpLoading-title"
        maxWidth="100%"
      >
        <Box>
          <img src={ociRedLogo} alt="OCI red logo" className="ociImage" />
          <img src={blackArrow} alt="black arrow" className="arrowImage" />
          <img src={gcpLogo} alt="GCP logo" className="gcpImage" />
        </Box>
        {!hideSpinner && (
          <div data-testid="gcp-loading-room-spinner">
            <CircularProgress />
          </div>
        )}
        <Typography variant="h2" data-testid="gcp-loading-room-title">
          {title}
        </Typography>
        <br />
        {subtitle && (
          <Typography variant="subtitle2" data-testid="gcp-loading-room-subtitle">
            {subtitle}
          </Typography>
        )}
        {errorPayload && (
          <div
            className="xs-block-margin odsgcpLoading-contain-width-65"
            data-testid="gcp-loading-error-alert"
          >
            <Alert severity="error">{errorPayload.message}</Alert>
          </div>
        )}
      </Box>
      {showFooter && <FooterContainer footerType="gcp" />}
    </div>
  );
};

export default GcpLoadingRoom;
